import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import { formatDate } from "../../../utils/formatDate";

import './style.css'

export function DatePickerComponent(props) {
  const { date, setDate, isTimePicker = false, disableOutsideTwoDates = [], hideOnScroll = false, calendarPosition = 'bottom-center' } = props;

  const format = isTimePicker ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY"
  const listPlugins = isTimePicker ? [<TimePicker position="bottom" />] : []

  return (
    <div className='datepicker-component--container'>
      <DatePicker
        inputClass={'custom-input'}
        value={date}
        onChange={(value) => setDate(value)}
        format={format}
        plugins={listPlugins}
        calendarPosition={calendarPosition}
        hideOnScroll={hideOnScroll}
        inputMode="none"
        mapDays={({ date }) => {
          if (disableOutsideTwoDates.length > 1) {
            if (date.format('DD') < formatDate(disableOutsideTwoDates[0], 'dd') || date.format('DD') > formatDate(disableOutsideTwoDates[1], 'dd') ) return {
              disabled: true,
              style: { color: "#ccc" },
              onClick: () => {}
            }
          }
        }}
      />
    </div>
  )
}