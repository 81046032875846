import logo from "./../assets/images/BipiLogoBlack.svg";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from "react-share";

import { copyToClipboard } from "../utils/copyToClipboard";

export function ShareModal(props) {
  const { article } = props;
  const shareUrl = window.location.href;
  
  return (
    <div className="modal modal-share fade position-absolute top-50 start-50 translate-middle" id="shareModal" tabIndex="-1" aria-labelledby="authModalLabel" aria-hidden="true" modal="show">
      <div className="modal-dialog modal-sm">
        <div className="modal-content p-2">
          <div className="modal-header">
            <img src={logo} alt="Bipi" width="30px" />
            <button type="button" className="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h6 className="mb-4">Share this link via</h6>
            <div className="d-flex justify-content-between mb-3">
              <div className="col">
                <FacebookShareButton url={shareUrl} quote={article.title} hashtag="Bipi">
                  <FacebookIcon size={35} round={true} />
                </FacebookShareButton>
              </div>
              <div className="col">
                <WhatsappShareButton url={shareUrl} title={article.title} separator=" - ">
                  <WhatsappIcon size={35} round={true} />
                </WhatsappShareButton>
              </div>
              <div className="col">
                <TwitterShareButton url={shareUrl} title={article.title} hashtag="Bipi" via="">
                  <TwitterIcon size={35} round={true} />
                </TwitterShareButton>
              </div>
              <div className="col">
                <TelegramShareButton url={shareUrl} title={article.title}>
                  <TelegramIcon size={35} round={true} />
                </TelegramShareButton>
              </div>
              <div className="col">
                <LinkedinShareButton url={shareUrl} title={article.title}>
                  <LinkedinIcon size={35} round={true} />
                </LinkedinShareButton>
              </div>
            </div>

            <h6 className="mt-4">Or copy link</h6>
            <div className="input-group mb-3">
              <input type="text" className="form-control" style={{ fontSize: "14px" }} readOnly value={shareUrl} id="" />
              <button
                onClick={() => {
                  copyToClipboard(shareUrl);
                  alert("Copied the link: " + shareUrl);
                }}
                className="btn btn-dark"
                type="button"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="bottom"
                data-bs-content="Bottom popover"
                style={{ fontSize: "14px" }}
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
