
import Modal from 'react-bootstrap/Modal';

import './style.css'

export default function ModalComponent(props) {
  const { showModal, handleClose, title, children, centered = false, isCustomWidth = false, isMiniModal = false, keyboard = false, noHeader = false, noPadding = false } = props;

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop={'static'} keyboard={keyboard} centered={centered} contentClassName={`${isMiniModal ? 'mini-modal' : 'modal-general-content'} ${isCustomWidth ? 'w-full' : ''} ${noPadding && 'no-padding'}`} dialogClassName={isCustomWidth ? 'w-full' : ''}>
        {!noHeader && 
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        }

        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    </>
  )
}