import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import { Like, Save, Share, ShareModal } from "../components";
import blankImg from "../assets/images/404.svg";
import { truncateContent } from "../utils/truncateString.js";

const ArticleStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  
  .card-title .cart-text {
    font-family: "DM Sans", sans-serif;
  }

  .card-title {
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 1em;
  }
  .card-text {
    font-size: 11px;
  }
  .card-size {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 1em;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .card-title {
      font-size: 20px;
    }
    .card-text {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .card-title {
      font-size: 24px;
    }
    .card-text {
      font-size: 15px;
    }
  }

`;

export function CardArticleBig(props) {
  const { article } = props;
  const [like, setLike] = useState();

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  return (
    <ArticleStyle id="popular-articles">
      {/* big content */}
      <div className="card border-none" style={{ boxShadow: "none" }}>
        <h3 className="card-title">
          <a className="card-title link-focus" href={`/articles/${article.slug}`}>
            {article.title}{" "}
          </a>
        </h3>
        <div
          style={{
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <img src={isImage(article.image) ? article.image : blankImg} className="card-img-top" alt={article.imageCaption} />
        </div>
        <div className="card-body me-0 py-2 px-1">
          <div className="card-size">
            <div className="card-text" dangerouslySetInnerHTML={{ __html: article?.content }}/>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="card-text">
              {moment(article.updatedAt).format("MMM D, YYYY")} - <span className="fw-light">{article.readingTime.text}</span>
              </p>
            </div>

            <div className="d-flex">
              <div className="px-2 px-lg-3">
                <Like id={article.id} setLike={setLike}/>
              </div>
              <div className="px-2 px-lg-3">
                <Save id={article.id} />
              </div>
              <div className="px-2 px-lg-3" style={{ color: "#5A5A5A" }}>
                <Share />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareModal article={article} />
    </ArticleStyle>
  );
}
