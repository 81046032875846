import { Metadata, PostForm } from "../components";
import { useParams} from "react-router-dom";


export function Post() {
  const params = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const mode = queryParameters.get("mode");

  return (
    <div className="newPost mt-3">
      <div className="container">
        <Metadata page={window.location.href} titlePage={`${mode === 'add' ? 'New Post' : 'Update Post'} • Bipi World`} />

        <PostForm mode={mode} idArticle={params.id}/>
      </div>
    </div>
  );
}
