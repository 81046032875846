import styled from "styled-components";
import { useLocation } from "react-router-dom";

const StyledButton = styled.button`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  min-width:85px;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  text-decoration: "none";
  border: 1px solid black;
  background-color: black;
  font-size: 14px;

  &.black {
    background-color: black;
    color: white;
  }

  &:hover {
    color: white;
    background-color: black;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
`;

export function ButtonModal(props) {
  const location = useLocation();
  const { styles } = props

  return (
    <StyledButton type="button" className={`btn btn-dark ${styles ? styles : ''}`} href={props.url} data-bs-toggle="modal">
      {" "}
      {props.text}{" "}
    </StyledButton>
  );
}
