import { useEffect, useState } from "react"

import { ToggleSwitch } from "../../ToggleSwitch"
import TravelAgencyForm from "./../TravelAgency";
import { formatDate } from "../../../utils/formatDate";

export default function AccomodationForm(props) {
  const { name, handleInputChange, setIsTravelAgencyExist, extensionsData } = props

  const [visibility, setVisibility] = useState(false)
  const [updatedData, setUpdatedData] = useState(
    {
      lodging_name: '',
      lodging_type: '',
      lodging_checkin: formatDate(new Date(), 'yyyy-MM-dd\'T\'HH:mm'),
      lodging_checkout: formatDate(new Date(), 'yyyy-MM-dd\'T\'HH:mm'),
      lodging_room_number: '',
      lodging_address: '',
      lodging_notes: '',
      subData: [
        {
          lodging_travel_agency_name: '',
          lodging_travel_agency_email: '',
          lodging_travel_agency_phone: '',
          lodging_travel_agency_contact_name: '',
          lodging_travel_agency_code: '',
          lodging_travel_agency_url: ''
        }
      ],
    }
  )

  const types = [
    {
      value: 'Hotel',
      label: 'Hotel'
    },
    {
      value: 'Homestay',
      label: 'Homestay'
    },
    {
      value: 'Apartment',
      label: 'Apartment'
    },
  ]

  useEffect(() => {
    if (extensionsData && extensionsData.length > 0) setUpdatedData(extensionsData[0])
  }, [extensionsData])

  useEffect(() => {
    if (Object.keys(updatedData).length > 0) {
      let isTravelAgentNull = true
      Object.keys(updatedData).forEach(key => {
        if (key.startsWith('subData')) {
          const subDataArray = updatedData[key];
          subDataArray.forEach(obj => { // looping each key object
            Object.keys(obj).forEach(subKey => { // looping each key object value to check if not empty
              const value = obj[subKey];

              if (!value || value !== '') { // check value each key object
                isTravelAgentNull = false;
              }
            });
          });
        }
      })
      setVisibility(!isTravelAgentNull)
    }
  }, [updatedData, visibility])

  return(
    <div className='form-input-trip'>
      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Lodging Name</label>
          <input type="text" name='lodging_name' value={updatedData.lodging_name} onChange={handleInputChange} className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Type</label>
          <select name="lodging_type" value={updatedData.lodging_type} className="form-input-group form-input simple-select" onChange={handleInputChange}>
            <option value={null}>Choose</option>
            {types.map((type) => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
          {/* <input type="text" name='lodging_type' onChange={handleInputChange} className='form-input' /> */}
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Check In</label>
          <input type="datetime-local" name='lodging_checkin' value={updatedData.lodging_checkin} onChange={handleInputChange} className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Check Out</label>
          <input type="datetime-local" name='lodging_checkout' value={updatedData.lodging_checkout} onChange={handleInputChange} className='form-input' />
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Room Number</label>
          <input type="text" name='lodging_room_number' value={updatedData.lodging_room_number} onChange={handleInputChange} className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Address</label>
          <input type="text" name='lodging_address' value={updatedData.lodging_address} onChange={handleInputChange} className='form-input' />
        </div>
      </div>

      <div className='form-input-group'>
        <label htmlFor="">Notes</label>
        <input type="text" name='lodging_notes' value={updatedData.lodging_notes} onChange={handleInputChange} className='form-input' />
      </div>

      <>
        <div className="toggle-switch--container">
          <ToggleSwitch value={visibility} setValue={setVisibility} setIsTravelAgencyExist={setIsTravelAgencyExist}/>
          <span className="label">Travel Agency ?</span>
        </div>
        {visibility && <TravelAgencyForm name={name.toLowerCase()} visibility={visibility} setVisibility={setVisibility} handleInputChange={handleInputChange} updatedData={updatedData} />}
      </>
    </div>
  )
}