
import { Link } from "react-router-dom";
import styled from "styled-components";

const Card = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;


  .card-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-decoration:none;
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .reading-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.6);
    margin-top:-5px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {

  .card-title {
    font-size: 14px;
  }
  .reading-time {
    font-size: 12px;
  }

  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {

  .card-title {
    font-size: 16px;
  }
  .reading-time {
    font-size: 12px;
  }
  }
`;

export function TitleArticle(props) {
  const { subArticle } = props;

  return (
    <Card className="col-12 mt-3">
      <h4>
        <Link to={`/articles/${subArticle.slug}`} className="card-title link-focus">{subArticle.title} </Link>
      </h4>
      <p className="reading-time">
        {subArticle.readingTime.text}
      </p>
    </Card>
  );
}
