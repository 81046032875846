import { useState } from 'react';
import './style.css'
import { IoTriangle } from "react-icons/io5";

import AgencyExtension from './Agency';
import TransportaionExtension from './Transportation';
import GuestExtension from './Guest';
import AccomodationExtension from './Accomodation';
import AttractionExtension from './Attraction';
import CulinaryExtension from './Culinary';
import AttachmentExtension from './Attachment';

export function ItineraryTripExtension(props) {
  const { trip, formatDate } = props
  
  const [tripAgencyExpand, setTripAgencyExpand] = useState(false)
  const [tripTransportationExpand, setTripTransportationExpand] = useState(false)
  const [tripGuestExpand, setTripGuestExpand] = useState(false)
  const [tripAccomodationExpand, setTripAccomodationExpand] = useState(false)
  const [tripAttractionExpand, setTripAttractionExpand] = useState(false)
  const [tripCulinaryExpand, setTripCulinaryExpand] = useState(false)
  const [tripAttachmentExpand, setTripAttachmentExpand] = useState(false)

  return (
    <>
      {/* {trip.tripTravelAgency.length > 0 &&
        <AgencyExtension trip={trip} IoTriangle={IoTriangle} tripAgencyExpand={tripAgencyExpand} setTripAgencyExpand={setTripAgencyExpand} />
      } */}
      {trip.tripGuest.length > 0 &&
        <GuestExtension trip={trip} IoTriangle={IoTriangle} tripGuestExpand={tripGuestExpand} setTripGuestExpand={setTripGuestExpand} formatDate={formatDate} />
      }
      {trip.tripTransportation.length > 0 && 
        <TransportaionExtension trip={trip} IoTriangle={IoTriangle} tripTransportationExpand={tripTransportationExpand} setTripTransportationExpand={setTripTransportationExpand} /> 
      }
      {trip.tripAccomodation.length > 0 &&
        <AccomodationExtension trip={trip} IoTriangle={IoTriangle} tripAccomodationExpand={tripAccomodationExpand} setTripAccomodationExpand={setTripAccomodationExpand} formatDate={formatDate} />
      }
      {trip.tripAttraction.length > 0 &&
        <AttractionExtension trip={trip} IoTriangle={IoTriangle} tripAttractionExpand={tripAttractionExpand} setTripAttractionExpand={setTripAttractionExpand} />
      }
      {trip.tripCulinary.length > 0 &&
        <CulinaryExtension trip={trip} IoTriangle={IoTriangle} tripCulinaryExpand={tripCulinaryExpand} setTripCulinaryExpand={setTripCulinaryExpand} />
      }
      {trip.tripAttachment.length > 0 &&
        <AttachmentExtension trip={trip} IoTriangle={IoTriangle} tripAttachmentExpand={tripAttachmentExpand} setTripAttachmentExpand={setTripAttachmentExpand} />
      }
    </>
  )
}