import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import searchIcon from "../assets/images/loupe.svg";


const StyledSearchBar = styled.form`
  width: 100%;
  background-color: #E9E9E9;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
  border: 1.5px solid transparent;
  height: 35px;
  backdrop-filter: blur(4px) saturate(180%);
  position: relative;

  margin: 1rem 0;

  .input {
    background: transparent;
    flex: 1;
    border: 0;
    outline: none;
    font-size: 16px;
    color: black;
  }

  ::placeholder {
    color: rgb(132, 148, 143);
  }

  
  @media only screen and (min-width: 768px) {
    background-color: rgba(255, 254, 254, 0.2);
    border: 1.5px solid black;
  }
`;

const StyledButton = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
`;

export function SearchBar(props) {

  const navigate = useNavigate();
  const [search, setSearch] = useState();

  const [updated, setUpdated] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const [page, setPage] = useState("");

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (search.length < 3) return;

      setIsSubmit(true);
      setUpdated(search);

      onSubmit(event)
    }
  };

  const onSubmit = (e) => {
    if ( (props.page === 'article' && !window.location.href.includes('/article-list')) || (props.page  !== 'article' && !window.location.href.includes('/news-list') )) {
      e.preventDefault()
    }
    const pageUrl = props.page === "article" ? "article-list" : "news-list";
    navigate(`/${pageUrl}?search=${search}`)
    
    setIsSubmit(false);
  }

  if (search === undefined && props.search) {
    setSearch(props.search);
  }

  return (
    <div className="mt-lg-0">
      <StyledSearchBar onSubmit={(e) => onSubmit(e)}>
        <input type="text" className="input" placeholder={`Search ${props.page}`} id="search" name="search" value={search} onChange={handleChange} onKeyDown={handleKeyDown} />
        <StyledButton disabled={isSubmit} type="submit">
          <img src={searchIcon} style={{ width: "20px", paddingBottom: "3px" }} alt="search" />
        </StyledButton>
      </StyledSearchBar>
    </div>
  );
}
