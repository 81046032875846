/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import { useState } from "react";

const NameLabel = styled.label`
  font-size: 1.3rem;
  padding-bottom: 0.7em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;
const LabelGallery = styled.label`
  width: 100%;
  height: 300px;
  border: 1px dashed grey;
  text-align: center;
  line-height: 2em;
  font-size: 1rem;
  background-color: #aaaaaa;
  color: white;

  &:hover {
    color: black;
  }
`;

const Button = styled.button`
  background: transparent;
  border-radius: 4px;
  margin: 0 0.5em;
  padding: 0.25em 0.75em;
  font-size: 0.9rem;
  border: 1px solid black;

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;
export function CropImage() {
  return (
    <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="0">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalToggleLabel2">
              Modal 2
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">Hide this modal and show the first with the button below.</div>
          <div className="modal-footer">
            <button className="btn btn-primary" data-bs-target="#uploadImage" data-bs-toggle="modal" data-bs-dismiss="modal">
              Back to first
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
