/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import {
  Metadata,
  CardArticleBig,
  Button,
  SearchBar,
  RecommendedBuddies,
  NoData,
  Paginate,
  LoadingScreen,
  CardItineraryHorizontal,
  CardArticleImage
} from "../components";
import "./style.css";
import * as articlesData from "../data/articles";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import defaultImageUser from "../assets/images/defaultUser.svg";

import Swal from "sweetalert2";
import { getItineraries } from "../data/itinerary";

import { HiDotsVertical } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";

const Layout = styled.div`
  .search h6 {
    font-size: 16px;
  }

  .articles {
    ${(props) => props.selectedViewMode === 'small-grid' && `padding: 0;`}
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    flex-direction: column;

    .articles {
        order: 2;
    }

    .search {
      order: 1;
      margin-bottom: 15px;
    }

    .search h6 {
      font-size: 16px;
      margin-right: 10px;
      margin-top: 23px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .search h6 {
      font-size: 18px;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  margin-bottom: 1.5rem;
  border-bottom: 1px solid transparent;

  ${(props) => props.selectedViewMode === 'small-grid' && `margin: 0 0.75rem 1.5rem;`}
`;

const TabButton = styled.button`
  background: transparent;
  outline: none;
  border: 1px solid transparent;
  border-bottom: ${(props) => props.isActive ? '2px solid black' : '2px solid transparent'};
  padding-bottom: 0.5rem;
  font-size: 1.3em;
  font-weight: ${(props) => props.isActive ? '600' : '400' };
`;

const Profile = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;

  .image {
    width: 120px;
    height: 120px;
    min-width: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .username {
    font-weight: 500;
    font-size: 16px;
  }
  .name {
    font-size: 24px;
    line-height: 21px;
    margin-bottom: 6px;
  }
  .text-occupation {
    font-weight: 600;
    font-size: 14px;
  }
  .text-bio {
    font-size: 14px;
  }
  a {
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin-top: 10px;
  }
  .bio {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .engagement > div {
    margin: 0 2em;
  }
  .number {
    font-size: 28px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 0 10px;
    flex-wrap: wrap;

    .image {
      width: 90px;
      height: 90px;
      min-width: 90px;
      margin-bottom: 15px;
      order: 1;
    }
    .profile {
      order: 3;

      & > div {
        width: 95%;
      }
    }
    .engagement {
      order: 2;
      margin-left: 2em;
    }
    .image > img {
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    .image {
      width: 100px;
      height: 100px;
      min-width: 100px;
    }
    .username {
      font-size: 16px;
    }
    .name {
      font-size: 18px;
    }
    .number {
      font-size: 22px;
    }
    .text-occupation {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
    .engagement > div {
      margin: 0 0.8em;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .image {
      width: 140px;
      height: 140px;
      min-width: 140px;
      margin-right: 20px;
    }
    .username {
      font-size: 18px;
      margin-top: 6px;
    }
    .name {
      font-size: 28px;
    }
    .number {
      font-size: 34px;
    }
    .text-occupation {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
    .engagement > div {
      margin: 0 2em;
    }
    .engagement > p {
      font-size: 28px;
    }
  }
`;

const SortButtonContainer = styled.div`
  display: flex;
  // align-items: center;
  justify-content: space-between;

  ${(props) => props.selectedViewMode === 'small-grid' && `padding: 0 0.75rem;`}
`;

const ButtonViewModeContainer = styled.div`
  position: relative;

  .view-option {
    position: absolute;
    z-index: 100;
    top: 30px;
    right: 0;

    width: 150px;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    display: ${(props) => props.showViewMode ? 'flex' : 'none' };
    flex-direction: column;
    gap: 10px;
  }
`;

const ButtonViewMode = styled.span`
  font-size: 1em;
  font-weight: ${ (props) => props.selectedViewMode === props.value  ? '600' : '500' };

  cursor: pointer;
  padding: 0.5rem 1rem;
  
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ArticlesContainer = styled.div`
  ${(props) => props.selectedViewMode === 'small-grid' 
    ? `
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2px;
      margin-top: 1rem;

      @media only screen and (min-width: 768px) {
        gap: 5px;
      }

      @media only screen and (min-width: 992px) {
        gap: 1rem;
        margin-top: 2rem;
      }
    `
    : `
      display: flex;
      flex-direction: column;
      align-items: ${props.isFetchingArticles ? 'center' : '' };
      margin-top: 2rem;
      gap: 1rem;
    `
  }

  margin-bottom: 2rem;
`;

export function UserArticles() {
  const params = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: '767px' })

  const queryParameters = new URLSearchParams(window.location.search);
  const search = queryParameters.get("search");
  const orderBy = queryParameters.get("orderBy");
  const [activeCategory, setActiveCategory] = useState();
  const [userProfile, setUserProfile] = useState(null);
  const [isFetchingUser, setIsFetchingUser] = useState(true)

  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "/" + params.username;
      const res = await articlesData.getArticlesUser(queryParam);

      if (res.code !== 200) {
        setIsFetchingUser(false)
        return Swal.fire({ title: 'Fetching Profile Failed', text: 'Please refresh page!', icon: 'error' })
      }

      setUserProfile(res.data);
      setIsFetchingUser(false)
    };
    if (params && params.username) getArticles();
  }, [params]);

  const [articlesUser, setArticlesUser] = useState([]);
  const [isFetchingArticles, setIsFetchingArticles] = useState(true);

  useEffect(() => {
    const getArticles = async () => {
      let queryParam;

      if (search !== null) {
        queryParam =
          "?createdByUser=" + params.username + "&limit=200&search=" + search;
      }
    
      if (search === null && orderBy === null) {
        queryParam = "?createdByUser=" + params.username + "&limit=200";
      }

      if (orderBy === "Newest") {
        queryParam = "?createdByUser=" + params.username + "&order=desc&limit=100";
      }
      if (orderBy === "Best") {
        queryParam = "?createdByUser=" + params.username + "&orderBy=likes&order=desc&limit=100";
      }
      if (orderBy === "Popular") {
        queryParam = "?createdByUser=" + params.username + "&orderBy=views&order=desc&limit=100";
      }

      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
        setIsFetchingArticles(false)
        return Swal.fire({ title: 'Fetching Data User Failed', text: 'Please refresh page!', icon: 'error' })
      }
      const data = res.data
      setArticlesUser(data);
      setIsFetchingArticles(false)
    };
    getArticles();
  }, [params.username, search, orderBy]);

  const countArticle = [];
  articlesUser.map((index) => countArticle.push(index));

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pageVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(countArticle.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [readMore, setReadMore] = useState(false);

  const [tabsItem, setTabsItem] = useState(['Articles', 'Itineraries'])
  const [currentData, setCurrentData] = useState('Articles')
  const [userItineraries, setUserItineraries] = useState([]);
  const [isFetchingItineraries, setIsFetchingItineraries] = useState(true)

  const [showViewMode, setShowViewMode] = useState(false)
  const [viewModeList, setViewModeList] = useState([
    {
      label: 'Small Grid',
      value: 'small-grid'
    },
    {
      label: 'Big Grid',
      value: 'big-grid'
    }
  ])
  const [selectedViewMode, setSelectedViewMode] = useState('big-grid')

  const handleChangeViewMode = (value) => {
    setSelectedViewMode(value)
    setShowViewMode(false)
  }

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : Math.sign(num) * Math.abs(num);
  }

  useEffect(() => {
    const fetchItineraries = async() => {
      const query = '?createdBy=' + params.username + '&limit=10'
      const result = await getItineraries(query, true)

      if (result.code !== 200) return setIsFetchingItineraries(false)

      const data = result.data

      setUserItineraries(data)
      setIsFetchingItineraries(false)
    }

    if (params && params.username) fetchItineraries()
  }, [params])

  if (isFetchingUser) return <div style={{ padding: '15rem 0 10rem', display: 'flex', justifyContent: 'center' }}><LoadingScreen /></div>

  return (
    <div
      className="articles d-flex flex-column"
      style={{ marginTop: "6.5rem", gap: "4.5rem", marginBottom: '5rem' }}
    >
      <Metadata page={window.location.href} userProfile={userProfile} isPublic={true} />
      <div className="d-flex flex-column gap-5">
        <Layout selectedViewMode={selectedViewMode} className="container">
          <div className="row g-4 justify-content-between">
            <div className="col-sm-12 col-md-7 articles">
              <Tabs selectedViewMode={selectedViewMode}>
                {tabsItem.map(data => (
                  <TabButton key={data} isActive={currentData === data} onClick={() => setCurrentData(data)}>{data}</TabButton>
                ))}
              </Tabs>

              {currentData.toLowerCase() === 'articles' 
                ? 
                  <>
                    <SortButtonContainer selectedViewMode={selectedViewMode}>
                      <div>
                        {["Newest", "Popular", "Best"].map((order, index) => (
                          <Button
                            key={index}
                            text={order}
                            colorBg={order === orderBy ? "black" : "white"}
                            colorText={order === orderBy ? "white" : "black"}
                            onClick={() => {
                              navigate("/user-articles/" + params.username + "?orderBy=" + order);
                              window.location.reload(false);
                            }}
                          />
                        ))}
                      </div>

                      <ButtonViewModeContainer showViewMode={showViewMode}>
                        <HiDotsVertical style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => setShowViewMode(!showViewMode)} />

                        <div className="view-option">
                          {viewModeList.map((data, idx) => <ButtonViewMode key={idx} selectedViewMode={selectedViewMode} value={data.value} onClick={() => handleChangeViewMode(data.value)}>{data?.label ?? ''}</ButtonViewMode>)}
                        </div>
                      </ButtonViewModeContainer>

                    </SortButtonContainer>

                    <ArticlesContainer isFetchingArticles={isFetchingArticles} selectedViewMode={selectedViewMode}>
                      {isFetchingArticles 
                        ? <LoadingScreen />
                        : articlesUser.length > 0
                          ? articlesUser
                              .slice(pageVisited, pageVisited + usersPerPage)
                              .map((article) => {
                                if (selectedViewMode === 'small-grid') 
                                  return <CardArticleImage key={article.id} data={article} height={isMobile ? '180px' : '300px'} noRounded={isMobile} noContent={isMobile} />

                                return <CardArticleBig key={article.id} article={article} />
                              })
                          : <NoData />
                      }
                    </ArticlesContainer>
                    {countArticle.length > 0 && <Paginate changePage={changePage} pageCount={pageCount} /> }
                  </>
                : <div className={`d-flex flex-column mt-4 gap-4 ${isFetchingItineraries ? 'align-items-center' : ''}`}>
                    {userItineraries.length > 0 
                      ? userItineraries.map((data) => <CardItineraryHorizontal key={data.id} itinerary={data} isNoProfile />)
                      : <NoData />
                    }
                  </div>
              }
            </div>

            <Profile className="col-md-4 profile">
              <div className="d-flex align-items-center">
                <div className="image">
                  <img
                    src={
                      userProfile?.traveler?.image
                        ? userProfile?.traveler?.image
                        : defaultImageUser
                    }
                    width="150px"
                    height="150px"
                    alt={userProfile?.traveler?.name}
                  />
                </div>

                <div className="d-flex engagement ">
                  <div className="text-center ">
                    <div className="number pb-1">
                      {userProfile?.articles?.length > 0
                        ? kFormatter(userProfile?.articles?.length)
                        : 0}
                    </div>
                    <p>Post</p>
                  </div>
                  <div>
                    <div className="number text-center pb-1">
                      {userProfile?.statistic?.totalLikes > 0
                        ? kFormatter(userProfile?.statistic?.totalLikes)
                        : 0}
                    </div>
                    <p>Likes</p>
                  </div>
                </div>
              </div>

              <div className="profile mt-3">
                <h1 className="name">
                  {userProfile?.traveler?.name}
                  <div className="username pt-1">{params?.username}</div>
                </h1>
                {userProfile.traveler?.bio?.length > 90 ? (
                  <p className="text-bio" style={{ display: "inline" }}>
                    {readMore !== true
                      ? userProfile.traveler?.bio?.substring(0, 90)
                      : userProfile.traveler?.bio}{" "}
                    <a
                      className="text-bio"
                      style={{ cursor: "pointer" }}
                      onClick={() => setReadMore(!readMore)}
                    >
                      {readMore === true ? "Read less" : "Read More"}
                    </a>
                  </p>
                ) : (
                  <p className="text-bio" style={{ display: "inline" }}>
                    {userProfile?.traveler?.bio}
                  </p>
                )}
                <div className="mt-4">
                  <SearchBar placeholder="Search Posts" page={`article`} />
                </div>
              </div>

              <div className="col-12 mt-5 d-none d-md-block">
                <div className="d-flex flex-column gap-3">
                  <RecommendedBuddies context="Blogs Like this One" />
                </div>
              </div>
            </Profile>
          </div>
        </Layout>
      </div>
    </div>
  );
}
