import { useEffect, useState } from "react"

import { ToggleSwitch } from "../../ToggleSwitch"
import TravelAgencyForm from "./../TravelAgency";

import { handleKeyOnlyNumber } from "../../../utils/currency";

export default function AttractionForm(props) {
  const { name, handleInputChange, setIsTravelAgencyExist, currencies, extensionsData } = props

  const [visibility, setVisibility] = useState(false)

  const types = [
    {
      value: 'Nature',
      label: 'Nature'
    },
    {
      value: 'Landscape',
      label: 'Landscape'
    },
    {
      value: 'Beach',
      label: 'Beach'
    },
    {
      value: 'Travel',
      label: 'Travel'
    },
  ]

  const [updatedData, setUpdatedData] = useState(
    {
      activities_name: '',
      activities_type: '',
      activities_currency: '',
      activities_price: '',
      activities_address: '',
      activities_notes: '',
      subData: [
        {
          activities_travel_agency_name: '',
          activities_travel_agency_email: '',
          activities_travel_agency_phone: '',
          activities_travel_agency_contact_name: '',
          activities_travel_agency_code: '',
          activities_travel_agency_url: ''
        }
      ],
    }
  )

  useEffect(() => {
    if (extensionsData && extensionsData.length > 0) setUpdatedData(extensionsData[0])
  }, [extensionsData])

  useEffect(() => {
    if (Object.keys(updatedData).length > 0) {
      let isTravelAgentNull = true
      Object.keys(updatedData).forEach(key => {
        if (key.startsWith('subData')) {
          const subDataArray = updatedData[key];
          subDataArray.forEach(obj => { // looping each key object
            Object.keys(obj).forEach(subKey => { // looping each key object value to check if not empty
              const value = obj[subKey];

              if (!value || value !== '') { // check value each key object
                isTravelAgentNull = false;
              }
            });
          });
        }
      })
      setVisibility(!isTravelAgentNull)
    }
  }, [updatedData])

  return(
    <div className='form-input-trip'>
      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Name</label>
          <input type="text" name='activities_name' value={updatedData.activities_name} onChange={handleInputChange} className='form-input' required />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Type</label>
          <select name="activities_type" value={updatedData.activities_type} className="form-input-group form-input simple-select" onChange={handleInputChange} required>
            <option value={null}>Choose</option>
            {types.map((type) => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
          {/* <input type="text" name='activities_type' onChange={handleInputChange} className='form-input' /> */}
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Currency</label>
          <select name="activities_currency" value={updatedData.activities_currency} className="form-input-group form-input simple-select" onChange={handleInputChange} required>
            <option value={null}>Choose</option>
            {currencies.map((type) => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
          {/* <input type="text" name='activities_currency' onChange={handleInputChange} className='form-input' /> */}
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Price</label>
          <input type="text" name='activities_price' value={updatedData.activities_price} onChange={handleInputChange} onKeyDown={handleKeyOnlyNumber} defaultValue={"0"} className='form-input' required/>
        </div>
      </div>

      <div className='form-input-group w-full'>
        <label htmlFor="">Address</label>
        <input type="text" name='activities_address' value={updatedData.activities_address} onChange={handleInputChange} className='form-input' required />
      </div>

      <div className='form-input-group'>
        <label htmlFor="">Notes</label>
        <input type="text" name='activities_notes' value={updatedData.activities_notes} onChange={handleInputChange} className='form-input' required />
      </div>

      <>
        <div className="toggle-switch--container">
          <ToggleSwitch value={visibility} setValue={setVisibility} setIsTravelAgencyExist={setIsTravelAgencyExist} />
          <span className="label">Travel Agency ?</span>
        </div>
        {visibility && <TravelAgencyForm name={name.toLowerCase()} visibility={visibility} setValue={setVisibility} handleInputChange={handleInputChange} updatedData={updatedData} />}
      </>
    </div>
  )
}