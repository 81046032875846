import { useState, useEffect } from "react";
import * as articlesData from "../data/articles";
import { Link } from "react-router-dom";
import defaultImageUser from "../assets/images/defaultUser.svg";
import styled from "styled-components";
import { NoData, LoadingScreen } from "../components"

const Title = styled.h4`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  padding-bottom: 0.5em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
`;

const Card = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  padding-bottom: 0.5em;
  img {
    width: 50px;
    height: 50px;
  }

  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  p {
    font-size: 12px;
    margin-top: -10px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    p {
      font-size: 14px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    p {
      font-size: 16px;
    }

    img {
      width: 65px;
      height: 65px;
    }
  }
`;

export const RecommendedBuddies = (props) => {
  const [contributors, setContributors] = useState([]);
  const [isFetching, setIsFetching] = useState(true)
  
  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  useEffect(() => {
    const getContributors = async () => {
      const queryParam = "&limit=6&random=true";
      const res = await articlesData.getContributors(queryParam);

      if (res.code !== 200) {
        setIsFetching(false)
      }
      setContributors(() => res.data);
      setIsFetching(false)
    };
    getContributors();
  }, []);

  return (
    <div className="col-12 pt-5">
      <Title>Suggested for you</Title>
      <Card className="d-flex flex-column gap-3">
        {!isFetching 
          ? contributors.length
            ? contributors.map((contributor, index) => (
                <div key={contributor.id} className="d-flex align-items-center  gap-3">
                  <div>
                    <img src={isImage(contributor?.traveler?.image) ? contributor?.traveler?.image : defaultImageUser} className="rounded-circle" alt={contributor?.traveler?.imageCaption} width="66px" height="66px" />
                  </div>
                  <div className="card-text">
                    <h6>
                      <Link to={`/user-articles/${contributor.username}`} className="text-decoration-none link-focus">
                        <span className="fw-bolde">{contributor.traveler?.name ?? "no name"}</span>
                      </Link>
                    </h6>
                    <p>{contributor.traveler?.bio ?? "no bio"}</p>
                  </div>
                </div>
              ))
            : <NoData />
          : <div className="d-flex justify-content-center"><LoadingScreen /></div>
        }
      </Card>
    </div>
  );
};
