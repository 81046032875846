import { useEffect } from "react"
import { Link } from "react-router-dom";

import styled from "styled-components";
import moment from "moment";

import blankImg from "../assets/images/404.svg";
import { ImageComponent } from '../components'
import { checkIsImage } from "../utils/image-check";

const DeleteButton = styled.i`
  cursor: pointer;
  color: black;
  font-size: 12px;
  &:hover {
    color: #de493c;
  }
  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;
const ChartButton = styled.i`
  cursor: pointer;
  color: black;
  font-size: 12px;

  &:hover {
    color: #4b6cc9;
  }
  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;
const EditButton = styled.i`
  cursor: pointer;
  color: black;
  font-size: 12px;

  &:hover {
    color: #c4701b;
  }
  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;

const RestoreButton = styled.button`
  // cursor: pointer;
  color: black;
  // font-size: 12px;
  border: 0;
  background: red;
  padding: 0;
  height: 0;
  
  position: relative;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    width: fit-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0.5rem;
    position: absolute;
    z-index: 1;
    // bottom: 125%;
    left: 50%;
    // margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  
  @media only screen and (min-width: 768px) {
    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;

const ArticleStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;

  p .card-title .cart-text {
    font-family: "DM Sans", sans-serif;
  }
  .card-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
  }
  .image {
    width: 45%;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 10px;
  }

  .image > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }

  .card-body {
    width: 55%;
    padding: 1rem 0.5rem;
  }

  .card-title {
    font-size: 12px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-text {
    font-size: 9px;
    margin-top: -5px;
  }
  .content {
    font-size: 10px;
    text-justify: inter-word;
  }
  .button-action {
    margin-top: -9px;
  }

  .btn-style {
    height: fit-content;
    background-color: transparent;
    padding: 0;
  }

  @media only screen and (min-width: 400px) {
    .image {
      width: 40%;
    }
  
    .card-body {
      width: 60%;
    }
  }

  
  @media only screen and (min-width: 400px) {
    .image {
      width: 40%;
    }
  
    .card-body {
      width: 60%;
    }
  }

  
  @media only screen and (min-width: 450px) {

    .card-title {
      font-size: 14px;
    }

    .card-text {
      font-size: 10px;
      margin-top: 0;
    }

    .content {
      line-height: 12px;
    }
  }

  @media only screen and (min-width: 550px) {

    .image {
      width: 35%;
    }
  
    .card-body {
      width: 65%;
    }

    .card-title {
      font-size: 16px;
    }

    .card-text {
      font-size: 12px;
      margin-top: 0;
    }

    .content {
      line-height: 12px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {

    .button-action {
      margin-top: 0;
    }

  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {

    margin-bottom: 15px;

    .image {
      width: 25%;
    }

    .card-body {
      width: 75%;
      padding: 0.3rem 0.5rem;
    }

    .card-title {
      font-size: 20px;
    }

    .card-text {
      font-size: 14px;
    }

    .content {
      font-size: 16px;
      line-height: 18px;
    }

    .card-action {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;
    }
  }
`;

export function CardUserPosts(props) {
  const { article, deleteArticle, comment, type, restoreArticle, setDeleteBeforeDate } = props;
  const date = new Date(article?.updatedAt);
  const dateComment = new Date(comment?.updatedAt);

  useEffect(() => {
    if (setDeleteBeforeDate && type !== 'comments') {
      setDeleteBeforeDate(moment(article?.deletedAt).add(30, 'days').format('MMM, Do YYYY'))
    }
  }, [setDeleteBeforeDate, article?.deletedAt, type])

  return (
    <ArticleStyle>
      <div className="image">
        <a href={type !== "deleted" && type !== 'comments' ? type !== 'draft' ? `/articles/${article?.slug}` : `/articles/preview/${article?.slug}` : `/articles/${comment?.article?.slug}?comment=${comment?.id || ''}`}>
          {type !== "comments" ? (
            <ImageComponent
              src={article.thumbnail && checkIsImage(article?.thumbnail) ? article.thumbnail : article.image && checkIsImage(article?.image) ? article?.image : blankImg}
              alt={article?.imageCaption}
            />
          ) : (
            <ImageComponent
              src={comment.article && comment.article.thumbnail && checkIsImage(comment.article.thumbnail) 
                ? comment.article.thumbnail
                : comment.article && comment.article.image && checkIsImage(comment.article.image)
                  ? comment.article.image
                  : blankImg}
              className="me-3"
              alt={comment?.article?.imageCaption}
            />
          )}
        </a>
      </div>

      <div className="card-body">
        <div className="card-content">
          <h3 className="card-title ">
            <span>
              {type === "comments" && <Link to={`/articles/${comment?.article?.slug}?comment=${comment?.id}`} className="card-title">{comment.comment}</Link> }
              {type !== "comments" && article?.title ? (
                <Link to={type !== "deleted" ? type !== 'draft' ? `/articles/${article?.slug}` : `/articles/preview/${article?.slug}` : ''} className="card-title">
                  {article?.title}
                </Link>
              ) : (
                ""
              )}
            </span>
          </h3>

          <div className="content d-block mt-2">
            {type === "comments" && "Commented on " + comment.article?.title} {comment?.isSubComment ? " In response to a comment by " + comment?.replyToUser?.traveler?.name : ''}
            {type !== "deleted" && article?.content ? <div dangerouslySetInnerHTML={{__html: article?.content }} /> : ''}
          </div>
        </div>

        <div className="card-action">
          <h6 className="card-text pt-2">
            {type === "published" ? "Publised " : "Last edited "}
            {type !== "comments"
              ? date.getTime() / 1000 > 604800
                ? "on " + moment(article?.updatedAt).fromNow() + " - "
                : "about " + moment(article?.updatedAt).format("MMM D, YYYY") + " - "
              : dateComment.getTime() / 1000 > 604800
              ? "on " + moment(comment?.updatedAt).fromNow()
              : "about " + moment(comment?.updatedAt).format("MMM D, YYYY")}

            {article?.readingTime?.text ? article?.readingTime.text : ""}
          </h6>

          <div className="d-flex justify-content-end button-action">
            {type !== "deleted" && (
              <>
                {type !== 'comments' && (
                  <>
                    <a href={`/post/${article?.id}?mode=edit`} className="me-3">
                      <EditButton className="fa-solid fa-pen-to-square"></EditButton>
                    </a>
                    <button type="button" onClick={() => deleteArticle(article?.id)} className="me-3 border-0 btn-style">
                      <DeleteButton className="fa-solid fa-trash "></DeleteButton>
                    </button>
                  </>
                )}
              </>
            )}
            {type === "published" && (
              <a href={`/statistic?id=${article?.id}`}>
                <ChartButton className="fa-solid fa-chart-column"></ChartButton>
              </a>
            )}
            {type === "draft" && (
              <a href={`/articles/preview/${article?.slug}`}>
                <ChartButton className="fa fa-eye"></ChartButton>
              </a>
            )}
            {type === "deleted" && (
              <RestoreButton onClick={() => {
                if (restoreArticle !== undefined) restoreArticle(article?.id)
              }}>
                <i className="fa-regular fa-window-restore"></i>
                <div className="tooltip-text">Restore</div>
              </RestoreButton>
            )}
          </div>
        </div>
      </div>
    </ArticleStyle>
  );
}
