import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { id, enUS } from 'date-fns/locale'

import "react-big-calendar/lib/css/react-big-calendar.css";
import './style.css'

import EventActivity from './event'

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export default function CalendarDay(props) {
  const { activeDate, setActiveDate, itineraryTrip, setCalendarView, formatDate } = props;

  const handleClickBack = () => {
    setCalendarView('month')
    setActiveDate(new Date())
  }

  return (
    <div className='custom-table-day-view-container'>
      <button type='button' className='btn-back' onClick={handleClickBack}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </button>
      <h5 className='title mb-3'>{formatDate(activeDate, 'd, MMMM yyyy')}</h5>

      <>
        <Calendar
          localizer={localizer}
          toolbar={false}
          events={itineraryTrip}
          view='day'
          defaultDate={activeDate ? new Date(activeDate) : new Date()}
          onView={() => {}}
          className='react-big-calendar-custom'
          eventPropGetter={(events) => {
            const backgroundColor = events.colorEvent ? events.colorEvent : 'blue';
            const border = '1px solid rgba(0, 0, 0, 0.3)';
            return { style: { backgroundColor, border } }
          }}
          components={{
            event: EventActivity
          }}
          dayLayoutAlgorithm={`no-overlap`}
        />
      </>
    </div>
  );
}