import { useState, useEffect } from "react";
import { CommentForm } from "../components";
import defaultImageUser from "../assets/images/defaultUser.svg";
import moment from "moment";
import styled from "styled-components";
import * as usersData from "../data/users";
import * as commentsData from "../data/comments";

const Style = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  
  padding: 0.5rem;

  .text-action {
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
    font-size: 9px;
    font-weight: 400;
    cursor: pointer;
    color: #6d6d6d;
  }
  .text-name {
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    font-size: 11px;
  }
  .text-comment {
    font-family: "DM Sans", sans-serif;
    margin-bottom: 2px;
    font-size: 12px;
  }
  img {
    width: 2rem;
    height: 2rem;
  }
  .reply {
    margin-left: 2rem;
  }
  .reply-form {
    padding-left: 2.5rem;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .text-action {
      font-size: 11px;
    }
    .text-name {
      font-size: 13px;
    }
    .text-comment {
      font-size: 14px;
    }
    img {
      width: 2.25rem;
      height: 2.25rem;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .text-action {
      font-size: 13px;
    }
    .text-name {
      font-size: 15px;
    }
    .text-comment {
      font-size: 16px;
    }
    img {
      width: 2.75rem;
      height: 2.75rem;
    }
    .reply {
      margin-left: 2.75rem;
    }
    .reply-form {
      padding-left: 2.8rem;
    }
  }

`;

export function Comment({ mainCommentIId, updateComment, comment, addComment, activeComment, setActiveComment, currentId, userId, deleteComment }) {
  const fiveMinutes = 100000000000;
  const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;
  const canReply = userId ? Boolean(userId) : null;
  const canEdit = userId ? userId === comment.createdBy && !timePassed : null;
  const canDelete = userId ? userId === comment.createdBy : null;
  const isReplying = activeComment && activeComment.type === "replying" && activeComment.id === comment.id;
  const isEditing = activeComment && activeComment.type === "editing" && activeComment.id === comment.id;
  const subComment = Boolean(comment.isSubComment === true);
  const mainCommentId = subComment === true ? comment.mainCommentId : comment.id;
  const replyTo = subComment === true ? comment.replyTo : comment.userId ? comment.userId : null;
  const isSubComment = true;
  const date = new Date(comment.updatedAt);
  const token = JSON.parse(localStorage.getItem("tokens"))?.data?.access_token;
  const [userProfile, setUserProfile] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await usersData.getUsersProfile().then((response) => setUserProfile(response.data));
      return response;
    }
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const [like, setLike] = useState();
  const h = comment?._count?.commentLikes;

  const likeComment = async (commentId) => {
    await commentsData.likeComment(commentId)
      .then((comment) => {
        // if (comment.data?.isLike === false) {
        //   setLike(h - 1);
        // } else if (comment.data?.isLike === true) {
        //   setLike(like + 1);
        // } else {
        //   console.log({comment})
        // }
        //  // console.log(like);
        setLike(comment.data.commentLikes);
      })
      .catch((error) => {
        console.log({error})
        // alert(error.response?.data);
      });
  };

  return (
    <Style className="comment mb-1" id={comment.id}>
      <div>
        <div className="d-flex gap-2 gap-md-3">
          {comment?.user?.traveler?.image === undefined ? (
            <img src={userProfile?.data?.traveler?.image} className="rounded-circle" alt={comment?.user?.traveler?.name} />
          ) : (
            <img src={comment?.user?.traveler?.image !== "" && comment?.user?.traveler !== null ? comment?.user?.traveler?.image : defaultImageUser} className="rounded-circle" alt={comment?.user?.traveler?.name} />
          )}

          <div style={{ width: "100%" }}>
          <div className="d-flex align-items-center">
            <a href="#" className="text-decoration-none text-name">
              {comment?.user?.traveler?.name}
            </a>
            {comment.replyTo !== null && comment.replyTo !== mainCommentId && (
              <a href="#" className="ps-1 text-decoration-none text-name fw-normal">
                <span style={{ color: "#6d6d6d" }}>{" @" + comment?.replyToUser?.traveler?.name}</span>
              </a>
              
            )}
            <p
                className="text-action m-0 ms-2"
                style={{
                  color: "#90949C",
                }}
              >
                {date.getTime() / 1000 > 604800 ? moment(comment.updatedAt).fromNow() : moment(comment.updatedAt).format("MMM D, YYYY")}
              </p>
          </div>
            

            {!isEditing && <p className="text-comment">{comment.comment}</p>}
            {isEditing && <CommentForm submitLabel="Update" initialText={comment.comment} handleSubmit={(text) => updateComment(text, comment.id, isSubComment, mainCommentId)} handleCancel={() => setActiveComment(null)} />}

            <div className="d-flex gap-2 mb-2">
              <a onClick={() => likeComment(comment.id)} className="text-action p-0 ms-0">
                <span className="pe-1">{like === undefined ? (comment?._count?.commentLikes ? comment?._count?.commentLikes : 0) : like}</span>
                Like
              </a>
              {canReply && (
                <a onClick={() => setActiveComment({ id: comment.id, type: "replying" })} className="text-action">
                  Reply
                </a>
              )}
              {canEdit && (
                <a onClick={() => setActiveComment({ id: comment.id, type: "editing" })} className="text-action">
                  Edit
                </a>
              )}
              {canDelete && (
                <a onClick={() => deleteComment(comment.id)} className="text-action">
                  Delete
                </a>
              )}
              
            </div>
          </div>
        </div>

        <div className="row reply-form">{isReplying && <CommentForm submitLabel="Reply" handleCancel={() => setActiveComment(null)} handleSubmit={(text) => addComment(text, isSubComment, mainCommentId, replyTo)} />}</div>
      </div>

      {comment?.subComments?.length
        ? comment?.subComments
            ?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .map((reply, index) => (
              <div className="px-3 reply" key={index}>
                <Comment
                  comment={reply}
                  replies={reply.mainCommentIId}
                  userId={userId}
                  deleteComment={deleteComment}
                  addComment={addComment}
                  activeComment={activeComment}
                  setActiveComment={setActiveComment}
                  updateComment={updateComment}
                  likeComment={likeComment}
                />
              </div>
            ))
        : ""}
    </Style>
  );
}
