import { TOKEN_SECRET } from "../config/config";
import moment from 'moment';

export function generateTokenPrivate() {
  var bcrypt = require('bcryptjs');

  const tokenPrivate = moment(new Date()).format('YYYYMMDD') + TOKEN_SECRET;
  const salt = bcrypt.genSaltSync(12);
  const tokenHashed = bcrypt.hashSync(tokenPrivate, salt);

  return tokenHashed;
}