import axios from "axios";
import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { BASE_URL } from "../../config/config";

const AuthContext = createContext();
export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(() => {
    if (localStorage.getItem("tokens")) {
      let tokens = JSON.parse(localStorage.getItem("tokens"));

      return jwt_decode(tokens.data.access_token);
    }
    return null;
  });

  const checkTokenExpiration = () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (tokens) {
      const expirationTime = jwt_decode(tokens.data.access_token).exp * 1000;
      const currentTime = Date.now();
      if (currentTime > expirationTime) {
        logout();
      }
    }
  };

  const login = async (payload) => {
    const res = await axios.post(`${BASE_URL}/auth/login`, payload);
    localStorage.setItem("tokens", JSON.stringify(res.data));
    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.access_token}`;
    setUser(() => jwt_decode(res.data.data.access_token));
  };

  const logout = async () => {
    // invoke the logout API call, for our NestJS API no logout API
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("tokens");
    setUser(null);
  };

  useEffect(() => {
    checkTokenExpiration()
  }, [])

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
}

export default AuthContext;
