export default function CulinaryExtension(props) {
  const { trip, IoTriangle, tripCulinaryExpand , setTripCulinaryExpand } = props;
  
  return(
    <div className='sub-extension-information--container'>
      <div className='btn-more-information--container no-margin'>
        <button type='button' className='btn-more-information' onClick={() => setTripCulinaryExpand(!tripCulinaryExpand)}>
          <IoTriangle className={`${tripCulinaryExpand && 'opened' }`}/>
        </button>
        <h5>Culinary</h5>
      </div>

      <div className='sub-extension-content--parent'>
        {trip.tripCulinary.map((data, index) => 
          <div key={data.id} className={`sub-extension-content--wrapper ${tripCulinaryExpand && 'visible'}`}>
            <div className='sub-extension-content--container'>
              <div className='extension-content'>
                <span className='full'>Name</span>
                <span>:</span>
                <span className='full'>{data.name}</span>
              </div>
              
              <div className='extension-content'>
                <span className='full'>Type</span>
                <span>:</span>
                <span className='full'>{data.type}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Address</span>
                <span>:</span>
                <span className='full'>{data.address}</span>
              </div>


              <div className='extension-content'>
                <span className='full'>Price</span>
                <span>:</span>
                <span className='full'>{data?.currency ?? ''} {data.price}</span>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  )
}