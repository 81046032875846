import styled from "styled-components";
import { Link } from "react-router-dom";
import blankImg from "../assets/images/404.svg";

import { ImageComponent } from "./Image/index";

const ArticleDescBottom = styled.div`
  width: 250px;
  line-height: 1px;
  overflow: hidden;
  padding: 0;

  p.card-text {
    font-size: 10px;
  }
  .reading-time {
    font-size: 9px;
  }
  .card-title {
    font-size: 11px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: black;
  }
  .inner {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background-color: grey;
    width: 100%;

    > a > img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {}

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    width: 270px;
    line-height: 5px;

    p.card-text {
      font-size: 16px;
    }
    .reading-time {
      font-size: 12px;
    }
    .card-title {
      font-size: 16px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 230px;

    p.card-text {
      font-size: 16px;
    }
    .reading-time {
      font-size: 13px;
    }
    // .card-title {
    //   font-size: 20px;
    // }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 270px;

    p.card-body {
      font-size: 12px;
    }
  }
`;

export function CardArticleDescBottom(props) {
  const { article } = props;

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  return (
    <ArticleDescBottom className="card mb-lg-5">
      <div className="inner">
        <a href={`/articles/${article.slug}`}>
          <ImageComponent src={isImage(article.thumbnail) ? article?.thumbnail : article?.image ? article?.image : blankImg} className="card-img-top" alt={props.title ?? "Bipi Article"} />
        </a>
      </div>

      <div className="card-body ps-2 ps-md-3 pe-1">
        <div className="d-flex justify-content-between">
          <p className="card-text fw-normal">
            <a className="name-user link-focus" href={`/user-articles/${article.user.username}`}>
              {article.user.traveler?.name || "not name"}
            </a>
          </p>
          <span className="reading-time d-none d-lg-block">{article.readingTime.text}</span>
        </div>

        <h3 className="card-title">
          <a className="link-focus" href={`/articles/${article.slug}`}>
            {article.title.length >= 60 ? article.title.slice(0, 60).concat("...") : article.title}
          </a>
        </h3>

        <span className="reading-time d-md-block d-lg-none">{article.readingTime.text}</span>
      </div>
    </ArticleDescBottom>
  );
}
