import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from "styled-components";

import './style.css'

export function PopupCampaign(props) {
  const { show, handleClose } = props;

  return (
    <Modal show={show} onHide={handleClose} size='md' contentClassName='background-image-popup-campaign' backdrop={'static'} centered>
      <button className='close-button-popup-campaign' onClick={handleClose}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" data-slot="icon" className='icon-close-popup-campaign'>
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>

      <div className='content-popup-campaign'>
        {/* image by https://unsplash.com/photos/a-person-standing-on-top-of-a-rocky-hill-gOqBe7ropxM */}
        <div>
          <h1>Welcome to Bipi</h1>
          <h5>Your virtual travel buddy</h5>
        </div>
        
        <button className='btn-explore-popup-campaign' type='button' onClick={handleClose} data-bs-toggle="modal" data-bs-target="#authModal">Explore</button>
      </div>
    </Modal>
  )
}