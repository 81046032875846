import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function getNotifications(query) {
  try {
    setHeaderToken();
    const notifications = await axios.get(`notifications` + query).then((response) => {
      return response.data;
    });
    return notifications;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function readNotification(id) {
  try {
    setHeaderToken();
    const notifications = await axios.patch(`notifications/${id}`).then((response) => {
      return response.data;
    });
    return notifications;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}
