import { useContext, useEffect, useState, useRef, forwardRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components'
import Swal from 'sweetalert2'
import { intervalToDuration } from 'date-fns';

import { CardItineraries, CardPopularDestination, ImageComponent, LoadingScreen, Metadata, ModalComponent, NoData, Paginate, SliderComponent } from '../components';
import { getItineraries } from '../data/itinerary';
import { getCountries } from '../data/articles';
import { formatDate, getDateTime } from '../utils/formatDate';
import AuthContext from '../components/shared/AuthContext';
import { useCallbackState } from '../hooks';

import { checkIsImage } from '../utils/image-check';
import blankImg from "./../assets/images/404.svg";

import { HiLocationMarker, HiOutlineSearch, HiArrowNarrowLeft, HiArrowNarrowRight, HiOutlineX } from "react-icons/hi";
import { FaBookOpen, FaPen, FaSearchLocation, FaHubspot } from "react-icons/fa";

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  width: 100%;
  // max-width: 1536px;
  
  // margin: auto;
  padding: 0 0 10rem;
  
  display: flex;
  flex-direction: column;
  background-color: #FEFDFF;

  h1 {
    font-size: 2.5em;
    font-weight: 600;
  }

  .sub-title {
    font-size: 1.2em;
    font-weight: 500;
  }

  .only-desktop {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    .only-desktop {
      display: block;
    }

    h1 {
      font-size: 3em;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 1580px) {}
`;

const MaxWidth = styled.div`
  padding: 0 1rem;

  
  @media only screen and (min-width: 510px) {
  }

  @media only screen and (min-width: 560px) {

  }

  @media only screen and (min-width: 768px) {

  }

  @media only screen and (min-width: 1024px) {
    padding: 0 5rem;
  }

  @media only screen and (min-width: 1280px) {
    padding: 0 7rem;
  }

  @media only screen and (min-width: 1536px) {
    padding: 0 10rem;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  
  @media only screen and (min-width: 1024px) {
    align-items: center;
  }
`;

const SearchInput = styled.input`
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: 0;
  width: 80%;

  
  @media only screen and (min-width: 768px) { 
    width: 100%;
  }
  
  @media only screen and (min-width: 1024px) {
    width: 150%;
  }
`;

const AddBtn = styled.a`
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background: transparent;
  border: 1px solid black;
  color: black;
  font-size: 1em;

  text-decoration: none;

  cursor: pointer;

  &:hover {
    background: black;
    color: white;
  }
`;

const ContentContainer = styled.div`
  margin-top: 2rem;

  display: ${(props) => props.data > 0 ? 'grid' : 'flex'};
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  row-gap: 2rem;
  column-gap: 2rem;

  
  @media only screen and (min-width: 510px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  @media only screen and (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const GridItem = styled.div`
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TagFlag = styled.span`
  padding: 0.4rem 0.7rem;
  margin: 0;

  border-radius 9999px;
  border: 1px solid black;

  font-weight: 600;
  cursor: pointer;
  font-size: 0.9em;
  
  min-width: 100px;
  max-width: 130px;
  text-align: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: gray;
    border-color: gray;
  }

  &.active {
    background: black;
    color: white;
  }

  @media only screen and (min-width: 768x) {
    padding: 0.5rem 1rem;
    font-size: 1em;

    min-width: 150px;
    max-width: 180px;
    text-align: center;
  }
`;

const LandingPage = styled.div`
  // background: url('https://images.unsplash.com/photo-1488747279002-c8523379faaa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background: url('https://images.unsplash.com/photo-1707344088547-3cf7cea5ca49?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  height: 500px;

  margin-bottom: 2rem;

  .content {
    padding: 2rem 1rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 10rem 0;
  }

  .title-itineraries {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    font-weight: 500;
    color: white;
    
    text-align: center;
  }

  .widget {
    // padding: 0.5rem 1.5rem;
    padding: 0.5rem 0.8rem 0.5rem 1.5rem;
    border-radius: 9999px;

    display: flex;
    align-items: center;
    gap: 1rem;

    background: white;

    width: 88%;
    margin-top: 0;
    
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    margin-top: 2.5rem;

    .where--container {
      display: flex;
      flex-direction: column;
      flex: 1;

      input {
        background: transparent;
        border: none;
      }
    }

    .date--container {
      // display: flex;
      display: none;
      flex-direction: column;
    }

    .title-widget {
      color: black;
      font-weight: 600;
      font-size: 0.9em;
      line-height: 1;
    }

    .text-widget {
      color: gray;
      font-weight: 400;
      font-size: 0.8em;

      cursor: pointer;
    }

    .icon-btn {
      background: black;
      color: white;
      border-radius: 9999px;

      // width: 35px;
      // height: 35px;

      padding: 8px;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      svg {
        width: 15px;
        height: 15px
      }
    }

    .text-single-line {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-decoration: none;
    }
  }

  @media only screen and (min-width: 390px) {
      .title-itineraries {
        font-size: 2em;
      }
  

    .widget {

      .where--container {
        flex: 1;
      }

      .date--container {
        flex: 1;
      }
    }
  }

  @media only screen and (min-width: 510px) {
    
    height: 650px;

    .content {
      padding: 5rem 2rem 0;
    }

    .widget {
      // padding: 0.5rem 1.5rem;
      margin-top: 3rem;

      .title-widget {
        font-size: 1.2em;
      }

      .text-widget {;
        font-size: 1em;
      }

      .icon-btn {
        // width: 40px;
        // height: 40px;
  
        svg {
          width: 25px;
          height: 25px
        }
      }
    }
  }

  @media only screen and (min-width: 560px) {
    .title-itineraries {
      font-size: 2.5em;
    }

    .widget {
      // padding: 0.5rem 2rem;
      width: 70%;
    }
  }

  @media only screen and (min-width: 630px) {
    
    .content {
      padding: 5rem 5rem 0;
    }

    .widget {
      width: 65%;
    }
  }

  @media only screen and (min-width: 768px) {
    height: 750px;
  
    .title-itineraries {
      font-size: 3em;
      // font-weight: 900;
    }

    .widget {
      width: 50%;
    }
  }

  @media only screen and (min-width: 1024px) {
    height: 1000px;

    .title-itineraries {
      font-size: 3.5em;
    }

    .widget {
      width: 35%;
    }
  }


  @media only screen and (min-width: 1580px) {
    .content {
      padding: 5rem 10rem 0;
      margin: 15rem 0;
    }

    // .widget {
    //   width: 20%;
    // }
  }
`;

const PopularDestination = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .header-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    .header-button--section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        background: black;
        color: white;
        padding: 0.5rem 1rem;

        border-radius: 9999px;
        cursor: pointer;
      }
    }
  }

  .slick-slider > .slick-list > .slick-track {
    display: flex;
    gap: 15px;
    margin: 1rem 0;

    .slick-slide > div {
      width: 100%;
    }
  }

  @media only screen and (min-width: 510px) {
    margin-top: 2rem;
    margin-bottom: 1rem;

    .header-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .header-button--section {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .slick-slider > .slick-list > .slick-track {
      gap: 1rem;
    }
  }
`;

const TopItineraries = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 1rem 0;
  margin-bottom: 5rem;

  .list-country {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;

    margin-top: 1rem;
  }

  
  @media only screen and (min-width: 768px) {
    .list-country {
      gap: 1rem;
    }
  }
`;

const TopItinerariesContainer = styled.div`
  display: ${(props) => (props.isFetching ? 'flex' : 'grid')};
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0.5rem;

  padding: 0;
  margin-top: 1.5rem;
  width: 100%;

  .row--1-column {
    grid-column: span 1;
  }

  .row--2-column {
    grid-column: span 3;
  }

  .row--3-column {
    grid-column: span 2;
  }

  @media only screen and (min-width: 510px) {
    gap: 1rem;
    padding: 1rem;
  }

  @media only screen and (min-width: 768px) {
    gap: 1.5rem;
  }
`;

const OurItineraries = styled.div`
  width: 100%;
  background: #EEF2F2;
  margin: 2rem 0;

  .inner-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 5rem 0;

    gap: 3rem;
  }

  .img-section {
    display: none;
  }

  .content-section {
    width: 100%;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;

    margin-top: 2.5rem;
  }

  .card-stories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    background: white;
    border-radius: 12px;
    padding: 2rem 0;

    p {
      margin: 0;
      padding: 0;
    }

    .text-info--wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .bg-icon {
    border-radius: 9999px;
    background: #D7E7E8;

    color: #048E8E;

    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 510px) {
    
    margin: 5rem 0;
  }

  @media only screen and (min-width: 630px) {
    .img-section {
      display: block;

      width: 100%;
      overflow: hidden;
      border-radius: 12px;
  
      height: 500px;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
  
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .inner-container {
      flex-direction: row;
      padding: 5rem 0;
      gap: 3rem;
    }

    .img-section {
      width: 50%;
    }

    .content-section {
      width: 50%;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .card-stories {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    
      padding: 2.5rem 0;

      .text-info--wrapper {
        align-items: start;
      }

    }
  }
`;

const WidgetForm = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    font-size: 1.2em;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    outline: none;

    margin-bottom: 1rem;
    width: 100%;
  }

  ul {
    padding: 0 1rem;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    gap: 10px;

    max-height: 300px;
    overflow-y: scroll;
    
    li {
      cursor: pointer;
      list-style: none;
      padding: 0.5rem 1.5rem;
      
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

      font-size: 1em;
      font-weight: 500;
    }
  }

`;

export function Itineraries() {
  const { user } = useContext(AuthContext)
  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const images = [
    {
      image: "https://images.unsplash.com/photo-1606240724602-5b21f896eae8?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1669316982093-b2ed20cc82ba?q=80&w=2097&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1676139292859-26b94000daf4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      image: "https://images.unsplash.com/photo-1482689860904-c5747d0c8995?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
      image: "https://images.unsplash.com/photo-1486912500284-6f2462ba07ea?q=80&w=2085&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }
  ];

  const [itineraries, setItineraries] = useCallbackState([])
  const [popularItineraries, setPopularItineraries] = useState([])
  const [topDestination, setTopDestination] = useState([])
  const [topDestinationImages, setTopDestinationImages] = useState(images)
  const [itinerariesInfo, setItinerarisInfo] = useState({
    destinationTrip: 0,
    itineraryCreator: 0,
    totalCategory: 0,
    tripAdventure: 0
  })

  const [querySearch, setQuerySearch] = useState('')

  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingPopularItineraries, setIsFetchingPopularItineraries] = useState(true);
  const [isFetchingImage, setIsFetchingImage] = useState(false)
  const [isFetchingItinerariesList, setIsFetchingItinerariesList] = useState(false)
  const [flagActive, setFlagActive] = useState('')

  const [showModalSearch, setShowModalSearch] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [filteredCountryList, setFilteredCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [searchCountry, setSearchCountry] = useState('')
  
  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 4;

  const pageCount = useMemo(() => Math.ceil((itineraries.length || 0) / dataPerPage), [itineraries.length]);
  
  const itineraryList = useMemo(() => {
    const start = pageNumber * dataPerPage;
    const end = start + dataPerPage;
    return itineraries.slice(start, end).map((data) => <CardItineraries key={data.id} data={data} />);
  }, [pageNumber, itineraries, dataPerPage]);

  
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleClickFlag = (data) => {
    if (flagActive === data.destinationCountry) {
      setFlagActive('')
      
      setIsFetchingImage(true);

      setTimeout(() => {
        setTopDestinationImages(images)
        setIsFetchingImage(false);
      }, 2000);

    } else {
      setFlagActive(data.destinationCountry)
      const selectImagesByCountry = topDestination.filter(val => val.destinationCountry === data.destinationCountry)
      const imageList = selectImagesByCountry.flatMap(data => {
        const itineraries = data.itineraries
        return itineraries.map(itinerary => ({ image: itinerary.thumbnail}))
      })

      setIsFetchingImage(true);

      setTimeout(() => {
        setTopDestinationImages(imageList);
        setIsFetchingImage(false);
      }, 2000);
    }
  }

  const handleClickCoutry = (data) => {
    setSelectedCountry(data.value)
    setShowModalSearch(false)
  }

  const handleClearSearchCountry = (e) => {
    e.stopPropagation();
    setSelectedCountry('')
  }

  const handleSearchWidget = () => {
    if (!selectedCountry) return Swal.fire({ title: 'Choose country first...', icon: 'warning'})

      const scrollToElement = document.getElementById('itineraries-section')
      if (scrollToElement) {
        scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        setQuerySearch(selectedCountry)
      }
  }

  useEffect(() => {
    const fetchItinerariesInfos = async() => {
      const query = '?limit=10&isLandingPage=true'
      const result = await getItineraries(query, true);

      if (result.code !== 200) {
        setIsFetching(false)
        return Swal.fire({title: 'Something went wrong', text:'Error Occured', icon: 'error'})
      }

      const topDestination = result.data.topDestination;
      const itinerariesDetailInfo = result.data.itinerariesInfo;

      setTopDestination(topDestination)
      setItinerarisInfo(itinerariesDetailInfo)

      setIsFetching(false)
    }

    const fetchPopularItineraries = async() => {
      const query = '?limit=10&popular=true'
      const result = await getItineraries(query, true);

      if (result.code !== 200) {
        setIsFetchingPopularItineraries(false)
        return Swal.fire({title: 'Something went wrong', text:'Error Occured', icon: 'error'})
      }

      const data = result.data;
      setPopularItineraries(data)
      setIsFetchingPopularItineraries(false)
    }

    const fetchingCountry = async() => {
      const query = '?limit=240'
      const result = await getCountries(query)
  
      if (result.code !== 200) {
        return setCountryList([])
      }
      const formatedCountry = result.data.length > 0 && result.data.map((item) => ({
        value: item.name,
        label: item.name,
      }))
  
      setCountryList(formatedCountry)
      setFilteredCountryList(formatedCountry)
    }

    fetchItinerariesInfos()
    fetchPopularItineraries()
    fetchingCountry()
  }, [])

  useEffect(() => {
    const fetchItineraries = async() => {
      if (querySearch.length > 0 && querySearch.length < 3) return;
      setIsFetchingItinerariesList(true)
      const query = `?limit=10${querySearch && `&q=${querySearch}`}`;
      const result = await getItineraries(query, true);

      if (result.code !== 200) {
        setIsFetchingItinerariesList(false)
        return Swal.fire({title: 'Something went wrong', text:'Error Occured', icon: 'error'})
      }
      
      const data = result.data;
      
      const addDayDiff = data.map((data) => {
        const itineraryStartDate = getDateTime(data.startDate)
        const itineraryEndDate = getDateTime(data.endDate)
        
        const itineraryDuration = intervalToDuration({
          start: new Date(itineraryStartDate.years, itineraryStartDate.months, itineraryStartDate.days, itineraryStartDate.hours, itineraryStartDate.minutes, itineraryStartDate.seconds),
          end: new Date(itineraryEndDate.years, itineraryEndDate.months, itineraryEndDate.days, itineraryEndDate.hours, itineraryEndDate.minutes, itineraryEndDate.seconds)
        })
        
        return {
          ...data,
          itineraryDuration: itineraryDuration,
        }
      })
      
      setItineraries(addDayDiff, () => { setIsFetchingItinerariesList(false) })
    }

    fetchItineraries()
  }, [querySearch])

  useEffect(() => {
    if (searchCountry.length > 0) {
      const filterCountry = countryList.filter((data) => data.value.toLowerCase().includes(searchCountry.toLowerCase().trim()))
      setFilteredCountryList(filterCountry)
    } else {
      setFilteredCountryList(countryList)
    }
  }, [searchCountry, countryList])

  return (
    <Container>
      <Metadata page={window.location.href} titlePage={`Itineraries • Bipi World`} isPublic={false} />
      
      <LandingPage>
        <div className='content'>
          <h1 className='title-itineraries'>Your Virtual Travel Buddy</h1>
          
          <div className='widget'>
            <div className='where--container'>
              <span className='title-widget'>Destination</span>
              <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <HiLocationMarker />
                <span className='text-single-line text-widget' onClick={() => setShowModalSearch(!showModalSearch)}>
                  {selectedCountry ? selectedCountry : 'Choose country....'} {selectedCountry && <HiOutlineX onClick={handleClearSearchCountry} style={{ width: 15, height: 15, color: 'red' }} />}
                </span>
              </span>
            </div>

            <div className='date--container'>
              <span className='title-widget'>Date</span>
              <span className='text-single-line text-widget'>{formatDate(new Date(), 'dd MMM yyyy')}</span>
            </div>

            <div className='icon-btn' onClick={handleSearchWidget}>
              <HiOutlineSearch />
            </div>
          </div>
        </div>
      </LandingPage>

      <MaxWidth>
        <PopularDestination>
          <div className='header-section'>
            <div className='header-title--section'>
              <h1>Popular Destination</h1>
              <p className='sub-title'>Explore our Popular Destination Created by Buddies</p>
            </div>

            <div className='header-button--section'>
              <span className='custom-prev-arrow--slider' onClick={() => { sliderRef.current.slickPrev() }}><HiArrowNarrowLeft style={{ width: 25, height: 25 }} /></span>
              <span className='custom-next-arrow--slider' onClick={() => { sliderRef.current.slickNext() }}><HiArrowNarrowRight style={{ width: 25, height: 25 }} /></span>
            </div>
          </div>

          {isFetchingPopularItineraries 
              ? <div className='w-100 d-flex justify-content-center'><LoadingScreen /></div>
              : popularItineraries.length > 0
                ? <SliderComponent forwardRef={sliderRef} settings={settings}>
                    {popularItineraries.map((data, idx) => <CardPopularDestination key={idx} data={data} />)}
                  </SliderComponent>
                : <div className='w-100 d-flex justify-content-center mt-5'><NoData /></div>
          }
        </PopularDestination>
      </MaxWidth>

      <OurItineraries>
        <MaxWidth>
          <div className='inner-container'>
            <div className='img-section'>
              <ImageComponent src="https://images.unsplash.com/photo-1519160558534-579f5106e43f?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Our Itineraries" />
            </div>

            <div className='content-section'>
              <h1>Our Itineraries Always <br /> Have Precious Things</h1>

              <div className='card-container'>
                <div className='card-stories'>
                  <span className='bg-icon'>
                    <FaBookOpen />
                  </span>
                  <div className='text-info--wrapper'>
                    <h3>{itinerariesInfo.tripAdventure > 100 ? '100+' : itinerariesInfo.tripAdventure}</h3>
                    <p>Trip Adventure</p>
                  </div>
                </div>

                <div className='card-stories'>
                  <span className='bg-icon'>
                    <FaPen />
                  </span>
                  <div className='text-info--wrapper'>
                    <h3>{itinerariesInfo.TopItinerariesContainer > 50 ? '50+' : itinerariesInfo.itineraryCreator}</h3>
                    <p>Itinerary Creator</p>
                  </div>
                </div>

                <div className='card-stories'>
                  <span className='bg-icon'>
                    <FaHubspot />
                  </span>
                  <div className='text-info--wrapper'>
                    <h3>{itinerariesInfo.destinationTrip > 100 ? '100+' : itinerariesInfo.destinationTrip}</h3>
                    <p>Destination Trip</p>
                  </div>
                </div>

                <div className='card-stories'>
                  <span className='bg-icon'>
                    <FaSearchLocation />
                  </span>
                  <div className='text-info--wrapper'>
                    <h3>{itinerariesInfo.totalCategory >= 25 ? '25+' : itinerariesInfo.totalCategory}</h3>
                    <p>Category Trip</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MaxWidth>
      </OurItineraries>

      <MaxWidth>
        <TopItineraries>
          <h1>Top Itineraries</h1>

          <div className='list-country'>
            {topDestination.map((data, index) => (
              <TagFlag key={index} className={flagActive === data.destinationCountry && 'active'} onClick={() => handleClickFlag(data)}>{data.destinationCountry}</TagFlag>
            ))}
          </div>

          <TopItinerariesContainer isFetching={isFetchingImage}>
            {!isFetchingImage 
              ? topDestination.length > 0 
                ? topDestinationImages.slice(0, 5).map((img, idx) => (
                  <GridItem key={idx} className={`${idx < 2 ? 'row--2-column' : idx > 4 ? 'row--1-column' : 'row--3-column'}`}>
                    <ImageComponent src={img?.image ?? blankImg} alt={`img ${idx}`} />
                  </GridItem>
                  ))
                :
                  <>No Data...</>
              :
              <div className='w-100 d-flex justify-content-center'><LoadingScreen /></div>
            }
          </TopItinerariesContainer>

        </TopItineraries>
      </MaxWidth>

      <MaxWidth>
        <HeaderContainer id='itineraries-section'>
          <div className='title-section'>
            <h1>Itineraries Buddies</h1>
            <SearchInput type='input' placeholder='Search title, destination, country...' value={querySearch} onChange={(e) => setQuerySearch(e.target.value)} /> 
          </div>

          <div>
            <AddBtn href={`${!user ? `#authModal` : '/itineraries/user/@' + user.username }`} data-bs-toggle={`${!user ? 'modal' : ''}`}>Add your itineraries</AddBtn>
          </div>
        </HeaderContainer>

        {isFetchingItinerariesList 
          ?
            <div className='w-100 d-flex justify-content-center mt-5'><LoadingScreen /></div> 
          : 
            <>
              <ContentContainer data={itineraries.length}>
                {itineraries.length > 0 ? itineraryList : <div className='w-100 d-flex justify-content-center mt-5'><NoData /></div>}
              </ContentContainer>

              <Paginate changePage={changePage} pageCount={pageCount} />
            </>
        }
      </MaxWidth>
      
      <ModalComponent showModal={showModalSearch} handleClose={() => setShowModalSearch(false)} isMiniModal centered title={`List Country`}>
        <WidgetForm>
          <input type="text" placeholder='Search...' value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)} />
          <ul>
            {filteredCountryList.length > 0 
              ? filteredCountryList.map(data => (<li key={data.value} onClick={(e) => handleClickCoutry(data)}>{data.label}</li>)) 
              : <span>No Country Available...</span>
            }
          </ul>
        </WidgetForm>
      </ModalComponent>
    </Container>
  )
}