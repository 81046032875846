import blankImg from "../assets/images/404.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { ImageComponent } from "./Image/index";

import { truncateContent } from "../utils/truncateString";

const ArticleStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  overflow: hidden;
  padding-right: 1rem;

  .innerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  .image-section {
    width: 42%;
    flex-shrink: 0;
    overflow: hidden;
    border: 0px;
    border-radius: 8px;
    position: relative;
  }

  .card-body {
    width: 70%;
  }

  p .card-title .cart-text {
    font-family: "DM Sans", sans-serif;
  }
  img {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-title {
    font-size: 13px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .card-text {
    font-size: 11px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-text.date {
    font-size: 11px;
    font-weight: bolder;
  }

  .card-body {
    overflow: hidden;
    margin-top: 0.7rem;
  }
  
  @media only screen and (min-width: 400px) {
    .image-section {
      width: 37%;
    }
  }
  
  @media only screen and (min-width: 450px) {
    .image-section {
      width: 28%;
    }
    .card-body {
      width: 72%;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .card-title {
      font-size: 13px;
    }
    .card-text {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .card-title {
      font-size: 20px;
      margin-top: -3px;
    }
    .card-text {
      font-size: 14px;
    }
    
    .image-section {
      width: 29%;
    }

    .card-body {
      width: 71%;
    }
  }

  
  @media only screen and (min-width: 992px) {

  }

`;
export function CardArticleHorizontal(props) {
  const { article, type } = props;

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  return (
    <ArticleStyle className="mb-3">
      <div className="innerContainer">
        <div className="image-section">
          <a href={`/articles/${article.slug}`}>
            <ImageComponent src={isImage(article.thumbnail) ? article.thumbnail : article.image ? article.image : blankImg} alt={article.imageCaption} />
          </a>
        </div>

        <div className="card-body">
          {type === "article" || type === "article-list" ? (
            <span className="card-text">
              <span className="fw-bolder">{article.user?.traveler?.name}</span> - {article.readingTime.text}
            </span>
          ) : (
            ""
          )}

          <a href={`/articles/${article.slug}`} className="text-decoration-none link-focus">
            <h3 className="card-title mt-1">{article.title}</h3>
          </a>

          {type === "news" || type === "news-home" ? <span className="card-text date mt-2"> {moment(article.updatedAt).format("MMM D, YYYY")}</span> : ""}
          <div className={`card-text mt-2 ${type === "news-home" && ""}`} dangerouslySetInnerHTML={{__html: truncateContent(article.content, 100) }} />
        </div>
      </div>
    </ArticleStyle>
  );
}
