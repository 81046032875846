export default function GuestExtension(props) {
  const {trip, IoTriangle, tripGuestExpand, setTripGuestExpand, formatDate} = props;

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  return(
    <div className='sub-extension-information--container'>
      <div className='btn-more-information--container no-margin'>
        <button type='button' className='btn-more-information' onClick={() => setTripGuestExpand(!tripGuestExpand)}>
          <IoTriangle className={`${tripGuestExpand && 'opened' }`}/>
        </button>
        <h5>Guests ({trip.tripGuest.length})</h5>
      </div>

      <div className='sub-extension-content--parent'>
        {trip.tripGuest.map((data, index) => 
          <div key={data.id} className={`sub-extension-content--wrapper ${tripGuestExpand && 'visible'}`}>
            <div className='sub-extension-content--container'>
              <div className='extension-content'>
                <span className='full'>Name</span>
                <span>:</span>
                <span className='full'>{capitalizeFirstLetter(data.title)} {data.name}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Phone Number</span>
                <span>:</span>
                <span className='full'>{data.phoneNumber}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Email</span>
                <span>:</span>
                <span className='full'>{data.email}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Gender</span>
                <span>:</span>
                <span className='full'>{data.gender}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>BirthDate</span>
                <span>:</span>
                <span className='full'>{formatDate(data.birthDate, 'dd, MMM yyyy')}</span>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  )
}