export default function AttachmentExtension(props) {
  const { trip, IoTriangle, tripAttachmentExpand , setTripAttachmentExpand } = props;

  return(
    <div className='sub-extension-information--container'>
      <div className='btn-more-information--container no-margin'>
        <button type='button' className='btn-more-information' onClick={() => setTripAttachmentExpand(!tripAttachmentExpand)}>
          <IoTriangle className={`${tripAttachmentExpand && 'opened' }`}/>
        </button>
        <h5>Attachment</h5>
      </div>

      <div className='sub-extension-content--parent'>
        {trip.tripAttachment.map((data, index) => 
          <div key={data.id} className={`sub-extension-content--wrapper ${tripAttachmentExpand && 'visible'}`}>
            <div className='sub-extension-content--container'>
              <div className='extension-content'>
                <span className='full'>Name</span>
                <span>:</span>
                <span className='full'>{data.name}</span>
              </div>
              
              <div className='extension-content'>
                <span className='full'>Url</span>
                <span>:</span>
                <span className='full'>
                  <a href={data.url} className="attachment-link--color" target="_blank" rel="noreferrer">Click here</a>
                </span>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  )
}