import Slider from "react-slick";

export function SliderComponent(props) {
  const { settings, children } = props;

  return (
    <>
      <Slider ref={props.forwardRef ? props.forwardRef : null} {...settings}>
        {children}
      </Slider>
    </>
  )
}