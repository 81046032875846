import { ImageComponent } from '../Image/index.js';
import './style.css'

import { IoCloudUploadOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { checkIsImage } from '../../utils/image-check';


export function UploadForm(props) {
  const { selectedFile, setSelectedFile, handleUploadChange, isUploading, acceptedFile = 'image/*,.pdf,.doc,.docx', directUplad = false, } = props;

  return (
    <div className='upload-form--container'>
      <input type="file" name='attachment_file' className='' onChange={handleUploadChange} accept={acceptedFile} disabled={isUploading} />
      <div className='upload-form--content'>
        
        {selectedFile && directUplad
          ? 
            <span>File Uploading...</span>
          : selectedFile && !directUplad
            ? <>
                <ImageComponent src={ !checkIsImage(selectedFile) ? URL.createObjectURL(selectedFile) : selectedFile } alt={'Thumbnail Preview'} styles={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                <FaRegTrashAlt
                  style={{ position: 'absolute', top: 5, right: 3, zIndex: 10000, background: 'white', padding: '5px', borderRadius: '6px', width: '30px', height: '30px', color: 'red' }}
                  onClick={() => { if (setSelectedFile) setSelectedFile(null)}}
                />
              </>
            : <>
                <IoCloudUploadOutline style={{ width: '50px', height: '50px' }} />
                <h3 className='label'>Drag & Drop File or <span>Choose File</span></h3>
              </> 
          }
      </div>
    </div>
  )
}