import { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import styled from 'styled-components'
import Swal from 'sweetalert2';
import { intervalToDuration } from 'date-fns';

import { getItinerary, readItinereary } from '../data/itinerary';
import { LoadingScreen } from '../components/index';
import { formatDate, getDateTime } from '../utils/formatDate';

import { FaRegDotCircle } from "react-icons/fa";
import { FaPlaneDeparture } from "react-icons/fa6";


const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  width: 100%;
  max-width: 1024px;
  
  margin: auto;
  padding-top: 2rem;
  padding-bottom ${(props) => props.pb ? props.pb : '10vh'};
  
  display: flex;
  flex-direction: column;
  background-color: #FEFDFF;
  
  @media only screen and (min-width: 400px) {}

  @media only screen and (min-width: 413px) {}

  @media only screen and (min-width: 768px) {
    padding: 4rem 0 0;
  }
  
  @media only screen and (min-width: 992px) {
    padding: 8rem 0 0;
  }

  @media only screen and (min-width: 1024px) {}

  @media only screen and (min-width: 1280px) {
    max-width: 1236px;
  }

  @media only screen and (min-width: 1536px) {
    max-width: 1536px;
  }

`;

const Card = styled.div`
  width: 100%;
  height: 700px;

  border-radius: 12px;
  
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  background-image: ${(props) => props.src ? `url(${props.src})` : 'url("https://images.unsplash.com/photo-1486299267070-83823f5448dd?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")'};
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  color: white;

  display: none;
  // display: flex;
  justify-content: end;
`;

const CardContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: rgba(0, 0, 0, 0.8);

  border-radius: 16px 0 0 16px;
  padding: 2rem 1.5rem;

  
  @media only screen and (min-width: 768px) {}

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`;

const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const CardTitle = styled.h1`
  font-size: 2.5em;
  font-weight: 800;
  margin: 0 0 1rem;
  padding: 0;

  
  @media only screen and (min-width: 1536px) {
    font-size: 3.5em;
  }
`;

const CardInformationText = styled.span`
  font-size: 1em;
  margin: 0;
  padding: 0;

  a {
    color: white;

    &:hover {
      color: blue;
    }
  }
`;

const CardText = styled.span`
  font-size: 1.5em;
  font-weight: 600;
  
  @media only screen and (min-width: 1536px) {
    font-size: 2em;
  }
`

const TripList = styled.div`
  .title-trip-list {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid white;
  }

  .trip-item--container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    max-height: 650px;
    overflow: hidden;
    overflow-y: auto;
  }
`;

const TripItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .date-time {
    font-size: 0.9em;
  }
  
  .activites {
    font-size: 0.9em;
  }
`;

const Card2 = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  
  // background-image: ${(props) => props.src ? `url(${props.src})` : 'url("https://images.unsplash.com/photo-1486299267070-83823f5448dd?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")'};

  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: bottom;

  
  @media only screen and (min-width: 992px) {
    flex-direction: row;

    gap: 1rem;
  }
  
  @media only screen and (min-width: 1024px) {}
`;

const CardOverlay = styled.div`
  width: 100%;
  height: ${(props) => props.height};

  transition: height 0.5s ease;

  border-radius: 0 0 2rem 2rem;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swipe-indicator {
    width: 100px;
    height: 5px;

    align-self: center;

    background-color: red;
    border-radius: 12px;
    cursor: pointer;

    margin-top: 1rem;
  }

  @media only screen and (min-width: 992px) {
    width: 50%;
    height: 100%;

    border-radius: 12px;
    
    box-shadow: unset;
  }
`;

const Card2Content = styled.div`
  width: 100%;

  .content {
    padding: 0.5rem 1.5rem;
    
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    .title {
      font-size: 1.4em;
      font-weight: 600;
      line-height: 1.8rem;
      margin-top: 0.6rem;
      letter-spacing: 2px;
    }

    .code {
        letter-spacing: 1.5px;
        font-weight: 600;
        font-size: 0.7em;
    }

    .desc {
      font-size: 0.95;
      margin: 1rem 0 0;
      text-align: justify;

      // max-width: 300px;
      // white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .detail-content {
    display: flex;
    gap: 5px;
    margin-top: 0.8rem;

    &.hor {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &.ver {
      flex-direction: column;
    }

    .title {
      font-size: 1.1em;
      font-weight; 600;
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-top: 0.5rem;
      padding 5px 7px;

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

      .photo-profile {
        width: 25px;
        height: 25px;

        border-radius: 9999px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      span {
        font-size: 1em;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }
  }

  .trip-creator {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .trip-duration {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .trip-list {
    margin-top: 1.5rem;

    .icon {
      width: 25px;
      height: 25px;

      margin-left: 1rem;
    }

    .list {
      margin: 0.5rem 0 0;
      padding: 0;
      list-style: none;

      display: flex;
      flex-direction: column;
      gap: 5px;

      .trip-content {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  
  @media only screen and (min-width: 992px) {
    width: 50%;

    .content {
      padding: 0 1rem;
    }
  }
`;

export function ItinerariesDetail() {
  const params = useParams()

  const [itineraryDetail, setItineraryDetail] = useState(null)
  const [isFetching, setIsFetching] = useState(true)

  const [bannerHeight, setBannerHeight] = useState('65vh')
  const [containerPaddingBott, setContainerPaddingBott] = useState('0')
  const [isScrolled, setIsScrolled] = useState(false);

  const bannerRef = useRef(null);

  const updatePaddingBottom = () => {
    if (bannerRef.current) {
      const bannerHeight = bannerRef.current.clientHeight;
      const viewportHeight = window.innerHeight;
      const calculatedPadding = Math.max(viewportHeight - bannerHeight, 0); // Hitung padding bottom berdasarkan sisa ruang
      setContainerPaddingBott(calculatedPadding);
    }
  };

  useEffect(() => {
    const fetchingData = async(id) => {
      const result = await getItinerary(id, true)

      if (result.code !== 200) {
        setIsFetching(false)
        return Swal.fire({ title: 'Someting went wrong', text: 'Error Occured', icon: 'error'})
      }
      const itinerary = result.data

      const itineraryStartDate = getDateTime(itinerary.startDate)
      const itineraryEndDate = getDateTime(itinerary.endDate)

      const itineraryDuration = intervalToDuration({
        start: new Date(itineraryStartDate.years, itineraryStartDate.months, itineraryStartDate.days, itineraryStartDate.hours, itineraryStartDate.minutes, itineraryStartDate.seconds),
        end: new Date(itineraryEndDate.years, itineraryEndDate.months, itineraryEndDate.days, itineraryEndDate.hours, itineraryEndDate.minutes, itineraryEndDate.seconds)
      })

      const addDurationsInDays = {
        ...itinerary,
        itineraryDuration
      }

      setItineraryDetail(addDurationsInDays)
      setIsFetching(false)
    }

    const readCurrentItinerary = async(id) => {
      const result = await readItinereary(id)

      if (result.code !== 200) {
        return Swal.fire({ title: 'Someting went wrong', text: 'read itinerary fail...', icon: 'error'})
      }

      /** do next thing */
    }

    if (params.slug) {
      fetchingData(params.slug)
      readCurrentItinerary(params.slug)
    }
  }, [params])

  useEffect(() => {
    updatePaddingBottom();

    const handleScrollOrTouchMove = () => {
      const scrollTop = document.documentElement.scrollTop;
      const triggerHeight = 50; // Misalkan perubahan terjadi jika scroll lebih dari 50px
  
      if (scrollTop > triggerHeight && !isScrolled) {
        setBannerHeight('25vh'); // Mengubah tinggi div pertama menjadi 1/4
        setIsScrolled(true); // Menandai bahwa perubahan sudah terjadi
        setContainerPaddingBott('8rem')
      } else if (scrollTop <= triggerHeight && isScrolled) {
        setBannerHeight('65vh'); // Mengembalikan tinggi div pertama ke 3/4
        setIsScrolled(false); // Menandai bahwa tinggi sudah kembali ke awal
        setContainerPaddingBott('0')
      }
    };
  
    // Menggunakan `touchmove` untuk menangani scroll pada perangkat mobile
    const onScrollOrTouchMove = () => {
      window.requestAnimationFrame(handleScrollOrTouchMove);
    };
  
    // Menambahkan event listener untuk scroll dan touchmove
    window.addEventListener('scroll', onScrollOrTouchMove, { passive: true });
    window.addEventListener('touchmove', onScrollOrTouchMove, { passive: true });
  
    // Bersihkan event listener ketika komponen di-unmount
    return () => {
      window.removeEventListener('scroll', onScrollOrTouchMove);
      window.removeEventListener('touchmove', onScrollOrTouchMove);
    };

  }, [isScrolled])

  useEffect(() => {
    // Menambahkan event listener untuk resize
    const handleResize = () => {
      updatePaddingBottom(); // Update padding saat ukuran layar berubah
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {console.log(containerPaddingBott)}, [containerPaddingBott])

  if (isFetching || !itineraryDetail) return <div className='d-flex justify-content-center align-items-center' style={{ padding: '20rem 0' }}><LoadingScreen /></div>

  return (
    <Container pb={containerPaddingBott + 'px'}>
      <Card src={itineraryDetail?.thumbnail ?? 'https://plus.unsplash.com/premium_photo-1676393904171-738ff11eceaa?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}>
        <CardContent>
          <div className='d-flex mb-4'>
            <CardTitleContainer>
              <CardTitle>{itineraryDetail?.name ?? ''}</CardTitle>
              <CardInformationText>Code : #{itineraryDetail.code}</CardInformationText>
              <CardInformationText>Destination : {itineraryDetail.destinationCountry}</CardInformationText>
              <CardInformationText>Duration : {`${formatDate(itineraryDetail.startDate, 'dd MMM yyyy')} - ${formatDate(itineraryDetail.endDate, 'dd MMM yyyy')} (${itineraryDetail.itineraryDuration.days} days)`}</CardInformationText>
              <hr style={{ width: '100%', height: 1, border: 'none', outline: 'none', background: 'white' }} />
              <CardInformationText>Creator : <Link to={`/user-articles/${itineraryDetail?.user?.username}`}>@{itineraryDetail?.user?.username ?? '-'}</Link></CardInformationText>
              <CardInformationText>Created : {formatDate(itineraryDetail.createdAt, 'dd MMM yyyy')}</CardInformationText>

              <div className='mt-5 d-none'>
                <CardText>{`${formatDate(itineraryDetail.startDate, 'dd MMM yyyy')} - ${formatDate(itineraryDetail.endDate, 'dd MMM yyyy')} (${itineraryDetail.itineraryDuration.days} days)`}</CardText>
              </div>
            </CardTitleContainer>

            {/* <div className='d-none flex-column gap-1'>
              <span>By : @{itineraryDetail?.user?.username ?? '-'}</span>
              <span>Created : {formatDate(itineraryDetail.createdAt, 'dd MMM yyyy')}</span>
            </div> */}
          </div>

          <TripList className='flex-grow-1'>
            <h3 className='title-trip-list'>Trip List</h3>
            <div className='trip-item--container'>
              {itineraryDetail.itineraryTrip.length > 0
                ?
                  itineraryDetail
                  .itineraryTrip
                  .sort((a, b) => Date.parse(new Date(a.startDate)) - Date.parse(new Date(b.startDate)))
                  .map((data, _) => (
                  <TripItem key={_}>
                    <span className='date-time'>{formatDate(data.startDate, 'dd/MM/yyyy - HH:mm a')}</span>
                    <span>&#9679;</span>
                    <span className='activities'>{data.title}</span>
                  </TripItem>
                ))
                :
                <span>No trip added...</span>
              }
            </div>
          </TripList>
        </CardContent>
      </Card>

      <Card2>
        <CardOverlay height={bannerHeight} ref={bannerRef}>
            <img src={itineraryDetail?.thumbnail ?? 'https://plus.unsplash.com/premium_photo-1676393904171-738ff11eceaa?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="itinerary's banner" />
        </CardOverlay>

        <Card2Content>
          <div className='content'>
            <span className='code'>Code : #{itineraryDetail.code}</span>
            <h1 className='title'>{itineraryDetail?.name ?? ''}</h1>

            <p className='desc'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc.</p>

            <div class='detail-content hor'>
              <div className='trip-creator'>
                <span className='title'>Creator</span>

                <div className='sub-title'>
                  <div className='photo-profile'>
                    <img src={itineraryDetail?.user?.traveler?.image ?? ''} alt={itineraryDetail?.user?.username} />
                  </div>
                  <a href="#"><span>@{itineraryDetail?.user?.username}</span></a>
                </div>
              </div>

              <div className='trip-duration'>
                <span className='title'>Duration</span>

                <div className='sub-title'>
                  <span>
                    {`${itineraryDetail.itineraryDuration.days} ${itineraryDetail.itineraryDuration.days > 1 ? 'Days' : 'Day'}`}
                  </span>
                </div>
              </div>
            </div>

            <div class='detail-content ver'>
              <div className='trip-list'>
                <span className='title'>
                  Top Trips
                  <FaPlaneDeparture className='icon' />
                </span>

                <ul className='list'>
                {itineraryDetail.itineraryTrip.length > 0
                  ?
                    itineraryDetail
                    .itineraryTrip
                    .sort((a, b) => Date.parse(new Date(a.startDate)) - Date.parse(new Date(b.startDate)))
                    .slice(0, 3)
                    .map((data, _) => (
                      <li key={_} className='trip-content'>
                        <FaRegDotCircle style={{ width: 15, height: 15 }} />
                        <span>{data.title}</span>
                      </li>
                  ))
                  :
                  <li>No trip added...</li>
                }
                </ul>
              </div>
            </div>
          </div>
        </Card2Content>
      </Card2>
    </Container>
  )
}