export default function AttractionExtension(props) {

  const { trip, IoTriangle, tripAttractionExpand , setTripAttractionExpand } = props;

  return(
    <div className='sub-extension-information--container'>
      <div className='btn-more-information--container no-margin'>
        <button type='button' className='btn-more-information' onClick={() => setTripAttractionExpand(!tripAttractionExpand)}>
          <IoTriangle className={`${tripAttractionExpand && 'opened' }`}/>
        </button>
        <h5>Attraction</h5>
      </div>

      <div className='sub-extension-content--parent'>
        {trip.tripAttraction.map((data, index) => 
          <div key={data.id} className={`sub-extension-content--wrapper ${tripAttractionExpand && 'visible'}`}>
            <div className='sub-extension-content--container'>
              <div className='extension-content'>
                <span className='full'>Name</span>
                <span>:</span>
                <span className='full'>{data.name}</span>
              </div>
              
              <div className='extension-content'>
                <span className='full'>Type</span>
                <span>:</span>
                <span className='full'>{data.type}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Address</span>
                <span>:</span>
                <span className='full'>{data.address}</span>
              </div>


              <div className='extension-content'>
                <span className='full'>Price</span>
                <span>:</span>
                <span className='full'>{data?.currency ?? ''} {data.price}</span>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  )
}