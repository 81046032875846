
import { ProgressBar, Step } from "react-step-progress-bar";

import "./styles.css"

export default function MultiStepProgressBar(props) {
  const { page, onPageNumberClick, formList } = props;

  
  const totalSteps = formList.length;

  const stepPercentage = (page / totalSteps) * 100;

  return (
    <ProgressBar percent={stepPercentage}>
      {formList.map((_, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                // onClick={() => onPageNumberClick(index, 'next')}
              >
                {index + 1}
              </div>
            )}
          </Step>
        ))}
    </ProgressBar>
  );
};