import React, { useState, useEffect, useMemo } from "react";
import { useOutletContext, Link } from "react-router-dom";

import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text } from "recharts";
import { Line, LineChart } from "recharts";
import moment from 'moment';
import styled from "styled-components";
import { ImageComponent, Metadata, LoadingScreen } from "../components";

import blankImg from "../assets/images/404.svg";
import * as usersData from "../data/users";

import { truncateContent } from "../utils/truncateString";


const ButtonStats = styled.div`
  color: black;
  border-radius: 7px;
  box-sizing: border-box;
  font-size: 0.9rem;

  .total-text {
    font-size: 1.3rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 1.2rem;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 1.2rem;
    .total-text {
      font-size: 1.5rem;
    }
  }
`;
const ButtonEngagement = styled.div`
  height: 150px
  color: black;
  border: 1px solid grey;
  border-radius: 7px;
  box-sizing: border-box;
  margin-right: 5px;
  font-size: 1.4rem;
  text-align: center;
  margin-top: 25px;
  padding: 0.6em 0;

  .total-text {
    font-size: 1.7rem;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    margin-left: 0.5vw;
    font-size: 1.3rem;
    margin-top: -20px;
    padding: 1.55em 0;

    .total-text {
      font-size: 1.5rem;
    }
  }
`;
const Banner = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  width: 100%;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    position: center;
    object-fit: cover;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    height: 30vh;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    height: 65vh;
  }
`;

const Table = styled.div`
  .image {
    width: 50%;
    margin-right: 10px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .image img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .title-container {
    width: 50%;
  }

  .title {
    text-decoration: none;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 767px) {
    .table {
      width: 700px;
    }
    .title {
      font-size: 12px;
      line-height: 1.1em;
    }
    .time {
      font-size: 10px;
      weight: 400;
    }
    th {
      font-size: 13px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .image {
      width: 45%;
    }
  
    .title-container {
      width: 65%;
    }

    .title {
      font-size: 14px;
    }
    .time {
      font-size: 12px;
    }
    th {
      font-size: 14px;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .image {
      width: 30%;
    }
  
    .title-container {
      width: 70%;
    }
  }
`;
const Title = styled.h2`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  font-size: 16px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 26px;
  }
`;

export function Statistic() {
  let xKey = "name";
  let zKey = "age";
  let yKey = "pv";
  const [activeCategory, setActiveCategory] = useState("Engagement");
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState([]);
  const [dataInsight, setDataInsight] = useState();
  const [dataInsightArticle, setDataInsightArticle] = useState();
  const [dataAge, setDataAge] = useState();
  const [dataLocation, setDataLocation] = useState();
  const [dataGender, setDataGender] = useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const token = JSON.parse(localStorage.getItem("tokens")).data.access_token;
  
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    async function fetchData() {
      const response = await usersData.getArticlesStatistics();

      if (response.code && response.code !== 200) {
        setIsFetching(false)
        return
      }

      setArticles(response.data);
      setDataInsight(response.data.summary?.articlesReadsSummaryGroupByMonthYear);

      setIsFetching(false)
    }
    fetchData();
  }, [token]);

  useEffect(() => {
    async function fetchData() {
      if (!id) return;
      const response = await usersData.getArticleStatistic(id).then((response) => {
        setArticle(response.data.article);
        setDataInsightArticle(response.data.article.summary?.articleReadsSummaryGroupByMonthYear);
        setDataAge(response.data.article.summary?.articleReadsByAge);
        setDataLocation(response.data.article.summary?.articleReadsByCity);
        setDataGender(response.data.article.summary?.articleReadsByGender);

         // console.log(dataAge);
      });
      return response;
    }
    fetchData();
  }, [token]);

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }

  const year = articles.summary?.articleReadsSummary?.years;

  const COLORS = ["#F84343", "#3663e8"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const blues = [["#3663e8"], ["#3663e8", "#3663e8"], ["#3663e8", "#3663e8", "#3663e8"], ["#3663e8", "#3663e8", "#3663e8", "#3663e8"], ["#3663e8", "#3663e8", "#3663e8", "#3663e8", "#3663e8"]];

  const getColor = (length, index) => {
    if (length <= blues.length) {
      return blues[length - 1][index];
    }

    return blues[blues.length - 1][index % blues.length];
  };

  const YAxisLeftTick = ({ y, payload: { value } }) => {
    return (
      <Text x={0} y={y} textAnchor="start" verticalAnchor="middle" scaleToFit>
        {value}
      </Text>
    );
  };

  let ctx;

  const measureText14HelveticaNeue = (text) => {
    if (!ctx) {
      ctx = document.createElement("canvas").getContext("2d");
      ctx.font = "14px 'Helvetica Neue";
    }

    return ctx.measureText(text).width;
  };

  const BAR_AXIS_SPACE = 10;

  const maxTextWidthAge = useMemo(
    () =>
      dataAge?.reduce((acc, cur) => {
        const value = cur[yKey] / 100;
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [dataAge, yKey]
  );
  const maxTextWidthLocation = useMemo(
    () =>
      dataLocation?.reduce((acc, cur) => {
        const value = cur[yKey];
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [dataLocation, yKey]
  );

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  const totalEngagemen = (like, view, comment, save) => {
    return like + view + comment + save;
  };

  const [windowSize] = useOutletContext()
  const [printLimitedContent, setPrintLimitedContent] = useState(0);
  useEffect(() => {
    if (windowSize.innerWidth > 991) {
      setPrintLimitedContent(150)
    } else {
      setPrintLimitedContent(40)
    }
  }, [windowSize])

  return (
    <div className="articles d-flex flex-column" style={{ marginTop: "6.5rem", gap: "4.5rem", marginBottom: "5rem" }}>
      <div className="container d-flex flex-column ">
        <Metadata page={window.location.href} titlePage={`Statistics • Bipi World`} />

        <h1 className="section-title">Insight</h1>

        {id && (
          <div className="d-flex flex-column gap-4 mb-5">
            <Title href={`/articles/${article.slug}`} className=" text-decoration-none">
              <Link className="card-title" onClick={() => (window.location.href = `/articles/${article.slug}`)}>
                {article.title}
              </Link>
            </Title>

            <Banner>
              <img src={article?.image && isImage(article?.image) ? article?.image : blankImg} alt={`Banner ${article?.title}`} />
            </Banner>
          </div>
        )}

        <div className="row mt-5">
          {!isFetching 
            ? (
              <>
                <div className="col-sm-12 col-lg-7 mb-3" style={{ marginLeft: "-24px" }}>
                  <ResponsiveContainer width="100%" aspect={1.7}>
                    <LineChart data={id ? dataInsightArticle : dataInsight}>
                      {(activeCategory === "Engagement" || activeCategory === "Views") && <Line type="monotone" dataKey="views" stroke="#F84343" strokeWidth={1.25} />}
                      {(activeCategory === "Engagement" || activeCategory === "Save") && <Line type="monotone" dataKey="bookmarks" stroke="#3663e8" strokeWidth={1.25} />}
                      {(activeCategory === "Engagement" || activeCategory === "Likes") && <Line type="monotone" dataKey="likes" stroke="#44d187" strokeWidth={1.25} />}
                      {(activeCategory === "Engagement" || activeCategory === "Comments") && <Line type="monotone" dataKey="comments" stroke="#f9cb08" strokeWidth={1.25} />}
                      <CartesianGrid stroke="#cccccc" />
                      <XAxis dataKey="monthYear" tick={{ fontSize: 12 }} />
                      <YAxis tick={{ fontSize: 12 }} />
                      <Tooltip />
                      {/* <Legend iconType="circle" align="center" iconSize={8} /> */}
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                <div className="col-sm-12 col-lg-5 d-flex flex-wrap  pt-0 justify-content-center align-items-center " style={{ marginTop: "-28px" }}>
                  <div className="justify-content-center row" style={{ marginLeft: "0.5vw", marginRight: "0.5vw" }}>
                    <ButtonEngagement className={`mb-2 col-12 ${activeCategory === "Engagement" && "border-bottom-blue"}`}>
                      <span onClick={() => setActiveCategory("Engagement")} className={`text-black text-decoration-none ${activeCategory === "Engagement" && "text-blue "}`} style={{ cursor: 'pointer' }}>
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="total-text me-3">{!id ? articles?.summary?.totalEngagement : article.engagement}</div>
                          <div>Engagement</div>
                        </div>
                      </span>
                    </ButtonEngagement>
                    {["Views", "Save", "Likes", "Comments"].map((item, index) => (
                      <ButtonStats key={index} className={`p-2 justify-content-between  col-6  ${activeCategory === item && "border-bottom-blue"}`}>
                        <div className="p-3 " style={{ border: "1px solid grey", borderRadius: "10px" }}>
                          <span onClick={() => setActiveCategory(item)} className={`text-black text-decoration-none ${activeCategory === item && "text-blue "}`} style={{ cursor: 'pointer'}}>
                            {item !== "image" && (
                              <>
                                <div className="d-flex justify-content-between">
                                  <div className="total-text">
                                    {item === "Views" && (!id ? articles.summary?.totalViews : article?.totalViews)}
                                    {item === "Save" && (!id ? articles.summary?.totalBookmarks : article?.totalBookmarks)}
                                    {item === "Likes" && (!id ? articles.summary?.totalLikes : article?.totalLikes)}
                                    {item === "Comments" && (!id ? articles.summary?.totalComments : article?.totalComments)}
                                  </div>
                                  <p>
                                    {item === "Views" && <i className="fa-regular fa-eye fa-xl pt-4 pe-2"></i>}
                                    {item === "Save" && <i className="fa-regular fa-bookmark fa-xl pt-4 pe-2"></i>}
                                    {item === "Likes" && <i className="fa-regular fa-heart fa-xl pt-4 pe-2"></i>}
                                    {item === "Comments" && <i className="fa-regular fa-comments fa-xl pt-4 pe-2"></i>}
                                  </p>
                                </div>
                                <div>{item}</div>
                              </>
                            )}
                          </span>
                        </div>
                      </ButtonStats>
                    ))}
                  </div>
                </div>
              </>
            ) 
            : <div className="d-flex justify-content-center p-5"><LoadingScreen /></div>
          }

        </div>

        {id && (
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-12 col-lg-8">
              <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div className="carousel-inner p-5">
                  <div className="carousel-item active pe-md-5 ps-md-5" data-bs-interval="2000">
                    <div className="pb-4">
                      <h3>Age Range</h3>
                    </div>
                    <ResponsiveContainer width={"100%"} height={300} debounce={50}>
                      <BarChart data={dataAge} layout="vertical" margin={{ left: 10, right: maxTextWidthAge + (BAR_AXIS_SPACE - 8) }}>
                        <XAxis hide axisLine={false} type="number" />
                        <YAxis
                          yAxisId={0}
                          dataKey={zKey}
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          tick={{
                            fontSize: "14px",
                          }}
                        />
                        <YAxis
                          orientation="right"
                          yAxisId={1}
                          dataKey={yKey}
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value) => value.toLocaleString()}
                          mirror
                          tick={{
                            transform: `translate(${maxTextWidthAge + BAR_AXIS_SPACE}, 0)`,
                            fontSize: "14px",
                          }}
                        />
                        <Bar dataKey={yKey} minPointSize={2} height={32} barSize={25} maxPointSize={10}>
                          {dataAge?.map((d, idx) => {
                            return <Cell key={d[zKey]} fill={getColor(dataAge.length, idx)} />;
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>{" "}
                  </div>
                  <div className="carousel-item pe-md-5 ps-md-5">
                    <div className="pb-4">
                      <h3>Top Location</h3>
                    </div>
                    <ResponsiveContainer width={"100%"} height={300} debounce={50}>
                      <BarChart data={dataLocation} layout="vertical" margin={{ left: 10, right: maxTextWidthLocation + (BAR_AXIS_SPACE - 8) }}>
                        <XAxis hide axisLine={false} type="number" />
                        <YAxis
                          yAxisId={0}
                          dataKey={xKey}
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          tick={{
                            fontSize: "10px",
                          }}
                        />
                        <YAxis
                          orientation="right"
                          yAxisId={1}
                          dataKey={yKey}
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value) => value.toLocaleString()}
                          mirror
                          tick={{
                            transform: `translate(${maxTextWidthLocation + BAR_AXIS_SPACE}, 0)`,
                            fontSize: "14px",
                          }}
                        />
                        <Bar dataKey={yKey} fontSize={10} minPointSize={2} height={32} barSize={25} fill="#8884d8">
                          {dataLocation?.map((d, idx) => {
                            return <Cell key={d[xKey]} fill={getColor(dataLocation.length, idx)} />;
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>{" "}
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="col-md-6 mt-sm-5  pt-2 mt-lg-0 col-lg-4 d-flex flex-column justify-content-center">
              <div className="pt-4 pt-md-2 text-center">
                <h3>Gender</h3>
              </div>
              <div className="d-flex justify-content-center">
                <PieChart width={300} height={340} background={{ fill: "#eee" }}>
                  <Pie data={dataGender} cx={170} cy={160} label={renderCustomizedLabel} labelLine={false} outerRadius={120} fill="#8884d8" dataKey="pv" nameKey="gender">
                    {dataGender?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    <Tooltip />
                  </Pie>
                  <Legend verticalAlign="bottom" align="center" iconType="square" iconSize={10} />
                </PieChart>
              </div>
            </div>
          </div>
        )}

        {!id && (
          <>
            {!isFetching 
              ? (
                <Table className="scroll mt-3 row g-4 justify-content-between">
                  <div className="col-sm-12 col-md-12 table">
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "40%" }}></th>
                          <th scope="col" style={{ width: "12%" }} className="text-center">
                            View
                          </th>
                          <th scope="col" style={{ width: "12%" }} className="text-center">
                            Save
                          </th>
                          <th scope="col" style={{ width: "12%" }} className="text-center">
                            Likes
                          </th>
                          <th scope="col" style={{ width: "12%" }} className="text-center">
                            Comments
                          </th>
                          <th scope="col" style={{ width: "12%" }} className="text-center">
                            Engagement
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {articles
                          ? articles.articles?.map((article, index) => (
                              <tr key={index} className="align-middle">
                                <th className="text-start d-flex" scope="row">
                                  <div className="image">
                                    <ImageComponent src={article.image && isImage(article.image) ? article.image : blankImg} className="img-fluid" alt={article.imageCaption} />
                                  </div>
                                  <div className="title-container">
                                    <a className="title" href={`/statistic?id=${article.id}`}>
                                      {truncateContent(article.title, printLimitedContent)}
                                    </a>
                                    <br /> <small className="time fw-normal">Published on { moment(article?.createdAt).format("MMM D, YYYY") } </small>
                                  </div>
                                </th>
                                <td className="text-center">{article?.totalViews}</td>
                                <td className="text-center">{article?.totalBookmarks}</td>
                                <td className="text-center">{article?.totalLikes}</td>
                                <td className="text-center">{article?.totalComments}</td>
                                <td className="text-center">{totalEngagemen(article?.totalViews, article?.totalBookmarks, article?.totalLikes, article?.totalComments)}</td>
                              </tr>
                            ))
                          : "No data"}
                      </tbody>
                    </table>
                  </div>
                </Table>
              )
              : <div className="d-flex justify-content-center p-5"><LoadingScreen /></div>
            }
          </>
        )}
      </div>
    </div>
  );
}
