import { useEffect } from "react";

import blankImg from "./../../assets/images/404.svg";
import { ImageComponent } from "./../Image/index";

import './style.css'
import { formatDate } from "../../utils/formatDate.js";

export function CardItineraryHorizontal(props) {
  const { itinerary, isNoProfile = false } = props;

  return (
    <div className="card-itineray--container mb-3">
      <div className="innerContainer">
        <div className="image-section">
          <a href={`#`}>
            <ImageComponent src={itinerary.destinationCountry && itinerary.thumbnail ? itinerary.thumbnail : blankImg} alt={itinerary?.name ?? ''} />
          </a>
        </div>

        <div className="card-body">
          {!isNoProfile &&
            <div className="user-profile--container">
              <ImageComponent src={itinerary.user?.traveler?.image} className={`user-profile--container`} alt={`itinerary.user?.traveler?.name ?? ''`} />
              <span className="card-text">
                <span className="fw-bolder">{`${itinerary.user?.traveler?.name ?? ''} - @${itinerary.user?.username ?? ''}`}</span>
              </span>
            </div>
          }
          <a href={`/itinerary/${itinerary.code}`} className="text-decoration-none link-focus">
            <h3 className="card-title mt-1">{itinerary.name}</h3>
          </a>

          <div className="d-flex flex-column gap-2 mt-3">
            <span className="flag-country" style={{ background: itinerary.color}}>{itinerary?.destinationCountry ?? 'Unavailable'}</span>

            <table class="card-content-table">
              <tr>
                <td>
                  <span className="range-date">{`Date`}</span>
                </td>
                <td>:</td>
                <td>
                  <span className="content">{`${formatDate(new Date(itinerary?.startDate), 'dd MMM yyyy')} - ${formatDate(new Date(itinerary?.endDate), 'dd MMM yyyy')}`}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span className="range-date">{`Total trip`}</span>
                </td>
                <td>:</td>
                <td>
                  <span className="content">{`${itinerary.itineraryTrip.length}`}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}