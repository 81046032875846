import { useEffect, useState } from "react"

export default function TravelAgencyForm(props) {
  const { name, handleInputChange, updatedData } = props
  
  const [travelAgencyData, setTravelAgencyData] = useState(
    {
      [`${name}_travel_agency_name`]: '',
      [`${name}_travel_agency_email`]: '',
      [`${name}_travel_agency_phone`]: '',
      [`${name}_travel_agency_contact_name`]: '',
      [`${name}_travel_agency_code`]: '',
      [`${name}_travel_agency_url`]: '',
    }
  )

  useEffect(() => {
    if (updatedData) {
      let subData = [];
      Object.keys(updatedData).forEach(key => {
        if (key.startsWith(`subData`)) {
          const subDataArrayTemp = updatedData[key];
          if (Array.isArray(subDataArrayTemp) && subDataArrayTemp.length > 0) {
            subData = subDataArrayTemp;
            return; // Exit loop once we find subDataArray with data
          }
        }
      })
      const travelAgencyData = subData.length > 0 ? subData[0] : {
        [`${name}_travel_agency_name`]: '',
        [`${name}_travel_agency_email`]: '',
        [`${name}_travel_agency_phone`]: '',
        [`${name}_travel_agency_contact_name`]: '',
        [`${name}_travel_agency_code`]: '',
        [`${name}_travel_agency_url`]: '',
      };

      setTravelAgencyData(travelAgencyData)
    }
  }, [name, updatedData])

  return (
    <>
      <div className="form-input-trip">
        <div className="form-input--wrapper">
          <div className='form-input-group w-full'>
            <label htmlFor="">Travel Agency Name</label>
            <input type="text" name={`${name}_travel_agency_name`} value={`${travelAgencyData[`${name}_travel_agency_name`]}`} onChange={handleInputChange} className='form-input' />
          </div>
          
          <div className='form-input-group w-full'>
            <label htmlFor="">Travel Agency Email</label>
            <input type="text" name={`${name}_travel_agency_email`} value={`${travelAgencyData[`${name}_travel_agency_email`]}`} onChange={handleInputChange} className='form-input' />
          </div>
        </div>

        <div className="form-input--wrapper">
          <div className='form-input-group w-full'>
            <label htmlFor="">Travel Agency Phone</label>
            <input type="text" name={`${name}_travel_agency_phone`} value={`${travelAgencyData[`${name}_travel_agency_phone`]}`} onChange={handleInputChange} className='form-input' />
          </div>
          <div className='form-input-group w-full'>
            <label htmlFor="">Contact Name</label>
            <input type="text" name={`${name}_travel_agency_contact_name`} value={`${travelAgencyData[`${name}_travel_agency_contact_name`]}`} onChange={handleInputChange} className='form-input' />
          </div>
        </div>

        <div className="form-input--wrapper">
          <div className='form-input-group w-full'>
            <label htmlFor="">Travel Agency Code</label>
            <input type="text" name={`${name}_travel_agency_code`} value={`${travelAgencyData[`${name}_travel_agency_code`]}`} onChange={handleInputChange} className='form-input' />
          </div>
          <div className='form-input-group w-full'>
            <label htmlFor="">Travel Agency Url</label>
            <input type="text" name={`${name}_travel_agency_url`} value={`${travelAgencyData[`${name}_travel_agency_url`]}`} onChange={handleInputChange} className='form-input' />
          </div>
        </div>

      </div>
    </>
  )
}