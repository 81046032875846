export default function AccomodationExtension(props) {
  const { trip, IoTriangle, tripAccomodationExpand , setTripAccomodationExpand, formatDate } = props;

  return(
    <div className='sub-extension-information--container'>
      <div className='btn-more-information--container no-margin'>
        <button type='button' className='btn-more-information' onClick={() => setTripAccomodationExpand(!tripAccomodationExpand)}>
          <IoTriangle className={`${tripAccomodationExpand && 'opened' }`}/>
        </button>
        <h5>Accomodation</h5>
      </div>

      <div className='sub-extension-content--parent'>
        {trip.tripAccomodation.map((data, index) => 
          <div key={data.id} className={`sub-extension-content--wrapper ${tripAccomodationExpand && 'visible'}`}>
            <div className='sub-extension-content--container'>
              <div className='extension-content'>
                <span className='full'>Lodging Name</span>
                <span>:</span>
                <span className='full'>{data.name}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Address</span>
                <span>:</span>
                <span className='full'>{data.address}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Room Number</span>
                <span>:</span>
                <span className='full'>{data.roomNumber}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Check In</span>
                <span>:</span>
                <span className='full'>{formatDate(data.checkIn, 'dd, MMM yyyy - HH:mm')}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Check Out</span>
                <span>:</span>
                <span className='full'>{formatDate(data.checkOut, 'dd, MMM yyyy - HH:mm')}</span>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  )
}