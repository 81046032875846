
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

import './style.css'

export function TimezoneComponent(props) {
  const { date, setDate, label = 'original' } = props;
  return (
    <div className='container-tz'>
      <TimezoneSelect
        value={date}
        onChange={setDate}
        timezones={{...allTimezones}}
        labelStyle={label}
      />
    </div>
  )
}