import React, { useState, useContext, useEffect } from 'react'
import styled from "styled-components"
import { useLocation, Link } from "react-router-dom";

import AuthContext from "../components/shared/AuthContext";
import defaultImageUser from "../assets/images/defaultUser.svg";
import { getUsersProfile } from '../data/users';
import { ImageComponent } from './Image';

import { FaCompass, FaRegCompass, FaRegUser, FaUser } from "react-icons/fa6"
import { GoHome, GoHomeFill  } from "react-icons/go";
import { FiPlusCircle } from "react-icons/fi";

const BottomNav = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  position: fixed;
  bottom: 0;
  overflow: hidden;
  background: white;
  z-index: 100;

  padding: 0.8rem 1.2rem;
  margin: 0 0 -2px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  a {
    font-size: 20px;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;

    img {
      width: 33px;
      height: 33px;
      border-radius: 25px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const ItemNav = styled.a`
  font-size: 20px;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;

  img {
    width: 33px;
    height: 33px;
    border-radius: 25px;
    border: ${(props) => (props.currentLocation === props.hrefMenu ? '2px solid rgba(0, 0, 0, 0.7)' : '2px solid transparent')};
  }
`;

export function BottomNavigation() {
  const location = useLocation();
  const { user } = useContext(AuthContext)

  const [userProfile, setUserProfile] = useState()

  const menusBot = [
    {
      name: 'Home',
      href: '/',
      icon: GoHome,
      iconActive: GoHomeFill,
      width: 29,
      height: 29,
      order: 1
    },
    {
      name: 'Explore',
      href: '/articles',
      icon: FaRegCompass,
      iconActive: FaCompass,
      width: 26,
      height: 26,
      order: 2
    },
    {
      name: 'Post',
      href: '/post?mode=add',
      icon: FiPlusCircle,
      iconActive: FiPlusCircle,
      width: 29,
      height: 29,
      order: 3
    },
    {
      name: 'Buddies',
      href: '/contributors',
      icon: FaRegUser,
      iconActive: FaUser,
      width: 26,
      height: 26,
      order: 4
    },
    {
      name: 'Account',
      href: '/user-profile',
      icon: '',
      iconActive: '',
      order: 5
    },
  ]

  useEffect(() => {
    const fetchUser = async() => {
      const res = await getUsersProfile();
      if (res.code !== 200) {
        return;
      }

      setUserProfile(res.data)
    }
    // for fetching user usage.
    // if (user) fetchUser()
  }, [user])

  return (
    <BottomNav>
      {menusBot.length > 0 ? menusBot.map((menu, index) => 
        <Link key={index} to={(menu.name === 'Account' || menu.name === 'Post') && !user ? `#authModal` : menu.name === 'Account' ? `${menu.href}/${user?.username}` : menu.href} data-bs-toggle={(menu.name === 'Account' || menu.name === 'Post') && !user ? 'modal' : ''}>
          {menu.name === 'Account' ? (
            <ImageComponent
              src={user && user?.photo ? user?.photo : defaultImageUser}
              alt="User Profile"
              styles={{ border: location.pathname === `${menu.href}/${user?.username}` ? '2px solid rgba(0, 0, 0, 0.7)' : '2px solid transparent'}}
            />
          ) : menu.href === location.pathname 
          ? (
            <menu.iconActive style={{width: menu.width, height: menu.height}}/>
          ) : (
            <menu.icon style={{width: menu.width, height: menu.height}}/>
          )}
        </Link>
        // <ItemNav key={index} href={(menu.name === 'Account' || menu.name === 'Post') && !user ? `#authModal` : menu.name === 'Account' ? `${menu.href}/${user?.username}` : menu.href} data-bs-toggle={(menu.name === 'Account' || menu.name === 'Post') && !user ? 'modal' : ''} currentLocation={location.pathname} hrefMenu={`${menu.href}/${user?.username}`}>

        //   {menu.name === 'Account' ? (
        //     <ImageComponent
        //       src={user && user?.photo ? user?.photo : defaultImageUser}
        //       alt="User Profile"
        //     />
        //   ) : menu.href === location.pathname 
        //   ? (
        //     <menu.iconActive style={{width: menu.width, height: menu.height}}/>
        //   ) : (
        //     <menu.icon style={{width: menu.width, height: menu.height}}/>
        //   )}
        // </ItemNav>
      ) : ''}
    </BottomNav>
  )
}