import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AuthContext from "./../components/shared/AuthContext";
import * as articlesData from "../data/articles";

const ButtonStyled = styled.div`
  font-size: 18px;
  border: none;
  text-decoration: none;
  outline: none;


  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
`;

export function Save(props) {
  const [articlesBookmark, setArticlesBookmark] = useState([]);
  const { user } = useContext(AuthContext);
  const [click, setClick] = useState(false);
  
  const [changeColor, setChangeColor] = useState("bg-dark");
  const [bg, setBg] = useState("bg-blue");
  const idArticle = props.id;

  useEffect(() => {
    async function fetchData() {

      const response = await articlesData.getbookmarkedArticles('?limit=100').then((response) => setArticlesBookmark(response?.data));
      return response;
    }
    if (user) {
      fetchData();
    }
  }, [user]);

  let bgColor;
  const savePost = async (e) => {
    e.preventDefault();
    await articlesData.bookmarkArticle(idArticle)
      .then(() => {
        if (changeColor === "bg-blue") {
          setChangeColor("bg-dark");
          setBg("bg-dark");
        }
        if (changeColor === "bg-dark") {
          setChangeColor("bg-blue");
          setBg("bg-blue");
        }
        if (bgColor === "bg-blue") {
          setChangeColor("bg-dark");
          setBg("bg-dark");
        }
      })
      .catch((error) => {
        alert(error.response?.data);
      });
  };

  useEffect(() => {
    const article = articlesBookmark.find((article) => article.id === idArticle);
    if (article) {
      setChangeColor(bg);
    } else {
      setChangeColor("bg-dark")
    }
  }, [idArticle, articlesBookmark, bg]);

  return (
    <>
      {user ? (
        <ButtonStyled id="btn-save" onClick={savePost} className="btn btn-save p-0">
         <i className={`fa-bookmark text-decoration-none ${bgColor === "bg-blue" || changeColor === "bg-blue" ? `fa-solid ${changeColor}` : "fa-regular"}
         ${(click === true && changeColor === "bg-blue") || (click === true && changeColor === "bg-dark") ? changeColor : ""}`}></i>
        </ButtonStyled>
      ) : (
        <ButtonStyled href="#authModal" data-bs-toggle="modal" className="btn btn-save p-0">
          <i className={`${bgColor === "bg-blue" || changeColor === "bg-blue" ? "fa-solid" : "fa-regular"} fa-bookmark fa-sm ${bgColor} ${changeColor}`}></i>
        </ButtonStyled>
      )}
    </>
  );
}
