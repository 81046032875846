import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function getItineraries(query, isPublic = false) {
  try {
    if (!isPublic) {
      setHeaderToken()
      const result = await axios.get(`itinerary` + query);
      return result.data;
    } else {
      const result = await axios.get(`itinerary/open/public` + query);
      return result.data;
    }
  } catch (error) {
    return error
  }
}

export async function getItinerary(id, isPublic = false){
  try {
    if (!isPublic) {
      setHeaderToken()
      const result = await axios.get(`itinerary/${id}`);
      return result.data;
    } else {
      const result = await axios.get(`itinerary/open/public/${id}`);
      return result.data;
    }
  } catch (error) {
    return error
  }
}

export async function createItinerary(payload) {
  try {
    setHeaderToken()
    const result = await axios.post(`itinerary`, payload)
    return result;
  } catch (error) {
    return error
  }
}

export async function removeItinerary(id) {
  try {
    setHeaderToken()
    const result = await axios.delete(`itinerary/${id}`)
    return result;
  } catch (error) {
    return error
  }
}

export async function updateItinerary(id, payload) {
  try {
    setHeaderToken()
    const result = await axios.patch(`itinerary/${id}`, payload)
    return result
  } catch (error) {
    return error
  }
}

export async function getTripsByItineraryId(id) {
  try {
    setHeaderToken()
    const result = await axios.get(`/itinerary/${id}/trips`)
    return result.data;
  } catch (error) {
    return error
  }
}

export async function createTrip(payload) {
  try {
    setHeaderToken()
    const result = await axios.post(`itinerary/trip`, payload)
    return result;
  } catch (error) {
    return error
  }
}

export async function removeTrip(id) {
  try {
    setHeaderToken()
    const result = await axios.delete(`itinerary/trip/${id}`)
    return result
  } catch (error) {
    return error
  }
}

export async function updateTrip(id, payload) {
  try {
    setHeaderToken()
    const result = await axios.patch(`itinerary/trip/${id}`, payload)
    return result
  } catch (error) {
    return error
  }
}

export async function uploadFile(formData, onUploadProgress) {
  try {
    setHeaderToken()
    const result = await axios.post('/itinerary/file/upload', formData, {
      onUploadProgress: onUploadProgress
    })

    return result;
  } catch (error) {
    return error
  }
}

export async function deleteFile(formData, onUploadProgress) {
  try {
    setHeaderToken()
    const result = await axios.post('itinerary/file/delete', formData, {
      onUploadProgress: onUploadProgress
    })
    return result
  } catch (error) {
    return error
  }
}

export async function readItinereary(id) {
  try {
    const result = await axios.get(`itinerary/${id}/read`)
    return result.data
  } catch (error) {
    return error
  }
}