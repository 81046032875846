import styled from "styled-components";
import "./../pages/style.css";

const LinkStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  text-decoration: "none";
  background-color: white;
  font-size: 14px;

  :hover {
    color: blue;
  }

  button {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
  font-size: 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  font-size: 18px;
  }

  .button-tabs {
    background: transparent;
    border: 0;
  }

`;

export function LinkCategory({ item, activeCategory, onClick }) {
  return (
    <LinkStyle key={item} className={`p-2 ${activeCategory === item && "border-bottom-blue"}`}>
      <button type="button" onClick={onClick} className={`text-black text-decoration-none text-nowrap p-1 ${activeCategory === item && "text-blue"} button-tabs`} >
        {item}
      </button>
    </LinkStyle>
  );
}
