import { useEffect } from 'react'
import Select from "react-select";
import './style.css'

export function Dropdown(props) {
  const { options, selected, setSelected, defaultValue } = props;

  return (
    <>
      <Select className="custom-dropdown" options={options} value={selected} onChange={(option) => setSelected(option.value)} defaultValue={defaultValue ? options.find(({ value }) => value === defaultValue) : 'Select...'} />
    </>
  )
}