import { useEffect, useState } from "react"

import { ToggleSwitch } from "../../ToggleSwitch"
import TravelAgencyForm from "./../TravelAgency";
import { Dropdown } from "../../";

export default function TransportationForm(props) {
  const { name, handleInputChange, setIsTravelAgencyExist, extensionsData } = props

  const [visibility, setVisibility] = useState(false)

  const [updatedData, setUpdatedData] = useState(
    {
      transportation_name: '',
      transportation_type: '',
      transportation_depart_terminal: '',
      transportation_depart_gate: '',
      transportation_arrive_terminal: '',
      transportation_arrive_gate: '',
      transportation_seat: '',
      subData: [
        {
          transportation_travel_agency_name: '',
          transportation_travel_agency_email: '',
          transportation_travel_agency_phone: '',
          transportation_travel_agency_contact_name: '',
          transportation_travel_agency_code: '',
          transportation_travel_agency_url: ''
        }
      ],
    }
  )

  const types = [
    {
      value: 'Flight',
      label: 'Flight'
    },
    {
      value: 'Train',
      label: 'Train'
    },
    {
      value: 'Car',
      label: 'Car'
    },
    {
      value: 'Bus',
      label: 'Bus'
    },
  ]

  useEffect(() => {
    if (extensionsData && extensionsData.length > 0) setUpdatedData(extensionsData[0])
  }, [extensionsData])

  useEffect(() => {
    if (Object.keys(updatedData).length > 0) {
      let isTravelAgentNull = true
      Object.keys(updatedData).forEach(key => {
        if (key.startsWith('subData')) {
          const subDataArray = updatedData[key];
          subDataArray.forEach(obj => { // looping each key object
            Object.keys(obj).forEach(subKey => { // looping each key object value to check if not empty
              const value = obj[subKey];

              if (!value || value !== '') { // check value each key object
                isTravelAgentNull = false;
              }
            });
          });
        }
      })
      setVisibility(!isTravelAgentNull)
    }
  }, [updatedData, visibility])

  return(
    <div className='form-input-trip'>
      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Transportation's Name</label>
          <input type="text" name='transportation_name' value={updatedData.transportation_name} onChange={handleInputChange} className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Type</label>
          <select name="transportation_type" value={updatedData.transportation_type} className="form-input-group form-input simple-select" onChange={handleInputChange}>
            <option value={""}>Choose</option>
            {types.map((type) => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
          {/* <input type="text" name='transportation_type' onChange={handleInputChange} className='form-input' /> */}
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Depart Terminal</label>
          <input type="text" name='transportation_depart_terminal' value={updatedData.transportation_depart_terminal} onChange={handleInputChange} className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Depart Gate</label>
          <input type="text" name='transportation_depart_gate' value={updatedData.transportation_depart_gate} onChange={handleInputChange} className='form-input' />
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Arrive Terminal</label>
          <input type="text" name='transportation_arrive_terminal' value={updatedData.transportation_arrive_terminal} onChange={handleInputChange} className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Arrive Gate</label>
          <input type="text" name='transportation_arrive_gate' value={updatedData.transportation_arrive_gate} onChange={handleInputChange} className='form-input' />
        </div>
      </div>

      <div className='form-input-group'>
        <label htmlFor="">Seat</label>
        <input type="text" name='transportation_seat' value={updatedData.transportation_seat} onChange={handleInputChange} className='form-input' />
      </div>

      <>
        <div className="toggle-switch--container">
          <ToggleSwitch value={visibility} setValue={setVisibility} setIsTravelAgencyExist={setIsTravelAgencyExist} />
          <span className="label">Travel Agency ?</span>
        </div>
        {visibility && <TravelAgencyForm name={name.toLowerCase()} visibility={visibility} setValue={setVisibility} handleInputChange={handleInputChange} updatedData={updatedData} />}
      </>
    </div>
  )
} 