import defaultImageUser from "../assets/images/defaultUser.svg";
import moment from "moment";
import styled from "styled-components";
import blankImg from "../assets/images/404.svg";

const Content = styled.div`
  font-size: 12px;
  p{
    margin-bottom:0px;
  }
`;

const Description = styled.p`
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
`

export function PopNotification(props) {
  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }
  const date = new Date(props.time);
  return (
    <div
      className="row pt-2 pe-4 align-items-center d-flex"
      type="button"
      onClick={() => props.type !== 1 
        ? props.type < 2 
          ? props.commentId !== ''
            ? props.readNotif(props.notifId, props.articleId, true, props?.commentId || '') 
            : props.readNotif(props.notifId, props.articleId)
          : props.readNotif(props.notifId, props.articleId, true, props?.commentId || '') 
        : props.readNotif(props.notifId, props.articleId)}
      style={{ backgroundColor: `${props.read === false && "#eeeeee"}`, borderRadius: "10px", overflow: "hidden", cursor: "pointer" }}
      >
      <div className="col-2">
        <img src={props.imageUser ? props.imageUser : defaultImageUser} alt={props.title ?? "article"} style={{ width: "40px", height: "40px", borderRadius: "50%" }} width="40px" height="40px" />
      </div>
      <div className="col-8">
        <Content className="lh-base">
          <p className="fw-normal text-wrap">
            <span className="fw-bold">{props.userFrom}</span> {props.activity}
          </p>
          {/* <p className="fw-bold"> {props.content.substring(0, 100)}</p> */}
          <Description>{props.content.substring(0, 100)}</Description>
          <p>{date.getTime() / 1000 < 604800 ? moment(props.time).fromNow() : moment(props.time).format("MMM D, YYYY")}</p>
        </Content>
      </div>
      <div className="col-2">
        <img src={!isImage(props.imageArticle) ? blankImg : props.imageArticle} alt={props.imageArticle?.imageCaption} style={{ width: "60px", height: "50px" }} width="60px" height="60px" />
      </div>
    </div>
  );
}
