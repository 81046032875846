import { useState, useEffect } from "react";
import * as articlesData from "../data/articles";
import styled from "styled-components";
import { Link } from "react-router-dom";
import blankImg from "../assets/images/404.svg";
import { NoData, ImageComponent, LoadingScreen } from "../components"

import Articles from './../data/json/Articles.json'

const Title = styled.h4`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  padding-bottom: 0.5em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
`;

const Card = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  display: flex;
  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .username {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 0;
  }
  .card-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 2px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .reading-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.6);
  }
  .image {
    width: 70px;
    min-width: 70px;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 10px;
  }
  .image > img {
    aspect-ratio: 1 /1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .username {
      font-size: 14px;
    }
    .card-title {
      font-size: 14px;
    }
    .reading-time {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .username {
      font-size: 14px;
    }
    .card-title {
      font-size: 16px;
    }
    .reading-time {
      font-size: 12px;
    }
  }
`;

const SeeAll = styled.a`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;

  font-size: 14px;
  font-weight: 400;
  text-decoration: none;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

export function RecommendedArticles(props) {
  const [articles, setArticles] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "?limit=5";
      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
        setIsFetching(false)
      }
      setArticles(() => res.data);
      setIsFetching(false)
    };
    getArticles();
  }, []);

  return (
    <div className="col-12 pt-5">
      <Title>Recommended Article</Title>
      <div className='d-flex flex-column gap-3'>
        {!isFetching 
        ? articles.length
          ? articles.map((article, index) => (
              <Card key={index}>
                <div className="image mt-1 d-md-none d-lg-block">
                  <ImageComponent src={isImage(article.thumbnail) ? article.thumbnail : article.image ? article.image : blankImg} alt={article.imageCaption} />
                </div>
                <div className="card-text">
                  <p className="username">
                    {article.user.traveler?.name} - <span className="reading-time">{article.readingTime.text}</span>
                  </p>
                  <p className="card-title">
                    <Link to={`/articles/${article.slug}`} className="card-title link-focus">
                      {article.title}
                    </Link>
                  </p>
                </div>
              </Card>
            ))
          : Articles.data.length 
            ? Articles.data.map((article, index) => (
              <Card key={index}>
                <div className="image mt-1 d-md-none d-lg-block">
                  <img src={article.image} alt={article.imageCaption} width="70px" height="60px" />
                </div>
                <div className="card-text">
                  <p className="username">
                    {article.user.traveler?.name} - <span className="reading-time">{article.readingTime.text}</span>
                  </p>
                  <p className="card-title">
                    <Link to={`#`} className="card-title">
                      {article.title}
                    </Link>
                  </p>
                </div>
              </Card>
            )) 
            : <NoData /> 
          : <div className="d-flex justify-content-center"><LoadingScreen /></div>}
      </div>
    </div>
  );
}
