import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ImageComponent } from '../Image'
import './style.css'

import { HiArrowUpRight } from "react-icons/hi2";
import { formatDistance } from 'date-fns';

export function CardPopularDestination(props) {
  const { data } = props;
  const [durationInDays, setDurationInDays] = useState('0 Days')

  useEffect(() => {
    const start = data.startDate;
    const end = data.endDate;

    if (start && end) {
      const duration = formatDistance(start, end);
      setDurationInDays(duration)
    }
  }, [data])

  return (
    <Link to={`/itineraries/${data.id}`}>
      <div className='card-popular-destination--container'>
        <div className='image-section'>
          <ImageComponent src={data?.thumbnail ?? '' } alt={data.title} />
        </div>

        <div className='content-section'>
          <div className='sub-content--container'>
            <h3 className='title-card'>{data.name}</h3>
          </div>
          <div className='sub-content--container'>
            <span className='link-active'><Link to={`/user-articles/${data?.user?.username ?? ''}`}>{`@${data?.user?.username ?? ''}`}</Link></span>
            <span className='sub-content-text color-gray'><HiArrowUpRight /> {durationInDays}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}