import { useEffect, useState } from 'react'
import './style.css'

import { IoIosArrowRoundForward  } from "react-icons/io";
import { IoPencil, IoTrash, IoSettingsOutline, IoTriangle } from "react-icons/io5";

import { ItineraryTripExtension } from './../'
import { removeTrip } from '../../data/itinerary';

import Swal from 'sweetalert2'

export function ItineraryTripCard(props) {
  const { index, trip, iconCategory, tripDuration, countryFlag, dataExtension, formatDate, actionShow, toggleActionShow, cardExpand, toggleCardExpand, navigate, handleUpdateTrip } = props;

  const [isRemoving, setIsRemoving] = useState(false)

  const handleRemoveTrip = async() => {
    setIsRemoving(true)
    const result = await removeTrip(trip.id)

    if (result?.data?.code !== 201) {
      setIsRemoving(false)
      const message = result?.response?.data?.data?.message ?? 'Remove trip failed'
      return Swal.fire({ title: 'Something went wrong!', text: message, icon: 'error' })
    }
    
    setIsRemoving(false)
    return Swal.fire({ title: 'Remove Trip Success!', text: 'trip remove successfully!', icon: 'success', })
    .then((result) => {
      if (result.isConfirmed) navigate(0)
    })
  }

  const removeTripDialog = () => {
    Swal.fire({ title: 'Are you sure ?', icon: 'warning' }).then((result) => {
      if (result.isConfirmed) handleRemoveTrip()
    })
  }

  return (
    <div className='itinerary-card bubble-left'>
      <div className='border-left-hider'></div>

      <div className='icon-category--container'>
        {iconCategory}
      </div>

      <div className='itinerary-card--title-container'>
        <div className='itinerary-card--title-wrapper'>
          <h5 className='date-event'>{formatDate(trip?.startDate ?? new Date(), 'dd, MMM yyyy')}</h5>
          <div className='destination-event'>
            <h5>{trip?.title ?? ''}</h5>
          </div>
          {trip.categoryTrip === "Transportation" && <h5 className='duration-event'>{`${tripDuration?.hours ?? 0}h ${tripDuration?.minutes ?? 0}m`}</h5>}
        </div>

        <div className='itinerary-card--budget d-none'>
          <h3>$5.000</h3>
        </div>
      </div>
      
      <div className='itinerary-card--content-container'>
        <div className='itinerary-card--start-container'>
          <div className='itinerary-card--icon d-none'>
            {trip.categoryTrip === "Travel" && <img src={countryFlag} alt="country's flag" /> }
            {trip.categoryTrip === "Activity" && 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
              </svg>
            }
            {trip.categoryTrip === "Accomodation" && 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
              </svg>
            
            }
          </div>

          <div className='itinerary-card--title-flight'>
            <h3>{trip?.subTitle ?? ''}</h3>

            {trip.categoryTrip === "Travel" && <span>Economy</span>}
            {trip.categoryTrip === "Activity" && 
              <span>{dataExtension.address}</span>
            }
          </div>
        </div>

        <div className='itinerary-card--middle-container'>
          {trip.categoryTrip === 'Travel' && 
            <>
              <div className='itinerary-card--route'>
                <span className='country-name'>{trip?.startCityorCountry ?? ''}</span>
                <IoIosArrowRoundForward style={{ width: 30, height: 30, color: '#cfd0cf' }} />
                <span className='country-name'>{trip?.endCityorCountry ?? ''}</span>
              </div>
              <span className='itinerary-card--seat'>Seat: <i>None</i></span>
            </>
          }

        </div>

        <div className='itinerary-card--end-container'>
          <div className='info-text'>
            {trip.categoryTrip === "Transportation" &&
              <>
                <span>Departs</span>
                <span>Arrives</span>
              </>
            }
            {trip.categoryTrip === "Lodging" &&
              <>
                <span>Check In</span>
                <span>Check Out</span>
              </>
            }
            {trip.categoryTrip === "Activities" && 
              <>
                <span>Start</span>
                <span>End</span>
              </>
            }
            {trip.categoryTrip === "Culinary" && 
              <>
                <span>Start</span>
                <span>End</span>
              </>
            }
          </div>

          <div className='time-text'>
            <span>{trip.categoryTrip === 'Lodging' ? formatDate(trip.tripAccomodation[0].checkIn, 'dd, MMM yyy HH:mm') : formatDate(trip.startDate, 'dd, MMM yyy HH:mm')}</span>
            <span>{trip.categoryTrip === 'Lodging' ? formatDate(trip.tripAccomodation[0].checkOut, 'dd, MMM yyy HH:mm') : formatDate(trip.endDate, 'dd, MMM yyy HH:mm')}</span>
          </div>
        </div>
      </div>

      <button type='button' className='icon-action--container' onClick={toggleActionShow}>
        <IoSettingsOutline width={20} height={20} />
      </button>

      <div className='action--list--container' style={{ display: `${actionShow ? 'block' : 'none'}`}}>
        <ul>
          <li onClick={() => handleUpdateTrip(trip)}>
            <IoPencil width={20} height={20} />
            Update
          </li>
          <li onClick={removeTripDialog}>
            <IoTrash width={20} height={20} />
            Delete
          </li>
        </ul>
      </div>
      
      <div className='btn-more-information--container'>
        <button type='button' className='btn-more-information' onClick={toggleCardExpand}>
          <IoTriangle className={`${cardExpand && 'opened' }`}/>
        </button>
        <h5>More Information...</h5>
      </div>

      <div className={`extension-information--container ${cardExpand && 'visible'}`}>
        <ItineraryTripExtension trip={trip} formatDate={formatDate} />
      </div>
    </div>
  )
}