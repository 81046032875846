import { useState, useEffect, useRef } from "react";
import * as articlesData from "../data/articles";
import styled from "styled-components";

const NameLabel = styled.label`
  font-size: 1.3rem;
  padding-bottom: 0em;
  color:white;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const SuggestionsArticle = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
  max-height: 200px;
  overflow: scroll;
  width:100%;

  > li {
    list-style: none;
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
  }
  li:hover {
    background-color: #d4d4d4;
  }

`;

const LabelFeatured = styled.label`
  width: 100%;
  aspect-ratio: 3/2;
  border: 1px dashed grey;
  font-size: 1rem;
  overflow: hidden;
  color: white;
  font-size: 0.7rem;
  background-color: #aaaaaa;
  color: white;

  &:hover {
    color: black;
  }
`;

const Button = styled.button`
  background: transparent;
  border-radius: 4px;
  margin: 0 0.5em;
  padding: 0.25em 0.75em;
  font-size: 0.9rem;
  border: 1px solid black;

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;

export function SubArticleForm({
  removeSubArticle,
  subArticle,
  titleSubArticle,
  setTitleSubArticle,
  contentSubArticle,
  setContentSubArticle,
  typeSubArticle,
  saveSubArticle,
  idEdit,
  setIdEdit,
  setShowSuggestionsArticle,
  showSuggestionsTitle,
  articles,
  setSelectArticleId,
  listSubArticle
}) {
  
  const [suggestionArticles, setSuggestionArticles] = useState([]);
  const [suggestionsArticleValue, setSuggestionsArticleValue] = useState("");
  const [isInputActive, setIsInputActive] = useState(false)

  function editSubArticle(id) {
    if (subArticle.id === id) {
      setIdEdit(id);
      setTitleSubArticle(subArticle.title);
      setContentSubArticle(subArticle.description)
      setSelectArticleId(subArticle.id);
    }
  }

  const handleSuggestionArticle = (suggestion) => {
    setSuggestionsArticleValue("");
    setSelectArticleId(suggestion.id);
    setShowSuggestionsArticle(false);
    setTitleSubArticle(suggestion.title)
    setContentSubArticle(suggestion.content);
  };

  useEffect(() => {
    let filteredDuplicateArticle = articles?.filter(article => {
      return !listSubArticle.some(subArticle => subArticle.id === article.id) && article.isSubArticle;
    });
    
    // Filter the filtered articles based on suggestionsArticleValue
    let suggestionArticles = filteredDuplicateArticle?.filter(article => article.title.toLowerCase().includes(suggestionsArticleValue));

    setSuggestionArticles(suggestionArticles)

  }, [articles, listSubArticle, suggestionsArticleValue]);

  return (
    <div className="">
      <div className="mb-3  mt-4">
        <div className="d-flex">
          <NameLabel htmlFor="title" className="form-label" style={{ color: 'black' }}>
            Title
          </NameLabel>
          {typeSubArticle !== "new Sub Article" && (
            <div className="ms-auto">
              {subArticle.id !== idEdit && (
                <Button type="button" onClick={() => editSubArticle(subArticle.id)}>
                  <i className="fa-regular fa-pen-to-square"></i> Edit
                </Button>
              )}
              {subArticle.id === idEdit && (
                <Button type="button" onClick={() => {saveSubArticle(subArticle.id); 
                  setIsInputActive(!isInputActive)}}>
                  <i className="fa-regular fa-save"></i> Save
                </Button>
              )}

              <Button type="button" onClick={() => removeSubArticle(subArticle.id)}>
                &#10005;
              </Button>
            </div>
          )}
        </div>
        {typeSubArticle !== "new Sub Article" ? (
          <input
            readOnly
            className="form-control"
            value={typeSubArticle === "new Sub Article" ? titleSubArticle : subArticle.title}
            // onFocus={() => {setShowSuggestionsArticle(true)}}
            onChange={(e) => {setSuggestionsArticleValue(titleSubArticle); setTitleSubArticle(e.target.value)}}
            name="title"
            id="title"
            placeholder="Add title ..." />
        ) : (
          <input
            id="title"
            name="title"
            className="form-control"
            placeholder="Choose article"
            value={typeSubArticle === "new Sub Article" || subArticle.id === idEdit ? titleSubArticle : subArticle.title}
            onFocus={() => {setShowSuggestionsArticle(true)}}
            onChange={(e) => {setSuggestionsArticleValue(e.target.value); setTitleSubArticle(e.target.value)}} 
            readOnly={typeof subArticle?.id !== 'undefined' && typeof idEdit !== 'undefined' && idEdit === subArticle?.id} />
        )}
        {showSuggestionsTitle && (
          <SuggestionsArticle className="suggestions">
            {suggestionArticles.length > 0
              ? suggestionArticles.map((article) => (
                  <li key={article.id} onClick={() =>{ handleSuggestionArticle(article)}}>
                    {article.title}
                  </li>
                ))
              : ""}
          </SuggestionsArticle>
        )}
      </div>

      <div className="mb-3  mt-4">
        <NameLabel htmlFor="exampleFormControlTextarea1" className="form-label" style={{ color: 'black' }}>
          Content
        </NameLabel>
        {typeSubArticle !== "new Sub Article" ? (
          <textarea
            readOnly
            type="text"
            className="form-control"
            value={typeSubArticle === "new Sub Article" || subArticle.id === idEdit ? contentSubArticle : subArticle.description}
            onChange={(e) => setContentSubArticle(e.target.value)}
            name="content"
            id="content"
            placeholder="Add content ..."
            rows="3"
          ></textarea>
        ) : (
          <textarea
            type="text"
            className="form-control"
            value={typeSubArticle === "new Sub Article" || (subArticle.id === idEdit && contentSubArticle !== undefined) ? contentSubArticle : subArticle.description}
            onChange={(e) => setContentSubArticle(e.target.value)}
            name="content"
            id="content"
            placeholder="Add content ..."
            rows="3"
          ></textarea>
        )}
      </div>
    </div>
  );
}
