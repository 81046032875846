import { useState } from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ImageComponent, Metadata } from '../components/';

import Swal from 'sweetalert2'
import * as auth from './../data/auth'

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  display: flex;
  justify-content: center;
  margin: 10rem 0.5rem;
`;

const ForgetPasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 5rem 1rem 1rem;

  img {
   width: 150px;
   height: 83px;
   object-fit: cover;
  }
`;

const ButtonSubmit = styled.button`
  background: black;
  color: white;
  font-weight: 500;
  font-size: 1em;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 8px;
  

  :hover {
    background white;
    color: black;
  }
`;

export function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const forgetPassword = async (e) => {
    e.preventDefault();
    setIsSubmit(true)
    
    await auth.forgetPassword({
      email
    })
    .then((response) => {
      setIsSubmit(false)
      if (response?.status === 201) {        
        Swal.fire({
          title: 'Request Reset Password Sucess',
          icon: 'success',
          text: 'Please check your email to continue this process..',
          confirmButtonText: 'Okay',
          confirmButtonColor: '#000'
        })
        return;
      }

      Swal.fire({
        title: 'Something Happened',
        icon: 'error',
        text: response?.data?.message || 'the email is not found, try another!',
        confirmButtonText: 'Okay',
        confirmButtonColor: '#000'
      })
    })
    .catch((err) => {
      setIsSubmit(false)
      Swal.fire({
        title: 'Request Reset Password Failed',
        text: 'Oopss' + err,
        icon: 'error',
        confirmButtonText: 'Okay',
        confirmButtonColor: '#d33'
      })
    })
  }

  return (
    <div className='container'>
    <Metadata page={window.location.href} titlePage={`Forget Password • Bipi World`} />
      
      <Container>
        <ForgetPasswordForm>
            <ImageComponent src={`https://drive.bipi.world/logo/BipiLogoBlack.png`} alt={'logo bipi'} />
            <p className='my-3'>
              Fear not. We'll email you instructions to reset your password.
            </p>

            <form action="POST" onSubmit={forgetPassword}>
              <div className='row'>
                <div className='col-sm-12'>
                  <input type="email" className='form-control' placeholder='Your Email' onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className='d-flex align-items-center gap-3 mt-5'>
                  <ButtonSubmit isSubmitBtn={true} type='submit' disabled={isSubmit}>Send Email</ButtonSubmit>
                  <Link to="/">Return to login</Link>
                </div>
              </div>
            </form>
        </ForgetPasswordForm>
      </Container>
    </div>
  )
}