import EmptyWhite from '../assets/images/BipiLogoWhite.png';
import EmptyBlack from '../assets/images/empty-folder.png';

export function NoData(props) {
  const { styles, IsBgGray = false } = props;
  return (
    <div id='EmptyComponent' className={`d-flex flex-column align-items-center justify-content-center ${styles ? styles : ''}`}>
      {/* <div>
        <img src={IsBgGray ? EmptyWhite : EmptyBlack} width={100} height={IsBgGray ? 50 : 100} alt="Empty icons created by smashingstocks - Flaticon" />
      </div> */}
      <span className='text-center fw-bold mt-2 text-semi-transparent'>
        No Data...
      </span>
    </div>
  )
}