import robot from "../assets/images/unauthorized.svg";
import styled from "styled-components";
import { ButtonModal, Metadata } from "../components";

const Style = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  margin-bottom: 20vh;
  margin-top: 13vh;
  img {
    width: 150px;
  }
  h1 {
    margin-top: 10px;
    font-size: 23px;
  }
  p {
    font-size: 14px;
  }


  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    img {
      width: 200px;
    }
    h1 {
      margin-top: 10px;
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
    height: 60vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    img {
      width: 220px;
      margin-top: 50px;
    }
    h1 {
      margin-top: 10px;
      font-size: 32px;
    }
    p {
      font-size: 18px;
      padding-bottom: 10px;
    }
    height: 26em;
  }
`;

const Unauthorized = () => (
  <Style className="px-2  d-flex justify-content-center align-items-center">
    <Metadata page={window.location.href} titlePage={`Unauthorize • Bipi World`} />
    
    <div className="text-center content">
      <img src={robot} width="220px" height="220px" />
      <h1>Unauthorized page</h1>
      <h2 className="d-none">Unauthorized page</h2>
      <h3 className="d-none">Unauthorized page</h3>
      <p>You must login first to be able to access this page.</p>
      <ButtonModal url="#authModal" text="Log In" />
    </div>
  </Style>
);

export default Unauthorized;
