import axios from "../plugins/axios";

export function setHeaderToken(token) {
  if (!token) {
   token = JSON.parse(localStorage.getItem("tokens"))?.data?.access_token;
  }
  
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export function removeHeaderToken() {
  delete axios.defaults.headers.common["Authorization"];
}
