import styled from "styled-components";

export function Label(props) {
  const Label = styled.div`
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
    .label-link {
      font-family: "Roboto", sans-serif;
      background-color: #eaeaea;
      color: black;
      border-radius: 8px;
      text-decoration: none;
      padding: 2px 11px;
      text-transform: capitalize;
      left: -15px;
      font-size: 12px;
    }
    img {
      left: -10px;
      width: 20px;
      padding-right: 5px;
      margin-top: -2px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      .label-link {
        font-size: 8px;
      }
    }
  `;

  const bgLabel = " position-relative ps-4 pt-1 pb-1 label-link label-link-blue";
  const bgCircle = "position-absolute top-50 start-1 translate-middle p-1 pt-1 border rounded-circle circle circle-blue";

  return (
    <Label className="ps-0 mb-2">
      <span className={`ps-1 pt-1 pb-1 label-link`}>
        <img src={props.icon ? props.icon : props.image ? props.image : props.blankImg} alt={props.text} />
        {props.text}
      </span>
    </Label>
  );
}
