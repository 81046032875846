import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function getUsersProfile() {
  try {
    setHeaderToken();
    const users = await axios.get(`users/account/articles`).then((response) => {
      return response.data;
    });
    return users;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getArticlesStatistics() {
  try {
    setHeaderToken();
    const articles = await axios.get(`users/statistic/articles`).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getArticleStatistic(id) {
  try {
    setHeaderToken();
    const articles = await axios.get(`users/statistic/articles/${id}`).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getActivityLogs(activity) {
  try {
    setHeaderToken()
    const activityLogs = await axios.get(`users/activity/logs/${activity}`).then((res) => {
      return res.data;
    })
    return activityLogs;
  } catch (error) {
    return error.response.data;
  }
}


