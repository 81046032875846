import { Metadata, Comments, Share, ShareModal, Like, Save, Gallery, TitleArticle, CardArticleDescBottom, NoData, CardArticleHorizontal, LoadingScreen, ImageSlider } from "../components";
import { useState, useEffect, useContext } from "react";
import * as articlesData from "../data/articles";
import { useParams, Link, useSearchParams } from "react-router-dom";
import blankImg from "../assets/images/404.svg";
import styled from "styled-components";
import defaultImageUser from "../assets/images/defaultUser.svg";

import moment from "moment";
import AuthContext from "../components/shared/AuthContext";

import { useMediaQuery } from "react-responsive";

const Banner = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  min-width: 100%;
  overflow: hidden;

  > img {
    width: 100%;
    height: auto;
    // object-fit: cover;
    display: block;
  }

`;
const Tag = styled.a`
  font-size: 12px;
  padding: 0;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
`;
const ImageSubArticle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;

  > img {
    width: 100%;
    height: 100%;
    position: center;
    object-fit: cover;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    height: 30vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    height: 75vh;
  }


`;
const Title = styled.h1`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-top: -15px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 28px;
  }
`;
const TitleSubArticle = styled.h3`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  // font-size: 18px;
  margin-top: -15px;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    // font-size: 18px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 20px;
  }
`;
const CommentText = styled.h2`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  margin-right: 10px;
  margin-left: 10px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 28px;
  }
`;

const Breadcrumb = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  color: #8d8d8d;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 15px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
`;

const Profile = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  display: flex;
  margin-top: -1em;
  // border-top: 1.5px solid black;
  // padding-top: 1.5em;
  // margin-left: 8px;

  img {
    width: 50px;
    height: 50px;
  }
  .bio {
    font-family: "DM Sans", sans-serif;
    font-size: 11px;
    line-height: 20px;
  }
  .time {
    font-size: 12px;
  }

  .name-user {
    font-size: 12px;
    text-decoration: none;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .bio {
      font-size: 13px;
    }
    .name-user {
      font-size: 14px;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    img {
      width: 80px;
      height: 80px;
    }
    .bio {
      font-size: 15px;
    }
    .name-user {
      font-size: 16px;
    }
    .time {
      font-size: 14px;
    }
    .main-article {
      font-size: 16px;
    }
    .name-user {
      font-size: 20px;
      text-decoration: none;
    }
  }
`;
const Content = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  margin-left: 8px;
  margin-right: 8px;
  .main-article {
    font-size: 15px;
    font-family: "DM Sans", sans-serif;
    line-height: 1.5em;
  }
  .caption {
    font-size: 10px;
    font-family: "DM Sans", sans-serif;
    color: #AAAAAA;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    p {
      font-size: 14px;
    }
    .caption {
      font-size: 12px;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .caption {
      font-size: 14px;
    }
    p {
      font-size: 16px;
    }
  }
`;

export function Article() {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState([]);
  const [mainArticles, setMainArticles] = useState([]);

  const [isNotFetchingArticle, setNotFetchingArticle] = useState(false);
  const [isNotFetchingComment, setNotFetchingComment] = useState(false);

  const [imageList, setImageList] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const { user } = useContext(AuthContext)

  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "?limit=6&random=true";

      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
      }
      setArticles(() => res.data);

    };
    getArticles();
  }, []);

  useEffect(() => {
    const readArticle = async (idNotif, idArticle) => {
      await articlesData.readArticle(params.id)
    };
    if (user){
      readArticle();
    }
  }, [user, params]);

  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "/" + params.id;
      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
      }
      setArticle(res.data);

      setImageList([res.data?.image])
      if (res.data.articleGallery && res.data.articleGallery.length > 0) {
        res.data.articleGallery.map((img) => setImageList(currentImg => [...currentImg, img.image]))
      }
      setNotFetchingArticle(true);

      if (res.data.mainArticle) {
        const queryParam2 = "/" + res.data?.mainArticle?.id;
        const res2 = await articlesData.getArticles(queryParam2);
  
        if (res2.code !== 200) {
        }
        setMainArticles(() => res2.data);
      }
    };
    getArticles();
  }, [params]);

  const galleries = [];
  const galleriesLength = article?.articleGallery?.length;
  for (var i = 0; i < galleriesLength; i++) {
    galleries.push(article?.articleGallery?.[i]);
  }

  const tags = [];
  const tagsLenght = article?.tags?.length;
  for (let i = 0; i < tagsLenght; i++) {
    tags.push(article?.tags?.[i].name);
  }
  const subArticle = [];
  const subArticlesLength = article?.subArticles?.length;
  for (let i = 0; i < subArticlesLength; i++) {
    subArticle.push(article?.subArticles?.[i]);
  }
  const mSubArticle = [];
  const mSubArticlesLength = mainArticles?.subArticles?.length;
  for (let i = 0; i < mSubArticlesLength; i++) {
    mSubArticle.push(mainArticles.subArticles?.[i]);
  }

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  const [like, setLike] = useState();

  useEffect(() => {
    function goToComment() {
      const commentId = searchParams.get('comment');
      if (commentId && commentId !== '') {
        const commentElement = document.getElementById(commentId);
        if (commentElement) {
          commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); 
          
          commentElement.classList.add('fade-in');
  
          const fadeTimeout = setTimeout(() => {
            commentElement.classList.add('fade-out');
            commentElement.classList.remove('fade-in');
          }, 500);
  
          commentElement.addEventListener('animationend', () => {
            commentElement.classList.remove('fade-out');
          });
  
          return () => {
            clearTimeout(fadeTimeout);
            commentElement.removeEventListener('animationend', () => {});
          }
        }
      }
    }

    if (isNotFetchingArticle && isNotFetchingComment) goToComment()

  }, [searchParams, isNotFetchingArticle, isNotFetchingComment])


  if (!isNotFetchingArticle && !isNotFetchingComment) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}><LoadingScreen /></div>
    )
  }

  return (
    <article className="article d-flex flex-column" style={{ marginTop: "4.5rem", gap: "3rem", marginBottom: "3rem" }}>

      <Metadata page={window.location.href} article={article} id={params.id} isPublic={!params?.mode ? true : false} />

      <div className="d-flex flex-column gap-1">
        <Breadcrumb className="text-center mt-lg-3">{article?.category?.name}</Breadcrumb>

        <Title className="text-center">{article?.title}</Title>
        {/* <Banner>
          <img src={!isImage(article?.image) ? blankImg : article?.image} alt={article?.imageCaption} />
        </Banner> */}

        <ImageSlider imageList={imageList} isMobile={isMobile} />
      </div>

      <div className="container d-flex flex-column gap-4 col-md-10 col-lg-9">
        <Profile className="align-items-center justify-content-between gap-3 mb-3">
          <div className="d-flex gap-3 align-items-center">
            <img src={article?.user?.traveler?.image !== "" && article?.user?.traveler !== null ? article?.user?.traveler?.image : defaultImageUser} className="rounded-circle" alt={article?.user?.username} />
            <div>
              <Link to={`/user-articles/${article?.user?.username}`} className="fw-bolder name-user link-focus">
                {article?.user?.traveler?.name}{" "}
              </Link>
              {/* <h6 className="occupation">{article?.user?.traveler?.occupation === null && "Traveler"}</h6> */}
              <p className="time">{moment(article?.createdAt).format("MMM D, YYYY") + ' - ' + article?.readingTime?.text}</p>
            </div>
          </div>

          <div className={`${params?.mode ? 'd-none' : 'd-flex align-items-center'}`}>
            <div className="me-4">
              <span className="me-2">{user ? like ? like : article?.likes : ""}</span>
              <Like id={article.id} setLike={setLike} />
            </div>
            <div className=" me-4">
              <Save id={article.id} />
            </div>
            <div className="me-2">
              <Share id={article.id} />
            </div>
          </div>
        </Profile>

        <Content>
          {article?.isSubArticle === true ? (
            <div className="row justify-content-between">
              <div className="col-md-8 pe-md-5">
                <div dangerouslySetInnerHTML={{__html: article?.content }} />
              </div>
              <div className="col-md-4">
                {article?.hasMap && 
                  <iframe
                    title="Map"
                    src={`https://www.google.com/maps?q=${article?.placeLatitude}, ${article?.placeLongitude}&z=15&output=embed`}
                    // -7.215650, 112.769650
                    width="100%"
                    height="200"
                    style={{ border: 0, marginBottom: "20px", borderRadius: "10px" }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  />
                }
                <h4 className="mt-1 mt-md-2 my-4">
                  <a href={`/articles/${mainArticles?.slug}`} className="text-decoration-none link-focus">
                    {mainArticles?.title}{" "}
                  </a>
                </h4>
                {mSubArticle.length > 0
                ? mSubArticle?.map((subArticle, index) => isMobile ? <CardArticleHorizontal key={subArticle.id} article={subArticle} type="article" /> : <TitleArticle key={subArticle.id} subArticle={subArticle} />) 
                : ""}
                {/* <section className="gallery mt-1" style={{ borderRadius: "8px" }}>
                  {galleries.length ? <Gallery /> : ""}
                </section> */}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                
                <div className="mb-5" dangerouslySetInnerHTML={{__html: article?.content }} />
                {subArticle.length > 0
                  ? subArticle?.map((subArticle, index) => (
                      <div className="mt-5" key={index}>
                        <a className="text-decoration-none link-focus" href={`/articles/${subArticle.slug}`}>
                          <TitleSubArticle>{subArticle?.title}</TitleSubArticle>
                        </a>
                        <ImageSubArticle>
                          <img src={!isImage(subArticle?.image) ? blankImg : subArticle?.image} alt={subArticle?.imageCaption} width="917px" height="560px" />
                        </ImageSubArticle>
                        <p className="caption text-center mt-2">{subArticle?.imageCaption}</p>

                        <section>
                          <div dangerouslySetInnerHTML={{__html: subArticle?.description }} />
                        </section>
                      </div>
                    ))
                  : <div className="my-4">No Sub Article Available...</div>}
              </div>
            </div>
          )}

          <div className="d-flex mt-5 gap-2">
            <table>
              <tr>
                <td valign="top" style={{ paddingTop: '0.4rem' }}>Tags</td>
                <td valign="top" style={{ paddingTop: '0.4rem' }}>:</td>
              </tr>
            </table>
            <div className="d-flex flex-wrap gap-2">
              {tags.length
                ? tags.map((tag, index) => (
                    <Tag href={`/article-list?byTag=${tag}&orderBy=Newest`} key={index} className="btn btn-outline-dark py-2 px-3">
                      {tag}
                    </Tag>
                  ))
                : "-"}
            </div>
          </div>
        </Content>

        <Profile
          className="author-desc py-4 px-1 mt-3"
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <div className="d-flex align-items-center gap-3">
            <img src={article?.user?.traveler?.image !== "" && article?.user?.traveler !== null ? article?.user?.traveler?.image : defaultImageUser} className="rounded-circle" alt={article?.user?.username} width="80px" height="80px" />
            <div style={{ lineHeight: "10px" }}>
              <p className="name-user mb-2" style={{ fontWeight: "700" }}>
                <Link to={`/user-articles/${article?.user?.username}`} className="name-user link-focus">
                  {article?.user?.traveler?.name ?? "no name"}
                </Link>
              </p>
              <p style={{ fontWeight: "400" }} className="bio m-0">
                {article?.user?.traveler?.bio}
              </p>
            </div>
          </div>
        </Profile>

        {!params?.mode && (
          <>
            <div id="commentsHeader" className="d-flex justify-content-between align-items-center pt-3">
              <CommentText>Comments</CommentText>
            </div>
            <div className="mx-2 pt-0 mt-0" id="comments">
              <Comments currentId={params.id} setFetchingComment={setNotFetchingComment} />
            </div>
            
            <section className={`${articles.length > 0 ? 'd-block' : 'd-none'}`}>
              <Title className="m-4 mb-4 text-center font-bolder mb-3">What to read next</Title>
              <div className="media-scroller">
                <div className="d-flex  justify-content-center gap-3 ">
                  {articles.length > 0 
                  ? articles.slice(0, 6).map((article, index) => <CardArticleDescBottom key={index} article={article} />) 
                  : <NoData />}
                </div>
              </div>
            </section>
          </>
        )}

      </div>
      <ShareModal article={article} />
    </article>
  );
}
