import defaultImageUser from "../assets/images/defaultUser.svg";
import moment from "moment";
import blankImg from "../assets/images/404.svg";
import styled from "styled-components";
import { ImageComponent } from "./Image/";

const NotifStyled = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  cursor: ${(props) => (props.type !== 1 ? 'pointer' : 'auto')};

  .card-title {
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  .card-text {
    font-size: 12px;
  }

  .text-user {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  .profile > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .img-article > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .img-article {
    width: 60px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
  }
  .close {
    font-size: 12px;
    text-decoration: none;
    color: black;
    margin-left: 10px;
    border: 0;
    background: transparent;
  }
  h6{
    font-size:1rem;
  }


  @media only screen and (min-width: 360px) {
    .card-title {
      max-width: 150px;
    }
  }

  
  @media only screen and (min-width: 400px) {
    .card-title {
      max-width: 200px;
    }

    .text-user {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 559px) {
    .card-title {
      max-width: 320px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .card-title {
      font-size: 12px;
      max-width: 150px;
    }
    .card-text {
      font-size: 12px;
    }
    .profile > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .img-article > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .img-article {
      width: 100px;
      min-width: 100px;
      height: 70px;
      border-radius: 10px;
      overflow: hidden;
    }
    .close {
      font-size: 14px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .card-title {
      font-size: 16px;
      max-width: 250px;
    }
    .card-text {
      font-size: 14px;
    }
    .text-user {
      margin-bottom: 0.3rem;
    }
    .profile > img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    .img-article > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .img-article {
      width: 120px;
      height: 80px;
      border-radius: 10px;
      overflow: hidden;
    }
    .close {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .card-title {
      font-size: 16px;
      max-width: 450px;
      text-wrap: wrap;
    }
  }

`;

export function UserNotification(props) {
  
  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  return (
    <NotifStyled className="dropdown-item pb-2" type={props.type}>
      <div className="justify-content-between p-3 d-flex" style={{ backgroundColor: `${!props.read && "#eeeeee"}`, borderRadius: "10px" }}
      onClick={() => props.type !== 1 
        ? props.type < 2 
          ? props.commentId !== ''
            ? props.readNotif(props.id, props.idArticle, true, props?.commentId || '') 
            : props.readNotif(props.id, props.idArticle) 
          : props.readNotif(props.id, props.idArticle, true, props?.commentId || '') 
        : ''}>

        <div className="profile">
          <img src={props.imageUser ? props.imageUser : defaultImageUser} alt={props.title ? props.title : "article"}  width="80px" height="80px"/>
        </div>

        <div className="text-right">
          <span className="card-text text-wrap">
            <h6 className="fw-bold text-user">{props.userFrom}</h6> {props.activity}
          </span>
          <p className="card-title">{props.content}</p>
          <p className="card-text">{moment(props.time).fromNow()}</p>
        </div>

        <div className="ms-auto d-flex">
          <div className="img-article ms-auto">
            <ImageComponent src={!isImage(props.imageArticle) ? blankImg : props.imageArticle} alt={props.title} />
          </div>
          <div className="ms-auto ">
            <button className="close" onClick={(e) => {e.stopPropagation(); props.deleteNotif(props.id)}}>
              &#10005;
            </button>
          </div>
        </div>
      </div>
    </NotifStyled>
  );
}
