import { useEffect, useState } from 'react'

import './styles.css'
import MultiStepProgressBar from './ProgressBar';

export const MultiForm = (props) => {
  const { page, onPageNumberClick, formList, showForm, handleSubmit, isSubmit, handleSubmitUpdate, isModeUpdate } = props;

  return (
    <div className='multiform--wrapper'>
      <MultiStepProgressBar page={page} onPageNumberClick={onPageNumberClick} formList={formList} />
      { showForm && formList && formList.length > 0 
        ?  formList.map((data, index) => 
              <div key={index} className={`form-input-extension--container overflow-scroll ${parseInt(page) === index + 1 ? 'visible': ''}`}>
                <h3 className="data-extension--title"><span>{data?.titleForm ?? ''}</span></h3>
                  {data?.renderForm()}
              </div>
            )
        : 'No Form'
      }

      <div className='mt-4 d-flex gap-3'>
        {page < formList.length 
          ? <>
              {parseInt(page) > 1 && <button type="button" className="rounded btn btn-outline-dark btn-md" onClick={() => onPageNumberClick(page, 'previous')}>Prev</button>}
              <button type="button" className="rounded btn btn-outline-dark btn-md" onClick={() => onPageNumberClick(page, 'next')}>{parseInt(page) === formList.length - 1 ? 'Review' : 'Next'}</button>
            </> 
          : <>
              <button type="button" className="rounded btn btn-outline-dark btn-md" onClick={() => onPageNumberClick(page, 'start')}>Edit</button>
              <button type="button" className="rounded btn btn-outline-dark btn-md" onClick={() => {if (!isModeUpdate) {handleSubmit()} else {handleSubmitUpdate()} }} disabled={isSubmit}>{isSubmit ? 'Loading...' : 'Submit'}</button>
            </>
        }
      </div>
    </div>
  );
};