/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import {
  CardArticleBig,
  Button,
  SearchBar,
  RecommendedBuddies,
  Paginate,
  Metadata
} from "../components";
// import "./style.css";
import * as articlesData from "../data/articles";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import defaultImageUser from "../assets/images/defaultUser.svg";

const ButtonPlus = styled.a`
  border-radius: 8px;
  border: 1px solid black;
  font-size: 25px;
  text-decoration: none;
  color: black;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 30px;

  display: none;

  &:hover {
    background-color: black;
    color: white;
  }
  
  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const Profile = styled.div`
  .image {
    width: 120px;
    height: 120px;
    min-width: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 30px;
  }
  .image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .username {
    font-weight: 500;
    font-size: 16px;
  }
  .name {
    font-size: 24px;
    line-height: 21px;
    margin-bottom: 6px;
  }

  .text-occupation {
    font-weight: 600;
    font-size: 14px;
  }
  .text-bio {
    font-size: 14px;
    display: inline;
  }
  a {
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin-top: 10px;
  }
  .bio {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .engagement > div {
    margin-right: 2em;
  }
  .number {
    font-size: 28px;
  }

  .btn-edit {
    width: 100%;
    color: black;
    font-weight: 500;
    background: white;

    padding: 0.3rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 4px;

    cursor: pointer;
    text-align: center;
  }

  .btn-edit:hover {
    background: black;
    color: white;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 0 10px;
    flex-wrap: wrap;

    .image {
      width: 90px;
      height: 90px;
      min-width: 90px;
      margin-bottom: 15px;
      order: 1;
    }
    .profile {
      order: 3;
      margin-top: 10px;
    }
    .engagement {
      order: 2;
      margin-left: 15px;
    }
    .image > img {
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .image {
      width: 120px;
      height: 120px;
      min-width: 120px;
    }
    .username {
      font-size: 18px;
    }
    .name {
      font-size: 26px;
    }

    .text-occupation {
      font-size: 14px;
    }
    a {
      font-size: 16px;
    }
    .engagement {
      margin-left: 45px;
    }
    .bio {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 62px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    justify-content: space-between;

    .image {
      width: 150px;
      height: 150px;
      min-width: 150px;
      margin-right: 20px;
    }
    .profile {
      width: 40vw;
      margin-right: 10vw;
    }
    .username {
      font-size: 18px;
      margin-top: 6px;
    }
    .name {
      font-size: 32px;
    }
    .text-occupation {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
    .engagement > div {
      margin: 0 2.5em;
    }
    .number {
      font-size: 32px;
    }
    .engagement {
      margin-right: 70px;
    }

    .btn-edit {
      width: 50%;
    }
  }

  
  @media only screen and (min-width: 1200px) {
    .btn-edit {
      width: 25%;
    }
  }
`;

const Layout = styled.div`
  .search h6 {
    font-size: 16px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    flex-direction: column;

    .search {
      order: 1;
      margin-bottom: 15px;
    }
    .articles {
      order: 2;
    }
    .search h6 {
      font-size: 16px;
      margin-right: 10px;
      margin-top: 23px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .search h6 {
      font-size: 18px;
    }
  }
`;

export function UserProfile() {
  const params = useParams();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const search = queryParameters.get("search");
  const [activeCategory, setActiveCategory] = useState();
  const orderBy = queryParameters.get("orderBy");

  const [userProfile, setUserProfile] = useState([]);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "/" + params.username;
      const res = await articlesData.getArticlesUser(queryParam);

      if (res.code !== 200) {
      }
      setUserProfile(res.data);
      setIsFetchingUser(false);
    };
    getArticles();
  }, [params.username]);

  const [articlesUser, setArticlesUser] = useState([]);
  useEffect(() => {
    const getArticles = async () => {
      let queryParam;
      if (search !== null) {
        queryParam =
          "?createdByUser=" +
          params.username +
          "&limit=200&" +
          "search=" +
          search;
      }
      if (search === null && orderBy === null) {
        queryParam = "?createdByUser=" + params.username + "&limit=200&";
      }
      if (orderBy === "Newest") {
        queryParam =
          "?createdByUser=" + params.username + "&order=desc&limit=100";
      }
      if (orderBy === "Best") {
        queryParam =
          "?createdByUser=" +
          params.username +
          "&orderBy=likes&order=desc&limit=100";
      }
      if (orderBy === "Popular") {
        queryParam =
          "?createdByUser=" +
          params.username +
          "&orderBy=views&order=desc&limit=100";
      }
      const res = await articlesData.getArticles(queryParam).then((res) => {
        setArticlesUser(() => res?.data);
      });
    };
    getArticles();
  }, [orderBy, params.username, search]);

  const countArticle = [];
  articlesUser.map((index) => countArticle.push(index));
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pageVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(countArticle.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [readMore, setReadMore] = useState(false);

  return (
    <div
      className="articles d-flex flex-column"
      style={{ marginTop: "6.5rem", gap: "4.5rem", marginBottom: "5rem" }}
    >
      <Metadata page={window.location.href} titlePage={`${!isFetchingUser ? userProfile?.traveler?.name + ' (@' + userProfile?.username + ') • User Profile' : '' }`} />

      <div className="container d-flex flex-column gap-5">
        <Profile className="d-flex">
          <div className="image">
            <img
              src={
                userProfile?.traveler?.image !== "" && userProfile?.traveler
                  ? userProfile?.traveler?.image
                  : defaultImageUser
              }
              width="150px"
              height="150px"
              alt={userProfile?.username}
            />
          </div>

          <div className="profile">
            <h1 className="name">{userProfile?.traveler?.name}</h1>
            <h2 className="username pt-1">{params.username}</h2>

            <div>
              <div>
                {userProfile?.traveler?.bio?.length > 90 ? (
                  <p className="text-bio" style={{ display: "inline" }}>
                    {readMore !== true
                      ? userProfile?.traveler?.bio?.substring(0, 90)
                      : userProfile?.traveler?.bio}{" "}
                    <a
                      className="text-bio"
                      style={{ cursor: "pointer" }}
                      onClick={() => setReadMore(!readMore)}
                    >
                      {readMore === true ? "Read less" : "Read More"}
                    </a>
                  </p>
                ) : (
                  <p className="text-bio" style={{ display: "inline" }}>
                    {userProfile?.traveler?.bio}
                  </p>
                )}
              </div>
            </div>

            <a
              href="/settings"
              className="btn-edit"
            >
              Edit Profile
            </a>
          </div>

          <div className="d-flex engagement align-self-center">
            <div className="text-center pe-3">
              <div className="number pb-1">{userProfile?.articles?.length}</div>
              <p>Post</p>
            </div>
            <div>
              <div className="number text-center pb-1">
                {userProfile?.statistic?.totalLikes}
              </div>
              <p>Likes</p>
            </div>
          </div>
        </Profile>

        <hr style={{ marginTop: "-20px", marginBottom: "-20px" }} />

        <Layout className="row d-flex justify-content-between">
          <div className="col-sm-12 col-md-7 articles">
            <div className="d-flex mb-4 align-items-center gap-3 justify-content-between">
              <div className="d-flex">
                {["Newest", "Popular", "Best"].map((order, index) => (
                  <Button
                    key={index}
                    text={order}
                    colorBg={order === orderBy ? "black" : "white"}
                    colorText={order === orderBy ? "white" : "black"}
                    onClick={() => {
                      navigate(
                        "/user-profile/" + params.username + "?orderBy=" + order
                      );
                      window.location.reload(false);
                    }}
                  />
                ))}
                <ButtonPlus href="/post?mode=add">&#43;</ButtonPlus>
              </div>
            </div>

            <div className="d-flex flex-column gap-4">
              {countArticle !== 0
                ? articlesUser
                    .slice(pageVisited, pageVisited + usersPerPage)
                    .map((article, index) => (
                      <CardArticleBig key={article.id} article={article} />
                    ))
                : "LOADING"}
            </div>

            {/* <ReactPaginate
              breakLabel="..."
              nextLabel={">"}
              onPageChange={changePage}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel={"<"}
              renderOnZeroPageCount={null}
              containerClassName={"paginationBttns"}
              pageLinkClassName="pagee-num"
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            /> */}
            <Paginate changePage={changePage} pageCount={pageCount} />
          </div>

          <div className="col-lg-3 col-md-4 d-md-block search">
            <SearchBar placeholder="Search Posts" page={`article`} />
            <div className="d-none d-lg-block d-md-block">
              <RecommendedBuddies context="Blogs Like this One" />
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
}
