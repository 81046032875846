import { format, isValid, parseISO } from 'date-fns';

export const formatDate = (date, givenFormat) => {
  return format(new Date(date), givenFormat)
}

export function checkValidDate(dateString) {
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate);
}

export const getDateTime = (dateString) => {
  const date = new Date(dateString);

  return {
      years: date.getFullYear(),
      months: date.getMonth(),
      days: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
  };
};