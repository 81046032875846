import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { ImageComponent, Metadata } from "../components";
import * as users from '../data/users.js'
import defaultImageUser from "../assets/images/defaultUser.svg";
import blankImg from "../assets/images/404.svg";
import bottomArrow from "../assets/images/bottomArrow.svg"

const ActivityDropDown = styled.div`
  display: block;
  margin: 2rem 1rem 0px;
  text-align: start;

  .dropdown {
    display: none;
    width: 50%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: none;
    outline: none;
    border-radius: 12px;
    color: black;
    padding: 0.5rem 1rem;
    cursor: pointer;
    
    appearance: none;
    background: url(${bottomArrow});
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
  }

  .menu-card {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    gap: 5px;
  }

  
  @media only screen and (min-width: 768px) {
    display: none;
  }
`
const InlineListMenu = styled.li`
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  background: ${(props) => (props.currentCategory === props.categoryValue ? 'rgba(0, 0, 0, 0.8)' : 'white')};
  color: ${(props) => (props.currentCategory === props.categoryValue ? 'white' : 'black')};
  border: ${(props) => (props.currentCategory === props.categoryValue ? '0px' : '1px solid rgba(0, 0, 0, 0.8)')};
`

const ActivityTitle = styled.div`
  display: block;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: start;

  @media only screen and (min-width: 768px) {
    display: none;
  }  
`

const OuterBox = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0rem 1rem;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  gap: 10px;

  
  @media only screen and (min-width: 516px) {
    padding: 1rem;
  }

  @media only screen and (min-width: 768px) {
    margin: 2rem 5rem;
  }

  
  @media only screen and (min-width: 1024px) {
    // margin: 2rem 7rem;
  }

  @media only screen and (min-width: 1280px) {
    margin: 2rem 10rem;
  }
`

const BoxMenu = styled.div`
  width: 20%;
  display: none;
  text-align: start;

  .titleSection {
    font-size: 1.3rem;
    font-weight: 600;
    padding-bottom: 1.5rem;
    margin-bottom: 15px;
  }

  .menus {
    list-style: none;
    padding: 0;
    margin: 0 2rem 0 0;
  }
  
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  @media only screen and (min-width: 1024px) {
    .titleSection {
      font-size: 1.5rem;
      font-weight: 600;
      padding-bottom: 1.5rem;
      margin-bottom: 15px;
    }
  }
`
const MenuItem = styled.li`
  cursor: pointer;
  margin-bottom: 5px;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  
  background: ${(props) => (props.currentCategory === props.categoryValue ? 'rgba(0, 0, 0, 0.8)' : 'white')};
  color: ${(props) => (props.currentCategory === props.categoryValue ? 'white' : 'black')};

  @media only screen and (min-width: 1024px) {
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
`

const BoxContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-height: 600px;
  overflow: hidden;

  .text-tabs {
    display: none;
  }

  .circle-img {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin-right: 10px;
  }
  
  @media only screen and (min-width: 516px) {
    .circle-img {
      width: 30px;
      height: 30px;
    }
  }

  @media only screen and (min-width: 768px) {
    width: 80%;

    .text-tabs {
      display: block;
    }

    .circle-img {
      width: 35px;
      height: 35px;
    }
  }
`
const TitleTabSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 2rem;

  div {
    padding-bottom: 0.5rem;
  }

`
const TitleTab = styled.div`
  cursor: pointer;
  border-bottom: ${(props) => (props.currentTab === props.tabValue ? '1px solid black' : 'none')};
  font-weight: ${(props) => (props.currentTab === props.tabValue ? '600' : '400')};
  
`

const ContentTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  overflow-y: scroll;

  
  @media only screen and (min-width: 768px) {
    padding: 0px 1rem;
  }
`

const ListCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 5px;
  margin-top: 1rem;

  
  @media only screen and (min-width: 516px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 768px) {
    // grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  
  @media only screen and (min-width: 1280px) {
    // grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`
const Card = styled.div`
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const HorizontalList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`
const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;

  .searchContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    .content {
      text-align: start;
    }
    
  }

  .contentDate {
    text-align: start;
    margin-left: 45px;
  }

  
  @media only screen and (min-width: 516px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .contentDate {
      margin-left: 0px;
    }
  }

  
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 1rem;
  }

`

const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  
  .relation-post-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  @media only screen and (min-width: 768px) {
    .relation-post-img {
      width: 100px;
      height: 100px;
    }
  }

`
const CommentContent = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;

  .content-wrapper {
    display: inline-flex;
  }

  .content-text {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Batasi hingga 2 baris */
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;;

    text-align: start;
  }

  .post-date {
    display: flex;
    font-size: 10px;
  }
  
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
  
  @media only screen and (min-width: 1024px) {
    font-size: 1rem;
  }
`

const ProfileDataContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  .content {
    text-align: start;

    .content-title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    .content-subtitle {
      font-size: 14px;
      font-weight: 400;
    }
    .content-date {
      margin-left: 0.5rem;
      font-size: 12px;
    }
  }
`

export function ActivityLogs(){
  const params = useParams();
  const navigate = useNavigate();
  
  const pathname = window.location.pathname;
  const segments = pathname.split('/');

  const [likedPost, setLikedPost] = useState([]);
  const [savedPost, setSavedPost] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [commentData, setCommentData] = useState([]);

  const [updateProfileData, setUpdateProfileData] = useState([]);
  const [updateProfileDataSetting, setUpdateProfileDataSetting] = useState([]);
  const [updateProfilePassword, setUpdateProfilePassword] = useState([]);

  const [createdPost, setCreatedPost] = useState([]);
  const [updatedPost, setUpdatedPost] = useState([]);
  const [deletedPost, setDeletedPost] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("INTERACTION")
  const [currentTab, setCurrentTab] = useState("LIKED");
  const [selectedValue, setSelectedValue] = useState("");

  const [titleActivity, setTitleActivity] = useState("");

  const menus = [
    {
      name: "Interaction",
      value: "INTERACTION",
      icon: '',
      iconActive: ''
    },
    {
      name: "Your Post",
      value: "POST",
      icon: '',
      iconActive: ''
    },
    {
      name: "Profile History",
      value: "PROFILE",
      icon: '',
      iconActive: ''
    }
  ]

  const tabs = [
    {
      name: "Like",
      value: "LIKED",
      icon: "fa-regular fa-heart",
      iconActive: "fa-solid fa-heart",
      category: "INTERACTION"
    },
    {
      name: "Save",
      value: "SAVED",
      icon: "fa-regular fa-bookmark",
      iconActive: "fa-solid fa-bookmark",
      category: "INTERACTION"
    },
    {
      name: "Search",
      value: "SEARCH",
      icon: "fa-solid fa-magnifying-glass",
      iconActive: "fa-solid fa-magnifying-glass",
      category: "INTERACTION"
    },
    {
      name: "Comment",
      value: "COMMENT",
      icon: "fa-regular fa-message",
      iconActive: "fa-solid fa-message",
      category: "INTERACTION"
    },
    {
      name: "Create Post",
      value: "CREATE_POST",
      icon: "fa-solid fa-table-cells",
      iconActive: "fa-solid fa-table-cells",
      category: "POST"
    },
    {
      name: "Update Post",
      value: "UPDATE_POST",
      icon: "fa-regular fa-pen-to-square",
      iconActive: "fa-solid fa-pen-to-square",
      category: "POST"
    },
    {
      name: "Delete Post",
      value: "DELETE_POST",
      icon: "fa-solid fa-folder-minus",
      iconActive: "fa-solid fa-folder-minus",
      category: "POST"
    },
    {
      name: "Profile Change",
      value: "PROFILE_CHANGE",
      icon: "fa-regular fa-user",
      iconActive: "fa-solid fa-user",
      category: "PROFILE"
    },
    {
      name: "Profile Setting",
      value: "PROFILE_SETTING",
      icon: "fa-solid fa-gear",
      iconActive: "fa-solid fa-gear",
      category: "PROFILE"
    },
    {
      name: "Password",
      value: "PASSWORD",
      icon: "fa-solid fa-lock",
      iconActive: "fa-solid fa-lock",
      category: "PROFILE"
    },
    
  ]

  const changeCategory = (currentCategory) => {
    setCurrentCategory(currentCategory);
    let tabName = currentCategory === 'INTERACTION' ? 'LIKED' : currentCategory === 'POST' ? 'CREATE_POST' : 'PROFILE_CHANGE' 
    setTitleActivity(tabName)
    navigate(`/activity/logs/${currentCategory.toLowerCase()}/${tabName.toLowerCase()}`)
  }

  const changeTab = (tabName, interaction) => {
    setCurrentTab(tabName)
    setTitleActivity(tabName)
    navigate(`/activity/logs/${interaction.toLowerCase()}/${tabName.toLowerCase()}`)
  }

  const concatString = (string1, string2) => {
    return `${string1} ${string2}`
  }

  function removePTagFromHTML(htmlString) {
    // Mencari indeks pertama dari tag <p> dalam string
    const firstPIndex = htmlString?.indexOf('<p>');
    if (firstPIndex !== undefined) {
      if (firstPIndex !== -1) {
        // Mengambil konten dari tag <p> pertama
        const contentStart = htmlString.indexOf('>', firstPIndex) + 1;
        const contentEnd = htmlString.indexOf('</p>', firstPIndex);
        const content = htmlString.slice(contentStart, contentEnd);
  
        // Menghapus tag <p> pertama dan tetap mempertahankan isinya
        htmlString = htmlString.slice(0, firstPIndex) + content + htmlString.slice(contentEnd + 4);
      }
  
      return htmlString;
    }

    // // Buat elemen div sementara untuk mengurai string HTML
    // const tempDiv = document.createElement('div');
    // tempDiv.innerHTML = htmlString;
      
    // // Cari semua elemen <p> dalam div
    // const pElements = tempDiv.querySelectorAll('p');

    // // Inisialisasi string kosong untuk menggabungkan konten
    // let content = '';

    // // Loop melalui elemen-elemen <p> dan gabungkan kontennya
    // pElements.forEach((pElement) => {
    //   content += pElement.innerHTML + ' '; // Tambahkan spasi jika perlu
    // });
    
    // return content;
  }

  function replaceUnderscoreWithSpace(str) {
    // Gunakan metode replace() dengan ekspresi reguler untuk mengganti semua underscore dengan spasi
    return str.replace(/_/g, ' ');
  }

  useEffect(() => {
    setSelectedValue(segments[segments.length - 2]);

    setCurrentCategory(selectedValue.toUpperCase())
    setCurrentTab(params.activity.toUpperCase())
    setTitleActivity(params.activity.toUpperCase())

    const getActivityLiked = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setLikedPost([])
        return;
      } 
      if (res.data) setLikedPost(res.data);
      setIsFetching(false)
    }

    const getActivitySaved = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);
      
      if (res.code !== 200) {
        setIsFetching(false)
        setSavedPost([])
        return;
      } 
      if (res.data) setSavedPost(res.data);
      setIsFetching(false)
    }

    const getActivitySearch = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setSearchData([])
        return
      }
      if (res.data) setSearchData(res.data);
      setIsFetching(false)
    }

    const getActivityComment = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setCommentData([])
        return
      }
      if (res.data) setCommentData(res.data);
      setIsFetching(false)
    }

    const getActivityCreatedPost = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setCreatedPost([])
        return
      }
      if (res.data) setCreatedPost(res.data);
      setIsFetching(false)
    }

    const getActivityUpdatedPost = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setUpdatedPost([])
        return
      }
      if (res.data) setUpdatedPost(res.data);
      setIsFetching(false)
    }

    const getActivityDeletedPost = async() => {
      setIsFetching(true)
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setDeletedPost([])
        return
      }
      if (res.data) setDeletedPost(res.data);
      setIsFetching(false)
    }

    const getActivityUpdateProfile = async() => {
      setIsFetching(true);
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setUpdateProfileData([])
        return
      }

      if (res.data) setUpdateProfileData(res.data);
      setIsFetching(false)
    }

    const getActivityUpdateProfileSetting = async() => {
      setIsFetching(true);
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setUpdateProfileDataSetting([])
        return
      }

      if (res.data) setUpdateProfileDataSetting(res.data);
      setIsFetching(false)
    }

    const getActivityUpdatePassword = async() => {
      setIsFetching(true);
      const res = await users.getActivityLogs(params.activity);

      if (res.code !== 200) {
        setIsFetching(false)
        setUpdateProfilePassword([])
        return
      }

      if (res.data) setUpdateProfilePassword(res.data);
      setIsFetching(false)
    }

    if (currentTab === 'LIKED') getActivityLiked();
    if (currentTab === 'SAVED') getActivitySaved();
    if (currentTab === 'SEARCH') getActivitySearch();
    if (currentTab === 'COMMENT') getActivityComment();

    if (currentTab === 'CREATE_POST') getActivityCreatedPost()
    if (currentTab === 'UPDATE_POST') getActivityUpdatedPost()
    if (currentTab === 'DELETE_POST') getActivityDeletedPost()

    if (currentTab === 'PROFILE_CHANGE') getActivityUpdateProfile()
    if (currentTab === 'PROFILE_SETTING') getActivityUpdateProfileSetting()
    if (currentTab === 'PASSWORD') getActivityUpdatePassword()

  }, [currentTab, params, selectedValue])

  return(
    <div style={{ marginTop: "6.5rem" }}>
      <div className="d-flex flex-column text-center">
        <Metadata page={window.location.href} titlePage={`Activity Logs • Bipi World`} />

        <h1>Your Activity</h1>
        <ActivityDropDown>
          <select className="dropdown" defaultValue={selectedValue.toUpperCase()} onChange={(e) => changeCategory(e.target.value)}>
            {menus.length > 0 ? menus.map((menu, index) => (
              <option key={index} value={menu.value}>{menu.name}</option>
            )) : <option value={''}>No Activity</option>}
          </select>

          <ul className="menu-card">
            {menus.length > 0 ? menus.map((menu, index) => (
              <InlineListMenu key={index} onClick={() => changeCategory(menu.value)} currentCategory={currentCategory} categoryValue={menu.value}>{menu.name}</InlineListMenu>
            )) : ''}
          </ul>
        </ActivityDropDown>
        <OuterBox>
          <BoxMenu>
            <div className="titleSection">
              Activity Menu
            </div>
            <ul className="menus">
              {menus.length > 0 ? menus.map((menu, index) => (
                <MenuItem key={index} onClick={() => changeCategory(menu.value)} currentCategory={currentCategory} categoryValue={menu.value}>
                  {menu.name}
                </MenuItem>
              )) : ''}
            </ul>
          </BoxMenu>

          <BoxContent>
            <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)", width: "100%" }}>
              <TitleTabSection>
                {tabs.length > 0 ? tabs.filter((tab) => tab.category === currentCategory).map((tab, index) => (
                  <TitleTab key={index} onClick={() => changeTab(tab.value, tab.category)} currentTab={currentTab} tabValue={tab.value}>
                    <div className="d-flex gap-2" style={{ alignItems: "center"}}>
                      <i className={tab.value === currentTab ? tab.iconActive : tab.icon }></i>
                      <span className="text-tabs">{tab.name}</span>
                    </div>
                  </TitleTab>
                )) : ''}
              </TitleTabSection>
            </div>

            <ContentTab>
              {/* add new div to add section header */}
              <ActivityTitle>
                {replaceUnderscoreWithSpace(titleActivity)}
              </ActivityTitle>

              {currentCategory === 'INTERACTION' && (
                <>
                  {currentTab === 'LIKED' && (
                    <ListCard>
                      {isFetching ? 'Loading...' : likedPost.length > 0 
                      ? likedPost.map((post) => (
                        <Card key={post.id}>
                          <ImageComponent src={post?.relationPost?.image !== "" ? post?.relationPost?.image : blankImg} alt={post?.relationPost?.slug} />
                        </Card>
                      )) 
                      : 'No Data..' }
                    </ListCard>
                  )}

                  {currentTab === 'SAVED' && (
                    <ListCard>
                      {isFetching ? 'Loading...' : savedPost.length > 0 
                      ? savedPost.map((post) => (
                        <Card key={post.id}>
                          <ImageComponent src={post?.relationPost?.image} alt={post?.relationPost?.slug} />
                        </Card>
                      )) 
                      : 'No Data..' }
                    </ListCard>
                  )}

                  {currentTab === 'SEARCH' && (
                    <HorizontalList>
                      {isFetching ? 'Loading...' : searchData.length > 0
                        ? searchData.map((search) => (
                          <SearchContent key={search.id}>
                            <div className="searchContent">
                              <img className="circle-img" src={search?.userFrom?.traveler?.image !== "" ? search?.userFrom?.traveler?.image : defaultImageUser} alt={search?.userFrom?.name} />
                              <div className="content" dangerouslySetInnerHTML={{__html: search.detailActivity}} />
                            </div>

                            <div className="contentDate">
                              {moment(search?.createdAt).format("MMM D, YYYY - HH:mm")}
                            </div>
                          </SearchContent>
                        ))
                        : 'No Data..'
                      }
                    </HorizontalList>
                  )}

                  {currentTab === 'COMMENT' && (
                    <CommentList>
                      {isFetching ? 'Loading...' : commentData.length > 0 
                      ? commentData.map((comment) => (
                        <div key={comment.id} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}>
                          <CommentContent>
                            <img className="circle-img" src={comment?.relationPost?.user?.traveler?.image !== "" ? comment?.relationPost?.user?.traveler?.image : defaultImageUser} alt={comment?.relationPost?.user?.username} />
                            <div>
                              <div className="content-wrapper">
                                <div className="content-text" dangerouslySetInnerHTML={{__html: concatString(`<strong>${comment?.relationPost?.user?.username}</strong>`, removePTagFromHTML(comment?.relationPost?.content))}}/>
                              </div>

                              <div className="post-date">{moment(comment?.relationPost?.createdAt).format("MMM D, YYYY")}</div>

                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px 0px' }}>
                                <img className="circle-img" src={comment?.userFrom?.traveler?.image !== "" ? comment?.userFrom?.traveler?.image : defaultImageUser} alt={comment?.userFrom?.name} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                    <div className="content-text" dangerouslySetInnerHTML={{__html: concatString(`<strong>${comment?.userFrom?.username}</strong>`, comment?.detailActivity)}} />
                                  </div>

                                  <div className="post-date">{moment(comment?.createdAt).format("MMM D, YYYY")}</div>
                                </div>
                              </div>
                            </div>
                          </CommentContent>

                          <div>
                            <img className="relation-post-img" src={comment?.relationPost?.image !== "" ? comment?.relationPost?.image : blankImg} alt={comment?.relationPost?.title || 'article image'} />
                          </div>
                        </div>
                      ))
                      : 'No Data...'
                      }
                    </CommentList>
                  )}
                </>
              )}

              {currentCategory === 'POST' && (
                <>
                  {currentTab === 'CREATE_POST' && (
                    <ListCard>
                      {isFetching ? 'Loading...' : createdPost.length > 0 
                      ? createdPost.map((post) => (
                        <Card key={post.id}>
                          <ImageComponent src={post?.relationPost?.image !== "" ? post?.relationPost?.image : blankImg} alt={post?.relationPost?.slug} />
                        </Card>
                      )) 
                      : 'No Data..' }
                    </ListCard>
                  )}

                  {currentTab === 'UPDATE_POST' && (
                    <>
                      <span style={{textAlign: 'start', fontSize: 12, fontWeight: 'bold'}}>Note : this is list of your last 30 days post</span>
                      <ListCard>
                        {isFetching ? 'Loading...' : updatedPost.length > 0 
                        ? updatedPost.map((post) => (
                          <Card key={post.id}>
                            <ImageComponent src={post?.relationPost?.image !== "" ? post?.relationPost?.image : blankImg} alt={post?.relationPost?.slug} />
                          </Card>
                        )) 
                        : 'No Data..' }
                      </ListCard>
                    </>
                  )}
                  
                  {currentTab === 'DELETE_POST' && (
                    <>
                      <span style={{textAlign: 'start', fontSize: 12, fontWeight: 'bold'}}>Note : this is list of your last 30 days post</span>
                      <ListCard>
                        {isFetching ? 'Loading...' : deletedPost.length > 0 
                        ? deletedPost.map((post) => (
                          <Card key={post.id}>
                            <ImageComponent src={post?.relationPost?.image !== "" ? post?.relationPost?.image : blankImg} alt={post?.relationPost?.slug} />
                          </Card>
                        )) 
                        : 'No Data..' }
                      </ListCard>
                    </>
                  )}
                </>
              )}

              {currentCategory === 'PROFILE' && (
                <>
                  {currentTab === 'PROFILE_CHANGE' && (
                    <HorizontalList>
                      {isFetching ? 'Loading...' : updateProfileData.length > 0 
                      ? updateProfileData.map((data) => (
                        <ProfileDataContent key={data.id}>
                          <div className="icon"><i className="fa-solid fa-pen"></i></div>
                          <div className="content">
                            <span className="content-title">{data?.activity}</span>
                            <div className="content-subtitle">
                              {data?.detailActivity}
                              <span className="content-date">{moment(data?.createdAt).fromNow()}</span>
                            </div>
                          </div>
                        </ProfileDataContent>
                      )) 
                      : 'No Data...' }
                    </HorizontalList>
                  )}

                  {currentTab === 'PROFILE_SETTING' && (
                    <HorizontalList>
                    {isFetching ? 'Loading...' : updateProfileDataSetting.length > 0 
                      ? updateProfileDataSetting.map((data) => (
                        <ProfileDataContent key={data.id}>
                          <div className="icon"><i className="fa-solid fa-gear"></i></div>
                          <div className="content">
                            <span className="content-title">{data?.activity}</span>
                            <div className="content-subtitle">
                              <div dangerouslySetInnerHTML={{__html: data?.detailActivity}} />
                              <span className="content-date">{moment(data?.createdAt).fromNow()}</span>
                            </div>
                          </div>
                        </ProfileDataContent>
                      )) 
                      : 'No Data...' }
                    </HorizontalList>
                  )}

                  {currentTab === 'PASSWORD' && (
                    <HorizontalList>
                    {isFetching ? 'Loading...' : updateProfilePassword.length > 0 
                      ? updateProfilePassword.map((data) => (
                        <ProfileDataContent key={data.id}>
                          <div className="icon"><i className="fa-solid fa-lock"></i></div>
                          <div className="content">
                            <span className="content-title">{data?.activity}</span>
                            <div className="content-subtitle">
                              <span dangerouslySetInnerHTML={{__html: data?.detailActivity}} />
                              <span className="content-date">{moment(data?.createdAt).fromNow()}</span>
                            </div>
                          </div>
                        </ProfileDataContent>
                      )) 
                      : 'No Data...' }
                    </HorizontalList>
                  )}
                </>
              )}
            </ContentTab>
          </BoxContent>
        </OuterBox>
      </div>
    </div>
  )
}