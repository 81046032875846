import { useEffect } from 'react'
import './style.css'

export function ToggleSwitch(props) {
  const { name, value, setValue, setIsTravelAgencyExist } = props

  const handleOnChange = (e) => {
    setValue(e.target.checked)
    setIsTravelAgencyExist(e.target.checked)
  }

  return (
    <>
      <label className="switch">
        <input type="checkbox" checked={value} name={name} onChange={handleOnChange} />
        <div></div>
      </label>
    </>
  )
}