import React, { useState, Fragment, useEffect } from "react";
import "./style.css"

import { IoTriangle } from "react-icons/io5";

import { formatDate } from "../../utils/formatDate";

const data = [
  {
    "id": 1,
    "name": "VehicleData",
    "owner": "admin",
    "sub_details": [
      {
        "sub_name": "Lexus",
        "sub_id": 4,
      },
      {
        "sub_name": "BMW",
        "sub_id": 3,
      }
    ]
  },
  {
    "id": 2,
    "name": "Mobiles",
    "owner": "admin",
    "sub_details": [
      {
        "sub_name": "Apple",
        "sub_id": 2,
      }
    ]
  },
  {
    "id": 3,
    "name": "Laptop",
    "owner": "admin",
    "sub_details": []
  }
]

const TableTree = (props) => {
  const { parentHeader, parentRow } = props;

  return (
    <table className="table-summary">
      <thead>
        <tr>
          {parentHeader && parentHeader.map((head, _) => 
            <th key={_}>{head}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {parentRow.length > 0 ? parentRow.map((data, _) => 
          <TableData key={_} parentHeader={parentHeader} data={data} childData={data?.subData ?? []} />
        ) : <tr><td colSpan={parentHeader.length}>No Data...</td></tr>}
      </tbody>
    </table>
  );
};

const TableData = (props) => {
  const { parentHeader, data, childData} = props;
  const [subHidden, setSubHidden] = useState(true);
  
  const Toggle = () => {
    setSubHidden(!subHidden);
  };

  return (
    <Fragment>
      <tr>
        <td>
          {data.subData && data.subData.length > 0 
            ? <IoTriangle onClick={() => Toggle()} style={{ width: '10px', height: '10px', rotate: `${!subHidden ? '180deg' : '90deg'}`, cursor: 'pointer'}} /> 
            : ''
          }
        </td>

        {data && Object.keys(data).length > 0 
          ? Object.keys(data).map((key, index) => {
              if (!Array.isArray(data[key])) {
                return(
                  <td key={index}>{(key.includes('checkin') && data[key] !== '') || (key.includes('checkout') && data[key] !== '') ? formatDate(data[key], 'dd, MMM yyyy HH:mm') : data[key]}</td>
                )
              }

              // if key is array, render nothing...
              return (<Fragment key={index}></Fragment>)
            })
          : <td colSpan={parentHeader.length}>No Data...</td>
        }
      </tr>

      {!subHidden && (
        <tr>
          <td></td>
          <td colSpan={parentHeader.length}>
            <TableSubDetails
              childData={childData}
            />
          </td>
        </tr>
      )}
    </Fragment>
  );
}

const TableSubDetails = (props) => {
  const { childData } = props;

  return (
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            {childData && childData.map((childHead) => {
                return Object.keys(childHead).map((key) => {
                  let header = '';

                  if (key.includes('_travel_agency_name')) header = 'Travel Agency';
                  if (key.includes('_travel_agency_email')) header = 'Email';
                  if (key.includes('_travel_agency_phone')) header = 'Phone';
                  if (key.includes('_travel_agency_contact_name')) header = 'Contact Name';
                  if (key.includes('_travel_agency_code')) header = 'Code';
                  if (key.includes('_travel_agency_url')) header = 'Url';

                  return <th key={key}>{header}</th>;
              });
            })}
          </tr>
        </thead>
        <tbody>
          {childData && childData.map((childHead, _) => {
              return (
                <tr key={_}>
                  {Object.keys(childHead).map((key) => {
                    return <td key={key}>{childHead[key]}</td>;
                  })}
                </tr>
              )
          })}
        </tbody>
      </table>
  )
      
}

export { TableTree };