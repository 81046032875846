import { useEffect, useRef } from 'react';
import styled from "styled-components"

import { ProfileMenu } from "./Profile-Menu"

const Container = styled.div`
  width: 100%;

  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;

export function BottomMenu(props) {
  const {isVisible, onClose} = props
  
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && menuRef.current === event.target) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  return(
    <>
      {isVisible && (
        <Container ref={menuRef} isVisible={isVisible}>
          <ProfileMenu parentRef={menuRef} />
        </Container>
      )}
    </> 
  )
}