import { useState, useEffect } from "react";

import styled from "styled-components";
const StyleLoading = styled.div`
  width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin-top:-2em;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 9999;
    .spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: white transparent white transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`;
export function Loading(props) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="">
    {loading &&
      <StyleLoading className="loader-container">
        <div className="spinner"></div>
      </StyleLoading>
    }
    </div>
  );
}

