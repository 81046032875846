import robot from "../assets/images/notFound.svg";
import styled from "styled-components";
import { Button, Metadata } from "../components";

const Style = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  img {
    width: 150px;
  }
  h1 {
    margin-top: 10px;
    font-size: 23px;
  }
  p {
    font-size: 14px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    img {
      width: 200px;
    }
    h1 {
      margin-top: 10px;
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
    height: 60vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    img {
      width: 220px;
      margin-top: 50px;
    }
    h1 {
      margin-top: 10px;
      font-size: 32px;
    }
    p {
      font-size: 18px;
      padding-bottom: 10px;
    }
    height: 26em;
  }
`;

export function NotFound() {
  return (
    <Style className="mt-5 pt-5 px-2  d-flex justify-content-center align-items-center">
      <Metadata page={window.location.href} titlePage={`Bipi World`} />
      
      <div className="text-center">
        <img src={robot} width="220px" height="220px" />
        <h1>Not Found page</h1>
        <p>The page you tried to access doesn't exist.</p>
        <a href="/">
          <Button text="Go back to homepage" className="p-4" type="button"></Button>
        </a>{" "}
      </div>
    </Style>
  );
}
