import { useEffect } from "react";

import { Calendar, DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/prime.css"

import './styles/calendar-month-mini.css'

import {
  format,
  eachDayOfInterval,
  isWithinInterval
} from "date-fns";

import { DEFAULT_SELECTED_DATE_COLOR } from '../../../config/config';

export default function CalendarMonthMini(props) {
  const { availablePlanDate, setItineraryStartDate, setItineraryEndDate, setIsSelectedDateSame, setFocusedDate, isMobile, isClickable = false, activeMonth, setActiveMonth } = props;
  const currentMonth = new DateObject()

  return (
    <>
      <Calendar
        multiple
        range
        currentDate={currentMonth}
        value={availablePlanDate}
        className='custom-calendar'
        onMonthChange={(date) => setActiveMonth(new Date(date).toString())}
        onFocusedDateChange={(focusedDate, clickedDate) => {
          if (isClickable) {
            const isClickedDateSameAsAvailableDate = availablePlanDate.some((date) => {
              const start = date[0]
              const end = date[1]
              const isSame = isWithinInterval(clickedDate.format('YYYY-MM-DD'), { start, end })
              if (isSame) {
                setItineraryStartDate(start)
                setItineraryEndDate(end)
              }
              return isSame
            })
  
            setIsSelectedDateSame(isClickedDateSameAsAvailableDate) 
            setFocusedDate((current) => [...current, focusedDate])
          }
        }}
        mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
          let selectedDateColor;
          let props = {}
          props.style = {
            color: 'black'
          }

          for (const selected of selectedDate) {
            const startDate = selected[0];
            const endDate = selected[1];
            
            if (startDate && endDate) {
              const filteredAvailableDateBySelectedDate = availablePlanDate.filter((data) => data[0] === startDate.format('YYYY-MM-DD') && data[1] === endDate.format('YYYY-MM-DD'))

              if (filteredAvailableDateBySelectedDate.length > 0 ) {
                for (const dateSelected of filteredAvailableDateBySelectedDate) {
                  if (dateSelected.length > 2) selectedDateColor = dateSelected[dateSelected.length - 1]
                }
              } else {
                selectedDateColor = DEFAULT_SELECTED_DATE_COLOR;
              }

              const dateInRange = eachDayOfInterval({ start: startDate.format('YYYY-MM-DD'), end: endDate.format('YYYY-MM-DD') })
              const formattedDateList = dateInRange.map(date => format(date, 'yyyy-MM-dd'));
              if (formattedDateList.includes(date.format('YYYY-MM-DD'))) {
                props.style = {
                  ...props.style,
                  backgroundColor: selectedDateColor,
                  color: 'white'
                }
              }
            }
          }

          if (isSameDate(date, today)) props.style = {
            ...props.style,
            fontWeight: "bold",
            border: "1px solid #777",
          }
          props.disabled = true
          return props
        }}
        showOtherDays={!isMobile}
      />
    </>
  )
}