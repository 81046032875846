import { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from "styled-components";
import { Metadata, CalendarMonthMini, CalendarMonthBig, CalendarWeek, CalendarDay, ModalComponent, DatePickerComponent, TimezoneComponent, Dropdown, ComingSoon, UploadForm } from "./../components"

import { formatDistance } from 'date-fns';

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

import { useMediaQuery } from "react-responsive";
import { formatDate } from '../utils/formatDate';

import { getItineraries, createItinerary, removeItinerary, updateItinerary } from '../data/itinerary';
import { getCountries } from '../data/articles';

import Swal from 'sweetalert2';
import AuthContext from '../components/shared/AuthContext';
import { imageToBase64, } from '../utils/uploadFile';
import { checkIsImage } from '../utils/image-check';
import { getFileName } from '../utils/getFileName';

const Style = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  width: 100%;
  
  margin: auto;
  padding-bottom: 4rem;

  display: flex;
  flex-direction: column;
  background-color: #FEFDFF;

  img {
    width: 150px;
    height: 180px;
  }
  h1 {
    margin-top: 10px;
    font-size: 23px;
  }
  p {
    font-size: 14px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    padding: 3rem 0 5rem;
    img {
      width: 200px;
    }
    h1 {
      margin-top: 10px;
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
    // height: 60vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    img {
      width: 220px;
      height: 250px;
      margin-top: 10px;
    }
    h1 {
      margin-top: 10px;
      font-size: 32px;
    }
    p {
      font-size: 18px;
      padding-bottom: 10px;
    }
    // height: 26em;
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1200px;
  }
`;

const ContainerHeader = styled.div`
  .header-mobile {
    display: block;
    margin 0 auto;
    width: fit-content;
  }

  .header-desktop {
    display: none;
  }

  @media only screen and (min-width: 992px) {
    .header-mobile {
      display: none;
    }

    .header-desktop {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
  
      .title-section {
        width: 100%;
      }
  
      .search-bar {
        padding: 0.5rem 1rem;
        font-size: 14px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        outline: 0;
        width: 30%;
      }
  
      .view-option-container {
        display: inline-flex;
        background: #212529;
        border-radius: 12px;
  
        button {
          border: 0;
          background: transparent;
          color: white;
          padding: 0.5rem 1rem;
  
          &:first-child {
            border-right: 1px solid white;
          }
  
          &:last-child {
            border-left: 1px solid white;
          }
        }
      }
    }
  }
`;

const ContainerCalendar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  gap: 0;

  .calendar-section {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    // justify-content: center;
    flex-direction: column;

    .title-section {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-top: 1rem;
    }

    hr {
      margin: 0;
    }

    .title {
      font-size: 1.2em;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .current-plan-list-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      max-height: 300px;
      overflow-y: scroll;
      padding-bottom: 1rem;
    }
  }
  
  .events-section {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    max-height: 730px;

    gap: 2rem;

    .calendar-section {
      width: 30%;

      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.1);

      .title-section {
        padding: 0 1rem;
      }

      hr {
        margin 1rem 0;
      }
    }

    .events-section {
      flex: 1;
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.1);

      overflow-y: scroll;
    }
  }
`;

const TripListContainer = styled.div`
  display: ${(props) => (props.calendarView === 'week' ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  
  overflow-x: scroll;
  width: 100%;
  max-width: 380px;
  align-self: center;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .trip-card-container {
    display: flex;
    overflow-x: scroll;
    height: max-content;
    gap: 15px;
    
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const TripCard = styled.div`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 14px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 18vmax;
  height: 20vmax;

  .trip-title {
    font-size: 1.2em;
    font-weight: 600;

    display: block;
    display: -webkit-box;

    max-width: 100%;
    height: fit-content;
    
    line-height: 1.3;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .trip-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      margin: 0;
      padding: 0;
    }
  }
`;

const ItineraryCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  padding: 1rem;
  margin: 0 3px;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);

  .itinerary-icon-section {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    background-color: white;
    padding: 0.5rem 1rem;
  }

  .itinerary-content-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;

    .title-content {    
      max-width: 100%;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .itinerary-sub-content {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .color-identifier {
    width: 25px;
    height: 25px;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
  }

  .ellipsis-icon {
    width: 25px;
    height: 25px;
    
    background: transparent;
    border: 0;
    padding: 0;

    position: relative;
  }

  .option-pop-up {
    position: absolute;
    top: 50%; right: 150%;
    transform: translate(0,-50%);
    padding: 0.5rem 0.2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1), -5px -5px 10px 0 rgba(0, 0, 0, 0.1);

    ul {
      display: inline-flex;
      gap: 15px;
      list-style: none;
      margin: 0;
      padding: 0 0.5rem;

      li {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const ButtonCustom = styled.button`
  width: 25%;
  padding: 0.5rem;
  background: transparent;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 12px;
`;

export function Itinenary(props) {
  const { isDemo = false } = props
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const { user } = useContext(AuthContext)
  
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [colorItinerary, setColorItinerary] = useColor("#A04F4F");
  const [colorTrip, setColorTrip] = useColor("#A04F4F");

  const [calendarView, setCalendarView] = useState('month');
  const [activeDate, setActiveDate] = useState(new Date());
  const [activeMonth, setActiveMonth] = useState(new Date())

  const [itineraryData, setItineraryData] = useState([]);
  const [itineraryTrip, setItineraryTrip] = useState([]);
  const [availablePlanDate, setAvailablePlanDate] = useState([])

  const [focusedDate, setFocusedDate] = useState([]);

  const [isFormInputLoaded, setIsFormInputLoaded] = useState(false);
  const [isSelectedDateSame, setIsSelectedDateSame] = useState(false);

  const [tripDetailList, setTripDetailList] = useState([]);
  const [modeInputTrip, setModeInputTrip] = useState(false);
  
  const [itineraryName, setItineraryName] = useState('')
  const [itineraryStartDate, setItineraryStartDate] = useState(new Date());
  const [itineraryEndDate, setItineraryEndDate] = useState(new Date());
  const [tripStartDate, setTripStartDate] = useState(new Date());
  const [tripEndDate, setTripEndDate] = useState(new Date());
  const [selectedThumbnail, setSelectedThumbnail] = useState(null)

  const [countries, setCountries] = useState([])
  const [itineraryDestinationCountry, setItineraryDestinationCountry] = useState('')

  const [tripStartDateTz, setTripStartDateTz] = useState(currentTimezone)
  const [tripEndDateTz, setTripEndDateTz] = useState(currentTimezone)

  const [showAction, setShowAction] = useState('');
  const showActionRefs = useRef([]);

  const [isFetching, setIsFetching] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)

  const [formTitle, setFormTitle] = useState('Add Itinerary')
  const [isModeUpdate, setIsModeUpdate] = useState(false)
  const [updatedItinerary, setUpdatedItinerary] = useState(null)


  const handleInputChange = (e) =>  {
    const { name, value } = e.target;
    switch (name) {
      case 'itinereary_name':
        setItineraryName(value)
        break;
    
      default:
        break;
    }
  }

  const removeTripDetail = (index) => {
    const newTripList = tripDetailList.filter((_, idx) => idx !== index)
    setTripDetailList(newTripList)
  }

  const onTripSave = (e) => {
    e.preventDefault()

    const startDateTimezone = tripStartDateTz?.value ?? tripStartDateTz
    const endDateTimezsone = tripEndDateTz?.value ?? tripStartDateTz
    const tripPayload = {
      title: e.target.trip_title.value,
      subtitle: e.target.trip_subtitle.value,
      categoryTrip: e.target.trip_category.value,
      startDate: formatDate(tripStartDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: formatDate(tripEndDate, 'yyyy-MM-dd HH:mm:ss'),
      startDateTimezone: startDateTimezone,
      endDateTimezone: endDateTimezsone,
      color: colorTrip.hex,
      totalGuest: parseInt(e.target.trip_total_guest.value),
      notes: e.target.trip_notes.value,
      attachment: '',
    }

    setTripDetailList(current => [...current, tripPayload])
    setModeInputTrip(false)
  }

  const onSubmitItinerary = async() => {
    setIsSubmit(true)

    if (!itineraryName || !itineraryDestinationCountry) {
      setIsSubmit(false)
      return Swal.fire({
        title: 'Failed enter itinerary...',
        text: 'Fill all the forms',
        icon: 'error',
      })
    }

    let thumbnail = { name: '', base64: ''};
    if (selectedThumbnail) {
      thumbnail = {
        name: selectedThumbnail.name,
        base64: await imageToBase64(selectedThumbnail)
      }
    }

    const payload = {
      name: itineraryName,
      startDate: formatDate(itineraryStartDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: formatDate(itineraryEndDate, 'yyyy-MM-dd HH:mm:ss'),
      color: colorItinerary.hex,
      destinationCountry: itineraryDestinationCountry,
      trips: tripDetailList,
      thumbnail: thumbnail ? thumbnail : null
    }

    const result = await createItinerary(payload);

    if (!result.data) {
      setIsSubmit(false)
      return Swal.fire({
        title: 'Failed enter itinerary...',
        text: result?.response?.data?.error?.message ?? '',
        icon: 'error',
      })
    }

    setIsFormInputLoaded(false)
    setIsSubmit(false)
    Swal.fire({
      title: 'Success enter itinerary...',
      icon: 'success',
    }).then((res) => {
      if (res.isConfirmed) navigate(`/itinerary/${result.data.data.id}`, { replace: true })
    })
  }

  const handleRemoveItinerary = async(id) => {
    Swal.fire({
      title: 'Are you sure to delete this itinerary ?',
      text: 'this action will delete itinerary permanently',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#198754',
      confirmButtonColor: '#dc3545'
    }).then(async(res) => {
      if (res.isConfirmed) {
        const result = await removeItinerary(id)
        if (!result.data) {
          return Swal.fire({title: 'Remove itinerary Failed', text: 'Error Occured', icon: 'error' });
        }
        Swal.fire({
          title: 'Remove itinerary success...',
          icon: 'success'
        }).then(res => { navigate(0) })
      }
    })
  }

  const handleViewUpdateItinerary = async(itinerary) => {
    if (itinerary) {
      setUpdatedItinerary(itinerary)
      setIsModeUpdate(true)

      setIsFormInputLoaded(true)
      setFormTitle('Update Itinerary')

      setItineraryName(itinerary.name)
      setItineraryStartDate(itinerary.startDate)
      setItineraryEndDate(itinerary.endDate)
      setColorItinerary(prev => ({
        ...prev,
        hex: itinerary.color
      }))
      setItineraryDestinationCountry(itinerary.destinationCountry)
      setSelectedThumbnail(itinerary.thumbnail)
    }
  }

  const onUpdateSubmitItinerary = async() => {
    setIsSubmit(true)

    if (!itineraryName || !itineraryDestinationCountry || !selectedThumbnail) {
      setIsSubmit(false)
      return Swal.fire({
        title: 'Failed enter itinerary...',
        text: 'Fill all the forms',
        icon: 'error',
      })
    }

    let thumbnail = null
    if (checkIsImage(selectedThumbnail)) {
      thumbnail = {
        name: getFileName(selectedThumbnail),
        base64: ''
      }
    } else {
      thumbnail = {
        name: selectedThumbnail.name,
        base64: await imageToBase64(selectedThumbnail)
      }
    }

    const payload = {
      name: itineraryName,
      startDate: formatDate(itineraryStartDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: formatDate(itineraryEndDate, 'yyyy-MM-dd HH:mm:ss'),
      color: colorItinerary.hex,
      destinationCountry: itineraryDestinationCountry,
      trips: tripDetailList,
      thumbnail: thumbnail ? thumbnail : null,
    }

    if (updatedItinerary) {
      const result = await updateItinerary(updatedItinerary.id, payload);

      if (!result.data) {
        setIsSubmit(false)
        return Swal.fire({
          title: 'Failed enter itinerary...',
          text: result?.response?.data?.error?.message ?? '',
          icon: 'error',
        })
      }
  
      setIsFormInputLoaded(false)
      setIsSubmit(false)
  
      Swal.fire({
        title: 'Success update itinerary...',
        icon: 'success',
      }).then((res) => {
        if (res.isConfirmed) navigate(0, { replace: true })
      })
    }

  }

  const toggleActions = (index) => {
    if (showAction === '') {
      setShowAction(index)
    } else {
      setShowAction('')
    }
  }

  const handleViewOnClick = (e) => {
    const selected = e.target.value;
    setCalendarView(selected)
  }

  const handleCloseModal = () => {
    setFocusedDate([])
    setIsSelectedDateSame(false)
    setModeInputTrip(false)
    setIsFormInputLoaded(false)
    
    setFormTitle('Add Itinerary')

    // reset update form value
    
    setIsModeUpdate(false)
    setItineraryName('')
    setItineraryStartDate(new Date())
    setItineraryEndDate(new Date())
    setColorItinerary(prev => ({
      ...prev,
      hex: '#A04F4F'
    }))
    setItineraryDestinationCountry('')
    setUpdatedItinerary(null)
    setSelectedThumbnail(null)
  }

  const handleThumbnailChange = (e) => {
    setSelectedThumbnail(e.target.files[0])
  }

  /** handle fetching calendar */
  useEffect(() => {
    async function fetchingItinerary() {
      const query = `?limit=10&createdBy=${user.username}`
      const result = await getItineraries(query);

      if (result?.code !== 200) return setIsFetching(false);

      const itineraries = result?.data

      if (itineraries.length > 0) {
        const plannedDate = itineraries.map((data) => [formatDate(data?.startDate, 'yyyy-MM-dd'), formatDate(data?.endDate, 'yyyy-MM-dd'), data?.color])
        setAvailablePlanDate(plannedDate)
        setItineraryData(itineraries)
      }

      if (activeDate) {
        const currentSelectedDate = formatDate(activeDate, 'yyyy-MM-dd');

        const filterItinerariesTripByDate = itineraries.reduce((result, item) => {
          const filteredTrips = item.itineraryTrip.filter(tripItem => {
            return (formatDate(tripItem.startDate, 'yyyy-MM-dd') === currentSelectedDate );
          });
      
          if (filteredTrips.length > 0) {
            const modifiedTrips = filteredTrips.map(tripItem => ({
              title: tripItem.title,
              subtitle: tripItem.subTitle,
              start: new Date(tripItem.startDate),
              end: new Date(tripItem.endDate),
              colorEvent: tripItem.color,
              categoryEvent: tripItem.categoryTrip,
              startTimeZone: tripItem.startDateTz,
              endTimeZone: tripItem.endDateTz,
            }))

            result.push(...modifiedTrips);
          }
      
          return result;
        }, []);

        if (filterItinerariesTripByDate.length > 0) {
          setItineraryTrip(filterItinerariesTripByDate)
        } else {
          setItineraryTrip([])
        }
      }
      
      setIsFetching(false)
    }

    async function fetchingCountry() {
      const query = '?limit=240'
      const result = await getCountries(query)

      if (result.code !== 200) {
        setCountries([])
        return
      }
      const formatedCountry = result.data.length > 0 && result.data.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      setCountries(formatedCountry)
    }

    fetchingCountry()
    fetchingItinerary()

  }, [activeDate])

  /** handle date change onClick calendar  */ 
  useEffect(() => {
    if (isSelectedDateSame) {
      setIsFormInputLoaded(true)
    } else {
      if (focusedDate.length > 1) {
        setIsFormInputLoaded(true)

        setItineraryStartDate(focusedDate[0])
        setItineraryEndDate(focusedDate[1])
      }
    }
  }, [focusedDate, isFormInputLoaded, isSelectedDateSame])

  // if (!isDemo) return (
  //   <Style className="mt-5 px-2 d-flex">
  //     <Metadata page={window.location.href} titlePage={`Itinerary • Bipi World`} isPublic />
  //     <ComingSoon />
  //   </Style>
  // )

  useEffect(() => {console.log(user)}, [user])

  return (
    <Style className="mt-5 px-2 d-flex">
      <Metadata page={window.location.href} titlePage={`Itinerary • Bipi World`} isPublic={false} />

      <ContainerHeader>
        <div className='header-desktop'>
          <div className='title-section'>
            <h1>Your Itineraries</h1>
            {/* <input type='input' placeholder='Search...' className='search-bar' />  */}
          </div>

          <div className='view-option-container d-none'>
            <button type='button' onClick={handleViewOnClick} value={`month`}>Month</button>
            <button type='button' onClick={handleViewOnClick} value={`week`}>Week</button>
            <button type='button' onClick={handleViewOnClick} value={`day`}>Day</button>
          </div>
        </div>

        <div className='header-mobile'>
          <ul className='option-view'>
            <li>
              <button type='button' className={`option-view-item  ${calendarView === 'month' ? 'active' : ''}`} onClick={() => setCalendarView('month')}>Month</button>
            </li>
            <li>
              <button type='button' className={`option-view-item  ${calendarView === 'week' ? 'active' : ''}`} onClick={() => setCalendarView('week')}>Week</button>
            </li>
            <li>
              <button type='button' className={`option-view-item  ${calendarView === 'day' ? 'active' : ''}`} onClick={() => setCalendarView('day')}>Day</button>
            </li>
          </ul>
        </div>
      </ContainerHeader>

      <ContainerCalendar>
        <div className='calendar-section'>
          <div className='title-section'>
            <h1 className='title'>Create New Plan</h1>
            <button type='button' className='btn-add' onClick={() => setIsFormInputLoaded(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </button>
          </div>

          <div className='custom-calendar-contaier'>
            <div className={`content-calendar ${!isMobile || calendarView === 'month' ? 'active' : ''} content-calendar-month-view`}> 
              <CalendarMonthMini availablePlanDate={availablePlanDate} setItineraryStartDate={setItineraryStartDate} setItineraryEndDate={setItineraryEndDate} setIsSelectedDateSame={setIsSelectedDateSame} setFocusedDate={setFocusedDate} isMobile={isMobile} isClickable={isMobile} activeMonth={activeMonth} setActiveMonth={setActiveMonth} />
            </div>

            <div className={`content-calendar ${isMobile && calendarView !== 'month' ? 'active' : ''} content-calendar-week-view`}>
              <CalendarWeek activeDate={activeDate} setActiveDate={setActiveDate} />
            </div>
          </div>

          <TripListContainer calendarView={calendarView}>
            <div className='title-container'>
              <h5 className='title'>Today Trip</h5>

              <button type='button' className='btn-add' onClick={() => {
                setItineraryStartDate(activeDate)
                setItineraryEndDate(activeDate)
                setModeInputTrip(true)
                setIsFormInputLoaded(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </button>
            </div>
            
            <div className='trip-card-container'>
              {itineraryTrip.length > 0 
              ? itineraryTrip.map((data, index) => 
                <TripCard key={index} backgroundColor={data?.colorEvent}>
                  <h5 className='trip-title'>{data.title}</h5>
                  <div className='trip-content'>
                    <p>{formatDate(data?.start, 'HH:mm')} - {formatDate(data?.end, 'HH:mm')}</p>
                    <p>{formatDistance(new Date(data?.start), new Date(), { addSuffix: true })}</p>
                  </div>
                </TripCard>
              ) 
              : 'No plan trip...'}
            </div>
          </TripListContainer>

          <hr />

          {(!isMobile || calendarView !== 'day') && 
            <>
              <div className='title-section'>
                <h1 className='title'>Next Plan</h1>
              </div>

              <div className='current-plan-list-container'>
                {!isFetching 
                ? itineraryData.length > 0 
                  ? itineraryData.map((data, index) => 
                    <ItineraryCard key={index}>
                      <span className='itinerary-icon-section'>
                        {index + 1}.
                      </span>

                      <div className='itinerary-content-section'>
                        <span className='title-content'>{data?.name}</span>
                        <div className='itinerary-sub-content'>
                          <span>{formatDistance(new Date(data?.startDate), new Date(), { addSuffix: true })}</span>
                        </div>
                      </div>

                      <div className='color-identifier' style={{ backgroundColor: data?.color }}>&nbsp;</div>

                      <button type='button' className='ellipsis-icon' ref={refItem => showActionRefs.current[index] = refItem} onClick={() => toggleActions(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                        </svg>

                        <div className='option-pop-up' style={{ display: `${showAction === index ? 'block' : 'none'}` }}>
                          <ul>
                            <li onClick={() => navigate(`/itinerary/${data.id}`)}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                            </li>

                            <li onClick={() => handleViewUpdateItinerary(data)}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                              </svg>
                            </li>

                            <li onClick={() => handleRemoveItinerary(data.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
                            </li>
                          </ul>
                        </div>
                      </button>
                    </ItineraryCard>
                  )
                  : <span style={{ margin: '0 1rem' }}>No Itineraries...</span> 
                : <span style={{ margin: '0 1rem' }}>Loading...</span>}
              </div>
            </>
          }
        </div>

        <div className='events-section'>
          {(calendarView === 'month' || calendarView === 'week') && <CalendarMonthBig setCalendarView={setCalendarView} activeMonth={activeMonth} setActiveDate={setActiveDate} itineraryData={itineraryData} eventEachDay={['event 1', 'event 2']} /> }
          {calendarView === 'day' && <CalendarDay activeDate={activeDate} itineraryTrip={itineraryTrip} formatDate={formatDate} setCalendarView={setCalendarView} setActiveDate={setActiveDate} /> }
        </div>
      </ContainerCalendar>

      <ModalComponent showModal={isFormInputLoaded} handleClose={handleCloseModal} title={formTitle} centered>
        <div className='form-input-itinerary'>
          <div className='form-input-group'>
            <label htmlFor="">Itinerary Name</label>
            <input type="text" name='itinereary_name' value={itineraryName} onChange={handleInputChange} className='form-input' />
          </div>

          <div className='form-input-group'>
            <label htmlFor="">Start Date</label>
            <DatePickerComponent date={itineraryStartDate} setDate={setItineraryStartDate} hideOnScroll />
          </div>

          <div className='form-input-group'>
            <label htmlFor="">End Date</label>
            <DatePickerComponent date={itineraryEndDate} setDate={setItineraryEndDate} hideOnScroll />
          </div>

          <div className='form-input-group'>
            <label htmlFor="">Destination</label>
            <Dropdown options={countries} value={itineraryDestinationCountry} setSelected={setItineraryDestinationCountry} defaultValue={itineraryDestinationCountry} />
          </div>

          <div className='form-input-group'>
            <label htmlFor="">Thumbnail / Cover (optional)</label>
            <UploadForm selectedFile={selectedThumbnail} setSelectedFile={setSelectedThumbnail} handleUploadChange={handleThumbnailChange} acceptedFile={'image/png, image/jpg, image/jpeg'} isUploading={selectedThumbnail} />
          </div>

          <div className='form-input-group'>
            <label htmlFor="">Color</label>
            <ColorPicker color={colorItinerary} onChange={setColorItinerary} height={150} hideInput={["rgb", "hsv", "hex"]} />
          </div>

          <div className='form-input-detail-trip-container'>
            <div className='form-input-detail-header'>
              <h5>Detail Trip</h5>
              
              {!modeInputTrip && 
              <button type="button" onClick={() => setModeInputTrip(!modeInputTrip)} className='btn-add'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>  
              </button>}
            </div>

            <div className='form-input-detail-trip'>
              {tripDetailList.map((trip, index) => 
                <div key={index} className='list-detail-trip' style={{ background: trip?.color }}>
                  <h5 className='title'>{trip.title}</h5>

                  <div className='detail-trip'>
                    <div className='sub-detail-trip'>
                      <span>{trip.categoryTrip}</span>
                    </div>

                    <span className='time'>{formatDate(trip.startDate, 'hh:mm a')} - {formatDate(trip.endDate, 'hh:mm a')}</span>
                  </div>

                  <button type='button' className='icon-remove' onClick={() => removeTripDetail(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  </button>
                </div>
              )}

              {modeInputTrip && 
                <form onSubmit={onTripSave} className='form-input-trip'>
                  <div className='form-input-group'>
                    <label htmlFor="">Trip Title</label>
                    <input type="text" name='trip_title' className='form-input' />
                  </div>
                  <div className='form-input-group'>
                    <label htmlFor="">Trip Subtitle</label>
                    <input type="text" name='trip_subtitle' className='form-input' />
                  </div>
                  <div className='form-input-group'>
                    <label htmlFor="">Trip Category</label>
                    <input type="text" name='trip_category' className='form-input' />
                  </div>
                  <div className='form-input-group'>
                    <label htmlFor="">Start Date</label>
                    <div className='form-date-input-group'>
                      <DatePickerComponent
                        date={tripStartDate}
                        setDate={setTripStartDate}
                        isTimePicker
                        hideOnScroll 
                        disableOutsideTwoDates={[itineraryStartDate, itineraryEndDate]}
                      />
                      <TimezoneComponent date={tripStartDateTz} setDate={setTripStartDateTz} label={`abbrev`} />
                    </div>
                  </div>
                  <div className='form-input-group'>
                    <label htmlFor="">End Date</label>
                    <div className='form-date-input-group'>
                      <DatePickerComponent date={tripEndDate} setDate={setTripEndDate} isTimePicker disableOutsideTwoDates={[itineraryStartDate, itineraryEndDate]} hideOnScroll />
                      <TimezoneComponent date={tripEndDateTz} setDate={setTripEndDateTz} label={`abbrev`} />
                    </div>
                  </div>

                  <div className='form-input-group'>
                    <label htmlFor="">Total Guest</label>
                    <input type="number" name='trip_total_guest' className='form-input' defaultValue={0} min={0} />
                  </div>

                  <div className='form-input-group'>
                    <label htmlFor="">Notes</label>
                    <input type="text" name='trip_notes' className='form-input' />
                  </div>

                  <div className='form-input-group'>
                    <ColorPicker color={colorTrip} onChange={setColorTrip} height={150} hideInput={["rgb", "hsv", "hex"]} />
                  </div>

                  <div style={{ width: '100%', alignSelf: 'center', display: 'inline-flex', gap: '10px' }}>
                    <ButtonCustom type='button' onClick={() => setModeInputTrip(false)}>Cancel</ButtonCustom>
                    {/* <ButtonCustom type='button'>Draft</ButtonCustom> */}
                    <ButtonCustom type='submit'>Save</ButtonCustom>
                  </div>
                </form>
              }
            </div>
          </div>

          <div className='mt-3 d-flex justify-content-end'>
            {!modeInputTrip && <ButtonCustom type='button' onClick={() => {if (!isModeUpdate) {onSubmitItinerary()} else {onUpdateSubmitItinerary()}}} disabled={isSubmit}>{isSubmit ? 'Submiting...' : 'Submit' }</ButtonCustom> }
          </div>
        </div>
      </ModalComponent>
    </Style>
  );
}
