import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ImageComponent } from "./Image";

import blankImg from "../assets/images/404.svg";
import moment from "moment";
import defaultImageUser from "../assets/images/defaultUser.svg";

import { Accordion } from 'react-bootstrap'
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const HeaderContent = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  
  h6 {
    font-size: 1.2rem;
    margin-top: 5px;
  }
  p {
    font-size: 0.9rem;
  }
  i {
    font-size: 0.9rem;
  }
  img {
    width: 70px;
    height: 70px;
    margin-left: -5px;
  }

`;

const BodyContent = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  display: flex;
  margin-bottom: 10px;

  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .time {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 0;
    margin-top:-5px;
  }

  .card-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    margin-bottom: 2px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .reading-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.6);
  }

  .image {
    width: 65px;
    min-width: 65px;
    height: 55.3px;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 10px;
  }

  .image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .time {
      font-size: 14px;
    }
    .card-title {
      font-size: 14px;
    }
    .reading-time {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .time {
      font-size: 14px;
    }
    .card-title {
      font-size: 16px;
    }
    .reading-time {
      font-size: 12px;
    }
  }
`;

const ButtonToggle = styled.button`
  background: transparent;
  border: 0;
`;

export const Contributor = (props) => {
  const { contributor, article, index } = props;

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  function CustomHeader({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <Card.Header style={{ padding: '1rem 1.5rem', backgroundColor: isCurrentEventKey ? 'rgba(0, 0, 0, 0.1)' : 'white'  , display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid rgba(0, 0, 0, 0.1)', borderBottom: 0}}>
        <HeaderContent>
          <Link to={`/user-articles/${contributor?.username}`}>
            <ImageComponent src={contributor?.traveler?.thumbnail ? contributor?.traveler?.thumbnail : contributor?.traveler?.image ? contributor?.traveler?.image : defaultImageUser} className="rounded-circle" alt={`${contributor.username}`} />
          </Link>
          <div>
            <Link to={`/user-articles/${contributor?.username}`} className="text-decoration-none link-focus">
              <h6 className="fw-bolder">{contributor?.traveler?.name ?? "no name"}</h6>
            </Link>
            <div className="d-flex text">
              <div className="d-flex gap-1 me-2">
                <i className="fa-regular fa-file"></i>
                <p> {contributor?.articleTotal} Post</p>
              </div>
              <div className="d-flex gap-1">
                <i className="fa-regular fa-thumbs-up"></i>
                <p> {contributor?.articleLikes}</p>
              </div>
            </div>
          </div>
        </HeaderContent>

        <ButtonToggle
          type="button"
          onClick={decoratedOnClick}
          >
          {isCurrentEventKey ? <FaAngleUp style={{ width: 15, height: 15 }} /> : <FaAngleDown style={{ width: 15, height: 15 }} /> }
        </ButtonToggle>

      </Card.Header>
    );
  }

  return (
    <>
      <Card style={{ marginBottom: '1px' }}>
        <CustomHeader eventKey={index} />
        <Accordion.Collapse eventKey={index}>
          <Card.Body style={{ borderBottom: 1 }}>
            <>
              {/* <p >{contributor.traveler?.bio ?? "no bio"}</p> */}
              <h5>Popular Article</h5>
              {article.length
                ? article.map((article, index) => (
                    <BodyContent key={index}>
                      <div className="image mt-1">
                        <ImageComponent src={isImage(article.thumbnail) ? article.thumbnail : article.image ? article.image : blankImg} alt={article.imageCaption} />
                      </div>
                      <div className="card-text">
                        
                        <p className="card-title">
                          <Link to={`/articles/${article.slug}`} className="card-title link-focus">
                            {article.title}
                          </Link>
                        </p>
                        <p className="time">
                        {moment(article.updatedAt).format("MMM D, YYYY")} - <span className="reading-time">{article.readingTime?.text}</span>
                        </p>
                      </div>
                    </BodyContent>
                  ))
                : ""}
            </>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};
