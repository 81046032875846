/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./EasyCrop";

const NameLabel = styled.label`
  font-size: 1.3rem;
  padding-bottom: 0.7em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;
const LabelGallery = styled.label`
  width: 100%;
  height: 300px;
  border: 1px dashed grey;
  text-align: center;
  line-height: 2em;
  font-size: 1rem;
  background-color: #aaaaaa;
  color: white;

  &:hover {
    color: black;
  }
`;

const Button = styled.button`
  background: transparent;
  border-radius: 4px;
  margin: 0 0.5em;
  padding: 0.25em 0.75em;
  font-size: 0.9rem;
  border: 1px solid black;

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;
export function CropImageProfile({ encodeFileBase64, setReviewImage, reviewImage, handleUploadChange }) {
  //  // console.log(imagePreview);
  const [openCrop, setOpenCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState();
  const [reviewCrop, setReviewCrop] = useState();

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const zoomPercent = (value) => {
    return `${Math.round(value * 33.3)}%`;
  };
  const cropImage = async () => {
    setLoading(true);
    try {
      const { file, url } = await getCroppedImg(reviewImage, croppedAreaPixels, rotation);
      setReviewImage(url);
      handleUploadChange(file);

       // console.log(file);
      setOpenCrop(false);
    } catch (error) {
       // console.log(error);
    }
  };

  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      {" "}
      <div className="modal-dialog" style={{ display: "block" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Image
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Cropper image={reviewImage ? reviewImage : "default"} crop={crop} zoom={zoom} rotation={rotation} aspect={1} onZoomChange={setZoom} onRotationChange={setRotation} onCropChange={setCrop} onCropComplete={cropComplete} />
            </>
          </div>

          <div className="modal-footer d-flex flex-column">
            <div>
              <label htmlFor="customRange2" className="form-label">
                Zoom : {zoomPercent(zoom)}
              </label>
              <input type="range" className="form-range" min="1" max="3" step="0.1" value={zoom} onChange={(e) => setZoom(e.target.value)} id="customRange2"></input>
            </div>
            <div>
              <label htmlFor="customRange2" className="form-label">
                Rotation : {rotation}
              </label>
              <input type="range" className="form-range" min="0" max="360" value={rotation} onChange={(e) => setRotation(e.target.value)} id="customRange2"></input>
            </div>
            <div>
              <button type="button" className="btn btn-outline-dark me-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => setReviewImage()}>
                Cancel
              </button>
              <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={cropImage}>
                Crop
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
