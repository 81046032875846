import { useState, useEffect, useContext } from "react";
import * as articlesData from "../data/articles";
import { Comment, CommentForm } from "../components";
import AuthContext from "./shared/AuthContext";
import Swal from "sweetalert2";
import * as commentsData from "../data/comments";

export function Comments({ currentId, setFetchingComment }) {
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);

  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "/" + currentId + "/comments";
      const res = await articlesData.getArticles(queryParam);
      // console.log({res})

      if (res.code !== 200) {
        return setComments([]);
      }
      setComments(() => res?.data);
      setFetchingComment(true);
    };
    getArticles();
  }, [currentId]);
  const rootComments = comments?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).filter((comment) => comment.isSubComment === false);
  
  const addComment = async (text, isSubComment, mainCommentId, replyTo) => {
    if (!currentId) return;
     // console.log(text + " " + isSubComment + " " + mainCommentId + " " + replyTo);
    const comment = await commentsData.postComment(currentId, {
        comment: text,
        isSubComment: isSubComment,
        mainCommentId: mainCommentId,
        replyTo: replyTo,
      })
      .then((comment) => {
        setComments(comment?.data?.allComments);
        setActiveComment(null);
        // console.table(comment.data.allComments)
      })
      .catch((error) => {
        console.log({error})
        // alert(error.response?.data);
      });
  };
  const deleteComment = async (commentId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
      await commentsData.deleteComment(commentId)
        .then((comment) => {
          Swal.fire("Deleted!", "Your comment has been deleted.", "success").then((result) => {
            if (result.isConfirmed) {
              setComments(comment.data.allComments)
            }
          });
        })
        .catch((error) => {
          alert(error.response?.data);
        });
      } else {
        Swal.fire("Your comment is safe!");
      }    
    })
  };
  const updateComment = async (text, commentId, isSubComment, mainCommentId) => {
    const updatedComment = await commentsData.updateComment(commentId, {
        comment: text,
        isSubComment: isSubComment,
        mainCommentId: mainCommentId,
      })
      .then((comment) => {
        setActiveComment(false)
        setComments(comment.data.allComments);
      })
      .catch((error) => {
        alert(error.response?.data);
      });
  };

  return (
    <div className="comment">
      {user && <CommentForm submitLabel="Send" handleSubmit={(text) => addComment(text, false, null, null)} />}
      {comments.length
        ? comments
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .filter((comment) => comment.isSubComment === false)
            .map((rootComment) => (
              <Comment
                key={rootComment.id}
                comment={rootComment}
                currentId={currentId}
                userId={user ? user.id : null}
                deleteComment={deleteComment}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                addComment={addComment}
                updateComment={updateComment}
                mainCommentId={rootComment.id}
              />
            ))
        : (<div><h6> No Comments...</h6></div>)}
    </div>
  );
}
