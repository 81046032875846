import { CircleButton } from "./button/CircleButton";
import { BottomNavigation, BottomMenu } from "./";

export function Footer(props) {
  const { isVisible, onClose } = props;

  return (
    <div className="footer mb-0 pb-0">
      <footer id="footer" className="bg-dark text-light pt-5 d-none d-md-block">
        <div className="container text-md-left">
          <div className="row text-md-left d-flex ">
            <div className="col-md-4 col-lg-4 col-xl-4 mt-3 me-5">
              <h1 className="mb-4 font-weight-bold">Bipi</h1>
              <p style={{ fontSize: "12px", textAlign: 'justify' }}>Bipi merupakan teman virtual yang akan menemani perjalanan kalian dan memberikan berbagai inspirasi dan informasi seputar dunia traveling. Bipi juga merupakan media portal untuk para komunitas traveler dan backpacker bisa saling bertukar pengalaman lewat artikel.</p>
              
              
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h5 className="mb-4 font-weight-bold">Explore</h5>
              <p>
                <a href="/articles" style={{ color: "white" }}>
                  Articles
                </a>
              </p>

              <p>
                <a href="/news" style={{ color: "white" }}>
                  News
                </a>
              </p>

              <p>
                <a href="/contributors" style={{ color: "white" }}>
                  Contributors
                </a>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h5 className="mb-4 font-weight-bold">Pages</h5>
              <p>
                <a href="/privacy-policy" style={{ color: "white"}}>
                  Privacy policy
                </a>
              </p>

              <p className="d-none">
                <a href="#" style={{ color: "white" }}>
                  Terms of service
                </a>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3 mb-4">
              <h5 className="mb-4 font-weight-bold">Social</h5>
              <div className="d-flex">
                <CircleButton style={{ color: "#fffff" }} icon="fa-brands fa-instagram" url={`https://www.instagram.com/bipi.id?igshid=MzRlODBiNWFlZA%3D%3D`} />
                <CircleButton style={{ color: "#fffff" }} icon="fa-brands fa-tiktok" url={`https://www.tiktok.com/@bipi.id?ug_source=op.auth&ug_term=Linktr.ee&utm_source=awyc6vc625ejxp86&utm_campaign=tt4d_profile_link&_r=1`} />
              </div>
              
            </div>
          </div>
          <div style={{ fontSize: "12px" }} className="copyright mt-5  pb-5">
                &copy; Copyright <strong>Bipi</strong>. 2023
              </div>
        </div>
      </footer>
      
      <BottomNavigation />
      <BottomMenu isVisible={isVisible} onClose={onClose} />
    </div>
  );
}
