import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { DatePickerComponent, MultiForm, TimezoneComponent, TableTree } from "./../"

import GuestForm from "./Guest";
import AttachmentForm from "./Attachment";
import TransportationForm from "./Transportation";
import AccomodationForm from "./Accomodation";
import AttractionForm from "./Attraction";
import CulinaryForm from "./Culinary";

import './style.css'

import { createTrip, updateTrip } from "../../data/itinerary";
import { formatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/currency";

import Swal from 'sweetalert2';
import { capitalizeFirstLetter } from "../../utils/capitalizeString";

export function ItineraryTripForm(props) {
  const navigate = useNavigate()
  const { selectedCategory, showForm, itineraryId, itineraryStartDate = new Date(), itineraryEndDate = new Date(), isMobile, isModeUpdate, updatedTrip } = props;
  
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [tripStartDate, setTripStartDate] = useState(new Date());
  const [tripEndDate, setTripEndDate] = useState(new Date());

  const [tripStartDateTz, setTripStartDateTz] = useState(currentTimezone)
  const [tripEndDateTz, setTripEndDateTz] = useState(currentTimezone)
  
  const [colorTrip, setColorTrip] = useState("")
  
  const [guestsForms, setGuestsForms] = useState([]);
  const [totalGuest, setTotalGuest] = useState(1)

  const [page, setPage] = useState("1");

  const [formTripData, setFormTripData] = useState(
    {
      trip_title: '',
      trip_subtitle: '',
      trip_category: '',
      trip_notes: '',
    }
  )
  const [guestsData, setGuestsData] = useState([
    {
      guest_title: '',
      guest_name: '',
      guest_email: '',
      guest_phone: '',
      guest_id_type: '',
      guest_id_number: '',
      guest_birthdate: '',
      guest_code: '',
    }
  ])
  const [extensionsData, setExtensionsData] = useState([])
  const [attachmentList, setAttachmentList] = useState([])

  const [transportationData, setTransportationData] = useState({})
  const [lodgingData, setLodgingData] = useState({})
  const [activitiesData, setActivitiesData] = useState({})
  const [culinaryData, setCulinaryData] = useState({})

  const [extensionDataHeader, setExtensionDataHeader] = useState([])

  const [isTravelAgencyExist, setIsTravelAgencyExist] = useState(false)

  const [formListBySelectedCategory, setFormListBySelectedCategory] = useState([
    {
      titleForm: "Guests Detail",
      renderForm: () => (
        <>
          {guestsForms}
        </>
      )
    },
    {
      titleForm: "Attachment Detail",
      renderForm: () => (
        <div className="form-extension--container">
          <AttachmentForm itineraryId={itineraryId} attachmentList={attachmentList} setAttachmentList={setAttachmentList} />
        </div>
      )
    },
    {
      titleForm: "Review Data",
      renderForm: () => (<></>)
    },
  ])

  const [isSubmit, setIsSubmit] = useState(false)

  const currencies = [
    {
      value: 'IDR',
      label: '(Rp) IDR'
    },
    {
      value: 'Dollar U.S',
      label: '($) Dollar U.S'
    },
    {
      value: 'Yen',
      label: '(¥) Yen'
    },
  ]

  const validateStepForm = (stepForm) => {
    let invalidKeys = [];

    if (stepForm === 1) {
      invalidKeys = guestsData.map((guest, index) => {
        const entries = Object.entries(guest);
        const invalidKeysInGuest = entries.filter(([key, value]) => !key.startsWith('guest_code') && value === '');

        if (invalidKeysInGuest.length !== 0 || entries.length !== 8) {
          return { index, invalidKeys: invalidKeysInGuest.map(([key, value]) => key) };
        } else {
          return null;
        }
      }).filter(item => item !== null);
    }

    if (stepForm === 2) {
      let categoryData = null
      if (selectedCategory === 'Transportation') {
        categoryData = transportationData
      } else if (selectedCategory === 'Lodging') {
        categoryData = lodgingData
      } else if (selectedCategory === 'Activities') {
        categoryData = activitiesData
      } else if (selectedCategory === 'Culinary') {
        categoryData = culinaryData
      }

      if (categoryData) {
        const entries = Object.entries(categoryData)
        let invalidKeyInExtension = entries.filter(([key, value]) => value === '')

        let subDataInvalidKeys = []
        if ('subData' in categoryData) {
          const subDataKeys = categoryData.subData.filter(key => !Object.keys(categoryData.subData).includes(key));

          subDataInvalidKeys = subDataKeys.map((subData, index) => {
            const object = Object.entries(subData)
            const invalidKeysInSubData = object.filter(([key, value]) => value === '')
            if (invalidKeysInSubData.length !== 0) {
              return invalidKeysInSubData.map(([key, value]) => key)
            }
            return null
          }).filter(item => item !== null)
          
          invalidKeyInExtension = invalidKeyInExtension.concat(subDataInvalidKeys);
        }

        if (invalidKeyInExtension.length !== 0) {
          invalidKeys = [{ index: 0, invalidKeys: invalidKeyInExtension.map(([key, value]) => key) }];
        } else {
          invalidKeys = []
        }
      }
    }
    
    if (invalidKeys.length === 0) {
      return { isValid: true };
    } else {
      return { isValid: false, invalidData: invalidKeys, stepForm };
    }
  }

  const handleTotalGuestChange = (e) => {
    const newTotalGuest = parseInt(e.target.value, 10);
    if (newTotalGuest > totalGuest) {
      setGuestsData(prev => [...prev, {
        guest_title: '',
        guest_name: '',
        guest_email: '',
        guest_phone: '',
        guest_id_type: '',
        guest_id_number: '',
        guest_birthdate: '',
      }])
    } else {
      let newGuestList = [...guestsData]
      newGuestList.pop()
      setGuestsData(newGuestList)
    }

    setTotalGuest(newTotalGuest);
  }

  const handlePageNumber = (pageNumber, direction) => {
    const stepIndex = parseInt(pageNumber)
    if (direction === 'next') {
      const isFormValid = validateStepForm(parseInt(page))
      if (!isFormValid.isValid) {
        const indexForm = isFormValid.invalidData[0].index
        const invalidForm = isFormValid.invalidData[0].invalidKeys[0]
        const formName = invalidForm.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());;

        let message = `Fill <strong>${capitalizeFirstLetter(formName)}</strong>`
        if (isFormValid.stepForm === 1) {
          message = `Fill <strong>${capitalizeFirstLetter(formName)}</strong> on Guest ${indexForm + 1}`
        }
  
        return Swal.fire({
          title: 'Step Form Invalid',
          html: message,
          icon: 'error',
        })
      }

      setPage(String(stepIndex + 1))
      // if (stepIndex >= 0 && stepIndex < formListBySelectedCategory.length) setPage(String(stepIndex + 1));
    } else if (direction === 'previous') {
      if (stepIndex > 1 && stepIndex <= formListBySelectedCategory.length) setPage(String(stepIndex - 1));
    } else if (direction === 'start') {
      setPage('1');
    } else if (direction === 'spesific') {
      setPage(pageNumber)
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name.startsWith('trip_')) {
      return setFormTripData(prev => ({...prev, [name]: value }))
    }

    /** untuk handle data yg bisa lebih dari 1 form */
    if (name.startsWith('guest_')) {
      setGuestsData(prevGuestsData => {
        if (prevGuestsData.length === 0) {
          return [{ [name]: value }];
        }

        if (index >= prevGuestsData.length) {
          return [...prevGuestsData, { [name]: value }];
        }
  
        return prevGuestsData.map((guest, i) => {
          if (i === index) {
            return { ...guest, [name]: value };
          }
          return guest;
        });
      });
    }

    if (selectedCategory === 'Transportation') {
      const elements = document.querySelectorAll('[name^="transportation_travel"]');
      if (name.startsWith('transportation_') && !name.startsWith('transportation_travel')) {
        setTransportationData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } else if (name.startsWith('transportation_travel_')) {
        if (elements.length > 0) {
          const subDataObject = {};
          
          elements.forEach(input => {
            subDataObject[input.name] = input.value;
          });
  
          setTransportationData(prevData => ({
            ...prevData,
            subData: [subDataObject]
          }));
        }
      }
    }

    if (selectedCategory === 'Lodging') {
      const elements = document.querySelectorAll('[name^="lodging_travel"]');

      if (name.startsWith('lodging_') && !name.startsWith('lodging_travel')) {
        setLodgingData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } else if (name.startsWith('lodging_travel_')) {
        if (elements.length > 0) {
          const subDataObject = {};
          
          elements.forEach(input => {
            subDataObject[input.name] = input.value;
          });
  
          setLodgingData(prevData => ({
            ...prevData,
            subData: [subDataObject]
          }));
        }
      }
    }

    if (selectedCategory === 'Activities') {
      const elements = document.querySelectorAll('[name^="activities_travel"]');

      if (name.startsWith('activities_') && !name.startsWith('activities_travel')) {
        if (name === 'activities_price') {
          const formattedValue = formatCurrency(value);
          
          setActivitiesData(prevData => ({
            ...prevData,
            [name]: formattedValue
          }))
        } else {
          setActivitiesData(prevData => ({
            ...prevData,
            [name]: value
          }))
        }
      } else if (name.startsWith('activities_travel_')) {
        if (elements.length > 0) {
          const subDataObject = {};
          
          elements.forEach(input => {
            subDataObject[input.name] = input.value;
          });
  
          setActivitiesData(prevData => ({
            ...prevData,
            subData: [subDataObject]
          }));
        }
      }
    }
    
    if (selectedCategory === 'Culinary') {
      const elements = document.querySelectorAll('[name^="culinary_travel"]');

      if (name.startsWith('culinary_') && !name.startsWith('culinary_travel')) {
        if (name === 'culinary_price') {
          const formattedValue = formatCurrency(value);
          
          setCulinaryData(prevData => ({
            ...prevData,
            [name]: formattedValue
          }))
        } else {
          setCulinaryData(prevData => ({
            ...prevData,
            [name]: value
          }))
        }
      } else if (name.startsWith('culinary_travel_')) {
        if (elements.length > 0) {
          const subDataObject = {};
          
          elements.forEach(input => {
            subDataObject[input.name] = input.value;
          });
  
          setCulinaryData(prevData => ({
            ...prevData,
            subData: [subDataObject]
          }));
        }
      }
    }
  };

  const insertForm = (data, insertedData) => {
    const isEvenLength = data.length % 2 === 0;

    let insertIndex;

    if (isEvenLength) {
      insertIndex = data.length / 2; // Jika genap, posisi tengah adalah di antara dua elemen
    } else {
      insertIndex = Math.floor(data.length / 2); // Jika ganjil, posisi tengah adalah elemen yang tepat
    }
    const newDataArray = [...data];
    newDataArray.splice(insertIndex, 0, insertedData);

    return newDataArray
  }

  const handleSubmitForm = async() => {
    setIsSubmit(true)
    const { trip_title, trip_subtitle, trip_notes = '' } = formTripData;
    
    if (!trip_title || !trip_subtitle || !selectedCategory || colorTrip === '') {
      setIsSubmit(false)
      return Swal.fire({
        title: 'Form Trip Not Completed',
        icon: 'error',
        text: 'Fill mandatory field (*) !'
      })
    }

    let extesionPayload = [];

    if (selectedCategory === 'Transportation') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.transportation_name ?? '',
          type: data?.transportation_type ?? '',
          departTerminal: data?.transportation_depart_terminal ?? '',
          departGate: data?.transportation_depart_gate ?? '',
          arriveTerminal: data?.transportation_arrive_terminal ?? '',
          arriveGate: data?.transportation_arrive_gate ?? '',
          seat: data?.transportation_seat ?? '',
          notes: '',
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.transportation_travel_agency_name
            payload.travelAgencyPhone = subData?.transportation_travel_agency_phone
            payload.travelAgencyEmail = subData?.transportation_travel_agency_email
            payload.travelAgencyContactName = subData?.transportation_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.transportation_travel_agency_code
            payload.travelAgencyUrl = subData?.transportation_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    if (selectedCategory === 'Lodging') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.lodging_name ?? '',
          type: data?.lodging_type ?? '',
          checkIn: new Date(data?.lodging_checkin) ?? '',
          checkOut: new Date(data?.lodging_checkout) ?? '',
          roomNumber: data?.lodging_room_number ?? '',
          address: data?.lodging_address ?? '',
          notes: data?.lodging_notes ?? '',
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.lodging_travel_agency_name
            payload.travelAgencyPhone = subData?.lodging_travel_agency_phone
            payload.travelAgencyEmail = subData?.lodging_travel_agency_email
            payload.travelAgencyContactName = subData?.lodging_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.lodging_travel_agency_code
            payload.travelAgencyUrl = subData?.lodging_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    if (selectedCategory === 'Activities') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.activities_name ?? '',
          type: data?.activities_type ?? '',
          currency: data?.activities_currency ?? '',
          price: data?.activities_price ?? '',
          address: data?.activities_address ?? '',
          notes: data?.activities_notes ?? ''
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.activities_travel_agency_name
            payload.travelAgencyPhone = subData?.activities_travel_agency_phone
            payload.travelAgencyEmail = subData?.activities_travel_agency_email
            payload.travelAgencyContactName = subData?.activities_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.activities_travel_agency_code
            payload.travelAgencyUrl = subData?.activities_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    if (selectedCategory === 'Culinary') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.culinary_name ?? '',
          type: data?.culinary_type ?? '',
          currency: data?.culinary_currency ?? '',
          price: data?.culinary_price ?? '',
          address: data?.culinary_address ?? '',
          notes: data?.culinary_notes ?? ''
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.culinary_travel_agency_name
            payload.travelAgencyPhone = subData?.culinary_travel_agency_phone
            payload.travelAgencyEmail = subData?.culinary_travel_agency_email
            payload.travelAgencyContactName = subData?.culinary_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.culinary_travel_agency_code
            payload.travelAgencyUrl = subData?.culinary_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    const startDateTimezone = tripStartDateTz?.value ?? tripStartDateTz
    const endDateTimezone = tripEndDateTz?.value ?? tripStartDateTz

    const payload = {
      itineraryId,
      title: trip_title ?? '',
      subtitle: trip_subtitle ?? '',
      categoryTrip: selectedCategory ?? '',
      startDate: formatDate(tripStartDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: formatDate(tripEndDate, 'yyyy-MM-dd HH:mm:ss'),
      startDateTimezone: startDateTimezone,
      endDateTimezone: endDateTimezone,
      color: colorTrip,
      totalGuest: totalGuest,
      notes: trip_notes ?? '',
      guestTrip: guestsData,
      extensionsData: extesionPayload,
      attachmentTrip: attachmentList,
    }

    const result = await createTrip(payload)
    
    if (result?.data?.code !== 201) {
      return setIsSubmit(false)
    }

    navigate(0)
    setIsSubmit(false)
  }

  const handleSubmitUpdate = async() => {
    const { trip_title, trip_subtitle, trip_category, trip_notes} = formTripData || {};
    
    if (!trip_title || !trip_subtitle || !selectedCategory || colorTrip === '') {
      return Swal.fire({
        title: 'Form Trip Not Completed',
        icon: 'error',
        text: 'Fill mandatory field (*) !'
      })
    }
    
    let extesionPayload = [];

    if (selectedCategory === 'Transportation') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.transportation_name ?? '',
          type: data?.transportation_type ?? '',
          departTerminal: data?.transportation_depart_terminal ?? '',
          departGate: data?.transportation_depart_gate ?? '',
          arriveTerminal: data?.transportation_arrive_terminal ?? '',
          arriveGate: data?.transportation_arrive_gate ?? '',
          seat: data?.transportation_seat ?? '',
          notes: '',
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.transportation_travel_agency_name
            payload.travelAgencyPhone = subData?.transportation_travel_agency_phone
            payload.travelAgencyEmail = subData?.transportation_travel_agency_email
            payload.travelAgencyContactName = subData?.transportation_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.transportation_travel_agency_code
            payload.travelAgencyUrl = subData?.transportation_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    if (selectedCategory === 'Lodging') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.lodging_name ?? '',
          type: data?.lodging_type ?? '',
          checkIn: new Date(data?.lodging_checkin) ?? '',
          checkOut: new Date(data?.lodging_checkout) ?? '',
          roomNumber: data?.lodging_room_number ?? '',
          address: data?.lodging_address ?? '',
          notes: data?.lodging_notes ?? '',
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.lodging_travel_agency_name
            payload.travelAgencyPhone = subData?.lodging_travel_agency_phone
            payload.travelAgencyEmail = subData?.lodging_travel_agency_email
            payload.travelAgencyContactName = subData?.lodging_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.lodging_travel_agency_code
            payload.travelAgencyUrl = subData?.lodging_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    if (selectedCategory === 'Activities') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.activities_name ?? '',
          type: data?.activities_type ?? '',
          currency: data?.activities_currency ?? '',
          price: data?.activities_price ?? '',
          address: data?.activities_address ?? '',
          notes: data?.activities_notes ?? ''
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.activities_travel_agency_name
            payload.travelAgencyPhone = subData?.activities_travel_agency_phone
            payload.travelAgencyEmail = subData?.activities_travel_agency_email
            payload.travelAgencyContactName = subData?.activities_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.activities_travel_agency_code
            payload.travelAgencyUrl = subData?.activities_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    if (selectedCategory === 'Culinary') {
      extesionPayload = extensionsData.map((data) => {
        let payload = {
          name: data?.culinary_name ?? '',
          type: data?.culinary_type ?? '',
          currency: data?.culinary_currency ?? '',
          price: data?.culinary_price ?? '',
          address: data?.culinary_address ?? '',
          notes: data?.culinary_notes ?? ''
        }

        if (data?.subData && data?.subData.length > 0) {
          data?.subData.map((subData) => {
            payload.travelAgencyName = subData?.culinary_travel_agency_name
            payload.travelAgencyPhone = subData?.culinary_travel_agency_phone
            payload.travelAgencyEmail = subData?.culinary_travel_agency_email
            payload.travelAgencyContactName = subData?.culinary_travel_agency_contact_name
            payload.travelAgencyConfirmationCode = subData?.culinary_travel_agency_code
            payload.travelAgencyUrl = subData?.culinary_travel_agency_url
            return payload
          })
        }

        return payload
      })
    }

    const startDateTimezone = tripStartDateTz?.value ?? tripStartDateTz
    const endDateTimezone = tripEndDateTz?.value ?? tripStartDateTz

    const payload = {
      itineraryId,
      title: trip_title,
      subtitle: trip_subtitle,
      categoryTrip: trip_category,
      startDate: formatDate(tripStartDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: formatDate(tripEndDate, 'yyyy-MM-dd HH:mm:ss'),
      startDateTimezone: startDateTimezone,
      endDateTimezone: endDateTimezone,
      color: colorTrip,
      totalGuest: totalGuest,
      notes: trip_notes,
      guestTrip: guestsData,
      extensionsData: extesionPayload,
      attachmentTrip: attachmentList,
    }

    const tripId = updatedTrip.id

    if (tripId) {
      const result = await updateTrip(tripId, payload)

      if (result.status && result.status !== 201) {
        return Swal.fire({
          title: 'Something went wrong..',
          icon: 'error',
          text: 'Error Occured'
        })
      }

      navigate(0)
    }
  }

  useEffect(() => {
    if (!isTravelAgencyExist) {
      /** remove travel agency data when travel agency form hidden */
      if (selectedCategory === 'Transportation') {
        setTransportationData(prevState => {
          const { subData, ...updatedTransportationData } = prevState;
          return updatedTransportationData;
        });
      }

      if (selectedCategory === 'Lodging') {
        setLodgingData(prevState => {
          const { subData, ...updatedLodgingData } = prevState
          return updatedLodgingData
        })
      }

      if (selectedCategory === 'Activities') {
        setActivitiesData(prevState => {
          const { subData, ...updatedActivitiesData } = prevState
          return updatedActivitiesData
        })
      }

      if (selectedCategory === 'Culinary') {
        setCulinaryData(prevState => {
          const { subData, ...updatedCulinaryData } = prevState
          return updatedCulinaryData
        })
      }
    } else {
      /** set initial value when travel agency form visible */
      if (selectedCategory === 'Transportation') {
        setTransportationData(prevData => ({
          ...prevData,
          subData: [
            {
              transportation_travel_agency_name: '',
              transportation_travel_agency_email: '',
              transportation_travel_agency_phone: '',
              transportation_travel_agency_contact_name: '',
              transportation_travel_agency_code: '',
              transportation_travel_agency_url: '',
            }
          ]
        }));
      }

      if (selectedCategory === 'Lodging') {
        setLodgingData(prevData => ({
          ...prevData,
          subData: [{
            travel_travel_agency_name: '',
            travel_travel_agency_email: '',
            travel_travel_agency_phone: '',
            travel_travel_agency_contact_name: '',
            travel_travel_agency_code: '',
            travel_travel_agency_url: '',
          }]
        }))
      }

      if (selectedCategory === 'Activities') {
        setActivitiesData(prevData => ({
          ...prevData,
          subData: [{
            activities_travel_agency_name: '',
            activities_travel_agency_email: '',
            activities_travel_agency_phone: '',
            activities_travel_agency_contact_name: '',
            activities_travel_agency_code: '',
            activities_travel_agency_url: '',
          }]
        }))
      }

      if (selectedCategory === 'Culinary') {
        setCulinaryData(prevData => ({
          ...prevData,
          subData: [{
            culinary_travel_agency_name: '',
            culinary_travel_agency_email: '',
            culinary_travel_agency_phone: '',
            culinary_travel_agency_contact_name: '',
            culinary_travel_agency_code: '',
            culinary_travel_agency_url: '',
          }]
        }))
      }
    }
  }, [isTravelAgencyExist, selectedCategory])

  useEffect(() => {
    /** handle change totalGuest and push forms */
    const renderGuestForm = () => {
      const newForms = [];

      for (let i = 0; i < totalGuest; i++) {
        newForms.push(
          <div key={i}>
            <h3 className="data-extension--title no-border no-margin"><span>Guest { i + 1 }</span></h3>
            <div className="form-extension--container">
              <GuestForm index={i} guestList={guestsData} handleInputChange={handleInputChange} />
            </div>
          </div>
        );
      }
  
      setGuestsForms(newForms)
    }

    renderGuestForm()
  }, [totalGuest, guestsData]);

  useEffect(() => {
    /** handle change state guestsForm and render NEWEST FORMS LIST */
    setFormListBySelectedCategory(prevFormList => {
      const updatedFormList = prevFormList.map(form => {
        if (form.titleForm === "Guests Detail") {
          return {
            ...form,
            renderForm: () => (
              <>
                {guestsForms}
              </>
            )
          };
        }

        if (form.titleForm === "Transportation Detail") {
          return {
            ...form,
            renderForm: () => (
              <div className="form-extension--container">
                <TransportationForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} extensionsData={extensionsData} />
              </div>
            )
          }
        }

        if (form.titleForm === "Accomodation Detail") {
          return {
            ...form,
            renderForm: () => (
              <div className="form-extension--container">
                <AccomodationForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} extensionsData={extensionsData} />
              </div>
            )
          }
        }

        if (form.titleForm === "Attraction Detail") {
          return {
            ...form,
            renderForm: () => (
              <div className="form-extension--container">
                <AttractionForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} currencies={currencies} extensionsData={extensionsData} />
              </div>
            )
          }
        }

        if (form.titleForm === "Culinary Detail") {
          return {
            ...form,
            renderForm: () => (
              <div className="form-extension--container">
                <CulinaryForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} currencies={currencies} extensionsData={extensionsData} />
              </div>
            )
          }
        }

        if (form.titleForm === "Attachment Detail") {
          return {
            ...form,
            renderForm: () => (
              <div className="form-extension--container">
                <AttachmentForm itineraryId={itineraryId} attachmentList={attachmentList} setAttachmentList={setAttachmentList} />
              </div>
            )
          }
        }

        if (form.titleForm === "Review Data") {
          return {
            ...form,
            renderForm: () => (
              <div className="form-extension--container summary-form">
                <div className="form-summary--container">
                  <div className="form-summary--header-container">
                    <h3 className="title">Guests</h3>
                    <button className="btn-action-summary" onClick={() => handlePageNumber(1,'spesific')}>Edit</button>
                  </div>

                  <table className="table-summary">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Id Type</th>
                        <th>Id Number</th>
                        <th>Birthdate</th>
                      </tr>
                    </thead>

                    <tbody>
                      {guestsData.length > 0 ? guestsData.map((guest, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{guest.guest_title} {guest.guest_name}</td>
                              <td>{guest.guest_email}</td>
                              <td>{guest.guest_phone}</td>
                              <td>{guest.guest_id_type}</td>
                              <td>{guest.guest_id_number}</td>
                              <td>{guest.guest_birthdate}</td>
                              {/* <td>{formatDate(guest.guest_birthdate, 'yyyy-MM-dd')}</td> */}
                            </tr>
                          )
                        }
                      ) : 
                        <tr>
                          <td colSpan={7}> No Data..</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>

                <div className="form-summary--container">
                  <div className="form-summary--header-container">
                    <h3 className="title">{selectedCategory}</h3>
                    <button className="btn-action-summary" onClick={() => handlePageNumber(2,'spesific')}>Edit</button>
                  </div>

                  <TableTree 
                    parentHeader={extensionDataHeader}
                    parentRow={extensionsData}
                  />
                </div>

                <div className="form-summary--container">
                  <div className="form-summary--header-container">
                    <h3 className="title">Attachment</h3>
                    <button className="btn-action-summary" onClick={() => handlePageNumber(3,'spesific')}>Edit</button>
                  </div>

                  <table className="table-summary">
                    <thead>
                      <tr>
                        <th width={'5%'}>No.</th>
                        <th width={'15%'}>Name</th>
                        <th>Url</th>
                      </tr>
                    </thead>

                    <tbody>
                      {attachmentList.length > 0 ? attachmentList.map((file, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{file.name}</td>
                              <td><a href={file.url} target="_blank" rel="noreferrer" className="attachment-link">Download</a></td>
                            </tr>
                          )
                        }
                      ) : 
                        <tr>
                          <td colSpan={3}> No Data..</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }
        }

        return form;
      });
      return updatedFormList;
    });
  }, [guestsForms, guestsData, extensionsData, attachmentList, itineraryId, selectedCategory]);

  useEffect(() => {
    /** === READ USAGE === render / show form extension depends on selected category */
    let requiredForm = null;

    if (selectedCategory === 'Transportation') {
      setExtensionDataHeader(['', 'Name', 'Type', 'Dep. Terminal', 'Dep. Gate', 'Arr. Terminal', 'Arr. Gate', 'Seat'])
      setTransportationData(
        {
          transportation_name: '',
          transportation_type: '',
          transportation_depart_terminal: '',
          transportation_depart_gate: '',
          transportation_arrive_terminal: '',
          transportation_arrive_gate: '',
          transportation_seat: '',
        }
      )

      requiredForm = {
        titleForm: "Transportation Detail",
        renderForm: () => (
          <div className="form-extension--container">
            <TransportationForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} />
          </div>
        )
      }
    }

    if (selectedCategory === 'Lodging') {
      setExtensionDataHeader(['', 'Name', 'Type', 'Check In', 'Check Out', 'No. Room', 'Address', 'Notes'])
      setLodgingData({
        lodging_name: '',
        lodging_type: '',
        lodging_checkin: '',
        lodging_checkout: '',
        lodging_room_number: '',
        lodging_address: '',
        lodging_notes: '',
      })

      requiredForm = {
        titleForm: "Accomodation Detail",
        renderForm: () => (
          <div className="form-extension--container">
            <AccomodationForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} />
          </div>
        )
      }
    }

    if (selectedCategory === 'Activities') {
      setExtensionDataHeader(['', 'Name', 'Type', 'Currency', 'Price', 'Address', 'Notes'])
      setActivitiesData({
        activities_name: '',
        activities_type: '',
        activities_currency: '',
        activities_price: '',
        activities_address: '',
        activities_notes: '',
      })

      requiredForm = {
        titleForm: "Attraction Detail",
        renderForm: () => (
          <div className="form-extension--container">
            <AttractionForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} currencies={currencies} />
          </div>
        )
      }
    }

    if (selectedCategory === 'Culinary') {
      setExtensionDataHeader(['', 'Name', 'Type', 'Currency', 'Price', 'Address', 'Notes'])
      setCulinaryData({
        culinary_name: '',
        culinary_type: '',
        culinary_currency: '',
        culinary_price: '',
        culinary_address: '',
        culinary_notes: '',
      })

      requiredForm = {
        titleForm: "Culinary Detail",
        renderForm: () => (
          <div className="form-extension--container">
            <CulinaryForm name={selectedCategory} handleInputChange={handleInputChange} setIsTravelAgencyExist={setIsTravelAgencyExist} currencies={currencies} />
          </div>
        )
      }
    }

    if (requiredForm) setFormListBySelectedCategory(insertForm(formListBySelectedCategory, requiredForm))

  }, [selectedCategory])

  useEffect(() => {
    let result = []

    if (Object.keys(transportationData).length > 0) {
      result.push(transportationData)
    } else if (Object.keys(lodgingData).length > 0) {
      result.push(lodgingData)
    } else if (Object.keys(activitiesData).length > 0) {
      result.push(activitiesData)
    } else if (Object.keys(culinaryData).length > 0) {
      result.push(culinaryData)
    }

    if (result.length > 0) setExtensionsData(result)
  }, [transportationData, lodgingData, activitiesData, culinaryData])

  useEffect(() => {
    if (isModeUpdate) {
      setFormTripData({
        trip_title: updatedTrip.title,
        trip_subtitle: updatedTrip.subTitle,
        trip_category: updatedTrip.categoryTrip,
        trip_notes: updatedTrip.notes,
      })
      setTotalGuest(updatedTrip.totalGuest)
      setTripStartDateTz(updatedTrip.startDateTz)
      setTripEndDateTz(updatedTrip.endDateTz)
      setColorTrip(updatedTrip.color)
      setTripStartDate(updatedTrip.startDate)
      setTripEndDate(updatedTrip.endDate)

      const guestList = updatedTrip.tripGuest.map((data) => {
        return {
          guest_title: data.title,
          guest_name: data.name,
          guest_email: data.email,
          guest_phone: data.phoneNumber,
          guest_id_type: data.identificationType,
          guest_id_number: data.identificationNumber,
          guest_birthdate: data.birthDate,
          guest_code: data.uniqueCode,
        }
      })
      setGuestsData(guestList)
      
      if (selectedCategory === 'Transportation') {
        const transportationData = updatedTrip.tripTransportation.map((data) => {
          return {
            transportation_name: data.name,
            transportation_type: data.type,
            transportation_depart_terminal: data.departTerminal,
            transportation_depart_gate: data.departGate,
            transportation_arrive_terminal: data.arriveTerminal,
            transportation_arrive_gate: data.arriveGate,
            transportation_seat: data.seat,
            subData: [
              {
                transportation_travel_agency_name: data.travelAgencyName ? data.travelAgencyName : '',
                transportation_travel_agency_email: data.travelAgencyEmail ? data.travelAgencyEmail : '',
                transportation_travel_agency_phone: data.travelAgencyPhone ? data.travelAgencyPhone : '',
                transportation_travel_agency_contact_name: data.travelAgencyContactName ? data.travelAgencyContactName : '',
                transportation_travel_agency_code: data.travelAgencyCode ? data.travelAgencyCode : '',
                transportation_travel_agency_url: data.travelAgencyUrl ? data.travelAgencyUrl : ''
              }
            ]
          }
        })
        setTransportationData(transportationData[0])
      }

      if (selectedCategory === 'Lodging') {
        const lodgingdata = updatedTrip.tripAccomodation.map((data) => {
          return {
            lodging_name: data.name,
            lodging_type: data.type,
            lodging_checkin: formatDate(data.checkIn, 'yyyy-MM-dd\'T\'HH:mm'),
            lodging_checkout: formatDate(data.checkOut, 'yyyy-MM-dd\'T\'HH:mm'),
            lodging_room_number: data.roomNumber,
            lodging_address: data.address,
            lodging_notes: data.notes,
            subData: [
              {
                lodging_travel_agency_name: data.travelAgencyName ? data.travelAgencyName: '',
                lodging_travel_agency_email: data.travelAgencyEmail ? data.travelAgencyEmail : '',
                lodging_travel_agency_phone: data.travelAgencyPhone ? data.travelAgencyPhone : '',
                lodging_travel_agency_contact_name: data.travelAgencyContactName ? data.travelAgencyContactName : '',
                lodging_travel_agency_code: data.travelAgencyCode ? data.travelAgencyCode : '',
                lodging_travel_agency_url: data.travelAgencyUrl ? data.travelAgencyUrl : ''
              }
            ],
          }
        })
        setLodgingData(lodgingdata[0])
      }

      if (selectedCategory === 'Activities') {
        const activities = updatedTrip.tripAttraction.map(data => {
          return {
            activities_name: data.name,
            activities_type: data.type,
            activities_currency: data.currency,
            activities_price: data.price,
            activities_address: data.address,
            activities_notes: data.notes,
            subData: [
              {
                activities_travel_agency_name: data.travelAgencyName ? data.travelAgencyName : '',
                activities_travel_agency_email: data.travelAgencyEmail ? data.travelAgencyEmail : '',
                activities_travel_agency_phone: data.travelAgencyPhone ? data.travelAgencyPhone : '',
                activities_travel_agency_contact_name: data.travelAgencyContactName ? data.travelAgencyContactName : '',
                activities_travel_agency_code: data.travelAgencyCode ? data.travelAgencyCode : '',
                activities_travel_agency_url: data.travelAgencyUrl ? data.travelAgencyUrl : ''
              }
            ]
          }
        })
        setActivitiesData(activities[0])
      }

      if (selectedCategory === 'Culinary') {
        const culinary = updatedTrip.tripCulinary.map(data => {
          return {
            culinary_name: data.name,
            culinary_type: data.type,
            culinary_currency: data.currency,
            culinary_price: data.price,
            culinary_address: data.address,
            culinary_notes: data.notes,
            subData: [
              {
                culinary_travel_agency_name: data.travelAgencyName ? data.travelAgencyName : '',
                culinary_travel_agency_email: data.travelAgencyEmail ? data.travelAgencyEmail : '',
                culinary_travel_agency_phone: data.travelAgencyPhone ? data.travelAgencyPhone : '',
                culinary_travel_agency_contact_name: data.travelAgencyContactName ? data.travelAgencyContactName : '',
                culinary_travel_agency_code: data.travelAgencyCode ? data.travelAgencyCode : '',
                culinary_travel_agency_url: data.travelAgencyUrl ? data.travelAgencyUrl : ''
              }
            ]
          }
        })
        setCulinaryData(culinary[0])
      }

      if (updatedTrip.tripAttachment.length > 0) {
        const attachmentList = updatedTrip.tripAttachment.map((data) => {
          return {
            name: data.name,
            url: data.url
          }
        })

        setAttachmentList(attachmentList)
      }
    }
  }, [isModeUpdate, updatedTrip, selectedCategory])

  return(
    <div>
      <div className="trip-form--container">
        <div className="trip-form--parent">
          <h3 className="data-extension--title"><span>Trip Form</span></h3>
          <div className='form-input-trip'>
            <div className='form-input-group'>
              <label htmlFor="">Trip Title<span className="mandatory-field-label">*</span></label>
              <input type="text" name='trip_title' value={formTripData.trip_title} onChange={handleInputChange} className='form-input' />
            </div>

            <div className='form-input-group'>
              <label htmlFor="">Trip Subtitle<span className="mandatory-field-label">*</span></label>
              <input type="text" name='trip_subtitle' value={formTripData.trip_subtitle} onChange={handleInputChange} className='form-input' />
            </div>

            <div className='form-input-group'>
              <label htmlFor="">Trip Category<span className="mandatory-field-label">*</span></label>
              <input type="text" name='trip_category' defaultValue={selectedCategory !== 'Other' ? selectedCategory : ''} onChange={handleInputChange} className='form-input' disabled={selectedCategory !== 'Other'} />
            </div>

            <div className='form-input-group'>
              <label htmlFor="">Start Date</label>
              <div className='form-date-input-group'>
                <DatePickerComponent
                  date={tripStartDate}
                  setDate={setTripStartDate}
                  isTimePicker
                  hideOnScroll
                  calendarPosition={isMobile ? 'bottom-start' : `right-center`}
                  disableOutsideTwoDates={[itineraryStartDate, itineraryEndDate]}
                />
                <TimezoneComponent date={tripStartDateTz} setDate={setTripStartDateTz} label={`abbrev`} />
              </div>
            </div>

            <div className='form-input-group'>
              <label htmlFor="">End Date</label>
              <div className='form-date-input-group'>
                <DatePickerComponent
                  date={tripEndDate}
                  setDate={setTripEndDate}
                  isTimePicker
                  calendarPosition={isMobile ? 'bottom-start' : `right-center`}
                  disableOutsideTwoDates={[itineraryStartDate, itineraryEndDate]}hideOnScroll 
                />
                <TimezoneComponent date={tripEndDateTz} setDate={setTripEndDateTz} label={`abbrev`} />
              </div>
            </div>

            <div className='form-input-group'>
              <label htmlFor="">Total Guest</label>
              <input type="number" name='trip_total_guest' className='form-input' value={totalGuest} onChange={handleTotalGuestChange} min={1} max={6} disabled={parseInt(page) !== 1}/>
            </div>

            <div className='form-input-group'>
              <label htmlFor="">Notes</label>
              <input type="text" name='trip_notes' value={formTripData.trip_notes} onChange={handleInputChange} className='form-input' placeholder="Optional" />
            </div>

            <div className='form-input-group'>
              {/* <ColorPicker color={colorTrip} onChange={setColorTrip} height={150} hideInput={["rgb", "hsv", "hex"]} /> */}
              <label htmlFor="">Color<span className="mandatory-field-label">*</span></label>
              <div className="color-list--container">
                <div className="color--item" style={{ backgroundColor: '#FD8A8A', border: `${colorTrip === '#FD8A8A' ? '2px solid black' : ''}`}} onClick={() => setColorTrip("#FD8A8A")}></div>
                <div className="color--item" style={{ backgroundColor: '#FFCBCB', border: `${colorTrip === '#FFCBCB' ? '2px solid black' : ''}`}} onClick={() => setColorTrip("#FFCBCB")}></div>
                <div className="color--item" style={{ backgroundColor: '#9EA1D4', border: `${colorTrip === '#9EA1D4' ? '2px solid black' : ''}`}} onClick={() => setColorTrip("#9EA1D4")}></div>
                <div className="color--item" style={{ backgroundColor: '#F1F7B5', border: `${colorTrip === '#F1F7B5' ? '2px solid black' : ''}`}} onClick={() => setColorTrip("#F1F7B5")}></div>
                <div className="color--item" style={{ backgroundColor: '#A8D1D1', border: `${colorTrip === '#A8D1D1' ? '2px solid black' : ''}`}} onClick={() => setColorTrip("#A8D1D1")}></div>
                <div className="color--item" style={{ backgroundColor: '#DFEBEB', border: `${colorTrip === '#DFEBEB' ? '2px solid black' : ''}`}} onClick={() => setColorTrip("#DFEBEB")}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="extension-form--parent">
          <div className={`form-input-extension--container visible`}>
            <MultiForm page={page} onPageNumberClick={handlePageNumber} setPage={setPage} formList={formListBySelectedCategory} showForm={showForm} handleSubmit={handleSubmitForm} isSubmit={isSubmit} handleSubmitUpdate={handleSubmitUpdate} isModeUpdate={isModeUpdate} />
          </div>
        </div>
      </div>

    </div>
  )
}