import { Link } from "react-router-dom";
import styled from "styled-components";
import defaultImageUser from "../assets/images/defaultUser.svg";

const ContributorStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  .count-post .name-user .rank {
    font-family: "DM Sans", sans-serif;
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  .name-user {
    font-size: 16px;
    text-decoration: none;
    text-decoration: none;
  }
  .count-post {
    font-size: 14px;
    margin-top:-5px;
  }

  .rank {
    font-size: 40px;
    font-weight: bolder;
    color: #a8a8a8;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .name-user {
      font-size: 15px;
    }
    .count-post {
      font-size: 12px;
    }
    img {
      width: 90px;
      height: 90px;
    }
    .rank {
      font-size: 30px;
      margin-top: -30px;
      margin-right: -10px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .name-user {
      font-size: 20px;
    }
    .count-post {
      font-size: 15px;
    }
    img {
      width: 120px;
      height: 120px;
    }
    .rank {
      font-size: 45px;
      margin-top: -50px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
`;

export const TopContributor = (props) => {
  const { contributor } = props;
  return (
    <ContributorStyle className="d-flex align-items-center gap-3">
      <img src={contributor?.traveler?.thumbnail ? contributor?.traveler?.thumbnail : contributor?.traveler?.image !== "" ? contributor?.traveler?.image : defaultImageUser} className="rounded-circle" alt={contributor?.traveler?.imageCaption} width="80px" height="80px" />
      <div>
        <h3 className="name-user">
          <Link className="name-user link-focus" to={`/user-articles/${contributor.username}`}>
            {contributor.traveler?.name ?? "no name"}
          </Link>
        </h3>
        <p className="count-post">{contributor?.articleTotal} Posts</p>
      </div>
    </ContributorStyle>
  );
};
