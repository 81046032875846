import { useState, useEffect } from "react";
import * as articlesData from "../data/articles";
import styled from "styled-components";
import { NoData, LoadingScreen } from "../components"

const Title = styled.h4`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  padding-bottom: 0.5em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
`;

const Tag = styled.a`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    padding-bottom:0.5em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 16px;

  }

`;


export function RecommendedTopics(props) {
  const [tags, setTags] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const getTags = async () => {
    const queryParam = "?limit=6&random=true&onlyHaveActiveArticle=true";
    const res = await articlesData.getTags(queryParam);
    if (res.code !== 200) {
      setIsFetching(false)
    }
    setTags(() => res.data);
    setIsFetching(false)
  };
    getTags();
  }, []);
  
  return (
    <div className="col-12 mt-3">
      <Title>Recommended Topics</Title>
      <div className={`d-flex flex-wrap gap-3 ${isFetching || tags.length < 1 ? 'justify-content-center' : ''}`}>
        {!isFetching 
        ? tags.length
          ? tags.map((tag, index) => (
              <Tag href={`/article-list?byTag=${tag.name}`} key={tag.id} className="btn btn-outline-dark py-1 px-3 link-focus">
                {tag.name}
              </Tag>
            ))
          : <NoData />
        : <LoadingScreen />}
      </div>
    </div>
  );
}
