import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import data from "../data/data.json";
import * as articlesData from "../data/articles";

export function Metadata(props) {
  const { id, titlePage } = props;
  const urlPage = props.page.split('/');
  const page = urlPage[3].split('?');
  const metaData = data.websitePage.filter(data => data.page === page[0] )

  const title = props?.userProfile !== undefined 
                ? props?.userProfile?.username + " - Bipi Traveller Profile" 
                : props?.article?.title
                  ? props.article.title
                  : metaData[0]?.title

  const keyword = props?.userProfile !== undefined 
                  ? props?.userProfile?.traveler?.name 
                  : props?.article?.SEOKeyword
                    ? props.article.SEOKeyword
                    : metaData[0]?.keyword

  const description = props?.userProfile !== undefined 
                      ? props?.userProfile?.articles?.length + " Post " + props?.userProfile?.statistic?.totalLikes + " Likes - " + props?.userProfile?.traveler?.bio 
                      : props?.article?.SEODescription
                        ? props.article.SEODescription
                        : metaData[0]?.description

  const og_image = props?.userProfile !== undefined 
                    ? props?.userProfile?.traveler?.image 
                    : props?.article?.image
                      ? props.article.image
                      : 'https://drive.bipi.world/logo/BipiLogoBlack.png'

  const og_title = ''
  const og_description = props?.userProfile !== undefined 
                          ? props?.userProfile?.articles?.length + " Post " + props?.userProfile?.statistic?.totalLikes + " Likes - " + props?.userProfile?.traveler?.bio 
                          : metaData[0]?.description

  const twitter_card = 'summary_large_image'
  const twitter_title = props?.userProfile !== undefined 
                        ? props?.userProfile?.username + " - Bipi Traveller Profile" 
                        : metaData[0]?.title

  const twitter_description = props?.userProfile !== undefined 
                              ? props?.userProfile?.articles?.length + " Post " + props?.userProfile?.statistic?.totalLikes + " Likes - " + props?.userProfile?.traveler?.bio 
                              : metaData[0]?.description

  const twitter_image = props?.userProfile !== undefined 
                        ? props?.userProfile?.traveler?.image 
                        : props?.article
                          ? props?.article?.image
                          : 'https://drive.bipi.world/logo/BipiLogoBlack.png'

  const [metaDatas, setMetaDatas] = useState({
    title: 'Bipi World',
    keywords: '',
    og_image: '',
    og_description: '',
  });

  useEffect(() => {
    if (id) {
      const getArticles = async () => {
        const queryParam = "/" + id;
        const res = await articlesData.getArticles(queryParam);
  
        if (res.code !== 200) {
        }

        if (res.data) {
          setMetaDatas({
            title: res.data?.title,
            keywords: res.data?.SEOKeyword,
            og_image: res.data?.image,
            og_description: res.data?.SEODescription
          })
        }
      };
      getArticles();
    }

    if (titlePage && titlePage !== '') {
      setMetaDatas({
        title: titlePage
      })
    }

    if (metaData.length > 0) {
      setMetaDatas({
        title: metaData[0].title,
        og_description: metaData[0].description,
        og_image: 'https://drive.bipi.world/logo/BipiLogoBlack.png'
      })
    }
  }, [id, titlePage])

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{metaDatas.title}</title>
      <meta name="keywords" content={metaDatas.keywords} />
      <meta name="description" content={metaDatas.og_description} />
      <meta property="og:title" content={metaDatas.title} />
      <meta property="og:image" content={metaDatas.og_image} />
      <meta property="og:description" content={metaDatas.og_description} />
      <meta name="twitter:title" content={metaDatas.title} />
      <meta name="twitter:description" content={metaDatas.og_description} />
      <meta name="twitter:image" content={metaDatas.og_image} />
      <meta name="twitter:card" content={`summary_large_image`} />

      {props.isPublic 
      ? 
        <meta name="robots" content="index, follow" /> 
      : 
        <meta name="robots" content="noindex, nofollow" />
      }
    </Helmet>
  );
}
