import { formatDate } from '../../../utils/formatDate';
import './style.css'

export default function EventActivity(props) {
  const { title, event } = props;

  return(
    <div className='custom-event-activity-container'>
      <h5>{title}</h5>
      <span>{formatDate(event?.start, 'hh:mm a') + ' - ' + formatDate(event?.end, 'hh:mm a')}</span>
      <div className='category-event-activity'>
        <span>{event?.categoryEvent}</span>
      </div>
    </div>
  )
}