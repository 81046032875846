import { useEffect, useState } from 'react'

import { UploadForm } from "../../UploadForm"
import { imageToBase64 } from '../../../utils/uploadFile';

import { deleteFile, uploadFile } from '../../../data/itinerary';

import { LoadingIcon } from '../../';

export default function AttachmentForm(props) {
  const { itineraryId, attachmentList, setAttachmentList } = props

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);
  const [deleteProgress, setDeleteProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState()

  const handleUploadChange = async(e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleRemoveAttachment = async(file) => {
    if (file) {
      const payload = { name: file?.name, type: '', itineraryId }

      const response = await deleteFile(payload, (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setDeleteProgress(progress)
      })

      if (response?.data?.code !== 200) {
        return setDeleteProgress(0)
      }
      
      const newList = attachmentList.filter((item) => item.name !== file?.name);
      setAttachmentList(newList)
      setDeleteProgress(0)
    }
  }

  useEffect(() => {
    async function uploadImage() {

      if (selectedFile) {
        const base64Image = await imageToBase64(selectedFile)

        const payload = {
          file: selectedFile,
          type: selectedFile.type,
          name: selectedFile?.name,
          base64Image,
          itineraryId
        }

        try {
          setIsUploading(true)
          setIsUploading(false)
          setUploadProgress(0)
          setShowAlert(false)
          
          const response = await uploadFile(payload, (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
          });

          if (response?.data?.code !== 200) {
            setAlertMessage(response?.response?.data?.message)
            setIsUploading(false)
            setUploadProgress(0)
            setSelectedFile(null)
            setShowAlert(true)
            return
          }
          
          setAttachmentList(prevState => (
            [...prevState, {
              name: response?.data?.data?.name,
              url: response?.data?.data?.url
            }]
          ))
          
          
          setAlertMessage(null)
          setShowAlert(false)
          setIsUploading(false)
          setUploadProgress(0)
          setSelectedFile(null)
        } catch (error) {
          console.error('Upload failed', error);
        }

      }
    }

    uploadImage()
  }, [selectedFile, itineraryId])

  return(
    <div className='form-input-trip'>
      <div className='form-input-group d-none'>
        <label htmlFor="">Name</label>
        <input type="text" name='attachment_name' className='form-input' />
      </div>

      <div className='form-input-group'>
        <UploadForm selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleUploadChange={handleUploadChange} isUploading={isUploading} directUplad />
        {showAlert && <p style={{ color: 'red', fontWeight: 600 }}>{alertMessage ? alertMessage : 'Upload failed, try again...'}</p>}
        <div>
          {uploadProgress > 0 && 
            <div className='attachment-upload-modal--container'>
              <div className='attachment-upload-progress-bar' style={{ width: `${uploadProgress}%` }}>
                <span>{`${Math.round(uploadProgress)}%`}</span>
              </div>
            </div>
          }
          {attachmentList.length > 0 &&
            <>
              <h1 className='attachment-container--title'>Uploaded File</h1>
              <div className='attachment-item--container'>
                {attachmentList.map((file, _) => (
                  <div key={_} className='attachment-item'>
                    <span>{_ + 1}.</span>
                    <span className='name'>{file.name}</span>
                    <span><a href={file.url} target='_blank' rel='noreferrer' className='attachment-link'>Preview</a></span>
                    {deleteProgress > 0 
                      ? <LoadingIcon />
                      : <span onClick={() => handleRemoveAttachment(file)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className='icon'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                          </svg>
                        </span>
                    }
                  </div>
                ))}
              </div>
            </>
          }
        </div>
      </div>

      <div className='form-input-group d-none'>
        <label htmlFor="">Notes</label>
        <input type="text" name='attachment_note' className='form-input' />
      </div>
    </div>
  )
}