/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Button, Metadata } from "../components";
import styled from "styled-components";
const NameLabel = styled.label`
  font-size: 1.3em;
  padding-bottom: 0.7rem;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;
export function Help() {
  const [openForm, setOpenForm] = useState(false);
  const [description, setDescription] = useState();
  const [email, setEmail] = useState();

  return (
    <div className="articles d-flex flex-column" style={{ marginTop: "6.5rem", gap: "4.5rem" }}>
      <div className="container d-flex flex-column gap-5">
        <Metadata page={window.location.href} titlePage={`Help • Bipi World`} isPublic={false} />

        <h6 className="text-center" style={{ marginBottom: "-30px" }}>
          FAQ
        </h6>

        <h1 className=" text-center">Frequenty Asked Question</h1>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h3 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Accordion Item #1
                  </button>
                </h3>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="flush-headingTwo">
                  <button className="accordion-button collapsed border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Accordion Item #2
                  </button>
                </h3>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="flush-headingThree">
                  <button className="accordion-button collapsed border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Accordion Item #3
                  </button>
                </h3>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but
                    just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Term of Service</h5>
                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Privacy</h5>
                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center pt-5 d-none">
              <h2>Still have Questions?</h2>
              <button type="button" onClick={() => setOpenForm(!openForm)} className="rounded mb-2 btn btn-outline-dark btn-md mt-3">
                Submit a request
              </button>
              {openForm === true && (
                <div className="d-flex flex-column border p-3 mt-3">
                  <div className="">
                    <input type="text" className="form-control " onChange={(e) => setEmail(e.target.value)} name="slug" id="slug" placeholder="Add Email ..." required />
                  </div>
                  <div className="mb-3  mt-4">
                    <textarea type="text" className="form-control" onChange={(e) => setDescription(e.target.value)} name="content" id="content" placeholder="Add details of your request ..." rows="3" required></textarea>
                  </div>
                  <div style={{ width: "100px" }}>
                    <Button text="Submit" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
