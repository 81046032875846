import styled from "styled-components";
import { Link } from "react-router-dom";
import { Label } from "./label/Label";
import blankImg from "../assets/images/404.svg";

const ArticleDescTop = styled.div`
  width: 250px;

  .card-body {
    padding: 0;
  }

  .card-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    text-decoration: none;
  }
  .inner {
    position: relative;
    width: 100%;
    overflow: hidden;

    > a > img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .inner {
      margin-top: -8px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    width: 270px;

    .inner {
      width: 100%;
      margin-top: -3px;
    }
    line-height: 5px;

    .card-title {
      font-size: 16px;
      padding-top: 5px;
    }
    .card-body {
      padding-top: 10px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 230px;

    .inner {
      width: 100%;
    }

    // .card-title {
    //   font-size: 18px;
    //   padding-top: 5px;
    // }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 270px;

    .inner {
      width: 100%;
    }
  }
`;

export function CardArticleDescTop(props) {
  const { article } = props;

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  return (
    <ArticleDescTop className="card mb-lg-5">
      <div className="card-body ps-2">
        <Label circle={"circle-blue"} label={"label-link-blue"} text={article.category.name} image={article.category.image} icon={article.category.icon} blankImg={blankImg}/>
        <h3 className="card-title">
          <a className="card-title link-focus" href={`/articles/${article.slug}`}>
            {article.title.length >= 60 ? article.title.slice(0, 60).concat("...") : article.title}
          </a>
        </h3>
      </div>
      <div className="inner">
        <a href={`/articles/${article.slug}`}>
          <img src={isImage(article.thumbnail) ? article.thumbnail : article.image ? article.image : blankImg} className="card-img-top" alt={props.title} />
        </a>
      </div>
    </ArticleDescTop>
  );
}
