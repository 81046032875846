import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from "../../../utils/capitalizeString";

import './styles/calendar-month-big.css'

import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  getDay,
  isSameMonth,
  isToday,
  parse,
  startOfWeek,
} from "date-fns";
import { formatDate } from '../../../utils/formatDate';

export default function CalendarMonthBig(props) {
  const navigate = useNavigate()
  const { setCalendarView, activeMonth, setActiveDate, eventEachDay, itineraryData } = props;
  const [currentMonth, setCurrentMonth] = useState(() => formatDate(activeMonth, "MMM-yyyy"));
  const [eventsEachDay, setEventsEachDay] = useState([]);

  const handleClickDay = (day) => {
    if (setActiveDate && setCalendarView) {
      setActiveDate(day)
      setCalendarView('day')
    }
  }

  const handleClickEvent = (e, event) => {
    e.stopPropagation()
    console.log(event)
    if (event && event !== '') {
      navigate(`/itinerary/${event}`)
    }
  }

  const renderHeaderCalendar = () => {
    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    return(
      <div className="calendar-big calendar-big--header">
        {days.map((day, idx) => {
            return (
            <div key={idx} className="calendar-big--header-title">
                {capitalizeFirstLetter(day)}
            </div>
            );
        })}
      </div>
    )
  }

  const renderDays = () => {
    const firstDayOfMonth = parse(currentMonth, "MMM-yyyy", new Date());
    const daysInMonth = eachDayOfInterval({ start: startOfWeek(firstDayOfMonth), end: endOfWeek(endOfMonth(firstDayOfMonth)) });
    const colStartClasses = ["", "2", "3", "4", "5", "6", "7"];

    return (
      <div className='calendar-big calendar-big--days'>
        {daysInMonth.map((day, idx) => {
          const eachDate = formatDate(day, 'yyyy-MM-dd')
          const eventsEachDays = itineraryData.reduce((acc, itinerary) => {
            
            const tripsOnDate = (itinerary.itineraryTrip || []).filter((trip) => formatDate(trip.startDate, 'yyyy-MM-dd') === eachDate);

            tripsOnDate.forEach((trip) => {
              trip.itineraryId = itinerary.id;
            });

            return acc.concat(tripsOnDate);
          }, []);

          return (
            <div key={idx} className='calendar-big--each-day' style={{ gridColumnStart: colStartClasses[getDay(day)]}} onClick={() => handleClickDay(day)}>
              <div
                className={`calendar-big--day`}
                style={{ color: `${isSameMonth(day, activeMonth) ? "#111827" : "#9CA3AF"}`}}
              >
                <div className='day-container' style={{ backgroundColor: `${isToday(day) ? "#EF4444" : "white"}`, color: `${isToday(day) ? "white" : "black"}`}}>
                  {eventsEachDays.length > 0 && <span className='event-sign' style={{ backgroundColor: eventsEachDays[0].color ?? 'black' }}>{eventsEachDays.length}</span>} 

                  <span className='day'>{formatDate(day, "d")}</span>

                  {eventsEachDays.slice(0, 1).map((event, index) => 
                    <div key={index} className='trip-on-day' onClick={(e) => handleClickEvent(e, event.itineraryId)} style={{ backgroundColor: event.color, color: event.color ? 'white' : 'black' }}>
                      <h5 className='event-each-day-title'>{event.title}</h5>
                    </div>)}

                  {eventsEachDays.length > 1 && 
                    <div className='trip-on-day' onClick={(e) => handleClickEvent(e, eventEachDay[0].itineraryId)} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
                      <h5 className='event-each-day-title'>More..</h5>
                    </div>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  }

  useEffect(() => {
    setCurrentMonth(formatDate(activeMonth, "MMM-yyyy"))
  }, [activeMonth])

  return (
    <div className='calendar-month-big-container'>
      {renderHeaderCalendar()}
      {renderDays()}
    </div>
  )
}