import { useState, useEffect } from "react";
import styled from "styled-components";

const Button = styled.button`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  text-decoration: "none";
  border: 1px solid black;
  background-color: white;
  font-size: 12px;

  &.black {
    background-color: black;
    color: white;
  }

  &:hover {
    color: white;
    background-color: black;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const FormStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  textarea {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    textarea {
    font-size: 14px;
  }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    textarea {
    font-size: 16px;
  }  }
`;



export function CommentForm({ handleSubmit, submitLabel, initialText = "", handleCancel }) {
  const [text, setText] = useState(initialText);
  const isTextDisabled = text.length === 0;
   // check is user logged in from local storage
  const user = JSON.parse(localStorage.getItem("tokens"));

  const onSubmit = (e) => {
    e.preventDefault();
    if (!user) {
      return alert("Please login first");
    }
    handleSubmit(text);
    setText("");
  };
  return (
    <div className="comment">
      <form onSubmit={onSubmit}>
        <FormStyle className="d-flex mb-2 row px-2">
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            name="comment"
            placeholder="Add a comment..."
            className="rounded py-2 form-control"
          ></textarea>
        </FormStyle>
        <div className="d-flex justify-content-end align-self-end mb-4 " style={{ marginRight:"-2px" }}>
          <Button className="btn white shadow px-2 px-md-3 py-1" disabled={isTextDisabled}>
            {submitLabel}
          </Button>
          {submitLabel !== "Write" && (
            <Button className="btn white ms-1 px-2 py-1 px-md-3 px-2 py-1 shadow" onClick={handleCancel}>
              Cancel
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}
