/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Metadata, Contributor, RecommendedArticles, RecommendedTopics, NoData, ImageComponent, LoadingScreen, CardItineraryHorizontal } from "../components";
import "./style.css";
import blankImg from "../assets/images/404.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import defaultImageUser from "../assets/images/defaultUser.svg";
import styled from "styled-components";
import * as articlesData from "../data/articles";
import * as ItinerariesModule from "../data/itinerary"

import { Accordion } from 'react-bootstrap'

const Container = styled.div`
  margin-top: 2.5rem;
  gap: 3.5rem;
  margin-bottom: 6.5rem;

  margin-left: 0;
  margin-right: 0;

  
  @media only screen and (min-width: 500px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 6.5rem;
  }
`

const ContributorStyle = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;
  }

  .card-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    margin-bottom: 2px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .image {
    width: 65px;
    min-width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }
  .image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .time {
      font-size: 14px;
    }
    .card-title {
      font-size: 14px;
    }
    .reading-time {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .time {
      font-size: 14px;
    }
    .card-title {
      font-size: 16px;
    }
    .reading-time {
      font-size: 12px;
    }
  }
`;

const Profile = styled.div`
  border-radius: 15px;
  transition: 2s;
  margin: 0.5em 0 1.5em;
  box-shadow: rgba(0, 0, 0, 0.1) -1px 4px 15px, rgba(0, 0, 0, 0.05) 0px -2px 5px;

  .image {
    width: 120px;
    height: 120px;
    min-width: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .username {
    font-weight: 500;
    font-size: 16px;
  }
  .name {
    font-size: 24px;
    line-height: 21px;
    margin-bottom: 6px;
  }
  .text-occupation {
    font-weight: 600;
    font-size: 14px;
  }
  .text-bio {
    font-size: 14px;
  }
  a {
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin-top: 10px;
  }
  .bio {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .engagement > div {
    margin: 0 2em;
  }
  .number {
    font-size: 28px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;

    .image {
      width: 90px;
      height: 90px;
      min-width: 90px;
      margin-bottom: 15px;
      margin: 1em;
    }
    .profile {
      margin: 0 1em;
    }

    .image > img {
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    padding: 1.5em;

    .image {
      width: 100px;
      height: 100px;
      min-width: 100px;
    }
    .username {
      font-size: 16px;
    }
    .name {
      font-size: 18px;
    }
    .number {
      font-size: 22px;
    }
    .text-occupation {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
    .engagement > div {
      margin: 0 0.8em;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .image {
      width: 140px;
      height: 140px;
      min-width: 140px;
      margin-right: 20px;
    }
    .username {
      font-size: 18px;
      margin-top: 6px;
    }
    .name {
      font-size: 28px;
      margin-bottom: 0.5em;
    }
    .number {
      font-size: 34px;
    }
    .text-occupation {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
    .engagement > div {
      margin-right: 2em;
    }
    .engagement > p {
      font-size: 28px;
    }
  }
`;

const Card = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  display: flex;
  margin-bottom: 10px;

  .card-text {
    width: 72%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .time {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 0;
    margin-top: -5px;
  }
  .card-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 2px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .reading-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.6);
  }
  .image-article {
    width: 28%;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 10px;
  }
  .image-article > img {
    aspect-ratio: 1 / 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }


  @media only screen and (min-width: 440px) {
    .card-text {
      width: 80%;
    }
    .image-article {
      width: 20%;
    }
  }

  
  @media only screen and (min-width: 466px) {
    .card-text {
      width: 85%;
    }
    .image-article {
      width: 15%;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .card-text {
      width: 75%;
    }
    .image-article {
      width: 25%;
    }
    .reading-time {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .card-text {
      width: 62%;
    }
    .image-article {
      width: 38%;
    }
    .time {
      font-size: 10px;
    }
    .card-title {
      font-size: 12px;
    }
    .reading-time {
      font-size: 12px;
    }
  }

  
  @media only screen and (min-width: 1200px) {
    .card-text {
      width: 75%;
    }
    .image-article {
      width: 25%;
    }
    .card-title {
      font-size: 14px;
    }
    .time {
      font-size: 14px;
    }
  }

  
  @media only screen and (min-width: 1480px) {
    .card-text {
      width: 75%;
    }
    .image-article {
      width: 25%;
    }
  }
`;

export function Contributors() {
  const [contributors, setContributors] = useState([]);
  const [contributor, setContributor] = useState();
  const [itineraries, setItineraries] = useState([])
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingItineraries, setIsFetchingItineraries] = useState(true);

  useEffect(() => {
    const getContributors = async () => {
      const queryParam = "";
      const res = await articlesData.getContributors(queryParam);

      if (res.code !== 200) {
        setIsFetching(false)
      }

      const filteredContributors = res.data.filter(contributor => contributor.articles.length > 0);

      setContributors(filteredContributors);
      
      if (filteredContributors.length > 0) {
        setContributor(filteredContributors[0]);
      }
      setIsFetching(false)
    };

    const getItineraries = async () => {
      const query = '?limit=5'
      const result = await ItinerariesModule.getItineraries(query, true)

      if (result.code !== 200) return setIsFetchingItineraries(false)

      setItineraries(result.data)
      setIsFetchingItineraries(false)
    }

    getContributors();
    getItineraries()
  }, []);

  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }
  function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K" : Math.sign(num) * Math.abs(num);
  }
  const [readMore, setReadMore] = useState(false);

  return (
    <Container className="articles d-flex flex-column container">
      <Metadata page={window.location.href} isPublic={true} />

      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-7">
          <h1 className="section-title">Buddies</h1>

          <h4 className="pt-2">Popular Contributors</h4>
          <div className="flex flex-column pt-2">
            <div className='d-flex scroll gap-4'>
              {!isFetching 
              ? contributors.length
                ? contributors.map((contributor, index) => (
                    <ContributorStyle key={index} onClick={() => setContributor(contributor)}>
                      <div className="image mt-1">
                        <ImageComponent src={contributor?.traveler?.thumbnail ? contributor?.traveler?.thumbnail : contributor?.traveler?.image ? contributor?.traveler?.image : defaultImageUser} alt={contributor.imageCaption} />
                      </div>
                      <div className="card-text mt-1 d-none">
                        <p className="card-title">{contributor.traveler.name}</p>
                      </div>
                    </ContributorStyle>
                  ))
                : <NoData />
              : 
              <div className="w-100 d-flex justify-content-center p-3">
                <LoadingScreen />
              </div>}
            </div>

            <Profile>
              {!isFetching 
                ?
                <>
                  <div className="d-flex align-items-center d-lg-none p-2">
                    <div className="image">
                      <img src={contributor?.traveler?.thumbnail ? contributor?.traveler?.thumbnail : contributor?.traveler?.image ? contributor?.traveler?.image : defaultImageUser} width="150px" height="150px" alt={contributor?.traveler?.name}/>
                    </div>
                    <div className="d-flex engagement">
                      <div className="text-center ">
                        <div className="number pb-1">{contributor?.articleTotal > 0 ? kFormatter(contributor?.articleTotal) : 0}</div>
                        <p>Post</p>
                      </div>
                      <div>
                        <div className="number text-center pb-1">{contributor?.articleLikes > 0 ? kFormatter(contributor?.articleLikes) : 0}</div>
                        <p>Likes</p>
                      </div>
                    </div>
                  </div>

                  <div className="d-lg-flex pt-md-4">
                    <div className="profile col-lg-5 pe-3 mb-3 mb-lg-0">
                      <div className="d-none d-lg-block">
                        <div className="image">
                          <img src={contributor?.traveler?.thumbnail ? contributor?.traveler?.thumbnail : contributor?.traveler?.image ? contributor?.traveler?.image : defaultImageUser} width="150px" height="150px" alt={contributor?.traveler?.name} />
                        </div>
                        <div className="d-flex engagement ">
                          <div className="text-center ">
                            <div className="number pb-1">{contributor?.articleTotal > 0 ? kFormatter(contributor?.articleTotal) : 0}</div>
                            <p>Post</p>
                          </div>
                          <div>
                            <div className="number text-center pb-1">{contributor?.articleLikes > 0 ? kFormatter(contributor?.articleLikes) : 0}</div>
                            <p>Likes</p>
                          </div>
                        </div>
                      </div>
                      <Link to={`/user-articles/${contributor?.username}`} className="link-focus">
                        <h2 className="name text-decoration-none ps-2 ps-md-0">{contributor?.traveler?.name}</h2>
                      </Link>

                      {/* <span className="pb-1 text-occupation">{contributor?.traveler?.occupation === null ? "traveler" : contributor?.traveler?.occupation} </span> */}
                      <div>
                        <h4 className="text-bio ps-2 ps-md-0">{contributor?.traveler?.bio}</h4>
                      </div>
                    </div>

                    <div className="bd-highlight p-3" style={{ backgroundColor: "#eee", borderRadius: "15px", width: "100%" }}>
                      <h5>Popular Article</h5>
                      {contributor?.articles.length
                        ? contributor?.articles.map((article, index) => (
                            <Card key={index}>
                              <div className="image-article">
                                <ImageComponent src={isImage(article.thumbnail) ? article.thumbnail : article.image ? article.image : blankImg} alt={article.imageCaption} />
                              </div>
                              <div className="card-text">
                                <p className="card-title">
                                  <Link to={`/articles/${article.slug}`} className="card-title link-focus">
                                    {article.title}
                                  </Link>
                                </p>
                                <p className="time">{moment(article.updatedAt).format("MMM D, YYYY")}</p>
                              </div>
                            </Card>
                          ))
                        : <NoData IsBgGray={true} />}
                    </div>
                  </div>
                </>
                :
                <div className="d-flex justify-content-center">
                  <LoadingScreen />
                </div>
              }
              
            </Profile>
          </div>

          <h4 className="pt-2">Suggested Contributors</h4>
          <div className="flex flex-column pt-2">
            <Accordion>
              {!isFetching 
              ? contributors.length 
                ? contributors.map((contributor, index) => <Contributor key={index} index={index} contributor={contributor} article={contributor.articles} />) 
                : <NoData />
              : 
                <div className="d-flex justify-content-center">
                  <LoadingScreen />
                </div>
              }
            </Accordion>

          </div>
          
          <div className="mt-3">
            <h4 className="pt-2">Itinerary</h4>
            <div className="itinerary-list--container">
              {!isFetchingItineraries 
                ? itineraries.length > 0 
                  ? itineraries.map(data => (
                    <CardItineraryHorizontal key={data.id} itinerary={data} />
                  ))
                  : <NoData />
                : <div className="w-100 d-flex justify-content-center p-3">
                    <LoadingScreen />
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-0 ">
          <RecommendedTopics />
          <RecommendedArticles />
        </div>
      </div>
    </Container>
  );
}
