import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function getArticles(query) {
  try {
    const articles = await axios.get(`articles` + query).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
    //  // console.log(error.response.data);
    return error.response.data;
  }
}

export async function readArticle(id) {
  try {
    setHeaderToken()
    const article = await axios.post(`articles/${id}/read`).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function storeArticle(data) {
  try {
    setHeaderToken()
    const article = await axios.post(`articles`, data).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function updateArticle(id, data) {
  try {
    setHeaderToken()
    const article = await axios.patch(`articles/${id}`, data).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function deleteArticle(id) {
  try {
    setHeaderToken()
    const article = await axios.delete(`articles/${id}`).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function restoreArticle(id) {
  try {
    setHeaderToken()
    const article = await axios.patch(`articles/restore/${id}`).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
    return error.response.data;
  }
}

export async function checkSlug(slug) {
  try {
    setHeaderToken()
    const article = await axios.get(`articles/checkSlug?slug=${slug}`).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getCategories(query) {
  try {
    const articles = await axios.get(`categories` + query).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getCities(query) {
  try {
    const articles = await axios.get(`cities` + query).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getTags(query) {
  try {
    const articles = await axios.get(`tags` + query).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getCountries(query) {
  try {
    const articles = await axios.get(`countries` + query).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}
export async function getProvinces(query) {
  try {
    const articles = await axios.get(`provinces` + query).then((response) => {
      return response.data;
    });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getContributors(query) {
  try {
    const articles = await axios
      .get(
        `users?limit=6&topContributor=true
    ` + query
      )
      .then((response) => {
        return response.data;
      });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function bookmarkArticle(id) {
  try {
    setHeaderToken()
    const article = await axios.post(`articles/${id}/bookmark`).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function likeArticle(id) {
  try {
    setHeaderToken()
    const article = await axios.post(`articles/${id}/like`).then((response) => {
      return response.data;
    });
    return article;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getArticlesUser(query) {
  try {
    const articles = await axios
      .get(
        `users` + query
      )
      .then((response) => {
        return response.data;
      });
    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getLikedArticles(query) {
  try {
    setHeaderToken()
    const articles = await axios.get(`liked/articles${query}`)
      .then((response) => {
        return response.data;
      });

    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function getbookmarkedArticles(query) {
  try {
    setHeaderToken()
    const articles = await axios.get(`bookmarked/articles${query}`)
      .then((response) => {
        return response.data;
      });

    return articles;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}
