
import ReactPaginate from "react-paginate";
import './style.css';

import { FaAngleDoubleLeft, FaAngleDoubleRight  } from "react-icons/fa";

export function Paginate(props) {
  const { changePage, pageCount,  } = props;

  return(
    <ReactPaginate
      breakLabel="..."
      previousLabel={
        <FaAngleDoubleLeft style={{width: 10, height: 10}}/>
      }
      nextLabel={
        <FaAngleDoubleRight style={{width: 10, height: 10}}/>
      }
      onPageChange={changePage}
      pageRangeDisplayed={1}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      containerClassName={'pagination-custom'}
      activeClassName={`active`}
    />
  )
}