import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './style.css'
import { checkIsImage } from '../../utils/image-check';
import blankImg from "./../../assets/images/404.svg";

export function CardArticleImage(props) {
  const { data, width = 'auto', noRounded = false, rounded = '12px', height = '300px', noContent = false } = props;

  const [background, setBackground] = useState({
    url: '',
    repeat: '',
    size: '',
    position: ''
  })

  useEffect(() => {
    if (data) {
      const image = data.thumbnail && checkIsImage(data.thumbnail) ? data.thumbnail : data.image && checkIsImage(data.image) ? data.image : blankImg
      setBackground({
        url: image,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center'
      })
    }
  }, [data])

  return (
    <Link to={`/articles/${data?.slug ?? ''}`}>
      <div className='card-article-image--container' style={{ background: `url(${background.url})`, backgroundRepeat: `${background.repeat}`, backgroundSize: `${background.size}`, backgroundPosition: `${background.position}`, width, height, borderRadius: noRounded ? '0px' : rounded }}>
        {!noContent && 
          <div className='card-article-image-content'>
            <h1 className='card-article-image--title text-one-line'>{data?.title ?? ''}</h1>
            <div className='card-article-image--content-bottom'>
              <span className='text-one-line'>{data?.city?.name ?? ''} - {data?.country?.name ?? ''}</span>
              <span className='text-one-line'>{data?.views ?? 0} views</span>
            </div>
          </div>
        }
      </div>
    </Link>
  )
}