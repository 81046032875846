import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import blankImg from "../assets/images/404.svg";
import AuthContext from "./../components/shared/AuthContext";
import * as articlesData from "../data/articles";

import { NoData, LoadingScreen } from "./";

const Title = styled.h4`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  padding-bottom: 0.5em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
`;

const Card = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;
  display: flex;
  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .username {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 0;
  }
  .card-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 2px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .reading-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.6);
  }
  .image {
    width: 70px;
    min-width: 70px;
    height: 60.3px;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 10px;
  }
  .image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    .username {
      font-size: 14px;
    }
    .card-title {
      font-size: 14px;
    }
    .reading-time {
      font-size: 12px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .username {
      font-size: 14px;
    }
    .card-title {
      font-size: 16px;
    }
    .reading-time {
      font-size: 12px;
    }
  }
`;

const SeeAll = styled.a`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

  font-family: "DM Sans", sans-serif;

  font-size: 14px;
  font-weight: 400;
  text-decoration: none;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

export function RecentlySaved(props) {
  const { user } = useContext(AuthContext);
  const [articles, setArticles] = useState([]);
  const [isFetching, setIsFetching] = useState(true)
  
  const token = JSON.parse(localStorage.getItem("tokens"))?.data.access_token;


  function isImage(url) {
    return /(.jpg|.jpeg|.png|.webp|.avif|.gif|.svg|_)$/.test(url);
  }

  useEffect(() => {
    async function fetchData() {
      if (!token) return;
      const response = await articlesData.getbookmarkedArticles('?limit=100');
      
      if (response.code && response.code !== 200) {
        setIsFetching(false);
        return;
      }

      setArticles(response?.data)
      setIsFetching(false);
    }
    fetchData();
  }, [token]);

  return (
    <>
      {user && (
        <div className="col-12 mt-5">
          <Title>Recently Saved</Title>
          <div className="d-flex flex-column gap-3">
            {!isFetching 
            ? articles?.length
              ? articles?.map((article, index) => (
                  <Card key={index}>
                    <div className="image mt-1 d-md-none d-lg-block">
                      <img src={!isImage(article.image) ? blankImg : article.image} alt={article.imageCaption} width="70px" height="60px" />
                    </div>
                    <div className="card-text">
                      <p className="username">
                        {article.user.traveler?.name} - <span className="reading-time">{article.readingTime.text}</span>
                      </p>
                      <p className="card-title">
                        <Link to={`/articles/${article.slug}`} className="card-title link-focus">
                          {article.title}
                        </Link>
                      </p>
                    </div>
                  </Card>
                ))
              : <NoData />
            : <div className="d-flex justify-content-center"><LoadingScreen /></div> }

            {articles?.length > 0 && <SeeAll href="/favorite/saved">See All</SeeAll>}
          </div>
        </div>
      )}
    </>
  );
}
