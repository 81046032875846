export const formatCurrency = (value) => {
    // Menghapus semua karakter selain digit
    const cleanedValue = value.replace(/[^\d]/g, '');
  
    // Menghindari masalah jika nilai yang dimasukkan kosong atau bukan angka
    if (!cleanedValue || isNaN(cleanedValue)) {
        return '';
    }

    // Membalikkan string agar kita bisa memasukkan titik setiap tiga digit dari belakang
    const reversedValue = cleanedValue.split('').reverse().join('');
    
    // Memasukkan titik setiap tiga digit
    const formattedValue = reversedValue.match(/.{1,3}/g).join('.').split('').reverse().join('');
    
    return formattedValue;
};

export const handleKeyOnlyNumber = (e) => {
  // Mendapatkan kode karakter yang ditekan
  const keyCode = e.keyCode || e.which;

  // Mencegah input jika karakter yang ditekan bukan angka
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
    e.preventDefault();
  }
};