import { useState, useEffect } from "react";
import axios from "axios";

import { UserNotification, LinkCategory, RecommendedArticles, RecommendedBuddies, RecommendedTopics, Button, Metadata, LoadingScreen, NoData } from "../components";
import "./style.css";
import { BASE_URL } from "../config/config";

import Swal from 'sweetalert2';

export function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [isFetching, setIsFetching] = useState(true)
  const [activeCategory, setActiveCategory] = useState("Show All");

  const token = JSON.parse(localStorage.getItem("tokens")).data.access_token;

  useEffect(() => {
    async function fetchData() {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(`${BASE_URL}/notifications?limit=100`);

      let filteredResponse;

      if (activeCategory === 'Show All'){
        filteredResponse = response?.data?.data;
      } else if (activeCategory === 'Comments') {
        filteredResponse = response?.data?.data.filter((notification) => notification.type === 2)
      } else if (activeCategory === 'Like') {
        filteredResponse = response?.data.data?.filter((notification) => notification.type === 1);
      }

      setNotifications(filteredResponse);
      setIsFetching(false);
    }
    fetchData();
  }, [token, activeCategory]);

  const readNotif = async (idNotif, idArticle, isComment = false, commentId = '') => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .patch(`${BASE_URL}/notifications/${idNotif}`)
      .then(() => {
        if (isComment) {
          window.location.href = commentId !== '' ? "/articles/" + idArticle + "?comment=" + commentId : "/articles/" + idArticle
        } else {
          window.location.href = "/articles/" + idArticle;
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Something went wrong..",
          text: 'Try again later',
          confirmButtonText: 'Okay'
        })
      });
  };

  const deleteNotif = (idNotif) => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure that you want to remove notification?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then( async(result) => {
      if(result.dismiss) return;

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.delete(`${BASE_URL}/notifications/${idNotif}`, {})
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          Swal.fire({
            title: "Opss...",
            text: "Something happened! " + error,
            icon: "error",
          })
        });
    })
  };

  const deleteAllNotif = () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure that you want to remove all notifications?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then( async(result) => {
      if(result.dismiss) return;

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .delete(`${BASE_URL}/notifications/action/removeAllReaded`, {})
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          Swal.fire({
            title: "Opss...",
            text: "Something happened! " + error,
            icon: "error",
          })
        });
    })
  };

  const readAll = async () => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure that you want to read all notifications?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then( async(result) => {
      if(result.dismiss) return;

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .patch(`${BASE_URL}/notifications/action/readAll`, {})
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          Swal.fire({
            title: "Opss...",
            text: "Something happened! " + error,
            icon: "error",
          })
        });
    })
  };
  const [editNotif, setEditNotif] = useState(false);

  return (
    <div className="articles d-flex flex-column" style={{ marginTop: "6.5rem", gap: "4.5rem", marginBottom: '5rem' }}>
      <div className="container d-flex flex-column gap-3">
        <Metadata page={window.location.href} titlePage={`Notifications • Bipi World`} />

        <h1 className="section-title">Notification</h1>
        <h2 className="d-none">Notification</h2>
        <div className="d-flex justify-content-between align-items-center gap-2 " style={{ borderBottom: "1px solid grey", paddingBottom: "0px" }}>
          <div className="d-flex scroll  gap-4">
            {["Show All", "Comments", "Like"].map((item, index) => (
              <LinkCategory item={item} key={index} activeCategory={activeCategory} setActiveCategory={setActiveCategory} onClick={() => setActiveCategory(item)} />
            ))}
          </div>
          <div className="d-flex">
            {editNotif === true ?
            <>
              <Button onClick={() => readAll()} text="Read All" />
              <Button onClick={() => deleteAllNotif()} text="Delete All Readed" />
            </>
            :
            ""
            }
            {activeCategory === 'Show All' && <Button onClick={() => setEditNotif(!editNotif)} text={`${!editNotif ? "Edit" : "Cancel"}`} /> }
            
          </div>
        </div>

        <div className="row g-4 pt-0 justify-content-between">
          <div className="col-sm-12 col-md-7">
            <div className="d-flex flex-column gap-2">
              {!isFetching 
                ? (
                  <>
                    {activeCategory === "Show All" 
                      ? notifications.length
                        ? notifications.map((notification, index) => (
                            <UserNotification
                              key={index}
                              deleteNotif={deleteNotif}
                              readNotif={readNotif}
                              id={notification.id}
                              idArticle={notification?.article?.slug || notification.relationId}
                              commentId={notification?.commentId}
                              author={notification.userFrom.username}
                              activity={notification.subject}
                              content={notification.description}
                              time={notification.createdAt}
                              read={notification.isRead}
                              imageUser={notification?.userFrom?.traveler?.image}
                              imageArticle={notification?.article?.image}
                              userFrom={notification?.userFrom.traveler.name}
                              type={0}
                            />
                          ))
                        : <NoData /> 
                      : activeCategory === "Comments" 
                        ? notifications.length
                          ? notifications.map((notification, index) => (
                                <UserNotification
                                  key={index}
                                  deleteNotif={deleteNotif}
                                  readNotif={readNotif}
                                  id={notification.id}
                                  idArticle={notification?.article?.slug || notification.relationId}
                                  commentId={notification?.commentId}
                                  author={notification.userFrom.username}
                                  activity={notification.subject}
                                  content={notification.description}
                                  time={notification.createdAt}
                                  read={notification.isRead}
                                  imageUser={notification?.userFrom?.traveler?.image}
                                  imageArticle={notification?.article?.image}
                                  userFrom={notification?.userFrom.traveler.name}
                                  type={2}
                                />
                              ))
                        : <NoData />
                      : activeCategory === "Like" 
                      ? notifications.length
                        ? notifications.map((notification, index) => (
                              <UserNotification
                                key={index}
                                deleteNotif={deleteNotif}
                                readNotif={readNotif}
                                id={notification.id}
                                author={notification.userFrom.username}
                                activity={notification.subject}
                                content={notification.description}
                                time={notification.createdAt}
                                read={notification.isRead}
                                imageUser={notification?.userFrom?.traveler?.image}
                                imageArticle={notification?.article?.image}
                                userFrom={notification?.userFrom.traveler.name}
                                type={1}
                              />
                            ))
                        : <NoData />
                      : ""
                    }
                  </>
                ) 
                : <div className="d-flex justify-content-center p-4"><LoadingScreen /></div>
              }
            </div>
          </div>
          <div className="col-md-4 mt-3 g-5 d-flex flex-column">
            <RecommendedTopics />
            <RecommendedArticles />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
