import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function likeComment(id) {
  try {
    setHeaderToken()
    const comment = await axios.post(`article/comments/${id}/like`).then((response) => {
      return response.data;
    });
    return comment;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function postComment(articleId, data) {
  try {
    setHeaderToken()
    const comment = await axios.post(`articles/${articleId}/comments`, data).then((response) => {
      return response.data;
    });
    return comment;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function updateComment(id, data) {
  try {
    setHeaderToken()
    const comment = await axios.patch(`article/comments/${id}`, data).then((response) => {
      return response.data;
    });
    return comment;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function deleteComment(id) {
  try {
    setHeaderToken()
    const comment = await axios.delete(`article/comments/${id}`).then((response) => {
      return response.data;
    });
    return comment;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}
