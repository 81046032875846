import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AuthContext from "./../components/shared/AuthContext";
import * as articlesData from "../data/articles";

const ButtonStyled = styled.div`
  font-size:18px;
  outline:none;
  border:none;

  @media only screen and (min-width: 768px) {
    font-size:20px;
  }

  @media only screen and (min-width: 992px) {
    font-size:24px;
  }
`;


export function Like(props) {
  const [articlesFavorite, setArticlesFavorite] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {

      const response = await articlesData.getLikedArticles('?limit=100').then((response) => setArticlesFavorite(response?.data));
      // return response;
    }
    if(user) {
      fetchData();
    }
  }, [user]);

  const [changeColor, setChangeColor] = useState("bg-dark");
  const [click, setClick] = useState(false);
  const [bg, setBg] = useState("bg-red");
  const idArticle = props.id;
  let bgColor;
  const likePost = async () => {
    await articlesData.likeArticle(idArticle)
      .then((like) => {
        props.setLike(like.data.articleLikes)
        setClick(true)
        if (changeColor === "bg-red") {
          setChangeColor("bg-dark");
          setBg("bg-dark");
          setClick(false)
        }

        if (changeColor === "bg-dark") {
          setChangeColor("bg-red");
          setBg("bg-red");     
        }

        if (bgColor === "bg-red") {
          setChangeColor("bg-dark");
          setBg("bg-dark");
          setClick(false)
        }

        if (bgColor === "bg-dark") {
          setChangeColor("bg-red");
          setBg("bg-red");     
        }
      })
      .catch((error) => {
        // alert(error.response?.data);
      });
  };

  useEffect(() => {
    const article = articlesFavorite.find((article) => article.id === idArticle);
    if (article) {
      setChangeColor(bg);
    } else {
      setChangeColor("bg-dark")
    }
  }, [idArticle, articlesFavorite, bg]);

  return (
    <>
      {user ? (
        <ButtonStyled id="btn-favorite " onClick={likePost} className="btn btn-favorite p-0">
          <i className={`fa-heart text-decoration-none ${bgColor === "bg-red" || changeColor === "bg-red" ? `fa-solid ${changeColor}` : "fa-regular"}
          ${(click === true && changeColor === "bg-red") || (click === true && changeColor === "bg-dark") ? changeColor : ""}`}></i>
        </ButtonStyled>
      ) : (
        <ButtonStyled href="#authModal" data-bs-toggle="modal" className="btn btn-favorite p-0">
          <i className={`${bgColor === "bg-red" || changeColor === "bg-red" ? "fa-solid" : "fa-regular"} fa-heart fa-sm ${bgColor} ${changeColor}`}></i>
        </ButtonStyled>
      )}
    </>
    
  );
}
