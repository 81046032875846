import { useEffect, useState } from 'react';
import {
  format,
  addDays,
  differenceInDays 
} from "date-fns";

import './style.css'

export default function CalendarWeek(props) {
  const { activeDate, setActiveDate } = props;
  
  const numberOfDays = 7;
  const [dateList, setDateList] = useState(Array.from({ length: numberOfDays }, (_, index) => addDays(activeDate, index - Math.floor(numberOfDays / 2))));

  const handleDateClick = (clickedDate) => {
    const dayDiff = differenceInDays(clickedDate, activeDate);
    const newActiveDate = addDays(activeDate, dayDiff);
    setActiveDate(newActiveDate);
  };

  useEffect(() => {
    setDateList((prevDateList) =>
      prevDateList.map((_, index) => addDays(activeDate, index - Math.floor(numberOfDays / 2)))
    );
  }, [activeDate])

  return (
    <div className='custom-week-view-container'>
      {dateList.map((date) => (
        <div
          key={date.toString()}
          onClick={() => handleDateClick(date)}
          className={`custom-week-view-item ${date.getTime() === activeDate.getTime() ? 'active' : ''}`}
        >
          <span>
            {format(date, 'E')}
          </span>
          <span>
            {format(date, 'dd')}
          </span>
        </div>
      ))}
    </div>
  );
}