export default function TransportaionExtension(props) {
  const {trip, IoTriangle, tripTransportationExpand, setTripTransportationExpand } = props
  return (
    <div className='sub-extension-information--container'>
      <div className='btn-more-information--container no-margin'>
        <button type='button' className='btn-more-information' onClick={() => setTripTransportationExpand(!tripTransportationExpand)}>
          <IoTriangle className={`${tripTransportationExpand && 'opened' }`}/>
        </button>
        <h5>Transportation</h5>
      </div>

      <div className='sub-extension-content--parent'>
        {trip.tripTransportation.map((data, index) => 
          <div key={data.id} className={`sub-extension-content--wrapper ${tripTransportationExpand && 'visible'}`}>
            <div className='sub-extension-content--container'>
              <div className='extension-content'>
                <span className='full'>Name</span>
                <span>:</span>
                <span className='full'>{data.name}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Type</span>
                <span>:</span>
                <span className='full'>{data.type}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Depart</span>
                <span>:</span>
                <span className='full'>{data.departTerminal}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Depart Gate</span>
                <span>:</span>
                <span className='full'>{data.departGate}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Arrive</span>
                <span>:</span>
                <span className='full'>{data.arriveTerminal}</span>
              </div>

              <div className='extension-content'>
                <span className='full'>Arrive Gate</span>
                <span>:</span>
                <span className='full'>{data.arriveGate}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}