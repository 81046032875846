import { useEffect, useState } from 'react'
import { formatDate } from '../../../utils/formatDate'


export default function GuestForm(props) {
  const { index, handleInputChange, guestList } = props
  const [birthdate, setBirthDate] = useState(formatDate(new Date(), 'yyyy-MM-dd'))

  const titles = [
    {
      label: "Mr",
      value: "Mr"
    },
    {
      label: "Mrs",
      value: "Mrs"
    }
  ]

  const idTypes = [
    {
      value: 'Passport',
      label: 'Passport'
    },
    {
      value: 'KTP',
      label: 'KTP'
    },
  ]

  useEffect(() => {
    if (guestList[index].guest_birthdate !== '') {
      setBirthDate(formatDate(guestList[index].guest_birthdate, 'yyyy-MM-dd'))
    }
  }, [guestList, index])

  return (
    <div className='form-input-trip'>
      <div className="form-input--wrapper">
        <input type='hidden' name={`guest_code`} value={guestList[index].guest_code} />
        <div className='form-input-group w-full'>
          <label htmlFor="">Title</label>
          <select name="guest_title" value={guestList[index].guest_title} className="form-input-group form-input simple-select" onChange={(e) => handleInputChange(e, index)}>
            <option value={""}>Choose</option>
            {titles.map((type) => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
          {/* <input 
            type="text"
            name={`guest_title`}
            value={guestList[index].guest_title}
            onChange={(e) => handleInputChange(e, index)}
            className='form-input' /> */}
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Name</label>
          <input
            type="text"
            name={`guest_name`}
            value={guestList[index].guest_name}
            onChange={(e) => handleInputChange(e, index)}
            className='form-input' />
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">Email</label>
          <input
            type="email"
            name={`guest_email`}
            value={guestList[index].guest_email}
            onChange={(e) => handleInputChange(e, index)}
            className='form-input' />
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">Phone Number</label>
          <input
            type="text"
            name={`guest_phone`}
            value={guestList[index].guest_phone}
            onChange={(e) => handleInputChange(e, index)}
            className='form-input' />
        </div>
      </div>

      <div className="form-input--wrapper">
        <div className='form-input-group w-full'>
          <label htmlFor="">ID Type</label>
          <select name="guest_id_type" className="form-input-group form-input simple-select" value={guestList[index].guest_id_type} onChange={(e) => handleInputChange(e, index)}>
            <option value={""}>Choose</option>
            {idTypes.map((type) => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
          {/* <input
            type="text"
            name={`guest_id_type`}
            onChange={(e) => handleInputChange(e, index)}
            className='form-input' /> */}
        </div>
        <div className='form-input-group w-full'>
          <label htmlFor="">ID Number</label>
          <input
            type="text"
            name={`guest_id_number`}
            value={guestList[index].guest_id_number}
            onChange={(e) => handleInputChange(e, index)}
            className='form-input' />
        </div>
      </div>

      <div className='form-input-group'>
        <label htmlFor="">Birthdate</label>
        <input
          type="date"
          name={`guest_birthdate`}
          value={birthdate}
          onChange={(e) => handleInputChange(e, index)}
          className='form-input'
          />
      </div>
    </div>
  )
}