import React, { useReducer } from "react";
import { Route, Routes, Switch, useLocation } from "react-router-dom";

import { Home, Help, Post, Articles, AfterRegister, Article, Notes, News, Favorite, UserArticles, NewsList, Settings, Notification, UserPosts, Statistic, ArticleList, NotFound, UserProfile, Contributors, Itinenary, ActivityLogs, TopContributors, ForgotPassword, ResetPassword, PrivacyPolicy, ItinenaryTrip, Itineraries, ItinerariesDetail } from "./pages";
import { Navbar, Footer, ModalAuth, Layout } from "./components";
import Unauthorized from "./pages/Unauthorized";

import { AuthContextProvider } from "./components/shared/AuthContext";
import ProtectedRoute from "./components/shared/ProtectedRoute";

import { AnimatePresence } from "framer-motion";

// initial state
// before login
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reduceer = (state, action) => {
  // fungsi2 yg disimpan di local storage
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

function App() {
  const location = useLocation();
  
window.dataLayer.push({
  event: 'pageview',
  page: {
    url: location,
    title: location.pathname
  }
});

  // panggil reducer
  const [state, dispatch] = useReducer(reduceer, initialState);
  return (
    <div className="App">
      <AuthContextProvider>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/login" element={<ModalAuth />} />
              <Route path="/articles/:id" element={<Article />} />
              <Route path="/articles/:slug" element={<Article />} />
              <Route path="/news" element={<News />} />
              <Route
                path="/favorite/:category"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Favorite />
                  </ProtectedRoute>
                }
              />
              <Route path="/user-articles/:username" element={<UserArticles />} />
              <Route path="/news-list" element={<NewsList />} />
              <Route path="/article-list" element={<ArticleList />} />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/post"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Post />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/post/:id"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Post />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notification"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Notification />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Statistic"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Statistic />
                  </ProtectedRoute>
                }
              />
              <Route path="/after-register" element={<AfterRegister />} />
              <Route path="/itineraries" element={ <Itineraries />} />
              <Route path="/itineraries/:slug" element={ <ItinerariesDetail />} />
              <Route path="/itineraries/user/:username" element={ <ProtectedRoute accessBy="authenticated"><Itinenary /></ProtectedRoute>} />
              <Route path="/itinerary/:itineraryId" element={ <ProtectedRoute accessBy="authenticated"><ItinenaryTrip /></ProtectedRoute>} />

              {/* route untuk demo itinerary */}
              {/* <Route path="demo-page/itinerary" element={ <ProtectedRoute accessBy="authenticated"><Itinenary isDemo /></ProtectedRoute>} />
              <Route path="demo-page/itinerary/:itineraryId" element={ <ProtectedRoute accessBy="authenticated"><ItinenaryTrip /></ProtectedRoute>} /> */}

              <Route path="/contributors" element={<Contributors />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
              <Route
                path="/user-posts"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <UserPosts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-profile/:username"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route path="/help" element={<Help />} />
              <Route 
                path="/activity/logs/interaction/:activity" 
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <ActivityLogs />
                  </ProtectedRoute>
                } />
              <Route 
                path="/activity/logs/post/:activity" 
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <ActivityLogs />
                  </ProtectedRoute>
                } />
              <Route 
                path="/activity/logs/profile/:activity" 
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <ActivityLogs />
                  </ProtectedRoute>
                } />
              <Route 
                path={"/top-buddies"}
                element={<TopContributors />} 
                />

              <Route 
                path={"/forget-password"}
                element={<ForgotPassword />} 
                />
              <Route 
                path={"/reset-password/:resetToken"}
                element={<ResetPassword />} 
                />

              <Route path="/articles/:mode/:id" 
              element={
                <ProtectedRoute accessBy="authenticated">
                  <Article />
                </ProtectedRoute>
              } />

              <Route 
                path={"/privacy-policy"}
                element={<PrivacyPolicy />} 
                />
            </Route>
          </Routes>
        </AnimatePresence>
      </AuthContextProvider>
    </div>
  );
}

export default App;
