import React from "react";
import styled from "styled-components";

const ButtonStyled = styled.div`
  font-size: 18px;
  color:black;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 24px;
  }
`;

export function Share(props) {
  
  return (
    <ButtonStyled href="#shareModal" data-bs-toggle="modal" type="button">
      <i className="fa-sharp  fa-regular fa-paper-plane"></i>
    </ButtonStyled>
  );
}
