/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { CropImageProfile, Metadata } from "../components";
import { Button } from "../components/Button";
import "./style.css";
import axios from "axios";
import defaultImageUser from "../assets/images/defaultUser.svg";
import styled from "styled-components";
import { Modal } from "bootstrap";
import * as articlesData from "../data/articles";
import moment from "moment";
import Swal from "sweetalert2";
import { BASE_URL } from "../config/config";

import { useDebounce } from "../hooks";
import { getFileName } from "../utils/getFileName";

const ImageProfile = styled.label`
  width: 250px;
  height: 250px;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  > .image-upload {
    position: absolute;
    top: 190px;
    boder-radius: 50%;
    left: 180px;

    > input {
      display: none;
    }
  }
`;

const Dropdown = styled.label`
  .dropbtn {
    background-color: white;
    color: black;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 8px;
  }

  .dropdown-content ul {
    list-style-type: none;
    text-decoration: none;
  }

  .dropdown-content li {
    color: black;
    padding: 5px 0px;
    margin-left: -40px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content li:hover {
    background-color: #eaeaea;
    padding-left: 5px;
  }

  :hover .dropdown-content {
    display: block;
  }

  :hover .dropbtn {
    background-color: black;
    color: white;
  }
`;

const FontStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  .row {
    margin-bottom: 1em;
  }
  label {
    font-size: 14px;
    margin-top: 7px;
  }
  .text {
    font-size: 16px;
    line-height: 24px;
  }
  input {
    margin-bottom: 0.2em;
  }

  @media only screen and (max-width: 600px) {
    label {
      margin-bottom: 0.3em;
    }
  }

`;
const Suggestions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
  height: 200px;
  overflow: scroll;

  > li {
    list-style: none;
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
  }
  li:hover {
    background-color: #d4d4d4;
  }


`;

export function Settings() {
  const [activeCategory, setActiveCategory] = useState("Account");
  const [view, setView] = useState(false);
  const [reviewImage, setReviewImage] = useState();
  const [saveImage, setSaveImage] = useState();
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [bio, setBio] = useState("");
  const [occupation, setOccupation] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageEdit, setImageEdit] = useState();
  const [countries, setCountries] = useState([]);
  const [selectCountryId, setSelectCountryId] = useState();
  const [suggestionsCountriesValue, setSuggestionsCountriesValue] = useState("");
  const [suggestionsCitiesValue, setSuggestionsCitiesValue] = useState("");
  const [userProfile, setUserProfile] = useState([]);

  const debouncedCity = useDebounce(suggestionsCitiesValue, 1000);
  const debouncedCountry = useDebounce(suggestionsCountriesValue, 1000);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [isPhotoProfileNew, setIsPhotoProfileNew] = useState(false);


  const token = JSON.parse(localStorage.getItem("tokens")).data.access_token;
  useEffect(() => {
    async function fetchData() {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // You can await here
      const res = await axios.get(`${BASE_URL}/users/setting/profile?=&=&=`).then((res) => {
        setUserProfile(res.data);
        setName(res.data?.data?.traveler?.name);
        setEmail(res.data?.data?.email);
        setUsername(res.data?.data?.username);
        setOccupation(res.data?.data?.traveler?.occupation);
        setBirthDate(moment(res.data.data.traveler.birthDate).format("YYYY-MM-DD"));
        setBio(res.data?.data?.traveler?.bio);
        setGender(res.data?.data?.traveler?.gender);
        setReviewImage(res.data?.data?.traveler?.image);
        setSuggestionsCountriesValue(res.data?.data?.traveler?.country?.name);
        setSelectCountryId(res.data?.data?.traveler?.country?.id);
        setSuggestionsCitiesValue(res.data?.data?.traveler?.city?.name);
        setCity(res.data?.data?.traveler?.city?.id);
        // getCities(res.data?.data?.traveler?.country?.id);
        convertUrlToFeatured(res.data?.data?.traveler?.image);
      });
      return res;
    }
    fetchData();
  }, [token]);

  const getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open("GET", myImageUrl, true);
      request.responseType = "blob";
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  const getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };
  const convertUrlToFeatured = async (image) => {
    try {
      const url = image;
      let blob = await getBlobFromUrl(url);
      // setReviewImage(blob)
      let imageData = await getDataFromBlob(blob);
      const fileName = getFileName(image)
      setSaveImage({
        filename: fileName,
        base64: imageData,
      });
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      const queryParam = "?limit=240&search=" + debouncedCountry;
      const res = await articlesData.getCountries(queryParam);

      if (res.code !== 200) {
        return setCountries(() => []);
      }

      setCountries(() => res.data);
    };

    getCountries();
  }, [debouncedCountry]);

  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      const queryParam = "?limit=6000&countryId=" + selectCountryId + "&search=" + debouncedCity;
      const res = await articlesData.getCities(queryParam);

      if (res.code !== 200) {
      }
      setCities(() => res.data);
    };

    getCountries();
  }, [selectCountryId, debouncedCity]);

  async function reduce_image_file_size(base64Str, MAX_WIDTH = 150, MAX_HEIGHT = 150) {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    setImageBase64(resized_base64);
    return resized_base64;
  }

  const encodeFileBase64 = (file, imageName) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result;
        reduce_image_file_size(Base64);
        setImageBase64(Base64);
        setSaveImage({ base64: Base64, filename: imageName });
      };
      reader.oneerror = (error) => {
         // console.log("error: ", error);
      };
    }
  };

  function handleUploadChange(e) {
    let uploaded = e.target.files[0];
    setIsPhotoProfileNew(true);
    setReviewImage(URL.createObjectURL(uploaded));
    setImageName(uploaded.name);
    encodeFileBase64(uploaded, uploaded.name);
    var reader = new FileReader();
    if (uploaded) {
      let myModal = Modal.getOrCreateInstance("#exampleModal");
      myModal.show();
      reader.readAsDataURL(uploaded);
      reader.onload = () => {
        var Base64 = reader.result;
        setImageBase64(Base64);
        setSaveImage({ filename: uploaded.name, base64: Base64 });
      };
      reader.oneerror = (error) => {
         // console.log("error: ", error);
      };
    }
  }

  function removeImage(e) {
    setReviewImage("");
    setSaveImage();
  }

  const updateProfile = async (e) => {
    e.preventDefault();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change your profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let newProfile = {
          email: email,
          username: username,
          birthDate: birthDate,
          bio: bio,
          occupation: occupation,
          countryId: selectCountryId,
          cityId: city,
          gender: gender,
          name: name,
        };

        if (setIsPhotoProfileNew) {
          newProfile = {
            ...newProfile,
            image: saveImage
          }
        }

        axios({
          method: "patch",
          url: `${BASE_URL}/users/setting/profile`,
          data: newProfile,
        })
          .then(() => {
            window.location.reload();
            setSaveImage()
          })
          .catch((error) => {
            if(error.response.data.statusCode === 400) {
              const messages = error.response.data.message;
              return Swal.fire({
                title: "Data not complete",
                text: `
                  Check your data again, 
                    ${
                      messages.map((message) => {
                        return `${message} `;
                      })
                    }
                `,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              });
            }

            return Swal.fire({
              title: "Ups something wrong",
              text: error?.response?.data?.data?.message || "Please try again later",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Okay",
            });
          });
      }
    });
  };

  const updateNotif = async (recommendedReading, newPostFromBipiStaff, allowEmailNotification) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .patch(`${BASE_URL}/users/settings`, {
        recommendedReading: recommendedReading,
        newPostFromBipiStaff: newPostFromBipiStaff,
        allowEmailNotification: allowEmailNotification,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You want to change your password?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if(result.dismiss) return;

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const payload = {
        oldPassword,
        newPassword,
        confirmNewPassword
      }

      axios({
        method: 'patch',
        url: `${BASE_URL}/users/settings/password`,
        data: payload,
      }).then((res) => {
        window.location.reload();
      }).catch((err) => {
        const message = err?.response?.data?.data?.message;
        Swal.fire({
          title: "Error Occured!",
          text: message,
          icon: 'error',
          confirmButtonText: 'Close!'
        })
      })

    })
  }

  const [showSuggestionsCountries, setShowSuggestionsCountries] = useState(false);
  const [showSuggestionsCity, setShowSuggestionsCities] = useState(false);
  const suggestionsCountry = countries.filter((countries) => countries?.name?.toLowerCase().includes(suggestionsCountriesValue?.toLowerCase()));
  const handleSuggestionCountry = (suggestion) => {
    setSuggestionsCountriesValue(suggestion.name);
    setSelectCountryId(suggestion.id);
    setShowSuggestionsCountries(false);
    setSuggestionsCitiesValue("");
    setCity("");
    // getCities(suggestion.id);
  };
  const suggestionsCity = cities?.filter((cities) => cities?.name?.toLowerCase().includes(suggestionsCitiesValue?.toLowerCase()));

  const handleSuggestionCity = (suggestion) => {
    setSuggestionsCitiesValue(suggestion.name);
    setCity(suggestion.id);
    setShowSuggestionsCities(false);
  };

  return (
    <div
      className="articles d-flex flex-column"
      style={{ marginTop: "6.5rem", gap: "4.5rem", marginBottom: '5rem' }}
    >
      <div className="container d-flex flex-column gap-5">
        <Metadata page={window.location.href} titlePage={`Account Settings • Bipi World`} />

        <h1 className="pt-3">Settings</h1>
        <h1 className="d-none">Edit Profile</h1>
        <h1 className="d-none">Edit Notification</h1>

        <div
          className="d-flex flex-wrap justify-content-between align-items-center gap-2 "
          style={{ borderBottom: "1px grey solid" }}
        >
          <div className="d-flex flex-wrap gap-4">
            {["Account", "Notification", "Change Password"].map((item, index) => (
              <div
                key={item}
                className={`p-2 ${
                  activeCategory === item && "border-bottom-blue"
                }`}
              >
                <span
                  onClick={() => setActiveCategory(item)}
                  className={`text-black text-decoration-none ${
                    activeCategory === item && "text-blue"
                  }`}
                  style={{ fontSize: "18px", color: "black", cursor: 'pointer' }}
                >
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="row g-4 justify-content-between">
          {activeCategory === "Account" 
          ? (
            <form
              className="col-sm-12 col-lg-7"
              method="post"
              onSubmit={updateProfile}
            >
              <div className="form-group row mb-6 pb-3">
                <div className="col-sm-12 col-md-3 text-center pt-1 mb-4">
                  {view ? (
                    <ImageProfile>
                      <img
                        alt="profile"
                        src={
                          userProfile.data?.traveler?.image
                            ? userProfile.data?.traveler?.image
                            : defaultImageUser
                        }
                        width="250px"
                        height="250px"
                      />
                    </ImageProfile>
                  ) : (
                    <>
                      {reviewImage !== "" ? (
                        <ImageProfile>
                          <img
                            alt="profile"
                            src={
                              reviewImage !== undefined
                                ? reviewImage
                                : userProfile.data?.traveler?.image
                            }
                            className="justify-content-center"
                            width="250px"
                            height="250px"
                          />
                          <div className="image-upload">
                            <div className="icon-upload dropdown">
                              <Dropdown
                                className="dropdown"
                                style={{ float: "left" }}
                              >
                                <div className="dropbtn">
                                  <i className="fa fa-solid fa-camera"></i>
                                </div>
                                <ul
                                  className="dropdown-content"
                                  style={{ textDecoration: "none" }}
                                >
                                  <li className="position-relative">
                                    <input
                                      onChange={handleUploadChange}
                                      accept="image/png, image/jpeg"
                                      className="form-control mt-3 position-absolute"
                                      type="file"
                                      id="formFile"
                                      style={{
                                        opacity: "0%",
                                        top: "0px",
                                        height: "20px",
                                      }}
                                    />
                                    <p>Upload</p>
                                  </li>
                                  {/* <li  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Crop
                                  </li>
                                  <li onClick={removeImage}>
                                    Remove
                                  </li> */}
                                </ul>
                              </Dropdown>
                            </div>
                          </div>
                        </ImageProfile>
                      ) : (
                        <ImageProfile>
                          <img
                            alt="profile"
                            src={`https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg`}
                            className="justify-content-center"
                          />
                          <div className="image-upload">
                            <div className="icon-upload dropdown">
                              {reviewImage === "" && (
                                <Dropdown
                                  className=""
                                  style={{ float: "left" }}
                                >
                                  <button className="dropbtn">
                                    <i className="fa fa-solid fa-camera">
                                      <input
                                        onChange={handleUploadChange}
                                        accept="image/*"
                                        className="form-control mt-3 position-absolute"
                                        type="file"
                                        id="formFile"
                                        style={{
                                          opacity: "0%",
                                          top: "-10px",
                                          left: "0px",
                                          height: "40px",
                                        }}
                                      />
                                    </i>
                                  </button>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        </ImageProfile>
                      )}
                    </>
                  )}
                </div>
              </div>
              <FontStyle>
                <div className="row ">
                  <label htmlFor="inputName3" className="col-sm-3">
                    Name
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      type="text"
                      value={name ? name : ""}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="inputName3"
                      readOnly={view}
                    />
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="inputEmail3" className="col-sm-3">
                    Email
                  </label>
                  <div className="col-sm-8">
                    <input
                      required
                      type="text"
                      value={email ? email : ""}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      id="inputEmail3"
                      readOnly={view}
                    />
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="inputUsername3" className="col-sm-3">
                    Username
                  </label>
                  <div className="col-sm-8">
                    <input
                      required
                      type="text"
                      value={username ? username : ""}
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control"
                      id="inputUsername3"
                      readOnly={view}
                      maxLength={20}
                    />
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="inputOccupation3" className="col-sm-3">
                    Occupation
                  </label>
                  <div className="col-sm-8">
                    <input
                      required
                      type="text"
                      value={occupation ? occupation : ""}
                      onChange={(e) => setOccupation(e.target.value)}
                      className="form-control"
                      id="ooccupation"
                      readOnly={view}
                    />
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="inputOccupation3" className="col-sm-3">
                    Country
                  </label>
                  <div className="col-sm-8">
                    <input
                      required
                      className="form-control"
                      value={
                        suggestionsCountriesValue
                          ? suggestionsCountriesValue
                          : ""
                      }
                      onFocus={() => setShowSuggestionsCountries(true)}
                      onChange={(e) =>
                        setSuggestionsCountriesValue(e.target.value)
                      }
                      readOnly={view}
                    />
                    {showSuggestionsCountries && (
                      <Suggestions className="suggestions">
                        {suggestionsCountry.length
                          ? suggestionsCountry.map((countries, index) => (
                              <li
                                key={countries.id}
                                onClick={() =>
                                  handleSuggestionCountry(countries)
                                }
                              >
                                {countries.name}
                              </li>
                            ))
                          : "Type a country"}
                      </Suggestions>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="inputOccupation3" className="col-sm-3">
                    City
                  </label>
                  <div className="col-sm-8">
                    <input
                      required
                      className="form-control"
                      value={
                        suggestionsCitiesValue !== ""
                          ? suggestionsCitiesValue
                          : ""
                      }
                      onFocus={() => setShowSuggestionsCities(true)}
                      onChange={(e) => {
                        setSuggestionsCitiesValue(e.target.value);
                        // getCities(selectCountryId);
                      }}
                      readOnly={view}
                    />
                    {showSuggestionsCity && (
                      <Suggestions className="suggestions">
                        {suggestionsCity.length
                          ? suggestionsCity.map((cities, index) => (
                              <li
                                key={cities.id}
                                onClick={() => handleSuggestionCity(cities)}
                              >
                                {cities.name}
                              </li>
                            ))
                          : "Type a City"}
                      </Suggestions>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="inputOccupation3" className="col-sm-3">
                    Gender
                  </label>
                  <div className="col-sm-8">
                    <select
                      disabled={view}
                      value={gender ? gender : ""}
                      className="form-select w-100 p-2"
                      onChange={function (event) {
                        setGender(event.target.value);
                      }}
                      required
                    >
                      <option value=""></option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                    </select>
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="date" className="col-sm-3">
                    Date of Birth
                  </label>
                  <div className="col-sm-8">
                    <div className="input-group date" id="datepicker">
                      <input
                        required
                        type="date"
                        value={birthDate}
                        onChange={(e) => setBirthDate(e.target.value)}
                        className="form-control"
                        id="date"
                        readOnly={view}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="Bio" className="col-sm-3">
                    Bio
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      required
                      maxLength="200"
                      value={bio ? bio : ""}
                      onChange={(e) => setBio(e.target.value)}
                      className="form-control"
                      id="Bio"
                      rows="3"
                      readOnly={view}
                    ></textarea>
                  </div>
                </div>
              </FontStyle>

              <div className="row">
                <div className="col-sm-11 pt-2 pe-1 text-end">
                  {view ? (
                    <Button
                      text="Update"
                      onClick={function () {
                        setView(false);
                        setName(userProfile.data?.traveler?.name);
                        setEmail(userProfile.data?.email);
                        setUsername(userProfile.data?.username);
                        setCountry(userProfile.data?.traveler?.country?.id);
                        setCity(userProfile.data?.traveler?.city?.id);
                        setGender(userProfile.data?.traveler?.gender);
                        setOccupation(userProfile.data?.traveler?.occupation);
                        setBirthDate(birthDate);
                        setBio(userProfile.data?.traveler?.bio);
                        setSelectCountryId(
                          userProfile.data?.traveler?.country?.id
                        );
                        setReviewImage(userProfile.data?.traveler?.image);
                      }}
                    />
                  ) : (
                    <div>
                      <Button
                        text="Save"
                        colorBg="black"
                        colorText="white"
                        type="submit"
                      />
                      <Button
                        isHidden={true}
                        text="Cancel"
                        onClick={function () {
                          setView(true);
                          setShowSuggestionsCountries(false);
                          setShowSuggestionsCities(false);
                          // setSuggestionsCitiesValue("");
                          // setSuggestionsCountriesValue("");

                          setName(userProfile.data?.traveler?.name);
                          setEmail(userProfile.data?.email);
                          setUsername(userProfile.data?.username);
                          setCountry(userProfile.data?.traveler?.country?.id);
                          setSelectCountryId(
                            userProfile.data?.traveler?.country?.id
                          );
                          setCity(userProfile.data?.traveler?.city?.id);
                          setGender(userProfile.data?.traveler?.gender);
                          setOccupation(userProfile.data?.traveler?.occupation);
                          setBirthDate(birthDate);
                          setBio(userProfile.data?.traveler?.bio);
                          setSelectCountryId(
                            userProfile.data?.traveler?.country?.id
                          );
                          setReviewImage(userProfile.data?.traveler?.image);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </form>
            ) 
          : activeCategory === "Notification" 
          ? (
            <FontStyle className="col-sm-12 col-md-7">
              <form onSubmit={updateProfile}>
                <div className="form-check form-switch pb-3">
                  <input
                    className="form-check-input text"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    defaultChecked={
                      userProfile.data?.userSetting.recommendedReading
                    }
                    onChange={() =>
                      updateNotif(
                        !userProfile.data?.userSetting.recommendedReading,
                        userProfile.data?.userSetting.newPostFromBipiStaff,
                        userProfile.data?.userSetting.allowEmailNotification
                      )
                    }
                  />
                  <h5
                    className="form-check-label text"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Recommended reading
                  </h5>
                  <label>
                    Featured stories , columns, and collections that we think
                    you’ll enjoy based on your reading history
                  </label>
                </div>
                <div className="form-check form-switch pb-3">
                  <input
                    className="form-check-input text"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    defaultChecked={
                      userProfile.data?.userSetting.newPostFromBipiStaff
                    }
                    onChange={() => {
                      updateNotif(
                        userProfile.data?.userSetting.recommendedReading,
                        !userProfile.data?.userSetting.newPostFromBipiStaff,
                        userProfile.data?.userSetting.allowEmailNotification
                      );
                    }}
                  />
                  <h5
                    className="form-check-label text"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    New Posts from Bipi Staff Writer
                  </h5>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input text"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    defaultChecked={
                      userProfile.data?.userSetting.allowEmailNotification
                    }
                    onChange={() => {
                      updateNotif(
                        userProfile.data?.userSetting.recommendedReading,
                        userProfile.data?.userSetting.newPostFromBipiStaff,
                        !userProfile.data?.userSetting.allowEmailNotification
                      );
                    }}
                  />
                  <h5
                    className="form-check-label text"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Allow email notification
                  </h5>
                  <label>
                    You’ll still receive administrative emails even if this
                    setting is of
                  </label>
                </div>
              </form>
            </FontStyle>
          )
          : (
            <form
              className="col-sm-12 col-lg-7"
              method="post"
              onSubmit={updatePassword}
            >
              <FontStyle>
                <div className="row ">
                  <label htmlFor="oldPassword" className="col-sm-3">
                    Old Password
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      type="password"
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="form-control"
                      id="oldPassword"
                      required
                    />
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="newPassword" className="col-sm-3">
                    New Password
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control"
                      id="newPassword"
                      required
                    />
                  </div>
                </div>
                <div className="row ">
                  <label htmlFor="confirmationNewPassword" className="col-sm-3">
                    Confirmation New Password
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      type="password"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      className="form-control"
                      id="confirmationNewPassword"
                      required
                    />
                  </div>
                </div>
              </FontStyle>

              <div className="row">
                <div className="col-sm-11 pt-2 pe-1 text-end">
                <Button
                  text="Save"
                  colorBg="black"
                  colorText="white"
                  type="submit"
                />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <CropImageProfile
        handleUploadChange={handleUploadChange}
        setReviewImage={setReviewImage}
        reviewImage={reviewImage}
        imageEdit={reviewImage}
      />
    </div>
  );
}
