import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  CardArticleHorizontal,
  RecommendedArticles,
  RecommendedTopics,
  RecommendedBuddies,
  Paginate,
  Metadata,
  NoData,
  LoadingScreen
} from "../components";
import "./style.css";
import * as articlesData from "../data/articles";

import AuthContext from "../components/shared/AuthContext";

export function Favorite() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)

  const [articlesFavorite, setArticlesFavorite] = useState([]);
  const [articlesSaved, setArticlesSaved] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const getArticles = async () => {
      const likedArticles = await articlesData.getLikedArticles(`?limit=100`);
      const bookmarkedArticles = await articlesData.getbookmarkedArticles(`?limit=100`);

      setArticlesFavorite(() => likedArticles.data);
      setArticlesSaved(() => bookmarkedArticles.data);
      setIsFetching(false)
    };
    getArticles();
    setActiveCategory(params?.category)
  }, []);

  const favorite = articlesFavorite.length ?? 0;
  const saved = articlesSaved.length ?? 0;

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pageVisited = pageNumber * usersPerPage;
  let pageCount = 0;

  if (activeCategory === "liked") {
    pageCount = Math.ceil(articlesFavorite.length / usersPerPage);
  }
  if (activeCategory === "saved") {
    pageCount = Math.ceil(articlesSaved.length / usersPerPage);
  }
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  
  const categories = [
    {
      name: "Likes (" + favorite + ")",
      value: 'liked'
    },
    {
      name: "Recently Saved (" + saved + ")",
      value: 'saved'
    }
  ]

  return (
    <div
      className="articles d-flex flex-column"
      style={{ marginTop: "6.5rem", gap: "4.5rem" }}
    >
      <Metadata page={window.location.href} titlePage={`${activeCategory === 'liked' ? 'Articles Liked • Bipi World' : 'Articles Saved • Bipi World'} `} />

      <div className="container d-flex flex-column gap-5">
        {activeCategory === "liked" && (
          <h1>Favorite Articles</h1>
        )}
        {activeCategory === "saved" && (
          <h1>Saved Articles</h1>
        )}
        <h2 className="d-none">Article Favorite</h2>
        <div
          className="d-flex flex-wrap justify-content-between align-items-center gap-2 "
          style={{ borderBottom: "1px grey solid" }}
        >
          <div className="d-flex flex-wrap gap-4">
            {categories.map(
              (item) => (
                <div
                  key={item.value}
                  className={`p-2 ${
                    activeCategory === item && "border-bottom-blue"
                  }`}
                >
                  <Link
                    to={`/favorite/${item.value}`}
                    className={`text-black text-decoration-none ${
                      activeCategory === item.value && "text-blue"
                    }`}
                    style={{ fontSize: "18px", color: "black" }}
                    role="button"
                  >
                    {item.name}
                  </Link>
                </div>
              )
            )}
          </div>
        </div>

        <div className="row g-4 justify-content-center">
          <div className="col-sm-12 col-md-8">
            <div className="flex flex-column gap-4">
              {activeCategory === 'liked' 
              ?
                (
                  !isFetching 
                    ? articlesFavorite.length
                      ? articlesFavorite
                          ?.slice(pageVisited, pageVisited + usersPerPage)
                          .map((article) => (
                            <CardArticleHorizontal
                              key={article.id}
                              article={article}
                              type="article-list"
                            />
                          ))
                      : <NoData />
                  : <div className="d-flex justify-content-center"><LoadingScreen /></div>
                ) 
              :
                (
                  !isFetching 
                    ? articlesSaved.length
                      ? articlesSaved
                          ?.slice(pageVisited, pageVisited + usersPerPage)
                          .map((article) => (
                            <CardArticleHorizontal
                              key={article.id}
                              article={article}
                              type="article-list"
                            />
                          ))
                      : <NoData />
                  : <div className="d-flex justify-content-center"><LoadingScreen /></div>
                )
              }
              {/* {activeCategory === 'saved' &&
                !isFetching 
                  ? articlesSaved.length
                    ? articlesSaved
                        ?.slice(pageVisited, pageVisited + usersPerPage)
                        .map((article) => (
                          <CardArticleHorizontal
                            key={article.id}
                            article={article}
                            type="article-list"
                          />
                        ))
                    : <NoData />
                : <div className="d-flex justify-content-center"><LoadingScreen /></div>
              } */}
            </div>
            {/* <ReactPaginate
              breakLabel="..."
              nextLabel={">"}
              onPageChange={changePage}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel={"<"}
              renderOnZeroPageCount={null}
              containerClassName={"paginationBttns"}
              pageLinkClassName="pagee-num"
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            /> */}
            <Paginate changePage={changePage} pageCount={pageCount} />
          </div>
          <div className="col-md-4 row mt-0 g-5">
            <RecommendedTopics />
            <RecommendedArticles />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
