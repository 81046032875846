/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { CardUserPosts, LinkCategory, RecommendedArticles, RecommendedBuddies, RecommendedTopics, Metadata } from "../components";

import "./style.css";
import Swal from "sweetalert2";
import styled from "styled-components";

import * as usersData from "../data/users";
import * as articlesData from "../data/articles";

const ButtonPlus = styled.a`
  border-radius: 8px;
  border: 1px solid black;
  font-size: 25px;
  text-decoration: none;
  color: black;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 30px;

  &:hover {
    background-color: black;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;
export function UserPosts() {
  const [articles, setArticles] = useState([]);

  const token = JSON.parse(localStorage.getItem("tokens")).data.access_token;
  
  const [deleteBeforeDate, setDeleteBeforeDate] = useState();

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await usersData.getUsersProfile().then((response) => setArticles(response.data));
      return response;
    }
    fetchData();
  }, [token]);

  const published = articles.articles?.published?.length ?? 0;
  const draft = articles.articles?.draft?.length ?? 0;
  const review = articles.articles?.review?.length ?? 0;
  const comments = articles.comments?.length ?? 0;
  const deleted = articles.articles?.deleted?.length ?? 0;
  const [activeCategory, setActiveCategory] = useState("");

  if (activeCategory === undefined || activeCategory === "") {
    setTimeout(() => {
      setActiveCategory("Published (" + published + ")");
    }, 1500);
  }

  const deleteArticle = async (articleId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this article!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await articlesData.deleteArticle(articleId)
          .then(() => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success").then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch((error) => {});
      } else {
        Swal.fire("Your article is safe!");
      }
    });
  };

  const restoreArticle = async (articleId) =>  {
    Swal.fire({
      title: "Restore this article?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await articlesData.restoreArticle(articleId)
          .then((res) => {
            Swal.fire("Restored!", "This article has been Restore, check on published / draft tab", "success").then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch((error) => {
            Swal.fire(`something happened ${error}`)
          });
      } else {
        Swal.fire(`You can restore this article before ${deleteBeforeDate}!`);
      }
    });
  }

  const tabs = ["Published (" + published + ")", "Review (" + review + ")", "Draft (" + draft + ")", "Comments (" + comments + ")", "Deleted (" + deleted + ")"]
  return (
    <div className="articles d-flex flex-column" style={{ marginTop: "6.5rem", gap: "4.5rem", marginBottom: '4rem' }}>
      <div className="container d-flex flex-column gap-5">
        <Metadata page={window.location.href} titlePage={`Post • Bipi World`} />

        <div className="d-flex justify-content-between">
          <h1 className="section-title">Your Articles</h1>
          <h2 className="d-none">article Published</h2>
          <ButtonPlus href="/post?mode=add">&#43;</ButtonPlus>
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center gap-2 " style={{ borderBottom: "1px grey solid" }}>
          <div className="d-flex scroll ">
            {tabs.map((item, index) => (
              <LinkCategory key={index} item={item} activeCategory={activeCategory} setActiveCategory={setActiveCategory} onClick={() => setActiveCategory(item)} />
            ))}
          </div>
        </div>

        <div className="row g-4 justify-content-between">
          <div className="col-sm-12 col-md-8 col-lg-7">
            <div className="flex flex-column gap-4">
              {activeCategory === "Published (" + published + ")" && articles.articles?.published?.length
                ? articles.articles?.published?.map((article, index) => <CardUserPosts deleteArticle={deleteArticle} key={index} article={article} type="published" />)
                : ""}

              {activeCategory === "Review (" + review + ")" && articles.articles?.review?.length 
                ? articles.articles?.review?.map((article, index) => <CardUserPosts key={index} deleteArticle={deleteArticle}  article={article} type="review" />) 
                : ""}

              {activeCategory === "Draft (" + draft + ")" && articles.articles?.draft?.length 
                ? articles.articles?.draft?.map((article, index) => <CardUserPosts key={index} deleteArticle={deleteArticle} article={article} type="draft" />) 
                : ""}

              {activeCategory === "Comments (" + comments + ")" && articles.comments?.length 
                ? articles.comments?.map((comment, index) => <CardUserPosts key={index} deleteArticle={deleteArticle} comment={comment} type="comments" />) 
                : ""}

              {activeCategory === "Deleted (" + deleted + ")" && articles.articles?.deleted?.length 
                ? articles.articles?.deleted?.map((deletedArticle, index) => <CardUserPosts key={index} deleteArticle={deleteArticle} restoreArticle={restoreArticle}  article={deletedArticle} type="deleted" setDeleteBeforeDate={setDeleteBeforeDate} />) 
                : ""}
            </div>
          </div>
          <div className="col-md-4 mt-3 g-5 d-flex flex-column">
            <RecommendedTopics />
            <RecommendedArticles />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
