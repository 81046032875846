import styled from "styled-components";

const Button = styled.div`
  display: flex;
  margin-right: 18px;

  a {
    min-width: 42px;
    min-height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 50%;
    color: rgb(255, 255, 255);
    background-color: white;
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
  }
  a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
    box-shadow: none;
  }
  a:hover {
    transform: scale(1);
    background-color: black;
    i{
      color:white;
    }
    
  }
  a i {
    z-index: 1;
    transition: 0.3s ease-in-out;
  }


  a i:hover {
    color: rgb(255, 255, 255);
  }

`;

export function CircleButton(props) {
  const { icon, url } = props;
  return (
    <Button className="circle-button bgwhite" style={{cursor: 'pointer'}}>
      <a href={url} target="_blank" rel="noreferrer">
        <i className={icon}></i>
      </a>
    </Button>
  );
}
