import styled from "styled-components";
import { Link } from "react-router-dom";
import blankImg from "../assets/images/404.svg";
import moment from "moment";

const StyledInner = styled.div`
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  aspect-ratio: 1x1;

  > img {
    transition: all 1s ease;
  }
`;

const HighlighNewsStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  p .card-title .cart-text {
    font-family: "DM Sans", sans-serif;
  }
  p.card-text {
    font-size: 14px;
  }
  p.card-content {
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .card-time {
    font-size: 10px;
  }
  .card-title {
    font-size: 16px;
    padding-bottom: 8px;
    text-decoration: none;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    p.card-text {
      font-size: 15px;
    }
    p.card-content {
      font-size: 13px;
    }
    .card-time {
      font-size: 11px;
    }
    .card-title {
      font-size: 19px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    p.card-text {
      font-size: 18px;
    }
    .reading-time {
      font-size: 13px;
    }
    .card-title {
      font-size: 20px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    p.card-content {
      font-size: 16px;
    }
    p.card-text {
      font-size: 19px;
    }
  }
`;

export function HighlightNews(props) {
  const { news } = props;
   // console.log(news)
  return (
    <div className="">
      <div className="border-none" style={{ boxShadow: "none" }}>
        <StyledInner>
          <img src={!news.image ? blankImg : news.image} className="card-img-top" alt={news.imageCaption} width="516px" height="314px" />
        </StyledInner>
        <HighlighNewsStyle className="card-body ps-2 pt-2">
          <p>
            <span className="card-text fw-normal">{news.user?.traveler?.name}</span> - <span className="card-time"> {moment(news.createdAt).format("MMM D, YYYY")} </span>
          </p>
          <h2 className="card-title fw-bold">
            {/* <Link to={`/articles/${news.id}`} className="fw-bold card-title">
            </Link> */}
            {news.title}
          </h2>
          <p className="card-content position-relative">
            <div dangerouslySetInnerHTML={{ __html: news.content }} />
            {news.content.length > 50 && 
              <Link to={`/articles/${news.id}`}>
                <span className="position-absolute bottom-0 end-0 bg-white text-primary text-decoration-none"style={{ cursor: 'pointer' }}>
                  {" "}
                  ... read more
                </span>
              </Link>
            }
          </p>
          <br />
        </HighlighNewsStyle>
      </div>
    </div>
  );
}
