import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import './style.css'

export function ImageSlider(props) {
  const { imageList, isMobile } = props;

  const PrevArrowCustom = (props) => {
    const { onClick } = props;
    return(
      <div
        className={`custom_arrows_sliders prev`}
        onClick={onClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='icon-arrows'>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </div>
    )
  }

  const NextArrowCustom = (props) => {
    const { onClick } = props;
    return(
      <div
        className={`custom_arrows_sliders next`}
        onClick={onClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='icon-arrows'>
          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </div>
    )
  }

  const settings = {
    autoplay: false,
    swipe: true,
    draggable: false,
    infinite: false,
    slidesToScroll: 1,
    dots: imageList.length > 1,
    dotsClass: "dots_indicators",
    arrows: !isMobile && imageList.length > 1,
    prevArrow: <PrevArrowCustom />,
    nextArrow: <NextArrowCustom />
  }

  return(
    <>
      <Slider {...settings}>
        {imageList.map((image, index) => 
          <div key={index}>
            <img style={{ width: '100%', height: 'auto', objectFit: 'cover' }} src={image} alt={`slider ${index + 1}`} />
          </div>
        )}
      </Slider>
    </>
  )
}