import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, {keyframes } from 'styled-components'

import AuthContext from '../shared/AuthContext.js';

const slideInAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 50vh;
  
  position: fixed;
  bottom: 0;
  z-index: 1000;

  border-radius: 14px 14px 0px 0px;
  background: #212529;
  color: white;

  display: flex;
  flex-direction: column;
  padding: 1rem;

  animation: ${slideInAnimation} 0.3s ease-out;

  h4 {
    text-align: center;
  }
`;

const MenuItem = styled.span`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  a {
    color: white;
  }
`;

export function ProfileMenu(props) {
  const { logout } = useContext(AuthContext)
  const { parentRef } = props;
  
  const Menus = [
    {
      name: 'My Journey',
      href: '/user-posts',
      order: 1
    },
    {
      name: 'Insight',
      href: '/statistic',
      order: 2
    },
    {
      name: 'Settings',
      href: '/settings',
      order: 3
    },
    {
      name: 'Activity Logs',
      href: '/activity/logs/interaction/liked',
      order: 4
    },
    {
      name: 'Sign Out',
      href: '',
      order: 5
    }
  ]

  return(
    <Container ref={parentRef}>
      <h4>Menu</h4>
      {Menus.length > 0 
      ? Menus.map((menu, index) => 
        <MenuItem key={index}>
          {menu.href !== '' ?
          <Link to={menu.href}>{menu.name}</Link> 
          :
          <span onClick={() => logout()}>{menu.name}</span>
          }
        </MenuItem>) 
      : ''}
    </Container>
  )
}