import { useEffect, useState } from 'react';

import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";

import { formatDate, getDateTime } from '../utils/formatDate';
import { BsTriangleFill } from "react-icons/bs";

import JapanFlag from './../assets/images/flag/japan.png'
import USAFlag from './../assets/images/flag/usa.png'
import CarIcon from './../assets/images/icon/car.webp'

import { IoIosAirplane } from "react-icons/io";
import { IoBed, IoWalk, IoTrainSharp, IoBusOutline, IoCar, IoFastFoodOutline } from "react-icons/io5";
import { LiaMountainSolid } from "react-icons/lia"
import { GiPalmTree } from "react-icons/gi";
import { FaTree } from "react-icons/fa6";

import { GoogleMapComponent, ModalComponent, LoadingScreen, ItineraryTripCard, ItineraryTripForm } from '../components/'
import { getItinerary } from '../data/itinerary';

import { intervalToDuration } from 'date-fns';


const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  
  // background-color: #FEFDFF;
  width: 100%;

  margin: 3rem auto 4rem;

  .header--container {
    display: none; //sengaja dicomment dulu
    width: 100%;
    background: #fff;
    // padding: 1rem 0;

    .header-title {
      font-size: 28px;
      font-weight: 600;
      margin: 0 1rem;
    }

    .header-subtitle {
      font-size: 16px;
      font-weight: 500;
      color: gray;
      margin: 0 1rem;
    }

    .header-btn-actions {
      display: none;
    }
  }

  .trip {
    display: none; //sengaja dicomment dulu
    width: 100%;

    .tripByDate:first-child {
      margin-top: 0;
    }

    .tripByDate {
      margin-top: 3rem;
    }
  }

  .trip--header {
    display: inline-flex;
    background: #F1F0F6;
    width: 100%;
    padding: 0.5rem 1rem;
    color: #7F7E84;

    .trip--header-title {
      font-size: 14px;
      font-weight: 600;
    }

    
    button {
      display: none;
    }
  }

  h1, h2, h3, h5 {
    margin: 0;
    padding: 0;
  }

  
  @media only screen and (min-width: 992px) {
    margin-top: 5rem;
    padding: 2rem;

    .header--container {
      width: 75%;
      background: #DADADA;
      padding: 1rem 0;

      .header-title {
        font-size: 30px;
        margin: 0 1rem;
      }
  
      .header-subtitle {
        font-size: 18px;
        margin: 0 1rem;
      }

      .header-btn-actions {
        display: flex;
        gap: 1rem;

        margin-top: 1rem;
        border-top: 1px solid white;
        padding: 1rem;
  
        button {
          padding: 0.5rem;
          background: blue;
          color: white;
          border: none;
  
          svg {
            width: 30px;
            heigth: 30px;
          }
        }
      }
    }

    .trip {
      width: 75%;
    }

    .trip--header {
      // display: inline-flex;
      justify-content: space-between;
      align-items: center;
      
      margin-bottom: 1rem;
      padding: 1rem 0.5rem;
  
      .trip--header-title {
        font-size: 20px;
        font-weight: 600;
      }
  
      button {
        background: transparent;
        padding: 0 0.5rem;
        border: 1px solid black;
        border-radius: 8px;
      }
    }

  }

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
    max-width: 1386px;
  }
`;

const ItineraryDetailContainer =  styled.section`
  display: flex;
  flex-direction: column;
  background-color: #EEEEEE;
  width: 100%;
  padding: 1rem 1.3rem;
  border-radius: 12px;

  .header--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-section--container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .title-itinerary {
    font-size: 2.5em;
    font-weight: 600;

    .code-itinerary {
      font-size: 0.4em;
      font-weight: 400;
    }

  }

  .sub-title-section--container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
  }

  .btn-add-plan {
    background: transparent;
    border: none;
    border-radius: 8px;
    padding: 0.3rem 1rem;
    color: #0d6efd;
    font-weight: 500;
  }

  .category-list--container {
    display: none;
  }

  .category-list--container.visible {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 20;

    display: flex;
    gap: 15px;
    background: white;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    .category-content--container {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      padding-right: 1rem;
    }

    .category-content--container:last-child {
      border-right: none;
    }

    .title-category {
      font-size: 1.1em;
      font-weight: 600;
      margin-bottom: 0.3rem;
    }

    .list-category {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .list-link {
      color: #0d6efd;
      background: transparent;
      border: none;
      font-size: 0.9em;
      font-weight: 600;
      padding: 0;

      &:hover {
        color: red;
        text-decoration: underline;
      }
    }
  }

  .sub-info--container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .flag-info {
      font-size: 0.8em;
      padding: 0.1rem 0.5rem;
      border-radius: 24px;
      background-color: #5771a5;
      min-width: 80px;

      display: flex;
      justify-content: center;
      align-items: center;

      color: white;
    }

    .--light-blue {
      background: #90E0EF;
    }

    .--light-green {
      background: #3CB043;
    }

    .total-guest {
      border-left: none;
      display: flex;
      align-items: center;
      padding: 0;
    }

    .btn-map-route {
      background: transparent;
      padding: 0.1rem 1rem;
      font-size: 0.8em;
      border: 1px solid black;
      border-radius: 24px;
    }
  }

  .trip-detail--text {
    font-weight: 400;
    font-size: 1em;
  }

  .trip-price--container {
    display: none;
  }

  
  @media only screen and (min-width: 540px) {}
  
  @media only screen and (min-width: 992px) {
    overflow-y: scroll;
    max-height: 650px;
    padding: 1rem 1.5rem;

    
    .sub-info--container {
      .flag-info {
        font-size: 1rem;
        padding: 0.1rem 1rem;
      }
  
      .total-guest {
        border-left: 1px solid #cfd0cf;
        padding: 0.5rem 0.8rem;
      }
      
      .btn-map-route {
        display: none;
      }
    }

    .trip-price--container {
      // display: block;
      // font-size: 1em;
    }
  }

  
  @media only screen and (min-width: 1280px) {
    width: 60%;
  }
`;

const ItineraryContentContainer = styled.div`
  display: flex;
  flex-direction column;

  margin-top: 2rem;
  padding: 0 0.5rem;

  .itinerary--content {
    border-left: 2px solid #cfd0cf;
    padding-left: 3rem;

    display: flex;
    flex-direction: column;

    gap: 2rem;
  }


  @media only screen and (min-width: 992px) {
    margin-top: 3rem;
  }
`;

const MapRouteContainer = styled.section`
  display: none;

  
  @media only screen and (min-width: 992px) {
    display: flex;

    background-color: #EEEEEE;
    border-radius: 12px;
  
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
  
  @media only screen and (min-width: 1280px) {
    width: 40%;
    height: 650px;
    max-height: 750px;
  }
`;

const NoDateContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function ItinenaryTrip() {
  const params = useParams()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [showModalMap, setShowMap] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showCategoryTrip, setShowCategoryTrip] = useState(false)

  const [itinerary, setItinerary] = useState()
  const [trips, setTrips] = useState([])
  const [itineraryDuration, setItineraryDuration] = useState({ days: 0 })
  const [itineraryStartDate, setItineraryStartDate] = useState()
  const [itineraryEndDate, setItineraryEndDate] = useState()

  const [isFetching, setIsFetching] = useState(true)
  
  const [actionShow, setActionShow] = useState(null)
  const [isCardExpand, setIsCardExpand] = useState(null)

  const [selectedCategory, setSelectedCategory] = useState('')
  const [modalTitle, setModalTitle] = useState('Add Trip')

  const [updatedTrip, setUpdatedTrip] = useState(null)

  const handleCloseModal = () => {
    setShowMap(false)
  }

  const toggleAction = (action, index, setAction) => {
    if (action === index) {
      return setAction(null); 
    }
    setAction(index);
  }

  const handleSelectCategory = (category) => {
    setSelectedCategory(category)
    setShowCategoryTrip(false)
    setShowForm(true)
  }

  const handleUpdateTrip = (trip) => {
    setModalTitle('Update Trip')
    setShowForm(true)
    setSelectedCategory(trip.categoryTrip)
    setUpdatedTrip(trip)
  }

  useEffect(() => {
    async function getItineraryDetail() {
      const id = params.itineraryId
      const query = ''
      const result = await getItinerary(id, query)
      
      if (result?.code === 200) {
        setItinerary(result.data)
        const sortedTripByStartDateASC = result.data.itineraryTrip.sort((a, b) => Date.parse(new Date(a.startDate)) - Date.parse(new Date(b.startDate)))
        setTrips(sortedTripByStartDateASC)
      }

      setIsFetching(false)
    }

    if (params && params.itineraryId) {
      getItineraryDetail()
    }
  }, [params])

  useEffect(() => {
    if (itinerary) {
      setItineraryStartDate(itinerary.startDate)
      setItineraryEndDate(itinerary.endDate)
      const itineraryStartDate = getDateTime(itinerary.startDate)
      const itineraryEndDate = getDateTime(itinerary.endDate)

      const itineraryDuration = intervalToDuration({
        start: new Date(itineraryStartDate.years, itineraryStartDate.months, itineraryStartDate.days, itineraryStartDate.hours, itineraryStartDate.minutes, itineraryStartDate.seconds),
        end: new Date(itineraryEndDate.years, itineraryEndDate.months, itineraryEndDate.days, itineraryEndDate.hours, itineraryEndDate.minutes, itineraryEndDate.seconds)
      })

      setItineraryDuration(itineraryDuration)
    }
  }, [itinerary])

  return (
    <Container>
      <ItineraryDetailContainer>
        {!isFetching 
          ? 
            <>
              <div className='header--wrapper'>
                <div className='title-section--container'>
                  <h1 className='title-itinerary'>{itinerary?.name ?? ''} <span className='code-itinerary'>#{itinerary?.code ?? ''}</span></h1>
                  <div className='sub-info--container'>
                    <span className='flag-info --light-blue'>{itinerary?.destinationCountry ?? ''}</span>
                    <span className='flag-info'>{`${formatDate(itinerary?.startDate ?? new Date(), 'dd, MMM yyyy')} - ${formatDate(itinerary?.endDate ?? new Date(), 'dd, MMM yyyy')}`}</span>
                    <span className='flag-info --light-green'>{itineraryDuration?.days} {itineraryDuration?.days > 1 ? 'Days' : 'Day' }</span>
                    {/* <span className='total-guest'>2 Adults</span> */}
                    <button type='button' className='btn-map-route' onClick={() => setShowMap(true)}>See Map</button>
                  </div>
                  <div className='sub-title-section--container'>
                    <span className='trip-detail--text'>Trip Summary Details</span>
                    <button type='button' className='btn-add-plan' onClick={() => toggleAction(showCategoryTrip, 0, setShowCategoryTrip)}>Add Plans</button>
                    <div className={`category-list--container ${showCategoryTrip === 0 ? 'visible' : ''}`}>
                      <div className='category-content--container'>
                        <h3 className='title-category'>Category</h3>
                        <ul className='list-category'>
                          <li><button className='list-link' onClick={() => handleSelectCategory('Transportation')}>Transportation</button></li>
                          <li><button className='list-link' onClick={() => handleSelectCategory('Lodging')}>Lodging</button></li>
                          <li><button className='list-link' onClick={() => handleSelectCategory('Activities')}>Activities</button></li>
                          <li><button className='list-link' onClick={() => handleSelectCategory('Culinary')}>Culinary</button></li>
                          {/* <li><button className='list-link' onClick={() => handleSelectCategory('Other')}>Other</button></li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='trip-price--container'>
                  <h3>$10.000</h3>
                </div>
              </div>

              <ItineraryContentContainer>
                <div className='itinerary--content'>
                  {trips.length > 0 
                    ? trips.map((trip, index) => {
                      const startDate = getDateTime(trip.startDate)
                      const endDate = getDateTime(trip.endDate)

                      const tripDuration = intervalToDuration({
                        start: new Date(startDate.years, startDate.months, startDate.days, startDate.hours, startDate.minutes, startDate.seconds),
                        end: new Date(endDate.years, endDate.months, endDate.days, endDate.hours, endDate.minutes, endDate.seconds)
                      })

                      const countryFlag = itinerary?.destinationCountry === 'United States' ? USAFlag : JapanFlag
                      const dataExtension = trip.tripAttraction.length ? trip.tripAttraction[0] : []

                      let iconCategory;
                      if (trip.categoryTrip === "Transportation" && trip.tripTransportation.length > 0) {
                        if (trip.tripTransportation[0].type === 'Flight') {
                            iconCategory = <IoIosAirplane style={{ width: 25, height: 25, rotate: '-90deg', color: '#1AA7EC' }} />;
                        } else if (trip.tripTransportation[0].type === 'Train') {
                            iconCategory = <IoTrainSharp style={{ width: 25, height: 25, color: '#fedc56' }} />;
                        } else if (trip.tripTransportation[0].type === 'Bus') {
                          iconCategory = <IoBusOutline style={{ width: 25, height: 25, color: '#FF5733' }} />
                        } else if (trip.tripTransportation[0].type === 'Car') {
                          iconCategory = <IoCar style={{ width: 25, height: 25, color: '#4FFF33' }} />
                        }
                      } else if (trip.categoryTrip === "Lodging" && trip.tripAccomodation.length > 0) {
                          iconCategory = <IoBed style={{ width: 25, height: 25, color: '#ff9913' }} />;
                      } else if (trip.categoryTrip === "Activities" && trip.tripAttraction.length > 0) {
                        if (trip.tripAttraction[0].type === 'Landscape') {
                          iconCategory = <LiaMountainSolid style={{ width: 25, height: 25, color: '#5dbb63' }} /> 
                        } else if (trip.tripAttraction[0].type === 'Nature') {
                          iconCategory = <FaTree style={{ width: 25, height: 25, color: '#5dbb63' }} /> 
                        } else if (trip.tripAttraction[0].type === 'Beach') {
                          iconCategory = <GiPalmTree style={{ width: 25, height: 25, color: '#5dbb63' }} /> 
                        } else if (trip.tripAttraction[0].type === 'Travel') {
                          iconCategory = <IoWalk style={{ width: 25, height: 25, color: '#5dbb63' }} /> 
                        }
                      } else if (trip.categoryTrip === 'Culinary' && trip.tripCulinary.length > 0) {
                        iconCategory = <IoFastFoodOutline style={{ width: 25, height: 25, color: '#FF8633' }} /> 
                      }
                      
                      return (
                          <ItineraryTripCard
                            key={trip.id}
                            index={index}
                            trip={trip}
                            iconCategory={iconCategory}
                            tripDuration={tripDuration}
                            countryFlag={countryFlag}
                            dataExtension={dataExtension}
                            formatDate={formatDate}
                            actionShow={actionShow === index}
                            toggleActionShow={() => toggleAction(actionShow, index, setActionShow)}
                            cardExpand={isCardExpand === index}
                            toggleCardExpand={() => toggleAction(isCardExpand, index, setIsCardExpand)}
                            navigate={navigate}
                            handleUpdateTrip={handleUpdateTrip}
                          />
                      )
                    })
                    : <div className='itinerary-card no-data'>
                        <div className='border-left-hider'></div>
                        <span>No Trips...</span>
                      </div>
                  }
                </div>
              </ItineraryContentContainer>
            </>
          : <NoDateContainer>
              <LoadingScreen />
            </NoDateContainer> }
      </ItineraryDetailContainer>

      <MapRouteContainer>
        <GoogleMapComponent />
      </MapRouteContainer>

      <ModalComponent showModal={showModalMap} handleClose={handleCloseModal} title={'Trip Route'} centered>
        <GoogleMapComponent height={'70vh'} />
      </ModalComponent>

      
      <ModalComponent showModal={showForm} handleClose={() => {
        setShowForm(false)
        setSelectedCategory('')
        setUpdatedTrip(null)
      }} title={modalTitle} centered isCustomWidth>
        <>
          <ItineraryTripForm selectedCategory={selectedCategory} showForm={showForm} itineraryId={params.itineraryId} itineraryStartDate={itineraryStartDate} itineraryEndDate={itineraryEndDate} isMobile={isMobile} isModeUpdate={updatedTrip !== null} updatedTrip={updatedTrip}  />
        </>
      </ModalComponent>
    </Container>
  )
}