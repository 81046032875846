import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Navbar, Footer, ModalAuth } from "./";

export function Layout(props) {
  const [isBottomMenuVisible, setBottomMenuVisibility] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const toggleBottomMenuVisibility = () => {
    setBottomMenuVisibility(!isBottomMenuVisible)
  };

  const closeBottomMenu = () => {
    setBottomMenuVisibility(false)
  };

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="layout pb-0 mb-0">
      <Navbar toggleBottomMenuVisibility={toggleBottomMenuVisibility} />
      <ModalAuth />
      <Outlet context={[windowSize]} />
      <Footer isVisible={isBottomMenuVisible} onClose={closeBottomMenu} />
    </div>
  );
}
