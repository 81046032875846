/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage/EasyCrop";
import { useMediaQuery } from "react-responsive";

import Resizer from 'react-image-file-resizer';

const Container = styled.div`
  .btn-close {
    margin-left: 0;
  }

  @media screen and (min-width: 767px) {
    .btn-close {
      margin-left: auto;
    }
  }
`;

const NameLabel = styled.label`
  font-size: 1.3rem;
  padding-bottom: 0.7em;
  &.required:after {
    content: " *";
    color: red;
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const LabelGallery = styled.label`
  width: 100%;
  height: 300px;
  border: 1px dashed grey;
  text-align: center;
  line-height: 2em;
  font-size: 1rem;
  background-color: #aaaaaa;
  color: white;

  &:hover {
    color: black;
  }
`;

const Button = styled.button`
  background: ${(props) => (props.selected && props.selected === props.currentValue ? '#212529' : 'transparent' )};
  color: ${(props) => (props.selected && props.selected === props.currentValue ? 'white' : '#212529' )};
  
  padding: 0.25em 0.75em;
  font-size: 0.8rem;
  
  border: 1px solid black;
  border-radius: 4px;

  :hover {
    background: #212529;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const CropperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  background: black;
`;

const AspectRatioContainer = styled.div`
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  flex-direction: column;
  margin-bottom: 1rem;

  .aspect-ratio-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export function UploadImageModal({
  newImageGallery,
  setListImageGallery,
  listImageGallery,
  setSubArticleImage,
  setSubArticleImagePreview,
  setImageFeatured,
  setFeaturedPreview,
  modeUpload,
  addImageGallery,
  setImageGalleryPreview,
  modeUploadImage,
  imageCaption,
  setImageCaption,
  imageBase64,
  setImageBase64,
  imageName,
  setImageName,
  setImagePreview,
  imagePreview,
  indexGallery,
  imageEdit,
  imageThumbnail,
  setImageThumbnail
}) {
  
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [errorImage, setErrorImage] = useState(false)
  const [errorCaption, setErrorCaption] = useState(false)

  async function reduce_image_file_size(base64Str, MAX_WIDTH = 1275, MAX_HEIGHT = 850) {
    let resized_base64 = await new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL()) // this will return base64 image results after resize
        }
    });
    setImageBase64(resized_base64)
    setErrorImage(false)
    return resized_base64;
  }

  const encodeFileBase64 = (file, fileName, setImage) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result;
        if (!setImage) {
          reduce_image_file_size(Base64)
          setImageBase64(Base64);
          return setErrorImage(false)
        }

        setImage({
          filename: imageName,
          base64: Base64,
          imageCaption,
        });
        setErrorImage(false)
      };
      reader.oneerror = (error) => {
         // console.log("error: ", error);
      };
    }
  };

  function addImage() {
    
    if (modeUpload === "featured") {
      if (imageBase64 === undefined || imageBase64 === "") {
        setErrorImage(true)
        return;
      }
      if (imageCaption === undefined || imageCaption === "") {
        setErrorCaption(true)
        return
      };

      setImageFeatured({
        filename: imageName,
        base64: imageBase64,
        imageCaption: imageCaption,
      });
      setFeaturedPreview(imagePreview);
    }

    if (modeUpload === "subArticle") {
      setSubArticleImagePreview(imagePreview);
      setSubArticleImage({
        filename: imageName,
        base64: imageBase64,
        imageCaption: imageCaption,
      });

    }

    if (modeUpload === "gallery") {
      if (imageBase64 === undefined || imageBase64 === "") {
        setErrorImage(true)
        return;
      }
      if (imageCaption === undefined || imageCaption === "") {
        setErrorCaption(true)
        return
      };

      setImageGalleryPreview(imagePreview);
      setListImageGallery([
        ...listImageGallery,
        {
          filename: imageName,
          base64: imageBase64,
          imageCaption: imageCaption,
        },
      ]);
      addImageGallery(newImageGallery);
    }

    if (modeUpload === "editGallery") {
      if (imageBase64 === undefined || imageBase64 === "") {
        setErrorImage(true)
        return;
      }
      if (imageCaption === undefined || imageCaption === "") {
        setErrorCaption(true)
        return
      };

      const updateImage = {
        filename: imageName,
        base64: imageBase64,
        imageCaption: imageCaption,
      };

      const updatedGallery = [...listImageGallery];
      updatedGallery[indexGallery] = updateImage;
      setListImageGallery(updatedGallery);
    }

    setImagePreview("");
    setImageName("");
    setImageBase64("");
    setImageCaption("");
  }

  const [reviewCrop, setReviewCrop] = useState();
  const [imgBlob, setImgBlob] = useState();

  function handleUpload(e) {
    let uploaded = e.target.files[0];
    setImageName(uploaded.name);


    setReviewCrop(URL.createObjectURL(uploaded));

    setOpenCrop(true);

  }

  const [openCrop, setOpenCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState();

  const [aspectRatios, setAspectRatios] = useState([
    {
      label: "1 / 1", 
      value: 1 / 1,
      type: 'square'
    },
    {
      label: "3 / 4", 
      value: 3 / 4,
      type: 'potrait'
    },
    {
      label: "4 / 3", 
      value: 4 / 3,
      type: 'landscape'
    },
    {
      label: "4 / 5", 
      value: 4 / 5,
      type: 'potrait'
    },
    {
      label: "5 / 4", 
      value: 5 / 4,
      type: 'landscape'
    },
    {
      label: "5 / 7", 
      value: 5 / 7,
      type: 'potrait'
    },
    {
      label: "7 / 5", 
      value: 7 / 5,
      type: 'landscape'
    },
    {
      label: "16 / 9", 
      value: 16 / 9,
      type: 'landscape'
    },
  ])

  const [modeImage, setModeImage] = useState("")

  const [selectedAspectRatio, setSelectedAspectRatio] = useState({
    label: "4 / 3", 
    value: 4 / 3,
    type: 'landscape'
  })

  const selectAspectRatio = (aspect) => {
    setSelectedAspectRatio({
      label: aspect?.label,
      value: aspect?.value
    })
  }

  const cancelImageCrop = () => {
    setOpenCrop(false)
    setReviewCrop(undefined)

    resetImagePosition()
  }

  const resetImagePosition = ()  => {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotation(0)
  }

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const zoomPercent = (value) => {
    return `${Math.round(value * 33.3)}%`;
  };

  const cropImage = async () => {
    setLoading(true);
    try {
      const { file, url } = await getCroppedImg(reviewCrop, croppedAreaPixels, rotation);
      setImagePreview(url);
      encodeFileBase64(file, file.name);
      setOpenCrop(false);
      if (file) {
        const maxSide = Math.max(file.width, file.height);
        Resizer.imageFileResizer(
          file,
          maxSide, // original width
          maxSide, // original height
          'WEBP', // format
          100, // quality
          0, // rotation
          (uri) => {
            encodeFileBase64(file, file.name, setImageThumbnail);
          },
          'blob' // output type
        );
      }
    } catch (error) {
      throw error;
    }
  };

  async function URLtoFile(url) {
    const res = await fetch(url, { mode: 'no-cors' });
    const blob = await res.blob();
    // Gets URL data and read to blob

    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf("/") + 1, mime.length);
    // Gets blob MIME type (e.g. image/png) and extracts extension

    const file = new File([blob], `filename.${ext}`, {
      type: mime,
    });
    // Creates new File object using blob data, extension and MIME type
    if (reviewCrop === undefined) {
      setReviewCrop(URL.createObjectURL(file));
    }
  }

  if (reviewCrop === undefined && imageEdit !== undefined) {
    URLtoFile(imageEdit);
  }

  return (
    <Container className="modal fade position-absolute top-50 start-50 translate-middle" id="uploadImage" aria-hidden="true" aria-labelledby="uploadImageLabel" tabIndex="0" data-bs-backdrop="static">
      <div className="modal-dialog" style={{ display: "block" }}>
        <form className="modal-content">
          <div className="modal-header">
            <h1 className="d-none d-md-block modal-title fs-5" id="exampleModalLabel">
              Image
            </h1>
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={cancelImageCrop}></button>
            <button type="button" className={`${!openCrop ? 'd-none' : 'd-block d-md-none btn btn-dark'}`} onClick={cropImage}>Done</button>
          </div>

          <div className="modal-body">
            {openCrop === false ? (
              <>
                {modeUploadImage === true && (
                  <div className="mb-3 mt-4 d-flex flex-column">
                    <input onChange={handleUpload} accept="image/png, image/jpeg" type="file" id="imagePreview" style={{ display: "none" }} />
                    <LabelGallery htmlFor="imagePreview" className="position-relative d-flex flex-column justify-content-center">
                      {imagePreview && <img src={imageBase64} style={{ width: "100%", height: "300px" }} className="img-fluid position-absolute top-0" alt="..." />}

                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div>
                          <i className="ps-3 fa-regular fa-image pe-3 fa-lg ps-lg-3"></i>
                        </div>
                        <div>{!imagePreview ? "Upload Image" : "Change Image"}</div>
                      </div>
                    </LabelGallery>
                    {imageBase64 && (
                      <button className="btn btn-primary" onClick={() => setOpenCrop(true)}>
                        <i className="fa-solid fa-crop-simple pe-2"> </i>Crop
                      </button>
                    )}
                    {errorImage && <span className="text-danger ml-3">Upload some image caption please...</span>}
                  </div>
                )}
                {modeUploadImage === false && (
                  <div className="mb-3 mt-4 d-flex flex-column">
                    <LabelGallery htmlFor="imagePreview" className="position-relative d-flex flex-column justify-content-center">
                      {imagePreview && <img src={imageBase64} style={{ width: "100%", height: "300px" }} className="img-fluid position-absolute top-0" alt="..." />}
                    </LabelGallery>
                  </div>
                )}
                <div className="mb-3  mt-4">
                  <NameLabel htmlFor="imageCaption" className="form-label required">
                    Caption Image
                  </NameLabel>
                  {modeUploadImage === true ? (
                    <input type="text" required className="form-control" value={imageCaption} onChange={(e) => {setImageCaption(e.target.value); setErrorCaption(false)}} name="imageCaption" id="imageCaption" placeholder="Add caption image ..." />
                  ) : (
                    <input type="text" required readOnly className="form-control" value={imageCaption} onChange={(e) => {setImageCaption(e.target.value); setErrorCaption(false)}} name="imageCaption" id="imageCaption" placeholder="Add caption image ..." />
                  )}
                  {errorCaption && <span className="text-danger ml-3">Fill the image caption please...</span>}
                </div>
              </>
            ) : (
              <CropperContainer>
                <Cropper image={reviewCrop ? reviewCrop : imageEdit} crop={crop} zoom={zoom} rotation={rotation} aspect={selectedAspectRatio.value} onZoomChange={setZoom} onRotationChange={modeImage === 'rotation' ? setRotation : null} onCropChange={setCrop} onCropComplete={cropComplete} showGrid={false} objectFit="horizontal-cover"/>
              </CropperContainer>
            )}
          </div>
          {modeUploadImage === true && openCrop === false ? (
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" id="btn-upload-img" className="btn btn-dark" data-bs-dismiss={imageBase64 === undefined || imageBase64 === "" || imageCaption === undefined || imageCaption === "" ? '' : 'modal'} onClick={addImage}>
                Upload
              </button>
            </div>
          ) : (
            <div className="modal-footer d-flex flex-column pb-5" style={{ borderTop: '0px'}}>
              <div className={`${isMobile ? 'd-none' : modeImage !== "" ? 'd-md-block' : 'd-none '}`}>
                <label htmlFor="customRange2" className="form-label">
                  Zoom : {zoomPercent(zoom)}
                </label>
                <input type="range" className="form-range" min="1" max="3" step="0.1" value={zoom} onChange={(e) => setZoom(e.target.value)} id="customRange2"></input>
              </div>

              <div className="d-none">
                <label htmlFor="customRange2" className="form-label">
                  Rotation : {rotation}
                </label>
                <input type="range" className="form-range" min="0" max="360" value={rotation} onChange={(e) => setRotation(e.target.value)} id="customRange2"></input>
              </div>

              <AspectRatioContainer isShow={modeImage !== ""} mode={modeImage}>
                <label htmlFor="" className="form-label">
                    Aspect Ratio : {selectedAspectRatio.label}
                </label>

                <div className="aspect-ratio-list">
                  {aspectRatios.filter(aspect => aspect.type === modeImage).map((aspect, index) => 
                    <Button key={index} type="button" selected={selectedAspectRatio.value} currentValue={aspect.value} onClick={() => selectAspectRatio(aspect)}>{aspect.label}</Button>
                  )}
                </div>

                <div className="d-flex flex-row align-items-center justify-content-center gap-2 mt-3">
                  <Button type="button" onClick={resetImagePosition}>Reset position</Button>
                  <Button type="button" onClick={() => { setModeImage("") }} className={`${modeImage !== "" ? 'd-block' : 'd-none'}`}>Other</Button>
                </div>
              </AspectRatioContainer>

              <div className={modeImage !== "" ? 'd-none' : 'd-flex flex-row justify-content-center gap-2'}>
                <Button type="button" onClick={() => {setModeImage("landscape")}}>Landscape</Button>
                <Button type="button" onClick={() => {setModeImage("square")}}>Square</Button>
                <Button type="button" onClick={() => {setModeImage("potrait")}}>Potrait</Button>
              </div>

              <div className={`${isMobile ? 'd-none' : 'd-block'}`}>
                <button type="button" className="btn btn-outline-dark me-2" onClick={() => setOpenCrop(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-dark mb-1" onClick={cropImage}>
                  Crop
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </Container>
  );
}
