import { useState, useEffect, useContext } from "react";
import { Metadata, CardArticleHorizontal, LinkCategory, RecommendedTopics, RecommendedArticles, RecommendedBuddies, RecentlySaved, FavoriteArticles, NoData, Paginate, LoadingScreen, CardArticleImage } from "../components";
import "./style.css";
import globe from "../assets/images/globe.svg";
import map from "../assets/images/map.svg";
import * as articlesData from "../data/articles";
import { useNavigate } from "react-router-dom";
import AuthContext from "./../components/shared/AuthContext";
import styled from "styled-components";

const SortStyled = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  text-decoration: "none";
  background-color: white;
  font-size: 14px;
  width: 220px;
  border-left: 1px solid grey;
  padding-left: 5px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    width: 130px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 18px;
    width: 100px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
`;

export function Articles() {
  const { user } = useContext(AuthContext);
  const [activeCategory, setActiveCategory] = useState("Show All");
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderBy = queryParameters.get("orderBy");
  const country = queryParameters.get("country");
  const city = queryParameters.get("city");
  const category = queryParameters.get("category");
  const [selectCountry, setSelectCountry] = useState();
  const [selectCity, setSelectCity] = useState();

  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const getArticles = async () => {
      let queryParam;
      if (orderBy === "Newest" || orderBy === null) {
        queryParam = "?limit=50";
      }
      if (orderBy === "Best") {
        queryParam = "?orderBy=likes&order=desc&limit=10";
      }
      if (orderBy === "Popular") {
        queryParam = "?orderBy=views&order=desc&limit=10";
      }

      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
        return setIsFetching(false)
      }
      setArticles(res.data);
      setIsFetching(false)
    };

    getArticles();
  }, []);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const getCountries = async () => {
      const queryParam = "?limit=240&onlyHaveActiveArticle=true";
      const res = await articlesData.getCountries(queryParam);
      if (res.code !== 200) {
      }
      setCountries(() => res.data);
    };

    getCountries();
  }, []);

  const [cities, setCities] = useState([]);
  useEffect(() => {
    const getCities = async () => {
      const queryParam = "?countryId=" + selectCountry +"&limit=6000&onlyHaveActiveArticle=true";
      const res = await articlesData.getCities(queryParam);

      if (res.code !== 200) {
      }
      setCities(() => res.data);
    };

    getCities();
  }, [selectCountry]);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getArticles = async () => {
      const queryParam = "?limit=8&onlyHaveActiveArticle=true";
      const res = await articlesData.getCategories(queryParam);

      if (res.code !== 200) {
      }
      setCategories(() => res.data);
    };
    getArticles();
  }, []);

  const categoryName = ["Show All"];
  const categoryNameLength = categories.length;

  for (var i = 0; i < categoryNameLength; i++) {
    categoryName.push(categories?.[i].name);
  }

  // filter
  const [dataSearch, setDataSearch] = useState([]);

  function addCategory(category) {
    if (category === "Show All") {
      setActiveCategory("Show All");
      if (dataSearch[0] === undefined && dataSearch[1] === undefined) {
        setDataSearch([]);
      } else {
        dataSearch[2] = undefined;
      }
    } else {
      setActiveCategory(category);
      setDataSearch([dataSearch[0], dataSearch[1], category]);
    }
  }

  let filter;
  if (dataSearch[0] !== undefined && dataSearch[1] !== undefined && dataSearch[2] !== undefined) {
    filter = (articles) => articles?.country?.name === dataSearch[0] && articles?.city?.name === dataSearch[1] && articles?.category.name === dataSearch[2];
  }
  if (dataSearch[0] !== undefined && dataSearch[1] !== undefined && dataSearch[2] === undefined) {
    filter = (articles) => articles?.country?.name === dataSearch[0] && articles?.city?.name === dataSearch[1];
  }
  if (dataSearch[0] !== undefined && dataSearch[1] === undefined && dataSearch[2] === undefined) {
    filter = (articles) => articles?.country?.name === dataSearch[0];
  }
  if (dataSearch[0] === undefined && dataSearch[2] !== undefined) {
    filter = (articles) => articles?.category?.name === dataSearch[2];
  }
  if (dataSearch[0] !== undefined && dataSearch[1] === undefined && dataSearch[2] !== undefined) {
    filter = (articles) => articles?.country?.name === dataSearch[0] && articles?.category?.name === dataSearch[2];
  }

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pageVisited = pageNumber * usersPerPage;
  const displayUser = articles.slice(pageVisited, pageVisited + usersPerPage).map((article, index) => <CardArticleHorizontal key={article.id} article={article} type="article-list" />);
  const pageCount = Math.ceil(articles.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  if (selectCountry === undefined && country !== undefined) {
    setSelectCountry(country);
  }
  if (selectCity === undefined && city !== undefined) {
    setSelectCity(city);
  }
  if (activeCategory === undefined) {
    setActiveCategory(category);
  }

  return (
    <div className="articles d-flex flex-column container" style={{ marginTop: "6.5rem", gap: "3rem", marginBottom: '5rem' }}>
      <Metadata page={window.location.href} isPublic={true} />

      <div className="row justify-content-center mt-4">
        <h1 className="d-none">List Article Bipi</h1>
        <h2 className="d-none">Bipi Articles</h2>
        <div className="col-lg-6 col-10 col-md-8 info-panel">
          <div className="row option">
            <div className="col p-3 d-flex align-items-center gap-3 w-100 border-end">
              <img src={globe} alt="globe" />
              <div className="w-100">
                <span>Country</span>
                <select
                  className="select-option"
                  onChange={function (event) {
                    const index = event.target.selectedIndex;
                    if (event.target[index].innerText === "All Country") {
                      setDataSearch([]);
                      setSelectCountry();
                      setCities([]);
                    } else {
                      setDataSearch([event.target[index].innerText]);
                      setSelectCountry(event.target.value);
                    }
                    setSelectCountry(event.target.value);
                    setActiveCategory("Show All");
                  }}
                  style={{
                    border: "none",
                    boxShadow: "none",
                    outline: "none",
                    scrollBehavior: "smooth",
                  }}
                  value={selectCountry === undefined ? country : selectCountry}
                >
                  <option value="">All Country</option>
                  {countries.length
                    ? countries
                        .filter((country) => country._count.articles > 0)
                        .map((countries, index) => (
                          <option className="list-option" key={countries.id} value={countries.id}>
                            {countries.name}
                          </option>
                        ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="col p-3 d-flex align-items-center gap-3 w-100">
              <img src={map} alt="map" />
              <div className="w-100">
                <span>City</span>
                <select
                  className="select-option"
                  onChange={function (event) {
                    if (dataSearch[0] === undefined) return;
                        
                    const index = event.target.selectedIndex;
                    if (event.target[index].innerText === "All City") {
                      setSelectCity();
                      if(dataSearch[0]) {
                        setDataSearch([dataSearch[0]])
                      }
                    } else {
                      const sCity = event.target[index].innerText;
                      setDataSearch([dataSearch[0], sCity]);
                      setSelectCity(event.target.value);
                    }
                  }}
                  style={{
                    border: "none",
                    boxShadow: "none",
                    outline: "none",
                    scrollBehavior: "smooth",
                  }}
                  value={selectCity === undefined ? city : selectCity}
                >
                  <option value="">All City</option>
                  {cities.length
                    ? cities
                        .filter((cities) => cities.countryId === selectCountry && cities._count.articles > 0)
                        .map((cities, index) => (
                          <option className="list-option" key={cities.id} value={cities.id}>
                            {cities.name}
                          </option>
                        ))
                    : ""}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column gap-5">
        <div className="media-scroller pb-5">
          <div className="d-flex align-items-center gap-3">
            {!isFetching 
              ? articles.length
                ? articles.slice(0, 10).sort((a, b) => b.views - a.views).map((article, index) => <CardArticleImage key={index} data={article} width={`250px`} /> ) 
                : <NoData />
              : ''
            }
          </div>
        </div>
        
        <div className="d-flex justify-content-between align-items-center gap-2 " style={{ borderBottom: "1px grey solid" }}>
          <div className="d-flex scroll gap-4">
            {categoryName.map((categoryName, index) => (
              <LinkCategory key={index} item={categoryName} activeCategory={activeCategory} setActiveCategory={setActiveCategory} onClick={() => addCategory(categoryName)} />
            ))}
          </div>

          <SortStyled className="dropdown">
            <select
              className="border-none w-100"
              onChange={function (event) {
                navigate("/articles?orderBy=" + event.target.value);
                window.location.reload(false);
              }}
              value={orderBy}
            >
              {["Newest", "Popular", "Best"].map((order, index) => (
                <option key={index} value={order}>
                  {order}
                </option>
              ))}
            </select>
          </SortStyled>
        </div>

        <div className="row g-4 justify-content-between">
          <div className="col-sm-12 col-md-7">
            <div className="flex flex-column gap-4">
              {!dataSearch.length && !dataSearch.includes(undefined) ? (
                <>
                  {!isFetching 
                    ? articles.length 
                      ? articles.slice(pageVisited, pageVisited + usersPerPage).map((article, index) => <CardArticleHorizontal key={article.id} article={article} type="article-list" />) 
                      : <NoData />
                    : <div className='d-flex justify-content-center'><LoadingScreen /></div>
                  }
                </>
              ) : (
                <>
                  {!isFetching 
                    ? articles.length 
                      ? articles.filter(filter).map((article, index) => <CardArticleHorizontal key={article.id} article={article} type="article-list" />) 
                      : <NoData />
                    : <div className='d-flex justify-content-center'><LoadingScreen /></div>}
                </>
              )}
            </div>

            {!dataSearch.length && (
              <Paginate changePage={changePage} pageCount={pageCount} />
            )}
          </div>
          <div className="col-md-4 mt-0">
            <RecommendedTopics />
            <RecommendedArticles />
            <RecentlySaved />
            <FavoriteArticles />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
